<template>
  <section>
    <div style="width:600px">
      <el-form ref="baseform" :model="baseform" size="small" label-width="100px" label-position="right">
        <el-row>
          <el-col :span="12">
            <el-form-item label="绑定楼栋">
              <el-input v-model="baseform.bdld" size="small" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发证时间">
              <el-date-picker
              style="width: 200px"
                size="small"
                v-model="baseform.fzsj"
                align="right"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="预售证图片">
              <el-upload
                class="avatar-uploader"
                action="https://ddfun.baifun.cn/oss/uploadFileReturnUrl"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="certificatePicUrl" :src="certificatePicUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="预售证号">
              <el-input v-model="baseform.yszh" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="证件楼栋信息">
              <el-input v-model="baseform.certificateMsg" placeholder="百度采集"></el-input>
            </el-form-item>
          </el-col> -->
        </el-row>

        <el-row>
          <el-col :span="24" style="vertical-align: middle;margin: 8px 0px;">
            <el-button v-if="editor"  @click="addProSaleInfo" type="primary" style="margin-left: 60px;padding-left:30px;padding-right:30px;" >
              新增
            </el-button>
            <el-button v-if="!editor"  @click="editor=true" style="margin-left: 60px;padding-left:30px;padding-right:30px;" >
              取消
            </el-button>
            <el-button v-if="!editor"  @click="confirmChange" type="primary" style="margin-left: 60px;padding-left:30px;padding-right:30px;" >
              确定
            </el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-table
      :data="proSaleInfo"
      border
      style="width: 100%;"
      :cell-style="{padding:'5px 0px'}"
      :header-cell-style="headcell"
      >
      <el-table-column
        prop="lpysid"
        label="预售id"
        width="200" align="center" v-if="false">
      </el-table-column>
      <el-table-column
        prop="bdld"
        label="绑定楼栋"
        width="200" align="center">
      </el-table-column>
      <el-table-column
        prop="yszh"
        label="预售证号"
        width="200" align="center">
      </el-table-column>
      <el-table-column
        prop="fzsj"
        label="发证时间"
        width="200" align="center">
      </el-table-column>
      <el-table-column
        label="操作" align="center">
        <template slot-scope="scope">
          <!-- <el-button @click="changeProSaleInfo(scope.row)" type="text" size="small">修改</el-button> -->
          <el-button type="text" size="small"  @click="delProSaleInfo(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </section>
</template>
<script>
  export default {
    props:['lpid', 'proSaleInfo'],
    data() {
      return {
        baseform: {},
        editor:true,
        certificatePicUrl: ''
      }
    },
    created(){
      this.certificatePicUrl = this.proSaleInfo.certificatePicUrl
      // this.getProSaleInfo()
    },
    methods: {
      // 预售证图片
    handleAvatarSuccess(res, file) {
      this.certificatePicUrl = file.response.filepath
      this.baseform.certificatePicUrl = file.response.filepath
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传附件只能是 JPG或PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
      // 新增预售信息
      async addProSaleInfo(){
        this.baseform.lpid = this.lpid
        const { data: res } = await this.$http.post('/prosale/ins', this.baseform)
        this.baseform = {}
        this.$emit('getprosaleinfo')
        // this.getProSaleInfo()
      },
      // 修改预售信息
      async changeProSaleInfo(info){
        this.editor = false
        this.baseform.lpysid = info.lpysid
        this.baseform.bdld = info.bdld
        this.baseform.fzsj = info.fzsj
        this.baseform.yszh = info.yszh
        this.baseform.certificatePicUrl = info.certificatePicUrl
      },
      // 确定修改
      // async confirmChange(){
      //   const { data: res } = await this.$http.put('/prosale/upl', this.baseform)
      //   if(res.code == "200"){
      //     this.$message.success("修改成功")
      //     // this.getProSaleInfo()
      //   }else{
      //     this.$message.error("修改失败")
      //   }
      // },
      // 删除预售信息
      async delProSaleInfo(info){
        const { data: res } = await this.$http.delete('prosale/del?lpysid=' + info.lpysid)
        if(res.code == "200"){
          this.$message.success("删除成功")
          this.$emit('getprosaleinfo')
          // this.getProSaleInfo()
        }else{
          this.$message.error("删除失败")
        }
      },
      headcell(){
          return 'text-align: center;background:#eef1f6;'
      }
    }
  }
</script>
<style scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px dashed #d9d9d9 !important;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-form-item {
  margin-bottom: 10px;
}
</style>
