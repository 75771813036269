<template>
  <section>
    <el-row>
      <el-col :span="18">
        <el-form ref="formT" :model="formT" label-width="100px" label-position="right">

          <el-row>
            <el-col :span="12">
              <el-form-item label="时间">
                <el-date-picker
                  v-model="formT.timeStr"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="百度采集,可空"  style="width: 150px;" >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="展示在前台的时间" style="width: 300px;" >
              <el-input v-model="formT.timeFront"  style="width: 150px;" placeholder="百度采集,2020年6月"></el-input>
              </el-form-item>

            </el-col>

          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="建筑描述">
                <el-input v-model="formT.buildDescribes"  style="width: 150px;" placeholder="百度采集"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="时间描述">
                <el-input v-model="formT.timeDescribe"  style="width: 150px;" placeholder="百度采集"></el-input>
              </el-form-item>
            </el-col>
          </el-row>




          <el-row>

            <el-col :span="12">
              <el-form-item label="时间类型"  style="color: blue" >
                <el-select clearable  v-model="formT.timeType" placeholder="请选择"    style="width: 150px;">
                  <el-option label="开盘" value="1"></el-option>
                  <el-option label="交房" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">

            </el-col>
          </el-row>

          <el-row>

            <el-col :span="24">
              <el-form-item>
                <el-button  @click="addHandle"  type="primary" style="margin-left: 240px;padding-left:30px;padding-right:30px;" >
                  新增
                </el-button>
                <!--
                <el-button v-if="formT.point" @click="addHandle"  type="primary" style="margin-left: 240px;padding-left:30px;padding-right:30px;" >
                  修改
                </el-button>-->
              </el-form-item>
            </el-col>
          </el-row>


        </el-form>
      </el-col>


      <el-col :span="24">
        <el-table
          :data="pointdatatime"
          border
          style="width: 100%;"
          :cell-style="{padding:'5px 0px'}"
          :header-cell-style="headcell"
        >
          <!--<el-table-column-->
          <!--prop="totalPrice"-->
          <!--label="楼盘总价"align="center">-->
          <!--</el-table-column>-->
          <el-table-column
            prop="buildDescribes"
            label="建筑描述" align="center">
          </el-table-column>

          <!--<el-table-column-->
          <!--prop="periodTime"-->
          <!--label="期数"align="center">-->
          <!--</el-table-column>-->

          <el-table-column
            prop="timeDescribe"
            label="时间描述" align="center">
          </el-table-column>

          <el-table-column
            prop="timeStr"
            label="时间" align="center">
          </el-table-column>

          <el-table-column
            prop="timeFront"
            label="展示在前台的时间" align="center">
          </el-table-column>

          <el-table-column
            prop="timeType"
            label="类型1开盘，2交房" align="center">
          </el-table-column>

          <el-table-column
            label="操作" align="center">
            <template slot-scope="scope">
              <el-button @click="deletClick(scope.row)" type="text" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>


  </section>
</template>
<script>
  export default {
    name: 'myOpentime',
    props:{
      formT:{
        type: Object,
        default: {}
      },
      formid: {
        type: String,
        default: ''
      },
      pointdatatime:{
        type:Array,
        default: []
      }
    },
    data() {
      return {
        pointdatatime_son:this.pointdatatime
      }
    },
    mounted() {
      this.queryLpPrice();
    },
    methods: {
      search(){
        this.pointdatatime_son=[];
        this.$emit('gesearch')
      },
      addHandle(){
        let this_ = this
        let params = this_.$api.getParam('bd6',{"lpid":this_.formid,"buildDescribes":this_.formT.buildDescribes,
          "timeDescribe":this_.formT.timeDescribe,"timeStr":this_.formT.timeStr,
          "timeFront":this_.formT.timeFront,"timeType":this_.formT.timeType})
        this_.$api.postData(this_, params,'/openapi/service',1).then((res) => {
          if(res.code == "0"){
            this_.$message("新增成功")
            this_.search();
            //this.queryLpPrice();
          }
        })
      },
      headcell(){
        return 'text-align: center;background:#eef1f6;'
      },
      turnOther(){
        window.open('http://api.map.baidu.com/lbsapi/getpoint/index.html', '_blank')
      },
      queryLpPrice(){
        let this_ = this
        let params = this_.$api.getParam('bd11',{"lpid":this_.formid})
        this_.$api.postData(this_, params,'/openapi/service',1).then((res) => {
          if(res.code == "0"){
            this.pointdatatime_son=[];
            this.pointdatatime_son=res.data;
          }
        })
      },
      deletClick(data){
        let this_ = this
        let params = this_.$api.getParam('bd12',{"optimeid":data.optimeid})
        this_.$api.postData(this_, params,'/openapi/service',1).then((res) => {
          if(res.code == "0"){
            this_.search();
          }
        })
      }
    }
  }
</script>
