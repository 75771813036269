<template>
  <div>
    <el-card>
      <!--导航栏-->
      <el-breadcrumb
        separatorClass="el-icon-arrow-right"
        style="font-size: 14px"
      >
        <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>新房管理</el-breadcrumb-item>
        <el-breadcrumb-item>楼盘区域维护</el-breadcrumb-item>
      </el-breadcrumb>

      <!--卡片区域-->
      <el-tabs
        type="border-card"
        style="margin-top: 20px; line-height: 10px;"
        @tab-click="
          ;(queryInfo.qybm = null), (queryInfo.ctid = null), getAreaList()
        "
      >
        <!--城市维护区域-->
        <el-tab-pane label="城市维护">
          <el-row :gutter="30" style="margin:30px 0">
            <!--搜索区域-->
            <!--搜索添加-->
            <el-col :span="25" style="font-size:16px">
              中国区域划分:
              <el-select
                clearable
                v-model="queryInfo.qybm"
                placeholder="请选择"
              >
                <el-option
                  v-for="items in areaList"
                  :value="items.qybm"
                  :label="items.qyname"
                  :key="items.qyname"
                >
                </el-option>
              </el-select>
              <el-button
                type="primary"
                icon="el-icon-search"
                style="width: 100px;margin-left: 80px"
                @click="getUrbanList"
                >搜索</el-button
              >
            </el-col>
            <!--添加按钮-->
            <el-col :span="2">
              <el-button
                type="primary"
                icon="el-icon-circle-plus"
                style="width:100px"
                @click="caddEquipment()"
                 v-if="zhtype == 1"
                >添加</el-button
              >
            </el-col>
          </el-row>
          <!--城市表格内容-->
          <template>
            <el-table
              :data="urbanList"
              style="width:100%;margin-top: 30px;font-size:18px"
            >
              <!--区域信息-->
              <el-table-column
                prop="qyname"
                label="区域"
                width="150"
                align="center"
              ></el-table-column>
              <!--城市信息-->
              <el-table-column
                prop="ctname"
                label="城市"
                width="150"
                align="center"
              ></el-table-column>
              <!--操作-->
              <el-table-column label="操作" width="300"
                align="center" v-if="zhtype == 1">
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    icon="el-icon-edit"
                    @click="getByCtnameAll(scope.row)"
                    >修改</el-button
                  >
                  <el-button
                    type="success"
                    icon="el-icon-share"
                    @click="release"
                    >发布数据</el-button
                  >
                  <!-- <el-button type="primary" @click="clickLs(scope.row)" v-if="!islsz && scope.row.ctname != '深圳'" >标为临深</el-button>
                                    <el-button type="warning" @click="clickLs(scope.row)" v-if="islsz && scope.row.ctname != '深圳'  && scope.row.ctid == itemid">取消标为临深</el-button>
                                    <el-button type="info" v-if="scope.row.ctname == '深圳'">无法标记</el-button> -->
                </template>
              </el-table-column>
            </el-table>
          </template>
          <!--分页-->
          <div>
            <el-pagination
              @size-change="chandleSizeChange"
              @current-change="chandleCurrentChange"
              :current-page="queryInfo.pageNum"
              :page-sizes="[5, 10, 20, 30, 50]"
              :page-size="queryInfo.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="ctotal"
            >
            </el-pagination>
          </div>

          <!--新增或维护城市信息-->
          <el-dialog
            :title="ctitleMap[cdialogStatus]"
            width="35%"
            :visible.sync="cDialogFormVisible"
            @close="caddDialogClosed"
          >
            <el-form
              label-width="70px"
              :model="caddForm"
              ref="caddFormRef"
              :rules="caddFormRules"
            >
              <!--中国区域划分-->
              <el-form-item
                label="中国区域划分"
                label-width="110px"
                prop="qybm"
              >
                <el-select
                  clearable
                  v-model="caddForm.qybm"
                  placeholder="请选择区域"
                  style="width: 100%"
                >
                  <el-option
                    v-for="items in areaList"
                    :value="items.qybm"
                    :key="items.qybm"
                    :label="items.qyname"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <!--城市-->
              <el-form-item label="城市" prop="ctname">
                <el-input
                  v-model="caddForm.ctname"
                  placeholder="请输入城市名"
                ></el-input>
              </el-form-item>
            </el-form>
            <!--内容底部区域-->
            <span slot="footer" class="dialog-footer">
              <el-button @click="cDialogFormVisible = false">取消</el-button>
              <el-button type="primary" @click="getUrbanAll">确认</el-button>
            </span>
          </el-dialog>
        </el-tab-pane>
        <!--辖区维护区域-->
        <el-tab-pane label="辖区维护">
          <!--搜索添加区域-->
          <el-row :gutter="30" style="margin: 30px 0">
            <!--搜索区域-->
            <!--搜索添加-->
            <el-col :span="25" style="font-size:14px">
              中国区域划分:
              <el-select
                clearable
                v-model="xaddForm.qybm"
                placeholder="请选择"
                @change="Selarea(xaddForm.qybm)"
              >
                <el-option
                  v-for="items in areaList"
                  :value="items.qybm"
                  :key="items.qybm"
                  :label="items.qyname"
                ></el-option>
              </el-select>
              城市:
              <el-select
                clearable
                v-model="xaddForm.ctid"
                placeholder="请选择城市"
                style="line-height: 20px"
                @change="queryInfo.pageNum = 1"
              >
                <el-option
                  v-for="items in cityList"
                  :value="items.ctid"
                  :key="items.ctid"
                  :label="items.ctname"
                ></el-option>
              </el-select>
              <el-button
                type="primary"
                icon="el-icon-search"
                style="width: 100px;margin-left: 80px"
                @click="getAreaList"
                >搜索</el-button
              >
            </el-col>
            <!--添加按钮-->
            <el-col :span="2">
              <el-button
                type="primary"
                icon="el-icon-circle-plus"
                style="width:100px"
                @click="xaddEquipment"
                >添加</el-button
              >
            </el-col>
          </el-row>

          <!--辖区表格内容-->
          <template>
            <el-table
              :data="ashList"
              style="width:100%;margin-top:30px;font-size:18px"
            >
              <!--区域信息-->
              <el-table-column
                prop="qyname"
                label="区域"
                width="150"
                align="center"
              ></el-table-column>
              <!--城市信息-->
              <el-table-column
                prop="ctname"
                label="城市"
                width="100"
                align="center"
              ></el-table-column>
              <!--辖区信息-->
              <el-table-column
                prop="ctqyname"
                label="辖区"
                width="100"
                align="center"
              ></el-table-column>
              <!--是否为热门-->
              <el-table-column label="是否为热门" width="100"
                align="center">
                <template slot-scope="scoped">
                  <span v-if="scoped.row.bz == '1'">是</span>
                  <span v-else>否</span>
                </template>
              </el-table-column>
              <!--操作-->
              <el-table-column label="操作" width="600"
                align="center">
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    class="el-icon-edit"
                    @click="xeditEquipment(scope.row)"
                    >修改</el-button
                  >
                  <el-button
                    type="danger"
                    class="el-icon-delete"
                    @click="delCity(scope.row.ctqyname)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>

          <div>
            <el-pagination
              @size-change="xhandleSizeChange"
              @current-change="xhandleCurrentChange"
              :current-page="queryInfo.pageNum"
              :page-sizes="[5, 10, 20, 30, 50]"
              :page-size="queryInfo.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="xtotal"
            >
            </el-pagination>
          </div>

          <!--新增辖区维护信息-->
          <el-dialog
            :title="xtitleMap[xdialogStatus]"
            width="40%"
            :visible.sync="xaddDialogVisiable"
            @close="
              xaddDialogVisiable = false
              xaddForm.qybm = null
              xaddForm.ctid = null
              xaddForm.ctqybm = null
            "
          >
            <el-form
              :inline="true"
              :model="xaddForm"
              ref="xaddFormRef"
              :rules="xaddFormRules"
            >
              <el-form-item label="中国划分区域" prop="qybm">
                <el-select
                  clearable
                  v-model="xaddForm.qybm"
                  placeholder="请选择"
                  @change="Selarea(xaddForm.qybm)"
                >
                  <el-option
                    v-for="items in areaList"
                    :value="items.qybm"
                    :key="items.qybm"
                    :label="items.qyname"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="城市" prop="ctid">
                <el-select
                  clearable
                  v-model="xaddForm.ctid"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="items in cityList"
                    :value="items.ctid"
                    :key="items.ctname"
                    :label="items.ctname"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="辖区"
                style="margin-right:14%;"
                prop="ctqyname"
              >
                <el-input v-model="xaddForm.ctqyname"></el-input>
              </el-form-item>
              <el-form-item label="是否为热门" style="margin-right:5%">
                <el-switch
                  v-model="xaddForm.bz"
                  :active-value="1"
                  :inactive-value="0"
                >
                </el-switch>
              </el-form-item>
            </el-form>
            <!--内容底部区域-->
            <span slot="footer" class="dialog-footer">
              <el-button
                @click="
                  xaddDialogVisiable = false
                  xaddForm.qybm = null
                  xaddForm.ctid = null
                  xaddForm.ctqybm = null
                "
                >取消</el-button
              >
              <el-button type="primary" @click="getAreaAll">确认</el-button>
            </span>
          </el-dialog>
        </el-tab-pane>

        <!--街道点信息-->
        <el-tab-pane label="街道点维护">
          <el-row :gutter="30" style="font-size:14px;margin:30px 0">
            <!--搜索区域-->
            <!--搜索添加-->
            <el-col :span="25">
              中国区域划分:<el-select
                clearable
                v-model="jaddForm.qybm"
                placeholder="请选择"
                style="margin-left: 20px;line-height: 32px"
                @change="Selarea(jaddForm.qybm)"
              >
                <el-option
                  v-for="items in areaList"
                  :value="items.qybm"
                  :key="items.qybm"
                  :label="items.qyname"
                ></el-option>
              </el-select>
              城市:<el-select
                clearable
                v-model="jaddForm.ctid"
                placeholder="请选择"
                style="margin-left: 20px;line-height: 32px"
                @change="Selcity(jaddForm.ctid)"
              >
                <el-option
                  v-for="items in cityList"
                  :value="items.ctid"
                  :key="items.ctid"
                  :label="items.ctname"
                ></el-option>
              </el-select>
              辖区:<el-select
                clearable
                v-model="jaddForm.ctqybm"
                placeholder="请选择"
                style="margin-left:20px;line-height: 32px"
              >
                <el-option
                  v-for="items in adsList"
                  :value="items.ctqybm"
                  :key="items.ctqybm"
                  :label="items.ctqyname"
                >
                </el-option>
              </el-select>
              <el-button
                type="primary"
                icon="el-icon-search"
                style="width: 100px;margin-left: 80px"
                @click="getStreetList"
                >搜索</el-button
              >
            </el-col>
            <!--添加按钮-->
            <el-col :span="2">
              <el-button
                type="primary"
                icon="el-icon-circle-plus"
                style="width:100px"
                @click="jaddEquipment()"
                >添加</el-button
              >
            </el-col>
          </el-row>

          <!--街道表格内容-->
          <template>
            <el-table
              :data="addressList"
              style="width:100%;margin-top:30px;font-size:18px"
            >
              <!--街道编号-->
              <el-table-column
                prop="lmqybm"
                label="编号"
                width="50"
                align="center"
                v-if="false"
              ></el-table-column>
              <!--区域信息-->
              <el-table-column
                prop="qyname"
                label="区域"
                width="100"
                align="center"
              ></el-table-column>
              <!--城市信息-->
              <el-table-column
                prop="ctname"
                label="城市"
                width="100"
                align="center"
              ></el-table-column>
              <!--辖区信息-->
              <el-table-column
                prop="ctqyname"
                label="辖区"
                align="center"
                width="100"
              ></el-table-column>
              <!--街道信息-->
              <el-table-column
                prop="lmqyname"
                label="街道"
                align="center"
                width="200"
              ></el-table-column>
              <el-table-column label="是否为热门" width="100"
                align="center">
                <template slot-scope="scoped">
                  <span v-if="scoped.row.rmlmqy == '1'">是</span>
                  <span v-else>否</span>
                </template>
              </el-table-column>
              <!--操作-->
              <el-table-column label="操作" width="600"
                align="center">
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    class="el-icon-edit"
                    @click="jeditEquipment(scope.row)"
                    >修改</el-button
                  >
                  <el-button
                    type="danger"
                    class="el-icon-delete"
                    @click="delads(scope.row.lmqybm)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>

          <div>
            <el-pagination
              @size-change="ahandleSizeChange"
              @current-change="ahandleCurrentChange"
              :current-page="queryInfo.pageNum"
              :page-sizes="[5, 10, 20, 30, 50]"
              :page-size="queryInfo.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="atotal"
            >
            </el-pagination>
          </div>

          <!--新增街道维护信息-->
          <el-dialog
            width="40%"
            :title="jtitleMap[jdialogStatus]"
            :visible.sync="jaddDialogVisiable"
            @close="
              jaddDialogVisiable = false
              jaddForm.qybm = null
              jaddForm.ctid = null
              jaddForm.ctqybm = null
            "
          >
            <el-form
              :inline="true"
              :model="jaddForm"
              ref="aaddFormRef"
              :rules="jaddFormRules"
            >
              <el-form-item label="中国划分区域" prop="qybm">
                <el-select
                  clearable
                  v-model="jaddForm.qybm"
                  placeholder="请选择"
                  @change="Selarea(jaddForm.qybm)"
                >
                  <el-option
                    v-for="items in areaList"
                    :value="items.qybm"
                    :key="items.qybm"
                    :label="items.qyname"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="城市" prop="ctid">
                <el-select
                  clearable
                  v-model="jaddForm.ctid"
                  placeholder="请选择"
                  @change="Selcity(jaddForm.ctid)"
                >
                  <el-option
                    v-for="items in cityList"
                    :value="items.ctid"
                    :key="items.ctid"
                    :label="items.ctname"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="辖区" prop="ctqybm">
                <el-select
                  clearable
                  v-model="jaddForm.ctqybm"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="items in adsList"
                    :value="items.ctqybm"
                    :key="items.ctqybm"
                    :label="items.ctqyname"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="街道"
                style="margin-left:7%;"
                prop="lmqyname"
              >
                <el-input v-model="jaddForm.lmqyname"></el-input>
              </el-form-item>
              <el-form-item label="是否为热门" style="margin-right:45%">
                <el-switch
                  v-model="jaddForm.bz"
                  :active-value="1"
                  :inactive-value="0"
                >
                </el-switch>
              </el-form-item>
              <el-form-item class="footer-float">
                <el-button @click="jaddDialogVisiable = false">取消</el-button>
                <el-button type="primary" @click="getAddressList"
                  >确认</el-button
                >
              </el-form-item>
            </el-form>
          </el-dialog>
        </el-tab-pane>
        <!--商圈信息-->
        <el-tab-pane label="商圈维护">
          <el-row :gutter="30" style="font-size:14px;margin:30px 0">
            <!--搜索区域-->
            <!--搜索添加-->
            <el-col :span="25">
              中国区域划分:<el-select
                clearable
                v-model="saddForm.qybm"
                placeholder="请选择"
                style="margin-left: 20px;line-height: 32px"
                @change="Selarea(saddForm.qybm)"
              >
                <el-option
                  v-for="items in areaList"
                  :value="items.qybm"
                  :key="items.qybm"
                  :label="items.qyname"
                ></el-option>
              </el-select>
              城市:<el-select
                clearable
                v-model="saddForm.ctid"
                placeholder="请选择"
                style="margin-left: 20px;line-height: 32px"
                @change="Selcity(saddForm.ctid)"
              >
                <el-option
                  v-for="items in cityList"
                  :value="items.ctid"
                  :key="items.ctid"
                  :label="items.ctname"
                ></el-option>
              </el-select>
              辖区:<el-select
                clearable
                v-model="saddForm.ctqybm"
                placeholder="请选择"
                style="margin-left:20px;line-height: 32px"
              >
                <el-option
                  v-for="items in adsList"
                  :value="items.ctqybm"
                  :key="items.ctqybm"
                  :label="items.ctqyname"
                >
                </el-option>
              </el-select>
              <el-button
                type="primary"
                icon="el-icon-search"
                style="width: 100px;margin-left: 80px"
                @click="getShangQuanList"
                >搜索</el-button
              >
            </el-col>
            <!--添加按钮-->
            <el-col :span="2">
              <el-button
                type="primary"
                icon="el-icon-circle-plus"
                style="width:100px"
                @click="saddEquipment()"
                >添加</el-button
              >
            </el-col>
          </el-row>

          <!--商圈表格内容-->
          <template>
            <el-table
              :data="sqList"
              style="width:100%;margin-top:30px;font-size:18px"
            >
              <!--街道编号-->
              <el-table-column
                prop="tradingAreaId"
                label="编号"
                width="50"
                v-if="false"
              ></el-table-column>
              <!--区域信息-->
              <el-table-column
                prop="qyname"
                label="区域"
                width="100"
                align="center"
              ></el-table-column>
              <!--城市信息-->
              <el-table-column
                prop="ctname"
                label="城市"
                width="100"
                align="center"
              ></el-table-column>
              <!--辖区信息-->
              <el-table-column
                prop="ctqyname"
                label="辖区"
                width="100"
                align="center"
              ></el-table-column>
              <!--街道信息-->
              <el-table-column
                prop="sqname"
                label="街道"
                width="200"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="point"
                label="经纬度"
                width="200"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="tc"
                label="层级"
                width="100"
                align="center"
              ></el-table-column>
              <!-- <el-table-column  label="是否为热门" width="100">
                                <template slot-scope="scoped">
                                    <span v-if="scoped.row.rmlmqy=='1'">是</span>
                                    <span v-else>否</span>
                                </template>
                            </el-table-column> -->
              <!--操作-->
              <el-table-column label="操作" width="600"
                align="center">
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    class="el-icon-edit"
                    @click="seditEquipment(scope.row)"
                    >修改</el-button
                  >
                  <el-button
                    type="danger"
                    class="el-icon-delete"
                    @click="delsq(scope.row.lmqybm)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>

          <div>
            <el-pagination
              @size-change="shandleSizeChange"
              @current-change="shandleCurrentChange"
              :current-page="queryInfo.pageNum"
              :page-sizes="[5, 10, 20, 30, 50]"
              :page-size="queryInfo.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="sqtotal"
            >
            </el-pagination>
          </div>

          <!--新增商圈维护信息-->
          <el-dialog
            class="sq-dialog"
            width="40%"
            :title="stitleMap[sdialogStatus]"
            :visible.sync="saddDialogVisiable"
            @close="
              saddDialogVisiable = false
              saddForm.qybm = null
              saddForm.ctid = null
              saddForm.ctqybm = null
            "
          >
            <el-form
              :inline="true"
              :model="saddForm"
              ref="saddFormRef"
              :rules="saddFormRules"
            >
              <el-col :span="12">
                <div class="sq-info">
                  <el-row>
                    <el-form-item label="中国划分区域" prop="qybm">
                      <el-select
                        clearable
                        v-model="saddForm.qybm"
                        placeholder="请选择"
                        @change="Selarea(saddForm.qybm)"
                      >
                        <el-option
                          v-for="items in areaList"
                          :value="items.qybm"
                          :key="items.qybm"
                          :label="items.qyname"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item label="城市" prop="ctid">
                      <el-select
                        clearable
                        v-model="saddForm.ctid"
                        placeholder="请选择"
                        @change="Selcity(saddForm.ctid)"
                      >
                        <el-option
                          v-for="items in cityList"
                          :value="items.ctid"
                          :key="items.ctid"
                          :label="items.ctname"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item label="辖区" prop="ctqybm">
                      <el-select
                        clearable
                        v-model="saddForm.ctqybm"
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="items in adsList"
                          :value="items.ctqybm"
                          :key="items.ctqybm"
                          :label="items.ctqyname"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-row>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="sq-info">
                  <el-row>
                    <el-form-item
                      label="商圈"
                      style="margin-left:7%;"
                      prop="sqname"
                    >
                      <el-input v-model="saddForm.sqname"></el-input>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item
                      label="经纬度"
                      style="margin-left:7%;"
                      prop="sqname"
                    >
                      <el-input v-model="saddForm.point"></el-input>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item
                      label="层级"
                      style="margin-left:7%;"
                      prop="sqname"
                    >
                      <el-input
                        v-model="saddForm.tc"
                        placeholder="12-15"
                      ></el-input>
                    </el-form-item>
                  </el-row>
                </div>
              </el-col>
              <!-- <el-row>
                                <el-form-item label="中国划分区域" prop="qybm">
                                    <el-select clearable  v-model="saddForm.qybm" placeholder="请选择" @change="Selarea(saddForm.qybm)">
                                    <el-option v-for="items in areaList" :value="items.qybm" :key="items.qybm" :label="items.qyname"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="城市" prop="ctid">
                                    <el-select clearable  v-model="saddForm.ctid" placeholder="请选择" @change="Selcity(saddForm.ctid)">
                                    <el-option v-for="items in cityList" :value="items.ctid" :key="items.ctid" :label="items.ctname"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-row>
                            <el-row>
                                <el-form-item label="辖区" prop="ctqybm">
                                    <el-select clearable  v-model="saddForm.ctqybm" placeholder="请选择">
                                    <el-option v-for="items in adsList" :value="items.ctqybm" :key="items.ctqybm" :label="items.ctqyname"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="商圈" style="margin-left:7%;" prop="sqname">
                                    <el-input v-model="saddForm.sqname"></el-input>
                                </el-form-item>
                            </el-row>
                            <el-row>
                                <el-form-item label="经纬度" style="margin-left:7%;" prop="sqname">
                                    <el-input v-model="saddForm.sqname"></el-input>
                                </el-form-item>
                                <el-form-item label="层级" style="margin-left:7%;" prop="sqname">
                                    <el-input v-model="saddForm.sqname" placeholder="12-15"></el-input>
                                </el-form-item>
                            </el-row> -->
              <!-- <el-form-item class="footer-float"> -->
              <!-- <span> -->
              <!-- </span> -->
              <!-- </el-form-item> -->
            </el-form>
            <el-button @click="saddDialogVisiable = false">取消</el-button>
            <el-button type="primary" @click="getSqList">确认</el-button>
          </el-dialog>
        </el-tab-pane>
        <!--临深城市信息-->
        <el-tab-pane label="临深城市维护">
          <el-row :gutter="30" style="margin:30px 0">
            <!--添加按钮-->
            <el-col :span="2">
              <el-button
                type="primary"
                icon="el-icon-circle-plus"
                style="width:100px"
                @click="lsaddEquipment()"
                >添加</el-button
              >
            </el-col>
          </el-row>
          <!--临深城市表格内容-->
          <template>
            <el-table
              :data="lsList"
              style="width:100%;margin-top: 30px;font-size:18px"
            >
              <el-table-column
                prop="sid"
                label="临深城市id"
                width="150"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="scrname"
                label="城市"
                width="100"
                align="center"
              ></el-table-column>
              <el-table-column label="操作" width="300"
                align="center" v-if="zhtype == 1">
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    icon="el-icon-edit"
                    @click="editLs(scope.row)"
                    >修改</el-button
                  >
                  <el-button
                    type="danger"
                    icon="el-icon-edit"
                    @click="deleLs(scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>

          <!--新增或维护临深城市信息-->
          <el-dialog
            :title="lstitleMap[lsdialogStatus]"
            width="15%"
            :visible.sync="lsDialogFormVisible"
          >
            <el-form label-width="70px" :model="lsaddForm" ref="caddFormRef">
              <!--城市-->
              <el-form-item label="城市" prop="scrname">
                <el-input
                  v-model="lsaddForm.scrname"
                  placeholder="请输入城市名"
                ></el-input>
              </el-form-item>
            </el-form>
            <!--内容底部区域-->
            <span slot="footer" class="dialog-footer">
              <el-button @click="lsDialogFormVisible = false">取消</el-button>
              <el-button type="primary" @click="LsConfirm">确认</el-button>
            </span>
          </el-dialog>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>
<script>
export default {
  created() {
    this.getUrbanList()
    this.SelQycodeAll()
    this.getAreaList()
    this.getStreetList()
    this.getShangQuanList()
    this.getLsAll()
    this.zhtype = localStorage.getItem('zhtype')
  },
  data() {
    return {
      zhtype: '',
      //查询信息实体
      queryInfo: {
        qybm: null, //区域编码
        pageNum: 1, //当前页
        pageSize: 5, //每页最大数
      },
      urbanList: [], //城市维护数据集
      ctotal: 0, //城市分页原始数
      ashList: [], //辖区维护数据集
      xtotal: 0, //辖区分页原始数
      addressList: [], //街道维护数据集
      atotal: 0, //街道分页原始数
      cDialogFormVisible: false, //城市添加对话框状态
      xaddDialogVisiable: false, //辖区添加对话框状态
      jaddDialogVisiable: false, //街道添加对话框状态
      saddDialogVisiable: false, //商圈添加对话框状态
      lsDialogFormVisible: false, //商圈添加对话框状态
      //城市添加信息
      caddForm: {
        ctid: '',
        qybm: '',
        ctname: '',
      },
      lsaddForm: {
        ctid: '',
        ctname: '',
        sid: '',
        scrname: '',
      },
      caddFormRules: {
        ctname: [
          { required: true, message: '请输入城市信息', trigger: 'blur' },
          { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' },
        ],
        qybm: [
          { required: true, message: '请选择区域信息', trigger: 'blur' },
          { min: 2, max: 5, message: '长度在 2 到 5个字符', trigger: 'blur' },
        ],
      },
      xaddFormRules: {
        ctid: [{ required: true, message: '请输入城市信息', trigger: 'blur' }],
        qybm: [
          { required: true, message: '请选择区域信息', trigger: 'blur' },
          { min: 2, max: 5, message: '长度在 2 到 5个字符', trigger: 'blur' },
        ],
        ctqyname: [
          { required: true, message: '请输入辖区信息', trigger: 'blur' },
        ],
      },
      jaddFormRules: {
        ctid: [{ required: true, message: '请输入城市信息', trigger: 'blur' }],
        qybm: [
          { required: true, message: '请选择区域信息', trigger: 'blur' },
          { min: 2, max: 5, message: '长度在 2 到 5个字符', trigger: 'blur' },
        ],
        ctqyname: [
          { required: true, message: '请输入辖区信息', trigger: 'blur' },
        ],
        lmqybm: [
          { required: true, message: '请输入街道信息', trigger: 'blur' },
        ],
      },
      saddFormRules: {
        ctid: [{ required: true, message: '请输入城市信息', trigger: 'blur' }],
        qybm: [
          { required: true, message: '请选择区域信息', trigger: 'blur' },
          { min: 2, max: 5, message: '长度在 2 到 5个字符', trigger: 'blur' },
        ],
        ctqyname: [
          { required: true, message: '请输入辖区信息', trigger: 'blur' },
        ],
        lmqybm: [
          { required: true, message: '请输入商圈信息', trigger: 'blur' },
        ],
      },
      //新增or编辑弹框标签(根据点击新增or编辑按钮显示不同的标题)
      ctitleMap: {
        caddEquipment: '新增城市信息',
        ceditEquipment: '修改城市信息',
      },
      //新增or编辑弹框标签(根据点击新增or编辑按钮显示不同的标题)
      xtitleMap: {
        xaddEquipment: '新增辖区信息',
        xeditEquipment: '修改辖区信息',
      },
      //新增or编辑弹框标签(根据点击新增or编辑按钮显示不同的标题)
      jtitleMap: {
        jaddEquipment: '新增街道信息',
        jeditEquipment: '修改街道信息',
      },
      //新增or编辑弹框标签(根据点击新增or编辑按钮显示不同的标题)
      stitleMap: {
        saddEquipment: '新增商圈信息',
        seditEquipment: '修改商圈信息',
      },
      lstitleMap: {
        lsaddEquipment: '新增临深城市信息',
        lseditEquipment: '修改临深城市信息',
      },
      //新增和编辑城市信息弹框标题
      cdialogStatus: '',
      //新增和编辑城市辖区信息弹框标题
      xdialogStatus: '',
      //新增和编辑城市辖区街道信息弹框标题
      jdialogStatus: '',
      //新增和编辑城市辖区商圈信息弹框标题
      sdialogStatus: '',
      //新增和编辑临深城市信息弹框标题
      lsdialogStatus: '',
      areaList: [], //下拉框---区域完整数据
      cityList: [], //下拉框---城市完整信息
      adsList: [], //下拉框---辖区完整信息
      areaByc: [], //根据城市名称查询城市信息
      sqList: [], // 商圈列表
      sqtotal: 0,
      lsList: [], // 临深城市列表
      // 辖区信息
      xaddForm: {
        qybm: '',
        ctid: null,
        ctqyname: '',
        ctqybm: '',
        bz: 0,
      },
      // 街道点信息
      jaddForm: {
        qybm: null,
        ctid: null,
        ctqyname: null,
        ctqybm: null,
        lmqyname: null,
        bz: 0,
        lmqybm: null,
      },
      // 商圈信息
      saddForm: {
        qybm: null,
        ctid: null,
        ctqyname: null,
        ctqybm: null,
        sqname: null,
        point: null,
        tc: null,
        // lmqyname: null,
        // lmqybm: null
      },
    }
  },
  methods: {
    // 发布数据
    release() {
      this.$http.put('area/status')
    },
    //查询所有区域信息
    async SelQycodeAll() {
      const { data: res } = await this.$http.get(
        'area/qycode' + '?query=' + this.queryInfo.query
      )
      this.areaList = res.data
    },
    //筛选区域之后根据对应区域查询对应城市信息
    async Selarea(qybm) {
      this.xaddForm.ctid = null
      this.xaddForm.ctqyname = null
      this.jaddForm.ctid = null
      this.jaddForm.ctqybm = null
      const { data: res } = await this.$http.get('city/sel?qybm=' + qybm)
      this.cityList = res.data
    },
    //查询街道维护里的下拉框信息
    async Selcity(ctid) {
      const { data: res } = await this.$http.get('ads/sel', {
        params: { ctid: ctid },
      })
      this.adsList = res.data
    },
    //查询所有区域下城市信息
    async getUrbanList() {
      const { data: res } = await this.$http.get('area/urban', {
        params: this.queryInfo,
      })
      this.urbanList = res.data.records
      this.ctotal = res.data.total
    },
    //分页最大数
    chandleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getUrbanList()
    },
    //分页当前页数pageNum
    chandleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getUrbanList()
    },
    //城市form框监听
    caddDialogClosed() {
      this.$refs.caddFormRef.resetFields()
    },
    //新增城市
    caddEquipment() {
      //显示弹框
      this.cDialogFormVisible = true
      //新增弹框标题
      this.cdialogStatus = 'caddEquipment'
    },
    //修改城市
    ceditEquipment() {
      //显示弹框
      this.cDialogFormVisible = true
      //新增弹框标题
      this.cdialogStatus = 'ceditEquipment'
    },
    //添加所有城市信息
    getUrbanAll() {
      this.$refs.caddFormRef.validate(async (valid) => {
        if (!valid) return
        if (this.cdialogStatus === 'caddEquipment') {
          const { data: res } = await this.$http.post(
            'area/urbanall',
            this.caddForm
          )
          if (res.code != 200) {
            return this.$message.error('操作失败！！！')
          }
        }
        if (this.cdialogStatus === 'ceditEquipment') {
          //调用编辑接口  在这里把你的编辑接口加上
          const { data: res } = await this.$http.put('area/upl/', this.caddForm)
          if (res.code != 200) {
            return this.$message.error('操作失败')
          }
        }
        this.$message.success('操作成功！！！')
        this.cDialogFormVisible = false
        this.getUrbanList()
      })
    },
    //根据Id查询城市信息
    async getByCtnameAll(ctinfo) {
      this.cDialogFormVisible = true
      this.cdialogStatus = 'ceditEquipment'
      this.caddForm.ctname = ctinfo.ctname
      this.caddForm.qybm = ctinfo.qybm
      this.caddForm.ctid = this.urbanList.filter(
        (u) => u.ctname == this.caddForm.ctname
      )[0]['ctid']
    },

    //---------------------------------------------------------------------------------

    //查询所有辖区维护信息
    async getAreaList() {
      const { data: res } = await this.$http.get('city/cql', {
        params: {
          pageNum: this.queryInfo.pageNum,
          pageSize: this.queryInfo.pageSize,
          ctid: this.xaddForm.ctid,
        },
      })
      this.ashList = res.data.records
      this.xtotal = res.data.total
    },
    //新增城市辖区
    xaddEquipment() {
      //显示弹框
      this.xaddDialogVisiable = true
      //新增弹框标题
      this.xdialogStatus = 'xaddEquipment'
      this.xaddForm.qybm = null
      this.xaddForm.ctid = null
      this.xaddForm.ctqyname = null
      this.xaddForm.bz = null
    },
    //修改城市辖区
    xeditEquipment(info) {
      //显示弹框
      this.xaddDialogVisiable = true
      //新增弹框标题
      this.Selarea(info.qybm)
      this.xdialogStatus = 'xeditEquipment'
      this.xaddForm.qybm = info.qybm
      this.xaddForm.ctid = info.ctid
      this.xaddForm.ctqyname = info.ctqyname
      this.xaddForm.bz = info.bz
      this.xaddForm.ctqybm = info.ctqybm
    },
    //最大数
    xhandleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getAreaList()
    },
    //pageNum的触发
    xhandleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getAreaList()
    },

    //根据Id删除辖区信息
    async delCity(ctqyname) {
      const confirmResult = await this.$confirm(
        '删除后用户信息将永久删除，是否继续？',
        '提示',
        {
          confirmButtonText: '删除',
          cancelButtonText: '放弃',
        }
      ).catch((err) => err)
      if (confirmResult != 'confirm') {
        //取消
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(
        '/city/del?ctqyname=' + ctqyname
      )
      if (res.code != 200) {
        return this.$message.error('删除失败!')
      }
      this.$message.success('删除成功!')
      this.getAreaList()
    },
    //添加所有辖区信息
    getAreaAll() {
      this.$refs.xaddFormRef.validate(async (valid) => {
        if (!valid) return
        if (this.xdialogStatus === 'xaddEquipment') {
          const { data: res } = await this.$http.post('city/ins', this.xaddForm)
          if (res.code != 200) {
            return this.$message.error('操作失败！！！')
          }
          this.$message.success('操作成功！！！')
          this.xaddDialogVisiable = false
        }
        if (this.xdialogStatus === 'xeditEquipment') {
          const { data: res } = await this.$http.put('city/upl', this.xaddForm)
          if (res.code != 200) {
            return this.$message.error('操作失败！！！')
          }
          this.$message.success('操作成功！！！')
          this.xaddDialogVisiable = false
        }
        this.xaddForm.qybm = null
        this.xaddForm.ctid = null
        this.xaddForm.ctqyname = null
        this.xaddForm.bz = null
        this.queryInfo.pageNum = 1
        this.getAreaList()
      })
    },
    /**
     *
     */
    //查询所有街道信息
    async getStreetList() {
      const { data: res } = await this.$http.get('ads/list', {
        params: {
          qybm: this.jaddForm.qybm,
          ctid: this.jaddForm.ctid,
          ctqybm: this.jaddForm.ctqybm,
          pageNum: this.queryInfo.pageNum,
          pageSize: this.queryInfo.pageSize,
        },
      })
      this.addressList = res.data.records
      this.atotal = res.data.total
    },
    //最大数
    ahandleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getStreetList()
    },
    //pageNum的触发
    ahandleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getStreetList()
    },
    //修改城市辖区街道
    jeditEquipment(info) {
      //显示弹框
      this.jaddDialogVisiable = true
      //新增弹框标题
      this.Selarea(info.qybm)
      this.Selcity(info.ctid)
      this.jdialogStatus = 'jeditEquipment'
      this.jaddForm.qybm = String(info.qybm)
      this.jaddForm.ctid = info.ctid
      this.jaddForm.ctqyname = info.ctqyname
      this.jaddForm.bz = info.rmlmqy
      this.jaddForm.ctqybm = info.ctqybm
      this.jaddForm.lmqyname = info.lmqyname
    },
    //根据Id删除街道信息
    async delads(lmqybm) {
      const confirmResult = await this.$confirm(
        '删除后用户信息将永久删除，是否继续？',
        '提示',
        {
          confirmButtonText: '删除',
          cancelButtonText: '放弃',
        }
      ).catch((err) => err)
      if (confirmResult != 'confirm') {
        //取消
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete('/ads/del?lmqybm=' + lmqybm)
      if (res.code != 200) {
        return this.$message.error('删除失败!')
      }
      this.$message.success('删除成功!')
      this.getStreetList()
    },
    // 街道添加监听添加
    jaddDialogClosed() {
      this.$refs.jaddFormRef.resetFields()
    },

    //添加街道信息
    jaddEquipment() {
      //显示弹框
      this.jaddDialogVisiable = true
      //新增弹框标题
      this.jdialogStatus = 'jaddEquipment'
      this.jaddForm.qybm = null
      this.jaddForm.ctid = null
      this.jaddForm.ctqybm = null
      this.jaddForm.lmqyname = null
      this.jaddForm.bz = null
    },

    getAddressList() {
      this.$refs.aaddFormRef.validate(async (valid) => {
        if (this.jdialogStatus === 'jaddEquipment') {
          if (!valid) return
          const { data: res } = await this.$http.post('ads/ins', this.jaddForm)
          if (res.code != 200) {
            return this.$message.error('操作失败！！！')
          }
          this.$message.success('操作成功！！！')
          this.jaddDialogVisiable = false
          this.getStreetList()
        }
        if (this.jdialogStatus === 'jeditEquipment') {
          if (!valid) return
          const { data: res } = await this.$http.post('ads/upl', {
            lmqyname: this.jaddForm.lmqyname,
            ctqybm: this.jaddForm.ctqybm,
            bz: this.jaddForm.bz,
            lmqybm: this.jaddForm.lmqybm,
          })
          if (res.code != 200) {
            return this.$message.error('操作失败！！！')
          }
          this.$message.success('操作成功！！！')
          this.jaddDialogVisiable = false
          this.getStreetList()
        }
      })
    },
    //添加商圈信息
    saddEquipment() {
      //显示弹框
      this.saddDialogVisiable = true
      //新增弹框标题
      this.sdialogStatus = 'saddEquipment'
      this.saddForm.qybm = null
      this.saddForm.ctid = null
      this.saddForm.ctqybm = null
      this.saddForm.sqname = null
      this.saddForm.point = null
      this.saddForm.tc = null
    },
    // 查询商圈
    getShangQuanList() {
      this.$http
        .get('/newhome/ctqybm', {
          params: {
            ctqybm: this.saddForm.ctqybm,
            ctid: this.saddForm.ctid,
            pageSize: this.queryInfo.pageSize,
            pageNum: this.queryInfo.pageNum,
          },
        })
        .then((res) => {
          this.sqList = res.data.data.records
          this.sqtotal = res.data.data.total
        })
    },
    // 商圈确认
    getSqList() {
      this.$refs.saddFormRef.validate(async (valid) => {
        if (this.sdialogStatus === 'saddEquipment') {
          if (!valid) return
          const { data: res } = await this.$http.post(
            '/newhome/trans',
            this.saddForm
          )
          if (res.code != 200) {
            return this.$message.error('操作失败！！！')
          }
          this.$message.success('操作成功！！！')
          this.saddDialogVisiable = false
          this.saddForm.ctqybm = null
          this.getShangQuanList()
        }
      })
    },

    shandleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getShangQuanList()
    },
    shandleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getShangQuanList()
    },
    // 修改商圈
    seditEquipment() {
      this.$message.warning('后端无修改模块')
    },
    // 删除商圈
    delsq() {
      this.$message.warning('后端无删除模块')
    },
    // 临深城市维护
    lsaddEquipment() {
      this.lsDialogFormVisible = true
      //新增弹框标题
      this.lsdialogStatus = 'lsaddEquipment'
    },
    LsConfirm() {
      if (this.lsdialogStatus === 'lsaddEquipment') {
        this.$http
          .post('/screen/ins', {
            ctid: '2016',
            scrname: this.lsaddForm.scrname,
          })
          .then((res) => {
            this.$message.success('新增成功')
            this.getLsAll()
            this.lsDialogFormVisible = false
          })
          .catch((err) => {
            this.$message.error(err)
          })
      }
      if (this.lsdialogStatus === 'lseditEquipment') {
        this.$http
          .put('/screen/upl', {
            ctid: this.lsaddForm.ctid,
            sid: this.lsaddForm.sid,
            scrname: this.lsaddForm.scrname,
          })
          .then((res) => {
            this.$message.success('修改成功')
            this.getLsAll()
            this.lsDialogFormVisible = false
          })
          .catch((err) => {
            this.$message.error(err)
          })
      }
      this.lsaddForm.scrname = null
    },
    // 获取临深城市表
    getLsAll() {
      this.$http.get('/screen/list?ctid=2016').then((res) => {
        this.lsList = res.data.data
      })
    },
    // 修改临深城市
    editLs(info) {
      this.lsDialogFormVisible = true
      this.lsdialogStatus = 'lseditEquipment'
      this.lsaddForm.scrname = info.scrname
      this.lsaddForm.ctid = info.ctid
      this.lsaddForm.sid = info.sid
    },
    // 删除临深城市
    deleLs(info) {
      this.$http
        .delete('/screen/del?sid=' + info.sid)
        .then((res) => {
          this.$message.success('删除成功')
          this.getLsAll()
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
  },
}
</script>
<style lang="less" scoped>
.el-pagination {
  text-align: left;
}
.sq-dialog .el-form-item {
  width: 100%;
}
.sq-dialog ::v-deep .el-form--inline .el-form-item__label {
  width: 30%;
  text-align: right;
}
.sq-dialog ::v-deep .el-form--inline .el-form-item__content {
  width: auto;
  text-align: left;
}
</style>
