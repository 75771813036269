<template>
  <section>
    <div style="width:600px">
      <el-form
        ref="baseform"
        :model="baseform"
        size="small"
        label-width="100px"
        label-position="right"
      >
        <el-row style="margin:50px">
          <el-radio-group @input="changeRadio" v-model="radio">
            <el-radio v-model="radio" label="1">装修样板间赏析封面</el-radio>
            <el-radio v-model="radio" label="2">户型概况信息</el-radio>
          </el-radio-group>
        </el-row>
        <!-- 户型概况 -->
        <el-row v-if="radio == 2">
          <el-col :span="12">
            <el-form-item label="户型封面">
              <el-upload
                class="avatar-uploader"
                action="https://ddfun.baifun.cn/oss/uploadFileReturnUrl"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="picUrl" :src="picUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="户型名称">
              <el-input
                v-model="baseform.modelname"
                size="small"
                placeholder="请输入名称"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 赏析图 -->
        <el-row v-else>
          <el-form-item label="">
            <el-col :span="24" style="margin-bottom: 20px;">
              <upload-img
                ref="uploadImg"
                @addvp="uploadClick2"
                @delImg="delImg2"
                :limit="5"
              ></upload-img>
            </el-col>
          </el-form-item>
        </el-row>
        <el-row>
          <el-col :span="24" style="vertical-align: middle;margin: 8px 0px;">
            <el-button
               
              @click="addInfo(radio)"
              type="primary"
              style="margin-left: 60px;padding-left:30px;padding-right:30px;"
            >
              新增
            </el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- 概况信息表格 -->
    <el-table
      :data="overviewList.records"
      border
      style="width: 100%;"
      :cell-style="{ padding: '5px 0px' }"
      :header-cell-style="headcell"
      v-if="radio == 2"
    >
      <el-table-column
        prop="modelname"
        label="户型名称"
        width="300"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="modelcover"
        label="图片路径"
        width="600"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="modelcover" label="户型封面" align="center">
        <template slot-scope="scope">
          <img
            :src="scope.row.modelcover"
            alt=""
            srcset=""
            style="width: 100px;height: 50px"
          />
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button @click="changeInfo(scope.row)" type="text" size="small"
            >修改</el-button
          >
          <el-button type="text" size="small" @click="delInfo2(scope.row.id)"
            >删除</el-button
          >
          <el-button
            type="primary"
            plain
            style="margin: 10px 50px"
            @click="editPhoto(scope.row)"
            >编辑户型相册</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 赏析图表格 -->
    <el-table
      :data="overviewList.records"
      border
      style="width: 100%;"
      :cell-style="{ padding: '5px 0px' }"
      :header-cell-style="headcell"
      v-else
    >
    <el-table-column
        prop="id"
        label=""
        width="300"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="modelcover"
        label="图片路径"
        width="600"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="modelcover" label="样板图" align="center">
        <template slot-scope="scope">
          <img
            :src="scope.row.modelcover"
            alt=""
            srcset=""
            style="width: 100px;height: 50px"
          />
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="delInfo(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 修改弹窗 -->
    <el-dialog
      width="50%"
      title="修改户型概况"
      :visible.sync="showEdit"
      @close="cancel"
      :before-close="handleClose"
      append-to-body
    >
      <el-form
        :inline="true"
        :model="editInfo"
        ref="aaddFormRef"
        label-width="150px"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="户型名称" prop="modelname">
              <el-input
                v-model="editInfo.modelname"
                placeholder="请输入名称"
                style="width:200px"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="户型封面">
          <el-upload
            class="avatar-uploader"
            action="https://ddfun.baifun.cn/oss/uploadFileReturnUrl"
            :show-file-list="false"
            :on-success="handleAvatarSuccess2"
            :before-upload="beforeAvatarUpload2"
          >
            <img
              v-if="editInfo.modelcover"
              :src="editInfo.modelcover"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="handelConfirm">确认</el-button>
      </span>
    </el-dialog>
    <!-- 编辑相册弹窗 -->
    <el-dialog
      width="80%"
      title="编辑户型相册"
      :visible.sync="showPhoto"
      @close="cancel2"
      :before-close="handleClose2"
      append-to-body
    >
      <section>
        <el-row>
          <el-col :span="8">
            <el-form
              ref="form"
              :model="form"
              label-width="100px"
              label-position="right"
            >
              <el-row>
                <el-col :span="12">
                  <el-form-item label="图片类型">
                    <el-select
                      clearable
                      v-model="form.phototype"
                      placeholder="请选择图片类型"
                       
                      style="width: 200px;"
                      :value="12"
                    >
                      <el-option
                        v-for="item in imgTypeList"
                        :label="item.aaa103"
                        :value="item.aaa101"
                        :key="item.aaa101"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-form-item>
                <el-button
                  @click="addHandle"
                   
                  type="primary"
                  style=""
                >
                  新增
                </el-button>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="8" style="margin-bottom: 20px;">
            <upload-img
              ref="uploadImg"
              @addvp="uploadClick"
              @delImg="delImg"
              v-if="form.phototype"
            ></upload-img>
          </el-col>
        </el-row>
        <el-table
          :data="imgList"
          border
          style="width: 100%;"
          :cell-style="{ padding: '5px 0px' }"
          :header-cell-style="headcell"
        >
          <el-table-column
            prop="phototypename"
            label="类型"
            width="200"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="modelphoto"
            label="相册路径"
            align="center"
            width="600"
          >
          </el-table-column>
          <el-table-column prop="modelphoto" label="图片展示" align="center">
            <template slot-scope="scope">
              <img
                :src="scope.row.modelphoto"
                alt=""
                srcset=""
                style="width: 80px;height: 40px"
              />
            </template>
          </el-table-column>
          <el-table-column prop="address" label="操作" align="center">
            <template slot-scope="scope">
              <el-button @click="deletClick(scope.row)" type="text" size="small"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </section>
    </el-dialog>
    <!--分页-->
    <div v-if="radio == 2">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="overviewList.pages"
        :page-sizes="[5, 10, 20, 30, 50]"
        :page-size="overviewList.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="overviewList.total"
      >
      </el-pagination>
    </div>
  </section>
</template>
<script>
import uploadImg from '@/components/upload/uploadImg.vue'
export default {
  components: {
    uploadImg,
  },
  props: ['lpid', 'overviewList'],
  data() {
    return {
      baseform: {},
      picUrl: '',
      dialogStatus: '', //新增弹窗状态
      showEdit: false,
      showPhoto: false,
      // 编辑户型概况信息
      editInfo: {
        id: null,
        modelname: null,
        modelcover: null,
      },
      // 编辑相册弹窗表单
      form: {
        phototype: '1',
        modelphoto: '',
      },
      inputFrom: [],
      inputFrom2: [],
      imgTypeList: [], // 下拉类型列表
      imgList: [], // 图片列表
      overviewId: '', // 概况id
      radio: '1',
    }
  },
  created() {
    this.getImgType()
  },
  methods: {
    // 封面
    handleAvatarSuccess(res, file) {
      this.picUrl = file.response.filepath
      this.baseform.modelcover = file.response.filepath
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error('上传附件只能是 JPG或PNG 格式!')
      }
      if (!isLt5M) {
        this.$message.error('上传附件大小不能超过 5M!')
      }
      return isJPG && isLt5M
    },
    // 修改封面
    handleAvatarSuccess2(res, file) {
      this.editInfo.modelcover = file.response.filepath
    },
    beforeAvatarUpload2(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error('上传附件只能是 JPG或PNG 格式!')
      }
      if (!isLt5M) {
        this.$message.error('上传附件大小不能超过 5M!')
      }
      return isJPG && isLt5M
    },
    changeRadio(e) {
      if(e == 1) {
        this.$emit('getOverviewList')
      } else {
         this.getOverviewList()
      }
    },
    //查询户型概况
    async getOverviewList() {
      const { data: res } = await this.$http.get('modelinfo/getmodelinfo', {
        params: {
          lpid: this.lpid,
          pageNum: 1,
          pageSize: 6,
          covertype: 2
        }
      })
      this.overviewList = res.data
    },
    // 新增概况、五张图
    async addInfo(num) {
      // 新增五张图
      if (num == 1) {
        await this.$http
        .post('/modelphoto/insmodelcover', this.inputFrom2)
        .then((res) => {
          if (res.data.code == '200') {
            this.$message.success('新增成功')
            this.$emit('getOverviewList')
            this.$refs.uploadImg.clear()
          } else {
            this.$message.error('新增失败')
          }
        })
      this.inputFrom2 = []
      } else {
        // 新增户型概况
        this.baseform.covertype = num
        this.baseform.lpid = this.lpid
        const { data: res } = await this.$http.post(
          '/modelinfo/insmodelinfo',
          this.baseform
        )
        this.baseform = {}
        this.picUrl = ''
        this.getOverviewList()
        if (res.code == 200) {
          return this.$message.success('新增成功')
        }
      }
    },
    // 修改户型概况
    async changeInfo(info) {
      this.showEdit = true
      this.editInfo = {
        id: info.id,
        modelname: info.modelname,
        modelcover: info.modelcover,
        covertype: 2
      }
    },
    // 确认修改
    async handelConfirm() {
      this.editInfo.lpid = this.lpid
      const { data: res } = await this.$http.put(
        '/modelinfo/uplmodelinfo',
        this.editInfo
      )
      this.editInfo = {}
      this.showEdit = false
      this.getOverviewList()
      if (res.code == 200) {
        return this.$message.success('修改成功')
      }
    },
    // 删除——赏析图
    async delInfo(id) {
      const confirmResult = await this.$confirm(
        '删除后用户信息将永久删除，是否继续？',
        '提示',
        {
          confirmButtonText: '删除',
          cancelButtonText: '放弃',
        }
      ).catch((err) => err)
      if (confirmResult != 'confirm') {
        //取消
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(
        '/modelinfo/delmodelinfo?id=' + id
      )
      if (res.code != 200) {
        return this.$message.error('删除失败!')
      }
      this.$message.success('删除成功!')
      this.$emit('getOverviewList')
    },
    // 删除——户型概况
    async delInfo2(id) {
      const confirmResult = await this.$confirm(
        '删除后用户信息将永久删除，是否继续？',
        '提示',
        {
          confirmButtonText: '删除',
          cancelButtonText: '放弃',
        }
      ).catch((err) => err)
      if (confirmResult != 'confirm') {
        //取消
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(
        '/modelinfo/delmodelinfo?id=' + id
      )
      if (res.code != 200) {
        return this.$message.error('删除失败!')
      }
      this.$message.success('删除成功!')
      this.getOverviewList()
    },
    // 点击编辑户型相册按钮
    editPhoto(info) {
      this.overviewId = info.id
      this.showPhoto = true
      this.getPhotoInfo()
    },
    // 获取概况详情
    async getPhotoInfo() {
      await this.$http
        .get('/modelinfo/getmodelphoto', {
          params: {
            modelid: this.overviewId,
            pageNum: 1,
            pageSize: 100,
          },
        })
        .then((res) => {
          this.imgList = res.data.data.records
        })
    },
    // 获取相册维护下拉类型列表
    async getImgType() {
      await this.$http.get('/dic/aaa100?aaa100=MODETYPE').then((res) => {
        this.imgTypeList = res.data.data
      })
    },
    // 点击上传按钮---编辑相册
    uploadClick(val) {
      let f = {}
      f.phototype = this.form.phototype
      f.modelphoto = val
      f.modelid = this.overviewId
      this.inputFrom.push(f)
    },
    // 删除图片---编辑相册
    delImg(val) {
      this.inputFrom = this.inputFrom.filter(
        (item) => item.modelphoto != val.filepath
      )
    },
    // 点击上传按钮---五张赏析图
    uploadClick2(val) {
      let f = {}
      f.modelcover = val
      f.covertype = '1'
      f.lpid = this.lpid
      this.inputFrom2.push(f)
    },
    // 删除图片---五张赏析图
    delImg2(val) {
      this.inputFrom2 = this.inputFrom2.filter(
        (item) => item.modelcover != val.filepath
      )
    },
    // 确认新增图片
    async addHandle() {
      await this.$http
        .post('modelinfo/insmodelphoto', this.inputFrom)
        .then((res) => {
          if (res.data.code == '200') {
            this.$message.success('新增成功')
            this.$refs.uploadImg.clear()
          } else {
            this.$message.error('新增失败')
          }
        })
      this.inputFrom = []
      this.getPhotoInfo()
    },
    // 删除图片
    async deletClick(info) {
      await this.$http
        .delete('modelinfo/delmodelphoto?id=' + info.id)
        .then((res) => {
          if (res.data.code == '200') {
            this.$message.success('删除成功')
            this.getPhotoInfo()
          } else {
            this.$message.error('删除失败')
          }
        })
    },
    // 修改弹窗
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => {})
    },
    handleClose2(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => {})
    },
    // 取消
    cancel() {
      this.showEdit = false
      this.editInfo = {
        id: null,
        modelname: null,
        modelcover: null,
      }
    },
    cancel2() {
      this.showPhoto = false
    },
    headcell() {
      return 'text-align: center;background:#eef1f6;'
    },
  },
}
</script>
<style scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px dashed #d9d9d9 !important;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-form-item {
  margin-bottom: 10px;
}
</style>
