<template>
    <div>
        <el-card>
            <!--导航栏-->
            <el-breadcrumb separatorClass="el-icon-arrow-right" style="font-size: 16px">
                <el-breadcrumb-item :to="{path:'/welcome'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item >二手房管理</el-breadcrumb-item>
                <el-breadcrumb-item >二手房维护</el-breadcrumb-item>
            </el-breadcrumb>



            <!--搜索添加区域-->
            <el-row :gutter="25" style="font-size: 16px">
                <!--搜索区域-->
                <!--搜索添加-->
                <el-col :span="25">
                    中国区域划分:<el-select clearable  v-model="queryInfo.qybm"  placeholder="请选择" style="margin-left: 20px;line-height: 32px"
                    @change="Selarea(queryInfo.qybm)">
                    <el-option v-for="items in areaList" :value="items.qybm" :key="items.qyname" :label="items.qyname">
                    </el-option>
                </el-select>
                    城市:<el-select clearable  v-model="queryInfo.ctid"  placeholder="请选择" style="margin-left: 20px;line-height: 32px" @change="queryInfo.pageNum=1">
                    <el-option v-for="items in ctList" :value="items.ctid" :key="items.ctname" :label="items.ctname">
                    </el-option>
                </el-select>
                    <el-button type="primary" icon="el-icon-search" style="width: 100px;margin-left: 80px" @click="getSecondList">搜索</el-button>
                </el-col>
                <!--添加按钮-->
                <el-col :span="2">
                    <el-button type="primary" icon="el-icon-circle-plus" style="width:100px" @click="addEquipment()">添加</el-button>
                </el-col>
            </el-row>

            <!--主体内容区域-->
            <template>
                <el-table :data="secondList" style="width: 100%;line-height: 32px" :default-sort = "{prop: 'date', order: 'descending'}">
                    <el-table-column prop="esfid" label="编号" sortable v-if="false"></el-table-column>
                    <el-table-column prop="ctname" label="城市" sortable width="180"></el-table-column>
                    <el-table-column prop="xqname" label="小区名称" sortable width="180"></el-table-column>
                    <el-table-column prop="fwdj" label="单价" sortable width="180"></el-table-column>
                    <el-table-column prop="wylx" label="物业类型" sortable width="180"></el-table-column>
                    <el-table-column prop="zxzk" label="装修状态" width="180" sortable></el-table-column>
                    <el-table-column prop="esfbq" label="楼盘标签" width="140" sortable></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                        <el-button type="primary" class="el-icon-edit" @click="editSec(scope.row)">修改</el-button>
                        <el-button type="danger" class="el-icon-delete" @click="delSecond(scope.row.esfid)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </template>

            <!--新增或维护二手房信息-->
            <el-dialog
                :title="titleMap[dialogStatus]"
                :visible.sync="DialogFormVisible"
                :close-on-click-modal="false"
                width="90%"
                top="20px"
                :modal="true"
                :lock-scroll="true"
                class="dialogHeader"
                center>
                <el-tabs type="border-card" v-model="activeName">
                    <el-tab-pane label="楼盘基本信息" name="first">
                    <my-base :baseform="infoform" :areaList="areaList"  :isEdit="isEdit" @getsecondlist="getSecondList" @next="nextHander"></my-base>
                    </el-tab-pane>
                    <el-tab-pane label="相册视频维护" :disabled="cantab" name="fifth">
                    <my-video :formid="String(infoform.esfid)" :xc="xc" @getimg="getImg"></my-video>
                    </el-tab-pane>
                    <el-tab-pane label="地图坐标维护" :disabled="cantab" name="sixth">
                    <my-map :formid="String(infoform.esfid)" :zb="zb" @getzb="getZb"></my-map>
                    </el-tab-pane>
                    <el-tab-pane label="经纪人信息" :disabled="cantab" name="seventh">
                    <my-person :formid="String(infoform.esfid)" @getper="getPer" :personinfo="personInfo"></my-person>
                    </el-tab-pane>
                    <el-tab-pane label="交通信息" :disabled="cantab" name="eighth">
                    <my-way :formid="String(infoform.esfid)" :jt="jt" @getjt="getJt"></my-way>
                    </el-tab-pane>
                </el-tabs>
            </el-dialog>

            <!--分页显示-->
            <div>
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryInfo.pageNum"
                    :page-sizes="[5, 10, 20, 30, 50]"
                    :page-size="queryInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                    >
                </el-pagination>
            </div>

        </el-card>
    </div>
</template>
<script>
  import myBase from "@/components/oldhouse/mybase.vue";
  import myVideo from "@/components/oldhouse/myVideo.vue";
  import myMap from "@/components/oldhouse/myMap.vue";
  import myPerson from "@/components/oldhouse/myPerson.vue";
  import myWay from "@/components/oldhouse/myWay.vue";
    export default {
         components:{
            myBase,
            myVideo,
            myMap,
            myPerson,
            myWay,
        },
        created(){
            this.getSecondList();
            this.getsQycode();
            this.getCtcode();
        },
        data() {
            return {
                activeName: "first",
                // infoform: {},
                infoform: {
                    esfid: 0,
                    ctid: null,
                    ctname: null,
                    qybm: null,
                    ctqybm: null,
                    lmqybm: null,
                    xqname: null,
                    szwz: null,
                    lc: null,
                    jzmj: null,
                    yzxt: null,
                    hxmd: null,
                    ckyg: null,
                    cksf: null,
                    hx: null,
                    fwcx: null,
                    sfdt: null,
                    zxzk: null,
                    fwnx: null,
                    jcnd: null,
                    fwdj: null,
                    fwzj: null,
                    wylx: null,
                    isdt: null,
                    isshool: null,
                    wyf: null,
                    esfbq: null
                },
                //查询信息实体
                queryInfo: {
                    ctid: null,
                    qybm: null, //区域编码
                    pageNum: 1, //当前页
                    pageSize: 5, //每页最大数
                },
                secondList: [],//二手房数据集
                total:0,//二手房总记录数
                areaList: [], //区域信息
                ctList: [], //城市信息
                titleMap:{
                    addEquipment:'新增二手房基本信息',
                    editEquipment: "修改二手房信息"
                },
                dialogStatus:"",//新增和修改弹窗标识
                DialogFormVisible:false,
                cantab: true,
                personInfo: [], // 经纪人信息
                isEdit: false,
                zb: [], // 坐标
                jt: [], // 交通
                xc: [], // 相册视频
            }
        },
        methods: {
            // 编辑
            async editSec(info){
                this.cantab = false
                this.isEdit = true
                this.Selarea(info.qybm)
                if(info.ctqybm==0){
                    info.ctqybm = String(info.ctqybm)
                    info.ctqybm = null
                }
                if(info.lmqybm==0){
                    info.lmqybm = String(info.lmqybm)
                    info.lmqybm = null
                }
                this.infoform = info
                this.infoform.esfid = info.esfid
                this.dialogStatus = 'editEquipment'
                this.DialogFormVisible = true
                this.getZb()
                this.getJt()
                this.getImg()
                this.getPer()
                
            },
            // 查询坐标
            async getZb(){
            const { data: res } = await this.$http.get('sep/list?esfid=' + this.infoform.esfid)
            this.zb = res.data
            },
            // 查交通
            async getJt(){
            const { data: res } = await this.$http.get('/setr/list', {
                    params: {
                        esfid: this.infoform.esfid
                    }
                })
                this.jt = res.data
            },
            // 查视频相册
            async getImg(){
            await this.$http.get('secp/list?esfid=' + this.infoform.esfid)
            .then(res => {this.xc = res.data.data})
            },
    
            // 查询经纪人信息
            async getPer(){
                // form.esfid = this.formid
                const { data: res } = await this.$http.get('bos/list' + '?esfid=' + this.infoform.esfid)
                this.personInfo = res.data
            },
            async nextHander(value){
                this.cantab=false
                this.infoform.esfid = value
                this.activeName = "fifth"
                this.getSecondList()
                // this.getPer()
            },
            async Selarea(qybm) {
                const { data: res } = await this.$http.get('city/sel' + '?qybm=' + qybm)
                this.ctList = res.data
                this.queryInfo.ctname = null
            },
            //区域搜索栏
            async getsQycode(){
                const {data:res} =await this.$http.get("area/qycode"+"?query="+this.queryInfo.query);
                this.areaList=res.data;
            },
            //城市搜索栏
            async getCtcode(){
                const {data:res} = await this.$http.get("city/sel"+"?query="+this.queryInfo.qyname);
                this.cityList=res.data;
            },
            //排序显示
            formatter(row, column) {
                return row.address;
            },
            //显示所有二手房信息
            async getSecondList(){
                const {data:res} = await this.$http.get("/second/list",{params:this.queryInfo});
                this.secondList=res.data.records;
                this.total=res.data.total;
            },
            //最大数
            handleSizeChange(newSize){
                this.queryInfo.pageSize = newSize;
                this.getSecondList();
            },
            //pageNum的触发
            handleCurrentChange(newPage){
                this.queryInfo.pageNum = newPage;
                this.getSecondList();
            },
            //根据Id删除相关楼盘拉页信息
            async delSecond(esfid){
                const confirmResult = await this.$confirm('删除后用户信息将永久删除，是否继续？','提示',{
                    confirmButtonText: '删除',
                    cancelButtonText: '放弃'
                }).catch(err =>err)
                if(confirmResult!='confirm'){//取消
                    return this.$message.info("已取消删除");
                }
                const {data:res}=await this.$http.delete('/second/del?esfid='+esfid);
                if(res.code!=200){
                    return this.$message.error("删除失败!");
                }
                this.$message.success("删除成功!");
                this.getSecondList();
            },
            //新增二手房基本信息
            addEquipment(){
                this.DialogFormVisible = true;//显示弹窗
                //新增弹窗标题
                this.dialogStatus="addEquipment";
            },
        }
    }
</script>
<style lang="less" scoped>

</style>