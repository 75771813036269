<template>
  <section>
    <el-row>
      <el-col :span="18">
        <el-form
          ref="form"
          :model="form"
          label-width="100px"
          label-position="right"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="相片描述">
                <el-input
                  type="textarea"
                  v-model="form.mpms"
                  :rows="4"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="相片类型">
                <el-select clearable 
                  v-model="form.xctype"
                  placeholder="请选择相片类型"
                  @change="changevideo"
                  
                  style="width: 200px;"
                >
                  <el-option label="楼盘图片" value="1"></el-option>
                  <el-option label="视频" value="2"></el-option>
                  <el-option label="VR" value="3"></el-option>
                  <el-option label="沙盘图" value="4"></el-option>
                  <el-option label="样板间" value="5"></el-option>
                  <el-option label="位置图" value="6"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item>
            <el-button
              v-if="!form.xcid"
              @click="addHandle"
              
              type="primary"
              style="margin-left: 240px;padding-left:30px;padding-right:30px;"
            >
              新增图片/视频
            </el-button>
            <!-- <el-button
              v-if="!form.xcid&&ischange==true"
              @click="addHandle"
              
              type="primary"
              style="margin-left: 240px;padding-left:30px;padding-right:30px;"
            >
              确认修改
            </el-button> -->
            <el-button
              v-if="!form.xcid"
              @click="form = {}"
              
              style="margin-left: 80px;padding-left:30px;padding-right:30px;"
            >
              取消
            </el-button>
            <!-- <el-button
              v-if="!form.xcid"
              @click="addHandle"
              
              type="primary"
              style="margin-left: 240px;padding-left:30px;padding-right:30px;"
            >
              确定
            </el-button> -->
          </el-form-item>
        </el-form>
        <el-row>
          <el-table
      :data="xc"
      border
      style="width: 100%;"
      :cell-style="{ padding: '5px 0px' }"
      :header-cell-style="headcell"
    >
      <el-table-column prop="fid" label="相册id" align="center" v-if="false"></el-table-column>
      <el-table-column prop="mpms" label="相册描述" align="center">
      </el-table-column>
      <el-table-column prop="xctype" label="类型" width="200" align="center">
      </el-table-column>
      <el-table-column prop="cxfmtpurl" label="相册路径" width="600" align="center">
      </el-table-column>

      <el-table-column prop="address" label="操作" align="center">
        <template slot-scope="scope">
          <!-- <el-button @click="handleClick(scope.row)" type="text" size="small"
            >修改</el-button
          > -->
          <el-button @click="deletClick(scope.row)" type="text" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
        </el-row>
      </el-col>
      <el-col :span="4" style="margin-bottom: 20px; margin-left: 5px">
        <upload-img @addvp="addVP" :isvideo="isvideo" v-if="form.xctype"></upload-img>
        <!-- <div v-if="!isImg" style="width: 100px;"> -->
          <!-- <upload-video @urlFunction="urlFunction"></upload-video> -->
        <!-- </div> -->
      </el-col>
    </el-row>
    
  </section>
</template>
<script>
import UploadImg from '@/components/upload/uploadImg.vue'
// import UploadVideo from '@/components/upload/UploadVideo.vue'
export default {
  components: {
    UploadImg,
    // UploadVideo,
  },
  name: 'VideoPict',
  props: ["formid", "xc"],
  data() {
    return {
      form: {},
      myurl: '',
      isImg: true,
      // xc: [],
      isvideo: false,
      inputFrom: []
    }
  },
  created(){
  },
  methods: {
    // 子组件函数
    async addVP(url){
      let f = {}
      f.mpms = this.form.mpms
      f.xctype = this.form.xctype
      f.cxfmtpurl = url
      f.xzlid = this.formid
      this.inputFrom.push(f)
    },
    // 确定新增
    async addHandle() {
      if(!this.form.xctype){
        this.$message.error("请填写相册类型")
      }else{
          await this.$http.post('op/ins', this.inputFrom)
          //   xzlid: this.formid,
          //   mpms: this.form.mpms,
          //   xctype: this.form.xctype,
          //   cxfmtpurl: this.form.cxfmtpurl
          // })
          .then(res =>{})
          this.$emit("getimg")
        }
        this.inputFrom = []
        this.form = {}
        // this.getXzlXc()
      },
    
    // async getXzlXc(){
    //   await this.$http.get('op/list?xzlid=' + this.formid)
    //   .then(res => {this.xc = res.data.data
    //   })
  

    // },
    // 删除
    async deletClick(info){
      await this.$http.delete('op/del?fid='+info.fid)
      // this.getXzlXc()
      this.$emit("getimg")
    },
    // 修改
    // handleClick(info){
    //   this.form = info
    //   this.form.mpms = info.mpms
    //   this.form.xctype = info.xctype
    //   this.form.cxfmtpurl = info.cxfmtpurl
    //   this.ischange = true

    //   this.$http.put('op/upl', {
    //     fid: info.id,
    //     xzlid: info.xzlid,
    //     mpms: this.form.mpms,
    //     xctype: this.form.xctype,
    //     cxfmtpurl: this.form.cxfmtpurl
    //   })
    // },
    headcell() {
      return 'text-align: center;background:#eef1f6;'
    },
    changevideo() {
      if (this.form.xctype == '2') {
        this.isvideo = true
      } else {
        this.isvideo = false
      }
    },
}
}
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
