<template>
  <el-form
    :inline="true"
    :model="xzladdForm"
    ref="xzladdForm"
    :rules="rules"
    label-position="left"
    v-if="xzladdForm"
  >
    <el-row>
      <el-col :span="6">
        <el-form-item label="写字楼名称" prop="szlp">
          <el-input v-model="xzladdForm.szlp" placeholder="请输入所在楼盘">
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item
          label="中国划分区域"
          prop="qybm"
        >
          <el-select clearable 
            v-model="xzladdForm.qybm"
            placeholder="请选择"
            @change="Selarea(xzladdForm.qybm)"
          >
            <el-option
              v-for="items in areaList"
              :value="items.qybm"
              :key="items.qybm"
              :label="items.qyname"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="城市" prop="ctid">
          <el-select clearable 
            v-model="xzladdForm.ctid"
            placeholder="请选择"
            @change="Selcity(xzladdForm.ctid)"
          >
            <el-option
              v-for="items in cityList"
              :value="items.ctid"
              :key="items.ctid"
              :label="items.ctname"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="辖区" prop="ctqybm">
          <el-select clearable 
            v-model="xzladdForm.ctqybm"
            placeholder="请选择"
            @change="Selctqy(xzladdForm.ctqybm)"
          >
            <el-option
              v-for="items in adsList"
              :value="items.ctqybm"
              :key="items.ctqybm"
              :label="items.ctqyname"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="6">
        <el-form-item label="街道" prop="lmqybm">
          <el-select clearable  v-model="xzladdForm.lmqybm" placeholder="请选择">
            <el-option
              v-for="items in lmList"
              :value="items.lmqybm"
              :key="items.lmqybm"
              :label="items.lmqyname"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
            <el-form-item label="商圈" prop="tradingAreaId">
              <el-select
                clearable
                v-model="xzladdForm.tradingAreaId"
                placeholder="请选择商圈"
              >
                <el-option
                  v-for="items in sqList"
                  :value="items.tradingAreaId"
                  :key="items.tradingAreaId"
                  :label="items.sqname"
                ></el-option>
                <!-- <el-input v-model="baseform.lpname" style="width: 200px;"    placeholder="请输入商圈"></el-input> -->
              </el-select>
            </el-form-item>
          </el-col>
      <el-col :span="6">
        <el-form-item label="总价/万" prop="sj">
          <el-input v-model="xzladdForm.sj" placeholder="请输入总价">
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="均价/万" prop="dj">
          <el-input
            v-model="xzladdForm.dj"
            placeholder="请输入均/单价"
          ></el-input>
        </el-form-item>
      </el-col>
      
    </el-row>
    <el-row>
      <el-col :span="6">
        <el-form-item label="类型" prop="type">
          <el-select clearable  v-model="xzladdForm.type" placeholder="请选择类型">
            <el-option label="写字楼" value="1"></el-option>
            <el-option label="商铺" value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="物业类型" prop="wylx">
          <el-select clearable  v-model="xzladdForm.wylx" placeholder="请选择物业类型">
            <el-option
              v-for="i in allDic.data.WYLX"
              :key="i.id"
              :label="i.aaa103"
              :value="i.aaa101"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="写字楼类型" prop="xzlty">
          <el-select clearable  v-model="xzladdForm.xzlty" placeholder="请选择写字楼类型">
            <el-option
              v-for="i in allDic.data.XZLTY"
              :key="i.id"
              :label="i.aaa103"
              :value="i.aaa101"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="建筑面积/㎡" prop="jzmj">
          <el-input v-model="xzladdForm.jzmj" placeholder="请输入建筑面积">
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="6">
        <el-form-item label="业主介绍" prop="xxjs">
          <el-input
            v-model="xzladdForm.xxjs"
            placeholder="请输入业主介绍"
            type="textarea"
            :rows="4"
            style="width: 120%"
          >
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="所在楼层" prop="lc">
          <el-input v-model="xzladdForm.lc" placeholder="请输入所在楼层">
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="装修状态" prop="zxzk">
          <el-select clearable  v-model="xzladdForm.zxzk" placeholder="请选择装修状态">
            <el-option
              v-for="i in allDic.data.ZXZK"
              :key="i.id"
              :label="i.aaa103"
              :value="i.aaa101"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="物业费" prop="wyf">
          <el-input v-model="xzladdForm.wyf" placeholder="请输入物业费">
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="6">
        <el-form-item label="是否可分割" prop="sfkfg">
          <el-select clearable  v-model="xzladdForm.sfkfg" placeholder="请选择是否可分割">
            <el-option
              v-for="i in allDic.data.ISXQF"
              :key="i.id"
              :label="i.aaa103"
              :value="i.aaa101"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="是否可注册" prop="sfkzc">
          <el-select clearable  v-model="xzladdForm.sfkzc" placeholder="请选择是否可注册">
            <el-option
              v-for="i in allDic.data.SFKF"
              :key="i.id"
              :label="i.aaa103"
              :value="i.aaa101"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="所在位置" prop="dz">
          <el-input v-model="xzladdForm.dz" placeholder="请输入楼盘地址">
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="客流人群" prop="klrq">
          <el-input v-model="xzladdForm.klrq" placeholder="请输入客流人群">
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="6">
        <el-form-item label="经营状态" prop="jyzt">
          <el-select clearable  v-model="xzladdForm.jyzt" placeholder="请选择经营状态">
            <el-option
              v-for="i in allDic.data.JYZT"
              :key="i.id"
              :label="i.aaa103"
              :value="i.aaa101"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="是否有地铁" prop="isdt">
          <el-select clearable  v-model="xzladdForm.isdt" placeholder="请选择是否有地铁">
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="0"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="使用率" prop="syl">
          <el-input v-model="xzladdForm.syl" placeholder="请输入使用率">
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="写字楼标签" prop="xzlbq">
          <el-select clearable  v-model="xzladdForm.xzlbq" placeholder="请选择写字楼标签">
            <el-option label="热门写字楼" value="1"></el-option>
            <el-option label="特色写字楼" value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="6">
        <el-form-item>
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="confirmAdd">确认提交</el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
export default {
  name: 'BaseInfo',
  data() {
    return {
      qybm: "",
      rules: {
          qybm: [
            {required: true, message: "请选择辖区", trigger: 'blur'},
            {min: 0, max: 15}
          ],
          ctid: [
            { required: true, message: "请选择城市", trigger: 'blur'},
          ]
        },
      cityList: [], // 选择区域后的城市列表
      adsList: [], // 选择城市后的辖区列表
      lmList: [], // 选择辖区后的街道列表
      sqList: [], // 商圈列表
      allDic: JSON.parse(window.sessionStorage.getItem('saveDic')),
      ct: 0
    }
  },
  created(){
    console.log(this.xzladdForm,111)
  },
  mounted() {
   
  },
  props: ['areaList', 'isEdit', 'xzladdForm'],
  watch: {
    xzladdForm: {
      handler: function(){
        if(this.xzladdForm.type =='写字楼') {
          this.xzladdForm.type = "1"
        } else {
          this.xzladdForm.type = "2"
        }
        if(this.xzladdForm.xzlbq =='热门写字楼') {
          this.xzladdForm.xzlbq = "1"
        } else {
          this.xzladdForm.xzlbq = "2"
        }
        this.allDic.data.WYLX.map(item => {
          if(this.xzladdForm.wylx == item.aaa103) {
            this.xzladdForm.wylx = item.aaa101
          }
        })
        this.allDic.data.ZXZK.map(item => {
          if(this.xzladdForm.zxzk == item.aaa103) {
            this.xzladdForm.zxzk = item.aaa101
          }
        })
        this.allDic.data.XZLTY.map(item => {
          if(this.xzladdForm.xzlty == item.aaa103) {
            this.xzladdForm.xzlty = item.aaa101
          }
        })
        this.allDic.data.JYZT.map(item => {
          if(this.xzladdForm.jyzt == item.aaa103) {
            this.xzladdForm.jyzt = item.aaa101
          }
        })
        this.ct = this.xzladdForm.ctid
        this.Selarea(this.xzladdForm.qybm)
        if(this.xzladdForm.ctqybm == 0 && this.xzladdForm.lmqybm == 0){
          this.xzladdForm.ctqybm = null
          this.xzladdForm.lmqybm = null
          this.Selcity(this.xzladdForm.ctid)
          this.lmList = []
        }
        if(this.xzladdForm.ctqybm && !this.xzladdForm.lmqybm){
          this.xzladdForm.lmqybm = null
          this.Selcity(this.xzladdForm.ctid)
          this.Selctqy(this.xzladdForm.ctqybm)
        }
        if(this.xzladdForm.lmqybm){
          this.Selcity(this.xzladdForm.ctid)
          this.Selctqy(this.xzladdForm.ctqybm)
        }
      },
      immediate: true
    },
    cityList: function(){
      if(this.xzladdForm.qybm != "" && this.isEdit==true){
        this.xzladdForm.ctid = this.ct
      }
    }
  },
  methods: {
    // 取消
    cancel(){
      this.$emit('cancel')
    },
    // 选择中国区域后可选城市
    async Selarea(qybm) {
      const { data: res } = await this.$http.get('city/sel?qybm=' + qybm)
      this.cityList = res.data
    },

    // 选择城市后可选辖区
    async Selcity(ctid) {
      // this.xzladdForm.lmqybm = null
      const { data: res } = await this.$http.get('ads/sel', {
        params: { ctid: ctid },
      })
      this.adsList = res.data
    },

    // 选择辖区后可选街道
    async Selctqy(ctqybm) {
      // this.lmqybm = null
      // const {data:res} =await this.$http.get("ads/sel?ctid="+ctid);
      const { data: res } = await this.$http.get('ads/list', {
        params: {
          ctqybm: ctqybm,
          pageNum: 1,
          pageSize: 30,
        },
      })
      this.lmList = res.data.records
      await this.$http
        .get('newhome/ctqybm?pageNum=1&pageSize=50&ctqybm=' + ctqybm)
        .then((res) => {
          this.sqList = res.data.data.records
        })
    },

    // 表单确认添加或修改按键功能
    async confirmAdd() {
      if(!this.isEdit){
        const { data: res } = await this.$http.post('ofs/ins', this.xzladdForm)
        if(res.code == 200) {
          this.$message.success(res.msg)
          this.$emit('next', res.value)
        } else {
          this.$message(res.msg)
        }
      }else{
        const { data: res } = await this.$http.put('ofs/upl', this.xzladdForm)
        if(res.code == 200) {
          this.$message.success(res.msg)
          this.$emit('next1')
        } else {
          this.$message(res.msg)
        }
      }
    },
  },
}
</script>
