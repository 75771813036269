<template>
  <section>
    <el-form ref="baseform" :model="baseform" :rules="rules" label-width="120px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="中国区域划分" prop="qybm">
            <el-select clearable  v-model="baseform.qybm" style="width: 200px;"  placeholder="请选择活动区域" @change="Selarea(baseform.qybm)">
              <el-option v-for="items in areaList" :value="items.qybm" :key="items.qyname" :label="items.qyname"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="城市" prop="ctid">
            <el-select clearable  v-model="baseform.ctid" style="width: 200px;"  placeholder="请选择活动区域" @change="Selcity(baseform.ctid)">
              <el-option v-for="items in cityList" :value="items.ctid" :key="items.ctid" :label="items.ctname"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="辖区">
            <el-select clearable  v-model="baseform.ctqybm" style="width: 200px;"  placeholder="请选择活动区域" @change="Selctqy(baseform.ctqybm)">
              <el-option v-for="items in adsList" :value="items.ctqybm" :key="items.ctqybm" :label="items.ctqyname"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="道路、街道">
            <el-select clearable  v-model="baseform.lmqybm" style="width: 200px;"  placeholder="请选择活动区域">
              <el-option v-for="items in lmList" :value="items.lmqybm" :key="items.lmqybm" :label="items.lmqyname"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="小区名称">
            <el-input v-model="baseform.xqname" style="width: 200px;" ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所在位置">
            <el-input v-model="baseform.szwz" style="width: 200px;" ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="所在楼层">
            <el-input v-model="baseform.lc" style="width: 200px;" ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="建筑面积">
            <el-input v-model="baseform.jzmj" style="width: 200px;" ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="业主介绍">
            <el-input
              type="textarea"
              style="width: 200px;"
              :rows="4"
              placeholder="请输入内容"
              v-model="baseform.yzxt">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="核心卖点">
            <el-input
            type="textarea"
            style="width: 200px;"
            :rows="4"
            placeholder="请输入内容"
            v-model="baseform.hxmd">
          </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="参考月供">
            <el-input v-model="baseform.ckyg" style="width: 200px;" ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="参考首付">
            <el-input v-model="baseform.cksf" style="width: 200px;" ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="户型">
            <el-select clearable  v-model="baseform.hx" placeholder="请选择活动区域"  style="width: 200px;">
              <el-option v-for="(i,k) in allDic.HX" :label="i.aaa103" :value="i.aaa101" :key="k"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="房屋朝向">
            <el-select clearable  v-model="baseform.fwcx" placeholder="请选择活动区域"  style="width: 200px;">
              <el-option v-for="(i,k) in allDic.FWCX" :label="i.aaa103" :value="i.aaa101" :key="k"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="是否有电梯">
            <el-radio-group v-model="baseform.sfdt">
              <el-radio label="1">有</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="装修状态">
            <el-select clearable  v-model="baseform.zxzk" placeholder="请选择活动区域"  style="width: 200px;">
              <el-option v-for="(i,k) in allDic.ZXZK" :label="i.aaa103" :value="i.aaa101" :key="k"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="房屋年限">
            <el-input v-model="baseform.fwnx" style="width: 200px;" ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="建造年代">
            <el-select clearable  v-model="baseform.jcnd" placeholder="请选择活动区域"  style="width: 200px;">
              <el-option v-for="(i,k) in allDic.JCNT" :label="i.aaa103" :value="i.aaa101" :key="k"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="单价">
            <el-input v-model="baseform.fwdj" style="width: 200px;" ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="物业类型">
            <el-select clearable  v-model="baseform.wylx" placeholder="请选择活动区域"  style="width: 200px;">
              <el-option v-for="(i,k) in allDic.WYLX" :label="i.aaa103" :value="i.aaa101" :key="k"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="是否近地铁">
            <el-radio-group v-model="baseform.isdt">
              <el-radio label="1" >有</el-radio>
              <el-radio label="0" >否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="是否近学校">
            <el-radio-group v-model="baseform.isshool">
              <el-radio label="1" >有</el-radio>
              <el-radio label="0" >否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="物业费">
            <el-input v-model="baseform.wyf" style="width: 200px;" ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="总售价">
            <el-input v-model="baseform.fwzj" style="width: 200px;" ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="二手房标签">
            <el-select clearable  v-model="baseform.esfbq" placeholder="请选择活动区域"  style="width: 200px;">
              <el-option v-for="(i,k) in esfbq" :key="k" :label="i.aaa103" :value="i.aaa101"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <el-button type="primary"  style="margin-left: 320px;" @click="confirmAdd">确认新增</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>
<script>
  export default {
    name: 'mybase',
    props:['baseform','areaList', 'isEdit'],
    data() {
      return {
        cityList:[],
        adsList: [],
        lmList: [],
        allDic:JSON.parse(window.sessionStorage.getItem('saveDic')).data,
        esfbq: [
            {
                "aaa101": "1",
                "aaa103": "超人气小区"
            },
            {
                "aaa101": "2",
                "aaa103": "最舒适小区"
            },
            {
                "aaa101": "3",
                "aaa103": "交通便利"
            },
            {
                "aaa101": "4",
                "aaa103": "商业繁华"
            },
            {
                "aaa101": "5",
                "aaa103": "高端小区"
            },
            {
                "aaa101": "6",
                "aaa103": "大型小区"
            }
        ],
        rules: {
          qybm: [{required: true}],
          ctid: [{required: true}]
        }
      }
    },
    mounted(){
    },
    methods: {
      async Selarea(qybm) {
      const { data: res } = await this.$http.get('city/sel', {
        params: { qybm: qybm },
      })
      this.cityList = res.data
    },
    async Selcity(ctid){
        const {data:res} =await this.$http.get("ads/sel", {params: {ctid: ctid}});
        this.adsList = res.data;
    },
    async Selctqy(ctqybm) {
      const { data: res } = await this.$http.get('ads/list', {
        params: {
          ctqybm: ctqybm,
          pageNum: 1,
          pageSize: 30,
        },
      })
      this.lmList = res.data.records
    },
      // 确认添加
      async confirmAdd(){
        if(!this.isEdit){
          const { data: res } = await this.$http.post('second/ins', this.baseform)
          this.$emit('next', res.value)
        }else{
          const { data: res } = await this.$http.put('second/upl', this.baseform)
          this.$emit('getsecondlist', res.value)
        }
      },
      async Selarea(qybm) {
      const { data: res } = await this.$http.get('city/sel', {
        params: { qybm: qybm },
      })
      this.cityList = res.data
    },
    async Selcity(ctid){
        const {data:res} =await this.$http.get("ads/sel", {params: {ctid: ctid}});
        this.adsList = res.data;
    },
    async Selctqy(ctqybm) {
      const { data: res } = await this.$http.get('ads/list', {
        params: {
          ctqybm: ctqybm,
          pageNum: 1,
          pageSize: 30,
        },
      })
      this.lmList = res.data.records
    },
      
    }
  }
</script>
<style scoped>
.el-form-item{
  margin-bottom: 10px;
}
</style>
