<template>
  <section>
    <el-row>
      <el-col :span="18">
        <el-form
          ref="form"
          :model="form"
          label-width="100px"
          label-position="right"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="姓名">
                <el-input
                  v-model="form.figurename"
                  style="width: 200px;"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="电话">
                <el-input
                  v-model="form.fphone"
                  style="width: 200px;"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="公司">
                <el-input
                  v-model="form.background"
                  style="width: 200px;"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="职位">
                <el-select
                  clearable
                  v-model="form.fidentity"
                  placeholder="请选择职位"
                  @change="changeFidentity"
                  style="width: 200px;"
                >
                  <el-option label="销售经理" value="1"></el-option>
                  <el-option label="置业顾问" value="2"></el-option>
                </el-select>
                <!-- <el-input v-model="form.fidentity" style="width: 200px;" ></el-input> -->
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="微信头像">
                <el-upload
                  class="avatar-uploader"
                  action="https://ddfun.baifun.cn/oss/uploadFileReturnUrl"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img v-if="figurephoto" :src="figurephoto" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <!-- <upload-img @addvp="uploadClickTx"></upload-img> -->
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
              <el-form-item label="微信二维码">
                <upload-img @addvp="uploadClick"></upload-img>
              </el-form-item>
            </el-col> -->
          </el-row>

          <el-form-item>
            <el-button
              v-if="!form.jjrid"
              @click="addHandle"
              type="primary"
              style="margin-left: 240px;padding-left:30px;padding-right:30px;"
            >
              新增
            </el-button>
            <el-button
              v-if="form.jjrid"
              @click="
                form = {}
                myurl = ''
                myurlTx = ''
              "
              style="margin-left: 85px;padding-left:30px;padding-right:30px;"
            >
              取消
            </el-button>
            <!-- <el-button v-if="form.jjrid"  @click="onSubmit" type="primary" style="margin-left: 260px;padding-left:30px;padding-right:30px;" >
              新增
            </el-button> -->
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-table
      :data="personinfo"
      border
      style="width: 100%;"
      :cell-style="{ padding: '5px 0px' }"
      :header-cell-style="headcell"
    >
      <el-table-column
        prop="id"
        label="销售id"
        width="200"
        align="center"
        v-if="false"
      >
      </el-table-column>
      <!-- <el-table-column
        prop="lpname"
        label="所在小区"
        width="200" align="center">
      </el-table-column> -->
      <el-table-column
        prop="figurename"
        label="销售姓名"
        width="200"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="fphone"
        label="销售电话"
        width="200"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="background"
        label="公司"
        width="300"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="fidentity" label="职位" width="200" align="center">
      </el-table-column>
      <!-- <el-table-column
      prop="jjrewm"
      label="销售微信二维码"
      width="200" align="center">

      <template slot-scope="scope">
        <img :src="scope.row.jjrewm" alt="" srcset="" style="width: 100px;height: 100px" v-if="scope.row.jjrewm">
        <span v-if="!scope.row.jjrewm">未上传</span>
      </template>
    </el-table-column> -->
      <el-table-column
        prop="figurephoto"
        label="销售微信头像"
        width="200"
        align="center"
      >
        <template slot-scope="scope">
          <img
            :src="scope.row.figurephoto"
            alt=""
            srcset=""
            style="width: 100px;height: 100px"
            v-if="scope.row.figurephoto"
          />
          <span v-if="!scope.row.figurephoto">未上传</span>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <!-- <el-button @click="handleClick(scope.row)" type="text" size="small">修改</el-button> -->
          <el-button type="text" size="small" @click="del(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </section>
</template>
<script>
import UploadImg from '@/components/upload/uploadImg.vue'
export default {
  name: 'myPerson',
  components: {
    UploadImg,
  },
  props: ['lpid', 'personinfo'],
  data() {
    return {
      form: {
        lpid: this.lpid,
      },
      figurephoto: '', // 微信头像
      jjrewm: '', // 微信二维码
      jjrid: 0,
    }
  },
  mounted() {
    // this.searchData()
  },
  methods: {
    // 楼盘logo上传
    handleAvatarSuccess(res, file) {
      this.figurephoto = file.response.filepath
      this.form.figurephoto = file.response.filepath
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传附件只能是 JPG或PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    uploadClickTx(val) {
      this.form.figurephoto = val
    },
    uploadClick(val) {
      this.form.jjrewm = val
    },
    // 选择职位
    changeFidentity(e) {},
    // 新增销售信息
    async addHandle() {
      if (this.form.fidentity == '1') {
        this.form.fidentity = '销售经理'
      } else if (this.form.fidentity == '2') {
        this.form.fidentity = '置业顾问'
      }
      let flag = false
      this.personinfo.map((item) => {
        if (
          item.fidentity == '销售经理' &&
          this.form.fidentity === '销售经理'
        ) {
          this.$message('销售经理只限一位！')
          flag = true
        } else {
          flag = false
        }
      })
      if (flag) {
        return
      }
      await this.$http.post('lyfigure/insfigure', this.form).then((res) => {
        this.jjrid = res.data.value
      })
      const { data: res } = await this.$http
        .post('borker/insborker', { lpid: this.lpid, jjrid: this.jjrid })
        .then((res) => {
          if (res.data.code == '200') {
          this.$emit('getper')
          this.$message.success('新增成功')
        } else {
          this.$message.error('新增失败')
        }
        })
    },
    // 删除
    async del(val) {
      await this.$http.delete('lyfigure/delfigure?id=' + val.id).then((res) => {
        if (res.data.code == '200') {
          this.$emit('getper')
          this.$message.success('删除成功')
        } else {
          this.$message.error('删除失败')
        }
      })
    },
    headcell() {
      return 'text-align: center;background:#eef1f6;'
    },
  },
}
</script>
<style scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px dashed #d9d9d9 !important;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-form-item {
  margin-bottom: 10px;
}
</style>
