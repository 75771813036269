<template>
  <section>
    <el-form
      ref="baseform"
      :rules="rules"
      :model="baseform"
      label-width="120px"
    >
      <div style="border-bottom: 2px solid #f2f2f2;padding: 10px;display:flex">
        <div style="width:40%">
          <h2>基础信息：</h2>
          <el-row>
            <el-col :span="8">
              <el-form-item label="楼盘logo">
                <el-upload
                  class="avatar-uploader"
                  action="https://ddfun.baifun.cn/oss/uploadFileReturnUrl"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img v-if="logoUrl" :src="logoUrl" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="楼盘名称" prop="lpname">
                <el-input
                  v-model="baseform.lpname"
                  style="width: 200px;"
                  placeholder="请输入楼盘名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="楼盘别名">
                <el-input
                  v-model="baseform.dictAlias"
                  style="width: 200px;"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="销售状态">
                <el-select
                  clearable
                  v-model="baseform.xszt"
                  placeholder="请选择销售状态"
                  style="width: 200px;"
                >
                  <el-option
                    v-for="(i, k) in allDic.XSZT"
                    :key="k"
                    :label="i.aaa103"
                    :value="i.aaa101"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="物业类型">
                <el-select
                  clearable
                  v-model="baseform.wylxList"
                  multiple
                  placeholder="请选择物业类型(可多选)"
                  style="width: 200px;"
                >
                  <el-option
                    :label="item.aaa103"
                    :value="item.aaa101"
                    v-for="item in allDic.WYLX"
                    :key="item.aaa101"
                  ></el-option>
                </el-select>
                <!-- <el-select
                clearable
                v-model="baseform.wylx"
                placeholder="请选择活动区域"
                 
                style="width: 200px;"
              >
                <el-option
                  v-for="(i, k) in allDic.WYLX"
                  :key="k"
                  :label="i.aaa103"
                  :value="i.aaa101"
                ></el-option>
              </el-select> -->
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="咨询电话">
                <el-input
                  v-model="baseform.zxdh"
                  style="width: 200px;"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="楼盘概述">
                <el-input
                  v-model="baseform.lpdescribe"
                  style="width: 200px;"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="均价">
                <el-input
                  v-model="baseform.junj"
                  style="width: 200px;"
                  placeholder="价格待定时不填"
                  type="number"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开发商名称">
                <el-input
                  v-model="baseform.kfsname"
                  style="width: 200px;"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="户型面积">
                <el-input
                  v-model="baseform.hxarearange"
                  style="width: 200px;"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div style="margin-left:100px;width:60%">
          <h2>手机端：</h2>
          <el-row>
            <el-col :span="8">
              <el-form-item label="交流群二维码">
                <el-upload
                  class="avatar-uploader"
                  action="https://ddfun.baifun.cn/oss/uploadFileReturnUrl"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess2"
                  :before-upload="beforeAvatarUpload2"
                >
                  <img v-if="chatphoto" :src="chatphoto" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="楼盘评分">
                <el-input
                  v-model="baseform.score"
                  style="width: 200px;"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="预售价格描述">
                <el-input
                  v-model="baseform.pricerecord"
                  style="width: 200px;"
                  type="textarea"
                  :rows="2"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="管理员微信号">
                <el-input
                  v-model="baseform.chatnumber"
                  style="width: 200px;"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="预售价格更新时间">
                <el-date-picker
                  style="width: 200px"
                  size="small"
                  v-model="baseform.updatetime"
                  align="right"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="到访礼金额">
                <el-input
                  v-model="baseform.dfprefer"
                  style="width: 200px;"
                  type="number"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="到访礼有限日期">
                <el-date-picker
                  style="width: 200px"
                  size="small"
                  v-model="baseform.dfdate"
                  align="right"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="优惠描述">
                <el-input
                  v-model="baseform.preferential"
                  style="width: 200px;"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="限时优惠活动文案">
                <el-input
                  v-model="baseform.limitactivity"
                  style="width: 200px;"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="优惠活动截止日期">
                <el-date-picker
                  style="width: 200px"
                  size="small"
                  v-model="baseform.limitdate"
                  align="right"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="限时优惠图片">
                <el-upload
                  class="avatar-uploader"
                  action="https://ddfun.baifun.cn/oss/uploadFileReturnUrl"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess3"
                  :before-upload="beforeAvatarUpload3"
                >
                  <img v-if="limitphoto" :src="limitphoto" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="楼盘地理位置描述">
                <template>
                  <div class="editorBox quill-wrap">
                    <quill-editor
                      v-model="baseform.locationdepictStr"
                      ref="myQuillEditor"
                      :options="editorOption"
                      @change="onEditorChange($event)"
                    >
                    </quill-editor>
                  </div>
                </template>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <div style="border-bottom: 2px solid #f2f2f2;padding: 10px">
        <h2>位置信息：</h2>
        <el-row>
          <el-col :span="8">
            <el-form-item label="中国区域划分" prop="qybm">
              <el-select
                clearable
                v-model="baseform.qybm"
                style="width: 200px;"
                placeholder="请选择活动区域"
                @change="Selarea(baseform.qybm)"
              >
                <el-option
                  v-for="items in areaList"
                  :value="items.qybm"
                  :key="items.qyname"
                  :label="items.qyname"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="城市" prop="ctid">
              <el-select
                clearable
                v-model="baseform.ctid"
                style="width: 200px;"
                placeholder="请选择活动区域"
                @change="Selcity(baseform.ctid)"
              >
                <el-option
                  v-for="items in cityList"
                  :value="items.ctid"
                  :key="items.ctid"
                  :label="items.ctname"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="辖区" prop="ctqybm">
              <el-select
                clearable
                v-model="baseform.ctqybm"
                style="width: 200px;"
                placeholder="请选择活动区域"
                @change="Selctqy(baseform.ctqybm)"
              >
                <el-option
                  v-for="items in adsList"
                  :value="items.ctqybm"
                  :key="items.ctqybm"
                  :label="items.ctqyname"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="道路、街道">
              <el-select
                clearable
                v-model="baseform.lmqybm"
                style="width: 200px;"
                placeholder="请选择活动区域"
              >
                <el-option
                  v-for="items in lmList"
                  :value="items.lmqybm"
                  :key="items.lmqybm"
                  :label="items.lmqyname"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="商圈">
              <el-select
                clearable
                v-model="baseform.tradingAreaId"
                style="width: 200px;"
                placeholder="请选择商圈"
              >
                <el-option
                  v-for="items in sqList"
                  :value="items.tradingAreaId"
                  :key="items.tradingAreaId"
                  :label="items.sqname"
                ></el-option>
                <!-- <el-input v-model="baseform.lpname" style="width: 200px;"    placeholder="请输入商圈"></el-input> -->
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="楼盘位置">
              <el-input
                v-model="baseform.lpwzms"
                style="width: 200px;"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="售楼处地址">
              <el-input
                v-model="baseform.sldz"
                style="width: 200px;"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div style="border-bottom: 2px solid #f2f2f2;padding: 10px">
        <h2>规划信息：</h2>
        <el-row>
          <el-col :span="8">
            <el-form-item label="规划户数">
              <el-input
                v-model="baseform.gfhs"
                style="width: 200px;"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="车位数">
              <el-input v-model="baseform.cws" style="width: 200px;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="绿化率">
              <el-input v-model="baseform.lhv" style="width: 200px;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="车位比">
              <el-input v-model="baseform.cwl" style="width: 200px;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="容积率">
              <el-input v-model="baseform.rjl" style="width: 200px;"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="交易权属" style="color: blue">
              <el-select
                clearable
                v-model="baseform.ownershipId"
                placeholder="请选择交易权属"
                style="width: 200px;"
              >
                <el-option label="公房" value="135"></el-option>
                <el-option label="商品房" value="136"></el-option>
                <el-option label="自住商品房" value="143"></el-option>
                <el-option label="共有产权房" value="144"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="占地面积">
              <el-input
                v-model="baseform.zdmj"
                style="width: 200px;"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="交房时间">
              <!-- <el-date-picker
              v-model="baseform.jfsj"
              type="date"
              value-format="yyyy/MM/dd"
              placeholder="选择日期"   style="width: 200px;">
            </el-date-picker> -->
              <el-input
                v-model="baseform.jfsj"
                style="width: 200px;"
                placeholder="请输入交房时间"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="建筑面积">
              <el-input
                v-model="baseform.jzmj"
                style="width: 200px;"
                placeholder="请输入建筑面积"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="建筑类型">
              <el-input
                v-model="baseform.jzlx"
                style="width: 200px;"
                placeholder="请输入建筑类型"
              ></el-input>
              <!-- <el-select clearable  v-model="baseform.jzlx" placeholder="请选择建筑类型"   style="width: 200px;">
              <el-option v-for="(i,k) in allDic.JZLX" :key="k" :label="i.aaa103" :value="i.aaa101"></el-option>
            </el-select> -->
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="装修状态">
              <el-select
                clearable
                v-model="baseform.zxzk"
                placeholder="请选择装修状态"
                style="width: 200px;"
              >
                <el-option
                  v-for="(i, k) in allDic.ZXZK"
                  :key="k"
                  :label="i.aaa103"
                  :value="i.aaa101"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="产权年限">
              <el-select
                clearable
                multiple
                v-model="baseform.cqnxList"
                placeholder="请选择产权年限"
                style="width:200px"
              >
                <el-option
                  :label="item.aaa103"
                  :value="item.aaa103"
                  v-for="item in yearList"
                  :key="item.aaa101"
                ></el-option>
                <!-- <el-option label="70年" value="70年"></el-option>
                <el-option label="50年" value="50年"></el-option>
                <el-option label="40年" value="40年"></el-option>
                <el-option label="其他" value="其他"></el-option> -->
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="物业公司">
              <el-input
                v-model="baseform.wygs"
                style="width: 200px;"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="物业管理费">
              <el-input
                v-model="baseform.wyglf"
                style="width: 200px;"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="开盘时间">
              <!-- <el-date-picker
              v-model="baseform.kpsj"
              type="date"
              value-format="yyyy/MM/dd"
              placeholder="选择日期"   style="width: 200px;">
            </el-date-picker> -->
              <el-input
                v-model="baseform.kpsj"
                style="width: 200px;"
                placeholder="请输入开盘时间"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div style="padding: 20px">
        <h2>其他：</h2>
        <el-row>
          <!-- <el-col :span="8">
          <el-form-item label="是否可报名看房">
            <el-select
              clearable
              v-model="baseform.iskbm"
              placeholder="请选择活动区域"
               
              style="width: 200px;"
            >
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col> -->
          <!-- <el-col :span="8">
          <el-form-item label="楼盘导购顺序" style="color: blue">
            <el-input
              v-model="baseform.sorn"
              style="width: 200px;"
               
              placeholder="可空,输入数字，越大越靠前"
            ></el-input>
          </el-form-item>
        </el-col> -->
          <el-col :span="8">
            <el-form-item label="新房分类">
              <el-select
                clearable
                v-model="baseform.newbqList"
                multiple
                @change="changeType"
                placeholder="请选择新房分类(可多选)"
                style="width: 200px;"
              >
                <el-option
                  :label="item.aaa103"
                  :value="item.aaa101"
                  v-for="item in houseTypeList"
                  :key="item.aaa101"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="楼盘标签">
              <el-select
                clearable
                v-model="baseform.lplabelList"
                multiple
                placeholder="请选择楼盘标签(可多选)"
                style="width: 200px;"
              >
                <el-option
                  :label="item.aaa103"
                  :value="item.aaa101"
                  v-for="item in houseTipsList"
                  :key="item.aaa101"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="导购拉页PC端地址" style="color: blue">
              <el-input
                v-model="baseform.lyurl"
                style="width: 200px;"
                placeholder="可空,输入拉页访问的地址"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8" v-if="isCooperate">
            <el-form-item label="是否为合作楼盘">
              <el-select
                v-model="baseform.iscooperate"
                placeholder="请选择"
                style="width: 200px;"
              >
                <el-option label="否" value=""></el-option>
                <el-option label="是" value="是"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="详情页标签">
              <el-select
                v-model="baseform.lpinfolableList"
                multiple
                filterable
                allow-create
                default-first-option
                placeholder="请输入"
                style="width: 200px;"
              >
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row>
        <el-col :span="8">
          <el-form-item label="导购拉页手机端地址" style="color: blue">
            <el-input
              v-model="baseform.iphonelyurl"
              style="width: 200px;"
               
              placeholder="可空,输入拉页手机访问的地址"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="广告显示顺序" style="color: blue">
            <el-input
              v-model="baseform.adver"
              style="width: 200px;"
               
              placeholder="可空,输入数字，越大越靠前"
            ></el-input>
          </el-form-item>
        </el-col>
        
      </el-row> -->
      </div>
      <el-row>
        <!-- <el-col :span="12">
          <el-form-item label="户型">
            <el-checkbox-group v-model="baseform.hxList">
              <el-checkbox v-for="(i,k) in allDic.HX" :key="k" :value="i.aaa103" :label="i.aaa103"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-col> -->
      </el-row>

      <!-- <el-row>
        <el-col style="color: blue">
          <el-form-item label="百度楼盘标签"   prop="dictFeatures" style="color: blue" >
            <el-checkbox-group v-model="baseform.dictFeatureIdsList">
              <el-checkbox v-for="(i,k) in allDic.FEAT" :key="k" :label="i.aaa103" :value="i.aaa103" ></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-col>
      </el-row> -->
      <el-form-item>
        <el-button
          type="primary"
          style="margin-left: 320px;"
          @click="confirmAdd"
          >确认</el-button
        >
      </el-form-item>
    </el-form>
  </section>
</template>
<script>
// require styles 引入样式
import { quillEditor, Quill } from 'vue-quill-editor'
import { container, ImageExtend, QuillWatch } from 'quill-image-extend-module'
Quill.register('modules/ImageExtend', ImageExtend)

import '@/assets/imagePaste'
export default {
  name: 'mybase',
  components: { quillEditor },
  props: ['baseform', 'areaList', 'isEdit'],
  data() {
    let self = this
    return {
      cityall: JSON.parse(window.sessionStorage.getItem('cityAll')).records,
      editorOption: {
        modules: {
          // imageDrop: true,
          ImageExtend: {
            // 如果不作设置，即{}  则依然开启复制粘贴功能且以base64插入
            loading: true,
            name: 'file', // 图片参数名
            size: 3, // 可选参数 图片大小，单位为M，1M = 1024kb
            action: 'https://ddfun.baifun.cn/oss/uploadFileReturnUrl', // 服务器地址, 如果action为空，则采用base64插入图片
            // response 为一个函数用来获取服务器返回的具体图片地址
            // 例如服务器返回{code: 200; data:{ url: 'baidu.com'}}
            // 则 return res.data.url
            response: (res) => {
              return res.filepath
            },

            sizeError: () => {}, // 图片超过大小的回调
            start: () => {}, // 可选参数 自定义开始上传触发事件
            end: () => {}, // 可选参数 自定义上传结束触发的事件，无论成功或者失败
            error: () => {}, // 可选参数 上传失败触发的事件
            success: () => {}, // 可选参数  上传成功触发的事件
            change: (xhr, formData) => {}, // 可选参数 每次选择图片触发，也可用来设置头部，但比headers多了一个参数，可设置formData
          },
          toolbar: {
            // 如果不上传图片到服务器，此处不必配置
            container: container,
            handlers: {
              image: function() {
                // 劫持原来的图片点击按钮事件
                QuillWatch.emit(this.quill.id)
              },
            },
          },
          imagePaste: {
            addImageBlob: function(blob, callback) {
              var formData = new FormData()
              formData.append('file', blob)
              // your upload function, get the uploaded image url, add then
              let config = {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Accept: '*/*',
                },
              }
              self.$http
                .post('/oss/uploadFileReturnUrl', formData, config)
                .then((res) => {
                  var imageUrl = res.data.filepath
                  callback(imageUrl)
                })
            },
          },
        },
      },
      rules: {
        qybm: [
          { required: true, message: '请选择区域', trigger: 'blur' },
          { min: 0, max: 15 },
        ],
        ctid: [{ required: true, message: '请选择城市', trigger: 'blur' }],
        ctqybm: [{ required: true, message: '请选择辖区', trigger: 'blur' }],
        lpname: [
          { required: true, message: '请输入楼盘名称', trigger: 'blur' },
        ],
      },
      cityList: [],
      adsList: [],
      lmList: [],
      sqList: [],
      allDic: JSON.parse(window.sessionStorage.getItem('saveDic')).data,
      lszList: [], // 临深圳城市表
      itemid: 0,
      houseTypeList: [], //新房分类类型列表
      houseTipsList: [], // 楼盘标签列表
      yearList: [], // 产权年限
      logoUrl: '', // 楼盘logo
      limitphoto: '', // 限时优惠活动图
      chatphoto: '', // 交流群二维码
      isCooperate: false, // 是否为合作楼盘
    }
  },
  created() {
    this.getLsz()
    this.getHouseType()
    this.getHouseTips()
    this.getHouseYear()
  },
  mounted() {},
  watch: {
    baseform: {
      handler: function() {
        this.logoUrl = this.baseform.lplogo
        this.limitphoto = this.baseform.limitphoto
        this.chatphoto = this.baseform.chatphoto
        this.baseform.ctqybm = Number(this.baseform.ctqybm)
        this.baseform.lmqybm = Number(this.baseform.lmqybm)
        this.Selarea(this.baseform.qybm)
        if (!this.baseform.ctqybm && !this.baseform.lmqybm) {
          this.Selcity(this.baseform.ctid)
          this.lmList = []
        }
        if (this.baseform.ctqybm && !this.baseform.lmqybm) {
          this.baseform.lmqybm = null
          this.Selcity(this.baseform.ctid)
          this.Selctqy(this.baseform.ctqybm)
        }
        if (this.baseform.lmqybm) {
          this.Selcity(this.baseform.ctid)
          this.Selctqy(this.baseform.ctqybm)
        }

        if (this.baseform.newbqList && this.baseform.newbqList.length > 1) {
          if (this.baseform.newbqList.includes('10')) {
            this.isCooperate = true
          } else {
            this.isCooperate = false
          }
        } else {
          if (this.baseform.newbqList[0] == '10') {
            this.isCooperate = true
          } else {
            this.isCooperate = false
          }
        }
      },
      // deep: true,
      immediate: true,
    },
  },
  methods: {
    changeType(e) {
      if (this.baseform.newbqList.includes('10')) {
        this.isCooperate = true
      } else {
        this.isCooperate = false
      }
    },
    onEditorChange() {
      // 内容改变事件
    },
    // 楼盘logo上传
    handleAvatarSuccess(res, file) {
      this.logoUrl = file.response.filepath
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error('上传附件只能是 JPG或PNG 格式!')
      }
      if (!isLt5M) {
        this.$message.error('上传附件大小不能超过 5M!')
      }
      return isJPG && isLt5M
    },
    // 微信二维码上传
    handleAvatarSuccess2(res, file) {
      this.chatphoto = file.response.filepath
    },
    beforeAvatarUpload2(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error('上传附件只能是 JPG或PNG 格式!')
      }
      if (!isLt5M) {
        this.$message.error('上传附件大小不能超过 5M!')
      }
      return isJPG && isLt5M
    },
    // 限时活动图上传
    handleAvatarSuccess3(res, file) {
      this.limitphoto = file.response.filepath
    },
    beforeAvatarUpload3(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error('上传附件只能是 JPG或PNG 格式!')
      }
      if (!isLt5M) {
        this.$message.error('上传附件大小不能超过 5M!')
      }
      return isJPG && isLt5M
    },
    // 获取新房分类类型列表
    async getHouseType() {
      await this.$http.get('/dic/aaa100?aaa100=NEWBQ').then((res) => {
        this.houseTypeList = res.data.data
      })
    },
    // 获取楼盘标签列表
    async getHouseTips() {
      await this.$http.get('/dic/aaa100?aaa100=lplable').then((res) => {
        this.houseTipsList = res.data.data
      })
    },
    // 获取产权年限
    async getHouseYear() {
      await this.$http.get('/dic/aaa100?aaa100=propertyRight').then((res) => {
        this.yearList = res.data.data
      })
    },
    // 获取临深圳城市表
    getLsz() {
      this.$http.get('/screen/list?ctid=2016').then((res) => {
        this.lszList = res.data.data
      })
    },
    async Selarea(qybm) {
      if (!this.isEdit) {
        this.baseform.ctid = null
      }
      const { data: res } = await this.$http.get('city/sel', {
        params: { qybm: qybm },
      })
      this.cityList = res.data
    },
    async Selcity(ctid) {
      this.itemid = ctid
      if (!this.isEdit) {
        this.baseform.ctqybm = null
        this.baseform.lmqybm = null
        this.baseform.tradingAreaId = null
      }
      const { data: res } = await this.$http.get('ads/sel', {
        params: { ctid: ctid },
      })
      this.adsList = res.data
    },
    async Selctqy(ctqybm) {
      if (!this.isEdit) {
        this.baseform.lmqybm = ''
        this.baseform.tradingAreaId = ''
      }
      await this.$http
        .get('ads/list', {
          params: {
            ctqybm: ctqybm,
            pageNum: 1,
            pageSize: 30,
          },
        })
        .then((res) => {
          this.lmList = res.data.data.records
        })
      await this.$http
        .get('newhome/ctqybm?pageNum=1&pageSize=50&ctqybm=' + ctqybm)
        .then((res) => {
          this.sqList = res.data.data.records
        })
    },
    // 新增
    async confirmAdd() {
      if (
        !this.baseform.lpname ||
        !this.baseform.qybm ||
        !this.baseform.ctid ||
        !this.baseform.ctqybm
      ) {
        this.$message.error('请先完整必填信息！')
        return
      }
      this.baseform.lplogo = this.logoUrl
      this.baseform.limitphoto = this.limitphoto
      this.baseform.chatphoto = this.chatphoto
      if (this.cityList.length && this.itemid) {
        let name = this.cityList.filter((c) => c.ctid == this.itemid)[0].ctname
        if (this.lszList.filter((l) => l.scrname == name).length) {
          let sid = this.lszList.filter((l) => l.scrname == name)[0].sid
          this.baseform.scrId = String(sid)
        } else {
          this.baseform.scrId = ''
        }
      }
      if (!this.isEdit) {
        const { data: res } = await this.$http.post(
          'newhome/ins',
          this.baseform
        )
        if (res.msg == '添加数据成功') {
          this.$message.success('新增成功')
        } else {
          this.$message.error('新增失败')
        }
        this.$emit('next')
      } else {
        const { data: res } = await this.$http.put('newhome/upl', this.baseform)
        if (res.code == '200') {
          this.$message.success('修改成功')
        } else {
          this.$message.error('修改失败')
        }
      }
      // this.$emit('getlp')
    },
  },
}
</script>
<style scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px dashed #d9d9d9 !important;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-form-item {
  margin-bottom: 10px;
}
::v-deep .quill-editor .ql-editor {
  height: 250px;
}
</style>
