<template>
  <div>
    <el-card>
      <!--导航栏-->
      <el-breadcrumb
        separatorClass="el-icon-arrow-right"
        style="font-size: 16px"
      >
        <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>新房管理</el-breadcrumb-item>
        <el-breadcrumb-item>价格面积区间维护</el-breadcrumb-item>
      </el-breadcrumb>
      <!--卡片区域-->
      <!--
                1.单价区间内容展示
                2.添加单价内容
                3.查询已有单价区间内容
                    -->
      <el-tabs
        type="border-card"
        style="margin-top: 15px;line-height: 10px;font-size: 14px "
      >
        <el-tab-pane label="单价区间">
          <!--搜索添加区域-->
          <el-row :gutter="25">
            <!--搜索区域-->
            <!--搜索添加-->
            <el-col :span="25" v-if="zhtype == 1">
              中国区域划分:
              <el-select clearable 
                v-model="queryInfo.qybm"
                placeholder="请选择"
                @change="Selarea(queryInfo.qybm)"
                style="margin-right: 20px"
              >
                <el-option
                  v-for="items in qyList"
                  :value="items.qybm"
                  :key="items.qyname"
                  :label="items.qyname"
                >
                </el-option>
              </el-select>
              城市:<el-select clearable 
                v-model="queryInfo.ctname"
                placeholder="请选择"
                @change="queryInfo.pageNum = 1"
              >
                <el-option
                  v-for="items in ctList"
                  :value="items.ctname"
                  :key="items.ctname"
                ></el-option>
              </el-select>
              <el-button
                type="primary"
                icon="el-icon-search"
                style="width: 100px;margin-left: 80px"
                @click="getUnitList"
                >搜索</el-button
              >
            </el-col>
            <!--添加按钮-->
            <el-col :span="2">
              <el-button
                type="primary"
                icon="el-icon-circle-plus"
                style="width:100px"
                @click="uaddEquipment"
                 v-if="zhtype == 1"
                >添加</el-button
              >
            </el-col>
          </el-row>
          <!--城市表格内容-->
          <template>
            <el-table
              :data="unitList"
              style="width:100%;margin-top: 30px;font-size: 18px"
              :header-cell-style="headcell"
            >
              <el-table-column
                prop="uid"
                label="编号"
                width="150"
                align="center"
                v-if="false"
              ></el-table-column>
              <el-table-column
                prop="ctname"
                label="城市"
                width="100"
                align="center"
              ></el-table-column>
              <el-table-column label="单价" width="600"
                align="center">
                <template slot-scope="scope">
                  {{ scope.row.djminvalue }}~{{
                    scope.row.djmaxvalue
                  }}</template
                >
              </el-table-column>
              <el-table-column label="操作" width="200"
                align="center" v-if="zhtype == 1">
                <template slot-scope="scope">
                  <!--修改-->
                  <el-button
                    type="primary"
                    icon="el-icon-edit"
                    @click="ueditEquipment(scope.row)"
                    >编辑</el-button
                  >
                  <!--删除-->
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    @click="delUnit(scope.row.djid)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>

          <!--分页-->
          <div>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryInfo.pageNum"
              :page-sizes="[5, 10, 20, 30, 50]"
              :page-size="queryInfo.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="utotal"
              
            >
            </el-pagination>
          </div>

          <!--新增或修改单价信息-->
          <el-dialog
            :title="utitleMap[udialogStatus]"
            :visible.sync="uaddDialogVisiable"
            width="40%"
            @close="djClose"
          >
            <el-form
              :model="caddForm"
              :rules="addFormRules"
              ref="addFormRef"
              label-width="70px"
              :label-position="labelPosition"
              inline
            >
              <el-form-item label="中国区域划分" label-width="100px">
                <el-select clearable 
                  v-model="queryInfo.qybm"
                  placeholder="请选择"
                  @change="Selarea(queryInfo.qybm)"
                >
                  <el-option
                    v-for="items in qyList"
                    :value="items.qybm"
                    :key="items.qybm"
                    :label="items.qyname"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="城市" label-width="100px;">
                <el-select clearable 
                  v-model="queryInfo.ctname"
                  placeholder="请选择"
                  style="line-height: 20px"
                >
                  <el-option
                    v-for="items in ctList"
                    :value="items.ctname"
                    :key="items.ctid"
                    :label="items.ctname"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="单价区间"
                label-width="100px;"
                style="margin-right: 63px"
              >
                <el-col :span="10">
                  <el-form-item prop="djminvalue">
                    <el-input v-model="caddForm.djminvalue"></el-input>
                  </el-form-item>
                </el-col>
                <el-col class="line" :span="3">-</el-col>
                <el-col :span="10">
                  <el-form-item prop="djmaxvalue">
                    <el-input v-model="caddForm.djmaxvalue"></el-input>
                  </el-form-item>
                </el-col>
              </el-form-item>
              <el-form-item
                label="单价描述"
                label-width="100px;"
                style="margin-right:300px"
                prop="dj"
              >
                <el-input v-model="caddForm.dj"></el-input>
              </el-form-item>
            </el-form>
            <!--内容底部区域-->
            <span slot="footer" class="dialog-footer">
              <el-button @click="djClose">取消</el-button>
              <el-button type="primary" @click="addPriceList">确认</el-button>
            </span>
          </el-dialog>
        </el-tab-pane>

        <el-tab-pane label="总价区间">
          <!--搜索添加区域-->
          <el-row :gutter="25">
            <!--搜索区域-->
            <!--搜索添加-->
            <el-col :span="25">
              中国区域划分:
              <el-select clearable 
                v-model="queryInfo.qybm"
                placeholder="请选择"
                @change="Selarea(queryInfo.qybm)"
              >
                <el-option
                  v-for="items in qyList"
                  :value="items.qybm"
                  :label="items.qyname"
                  :key="items.qyname"
                >
                </el-option>
              </el-select>
              城市:<el-select clearable 
                v-model="queryInfo.ctname"
                placeholder="请选择"
                @change="queryInfo.pageNum = 1"
              >
                <el-option
                  v-for="items in ctList"
                  :value="items.ctname"
                  :key="items.ctname"
                >
                </el-option>
              </el-select>
              <el-button
                type="primary"
                icon="el-icon-search"
                style="width: 100px;margin-left: 80px"
                @click="getTotalList"
                >搜索</el-button
              >
            </el-col>
            <!--添加按钮-->
            <el-col :span="4">
              <el-button
                type="primary"
                icon="el-icon-circle-plus"
                style="width:100px"
                @click="taddEquipment"
                >添加</el-button
              >
            </el-col>
          </el-row>
          <!--总价区间内容-->
          <template>
            <el-table
              :data="totalList"
              style="width:100%;margin-top: 30px;font-size: 18px"
              :header-cell-style="headcell"
            >
              <el-table-column
                prop="zjid"
                label="编号"
                width="150"
              ></el-table-column>
              <el-table-column
                prop="ctname"
                label="城市"
                width="150"
              ></el-table-column>
              <el-table-column
                prop="zj"
                label="总价"
                width="300"
              ></el-table-column>
              <el-table-column label="操作" width="600">
                <template slot-scope="scope">
                  <!--修改-->
                  <el-button
                    type="primary"
                    icon="el-icon-edit"
                    @click="teditEquipment(scope.row)"
                    >修改</el-button
                  >
                  <!--删除-->
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    @click="delTotal(scope.row.zjid)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>

          <!--新增或修改总价信息-->
          <el-dialog
            :title="utitleMap[udialogStatus]"
            :visible.sync="taddDialogVisiable"
            width="40%"
            @close="zjClose"
          >
            <el-form
              :model="taddForm"
              :rules="addToFormRules"
              ref="addToFormRef"
              label-width="70px"
              :label-position="labelPosition"
              inline
            >
              <el-form-item label="中国区域划分" label-width="100px">
                <el-select clearable 
                  v-model="queryInfo.qybm"
                  placeholder="请选择"
                  @change="Selarea(queryInfo.qybm)"
                  style="margin-left: 10px;width:200px"
                >
                  <el-option
                    v-for="items in qyList"
                    :value="items.qybm"
                    :key="items.qybm"
                    :label="items.qyname"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="城市" label-width="100px;">
                <el-select clearable 
                  v-model="queryInfo.ctname"
                  placeholder="请选择"
                  style="line-height: 20px"
                >
                  <el-option
                    v-for="items in ctList"
                    :value="items.ctname"
                    :key="items.ctid"
                    :label="items.ctname"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="总价区间"
                label-width="100px;"
                style="margin-right: 63px"
              >
                <el-col :span="10">
                  <el-form-item prop="zjminvalue">
                    <el-input v-model="taddForm.zjminvalue"></el-input>
                  </el-form-item>
                </el-col>
                <el-col class="line" :span="3">-</el-col>
                <el-col :span="10">
                  <el-form-item prop="zjmaxvalue">
                    <el-input v-model="taddForm.zjmaxvalue"></el-input>
                  </el-form-item>
                </el-col>
              </el-form-item>
              <el-form-item
                label="总价描述"
                label-width="100px;"
                style="margin-right:300px"
                prop="zj"
              >
                <el-input v-model="taddForm.zj"></el-input>
              </el-form-item>
            </el-form>
            <!--内容底部区域-->
            <span slot="footer" class="dialog-footer">
              <el-button @click="zjClose">取消</el-button>
              <el-button type="primary" @click="addTotalList">确认</el-button>
            </span>
          </el-dialog>

          <!--分页-->
          <div>
            <el-pagination
              @size-change="thandleSizeChange"
              @current-change="thandleCurrentChange"
              :current-page="queryInfo.pageNum"
              :page-sizes="[5, 10, 20, 30, 50]"
              :page-size="queryInfo.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="ttotal"
              
            >
            </el-pagination>
          </div>
        </el-tab-pane>

        <el-tab-pane label="面积区间">
          <el-row :gutter="25">
            <!--搜索区域-->
            <!--搜索添加-->
            <el-col :span="25">
              中国区域划分:
              <el-select clearable 
                v-model="queryInfo.qybm"
                placeholder="请选择"
                @change="Selarea(queryInfo.qybm)"
              >
                <el-option
                  v-for="items in qyList"
                  :value="items.qybm"
                  :label="items.qyname"
                  :key="items.qyname"
                >
                </el-option>
              </el-select>
              城市:<el-select clearable 
                v-model="queryInfo.ctname"
                placeholder="请选择"
                @change="queryInfo.pageNum = 1"
              >
                <el-option
                  v-for="items in ctList"
                  :value="items.ctname"
                  :key="items.ctname"
                >
                </el-option>
              </el-select>
              <el-button
                type="primary"
                icon="el-icon-search"
                style="width: 100px;margin-left: 80px"
                @click="getAcreList"
                >搜索</el-button
              >
            </el-col>
            <!--添加按钮-->
            <el-col :span="4">
              <el-button
                type="primary"
                icon="el-icon-circle-plus"
                style="width:100px"
                @click="aaddEquipment"
                >添加</el-button
              >
            </el-col>
          </el-row>
          <!--面积区间内容-->
          <template>
            <el-table
              :data="acreList"
              style="width:100%;margin-top: 30px;font-size: 18px;"
              :header-cell-style="headcell"
            >
              <el-table-column
                prop="mjid"
                label="编号"
                width="150"
                v-if="false"
              ></el-table-column>
              <el-table-column
                prop="ctname"
                label="城市"
                width="150"
              ></el-table-column>
              <el-table-column
                prop="mj"
                label="总面积"
                width="300"
              ></el-table-column>
              <el-table-column label="操作" width="600">
                <template slot-scope="scope">
                  <!--修改-->
                  <el-button
                    type="primary"
                    icon="el-icon-edit"
                    @click="aeditEquipment(scope.row)"
                    >修改</el-button
                  >
                  <!--删除-->
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    @click="delAcre(scope.row.mjid)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>

          <!--新增或修改面积信息-->
          <el-dialog
            :title="utitleMap[udialogStatus]"
            :visible.sync="aaddDialogVisiable"
            width="40%"
            @close="mjClose"
          >
            <el-form
              :model="aaddForm"
              :rules="addArFormRules"
              ref="addArFormRef"
              label-width="70px"
              :label-position="labelPosition"
              inline
            >
              <el-form-item label="中国区域划分" label-width="100px">
                <el-select clearable 
                  v-model="queryInfo.qybm"
                  placeholder="请选择"
                  @change="Selarea(queryInfo.qybm)"
                >
                  <el-option
                    v-for="items in qyList"
                    :value="items.qybm"
                    :key="items.qybm"
                    :label="items.qyname"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="城市" label-width="100px;">
                <el-select clearable 
                  v-model="queryInfo.ctname"
                  placeholder="请选择"
                  style="line-height: 20px"
                >
                  <el-option
                    v-for="items in ctList"
                    :value="items.ctname"
                    :key="items.ctid"
                    :label="items.ctname"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="总面积区间"
                label-width="100px;"
                style="margin-right: 63px"
              >
                <el-col :span="10">
                  <el-form-item prop="mjminvalue">
                    <el-input v-model="aaddForm.mjminvalue"></el-input>
                  </el-form-item>
                </el-col>
                <el-col class="line" :span="3">-</el-col>
                <el-col :span="10">
                  <el-form-item prop="mjmaxvalue">
                    <el-input v-model="aaddForm.mjmaxvalue"></el-input>
                  </el-form-item>
                </el-col>
              </el-form-item>
              <el-form-item
                label="总面积描述"
                label-width="100px;"
                style="margin-right:300px"
                prop="mj"
              >
                <el-input v-model="aaddForm.mj"></el-input>
              </el-form-item>
            </el-form>
            <!--内容底部区域-->
            <span slot="footer" class="dialog-footer">
              <el-button @click="aaddDialogVisiable = false">取消</el-button>
              <el-button type="primary" @click="addAreaList">确认</el-button>
            </span>
          </el-dialog>
          <!--分页-->
          <div>
            <el-pagination
              @size-change="ahandleSizeChange"
              @current-change="ahandleCurrentChange"
              :current-page="queryInfo.pageNum"
              :page-sizes="[5, 10, 20, 30, 50]"
              :page-size="queryInfo.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="atotal"
              
            >
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>
<script>
export default {
  created() {
    this.getUnitList()
    this.SelQycodeAll()
    this.getTotalList()
    this.getAcreList()
    this.zhtype = localStorage.getItem('zhtype')
  },
  data() {
    return {
      zhtype: '',
      //查询信息实体
      queryInfo: {
        djid: null,
        zjid: null,
        ctid: localStorage.getItem('bdcityid') || null,
        ctname: '',
        qybm: null, //区域编码
        pageNum: 1, //当前页
        pageSize: 5, //每页最大数
      },
      unitList: [], //单价数据集合
      totalList: [], //总价数据集合
      acreList: [], //面积区间数据集合
      uaddDialogVisiable: false, //单价价格对话框状态
      taddDialogVisiable: false, //总价价格对话框状态
      aaddDialogVisiable: false, //面积数据对话框状态
      labelPosition: 'right', //单价增加窗口字位
      //添加单价表单信息
      caddForm: {
        ctid: '',
        djminvalue: '',
        djmaxvalue: '',
        dj: '',
      },
      //添加总价表单信息
      taddForm: {
        ctid: '',
        zjminvalue: '',
        zjmaxvalue: '',
        zj: '',
      },
      //添加面积表单信息
      aaddForm: {
        ctid: '',
        mjminvalue: '',
        mjmaxvalue: '',
        mj: '',
      },
      //添加单价表单验证
      addFormRules: {
        djminvalue: [
          { required: true, message: '请输入单价最低价', trigger: 'blur' },
        ],
        djmaxvalue: [
          { required: true, message: '请输入单价最高价', trigger: 'blur' },
        ],
        dj: [{ required: true, message: '请输入单价描述', trigger: 'blur' }],
      },
      //添加总价表单验证
      addToFormRules: {
        zjminvalue: [
          { required: true, message: '请输入总价最低价', trigger: 'blur' },
        ],
        zjmaxvalue: [
          { required: true, message: '请输入总价最高价', trigger: 'blur' },
        ],
        zj: [{ required: true, message: '请输入总价描述', trigger: 'blur' }],
      },
      //添加面积表单验证
      addArFormRules: {
        mjminvalue: [
          { required: true, message: '请输入面积最低价', trigger: 'blur' },
        ],
        mjmaxvalue: [
          { required: true, message: '请输入面积最高价', trigger: 'blur' },
        ],
        mj: [{ required: true, message: '请输入面积描述', trigger: 'blur' }],
      },
      qyList: [], //区域信息
      ctList: [], //城市信息
      utotal: 0, //单价总记录数
      ttotal: 0, //总价总记录数
      atotal: 0, //面积区间总记录数
      //新增或修改的弹窗设置
      utitleMap: {
        uaddEquipment: '新增单价信息',
        ueditEquipment: '编辑单价信息',
        taddEquipment: '新增总价信息',
        teditEquipment: '编辑总价信息',
        aaddEquipment: '新增面积信息',
        aeditEquipment: '编辑面积信息',
      },
      udialogStatus: [], //新增和编辑的弹窗标题
      //单价修改信息查询数据
      uedList: [],
    }
  },
  methods: {
    /**
     * 查询所有下拉框信息
     * 信息提供为楼盘区域维护
     */
    // 给表头设置样式
    headcell() {
      return 'background:#eef1f6;'
    },
    //查询所有区域信息
    async SelQycodeAll() {
      const { data: res } = await this.$http.get(
        'area/qycode' + '?query=' + this.queryInfo.query
      )
      this.qyList = res.data
    },
    //查询城市信息
    async Selarea(qybm) {
      const { data: res } = await this.$http.get('city/sel' + '?qybm=' + qybm)
      this.ctList = res.data
      if(this.uaddDialogVisiable == false){
        this.queryInfo.ctname = null
      }
    },

    //查询所有单价信息
    async getUnitList() {
      if (this.queryInfo.ctname && this.ctList.length) {
        this.queryInfo.ctid = this.ctList.filter(
          (c) => c.ctname == this.queryInfo.ctname
        )[0].ctid
      } else {
        ;(this.queryInfo.qybm = null), (this.queryInfo.ctid = null)
        this.queryInfo.ctname = null
      }

      const { data: res } = await this.$http.get('unit/list', {
        params: {
          qybm: this.queryInfo.qybm,
          ctid: localStorage.getItem('bdcityid') || null,
          pageNum: this.queryInfo.pageNum,
          pageSize: this.queryInfo.pageSize,
        },
      })
      this.unitList = res.data.records
      this.utotal = res.data.total
    },
    //最大数
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getUnitList()
    },
    //pageNum的触发
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getUnitList()
    },
    
    //显示添加单价表单框信息
    uaddEquipment() {
      this.udialogStatus = 'uaddEquipment'
      this.uaddDialogVisiable = true
      this.queryInfo.qybm = null
      this.queryInfo.ctname = null
      this.getUnitList()
    },
    //新增和修改单价信息确认功能
    addPriceList() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) return
        if (this.queryInfo.ctname) {
          if (this.udialogStatus == 'uaddEquipment') {
            this.caddForm.ctid = this.ctList.filter(
              (c) => c.ctname == this.queryInfo.ctname
            )[0].ctid
            const { data: res } = await this.$http.post(
              'unit/ins',
              this.caddForm
            )
            this.unitList = res.data.records
            if (res.code != '200') {
              return this.$message.error('操作失败！！！')
            }
          }
          if (this.udialogStatus == 'ueditEquipment') {
            const { data: res } = await this.$http.put('unit/upl', {
              ctid: this.queryInfo.ctid,
              djid: parseInt(this.queryInfo.djid),
              dj: this.caddForm.dj,
              djmaxvalue: parseInt(this.caddForm.djmaxvalue),
              djminvalue: parseInt(this.caddForm.djminvalue),
            })
            this.unintList = res
            if (res.code != '200') {
              return this.$message.error('操作失败！！！')
            }
          }
          this.$message.success('操作成功！！！')
          this.uaddDialogVisiable = false
          this.caddForm.djmaxvalue = null
          this.caddForm.djminvalue = null
          this.queryInfo.qybm = null
          this.queryInfo.ctid = null
          this.queryInfo.ctname = null
          this.getUnitList()
        } else {
          return this.$message.error('请选择中国区域和城市！！！')
        }
      })
    },
    // 单价取消
    djClose(){
      this.uaddDialogVisiable = false; 
      this.queryInfo.ctname = null; 
      this.queryInfo.qybm = null; 
      this.caddForm.djminvalue = null
      this.caddForm.djmaxvalue = null
      this.caddForm.dj = null
    },
    //修改单价信息
    ueditEquipment(u) {
      this.udialogStatus = 'ueditEquipment'
      this.uaddDialogVisiable = true
      this.queryInfo.qybm = u.qybm
      this.queryInfo.qyname = u.qyname
      this.queryInfo.ctname = u.ctname
      this.queryInfo.ctid = u.ctid
      this.queryInfo.djid = u.djid
      this.caddForm.djmaxvalue = u.djmaxvalue
      this.caddForm.djminvalue = u.djminvalue
      this.caddForm.dj = u.dj
      this.Selarea(u.qybm)
    },
    //删除所有单价信息
    async delUnit(djid) {
      const confirmResult = await this.$confirm(
        '删除后用户信息将永久删除，是否继续？',
        '提示',
        {
          confirmButtonText: '删除',
          cancelButtonText: '放弃',
        }
      ).catch((err) => err)
      if (confirmResult != 'confirm') {
        //取消
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete('/unit/del?djid=' + djid)
      if (res.code != 200) {
        return this.$message.error('删除失败!')
      }
      this.$message.success('删除成功!')
      this.queryInfo.ctid = null
      this.queryInfo.ctname = null
      this.queryInfo.qybm = null
      this.queryInfo.pageNum = 1
      this.getUnitList()
    },

    //新增和修改总价信息确认功能
    addTotalList() {
      this.$refs.addToFormRef.validate(async (valid) => {
        if (!valid) return
        if (this.queryInfo.ctname) {
          if (this.udialogStatus == 'taddEquipment') {
            this.taddForm.ctid = this.ctList.filter(
              (c) => c.ctname == this.queryInfo.ctname
            )[0].ctid

            const { data: res } = await this.$http.post(
              'total/ins',
              this.taddForm
            )
            this.totalList = res.data.records
            if (res.code != '200') {
              return this.$message.error('操作失败！！！')
            }
          }
          if (this.udialogStatus == 'teditEquipment') {
            const { data: res } = await this.$http.put('total/upl', {
              ctid: this.taddForm.ctid,
              zjid: parseInt(this.queryInfo.zjid),
              zj: this.taddForm.zj,
              zjmaxvalue: parseInt(this.taddForm.zjmaxvalue),
              zjminvalue: parseInt(this.taddForm.zjminvalue),
            })
            if (res.code != '200') {
              return this.$message.error('操作失败！！！')
            }
          }
          this.$message.success('操作成功！！！')
          this.taddDialogVisiable = false
          this.queryInfo.qybm = null
          this.queryInfo.ctid = null
          this.queryInfo.ctname = null
          this.taddForm.zjminvalue = null
          this.taddForm.zjmaxvalue = null
          this.taddForm.zj = null
          this.getTotalList()
        } else {
          return this.$message.error('请选择中国区域和城市！！！')
        }
      })
    },

    //显示添加总价表单框信息
    taddEquipment() {
      this.udialogStatus = 'taddEquipment'
      this.taddDialogVisiable = true
      this.queryInfo.qybm = null
      this.queryInfo.ctname = null
      this.getTotalList()
    },
    // 总价取消
    zjClose(){
      this.taddDialogVisiable = false; 
      this.queryInfo.ctname = null; 
      this.queryInfo.qybm = null; 
      this.taddForm.zjminvalue = null
      this.taddForm.zjmaxvalue = null
      this.taddForm.zj = null
    },
    //查询所有总价信息
    async getTotalList() {
      if (this.queryInfo.ctname && this.ctList.length) {
        this.queryInfo.ctid = this.ctList.filter(
          (c) => c.ctname == this.queryInfo.ctname
        )[0].ctid
      } else {
        ;(this.queryInfo.qybm = null), (this.queryInfo.ctid = null)
      }
      const { data: res } = await this.$http.get('/total/list', {
        params: {
          qybm: this.queryInfo.qybm,
          ctid: this.queryInfo.ctid,
          pageNum: this.queryInfo.pageNum,
          pageSize: this.queryInfo.pageSize,
        },
      })

      this.totalList = res.data.records
      this.ttotal = res.data.total
    },
    //修改总价信息
    teditEquipment(t) {
      this.udialogStatus = 'teditEquipment'
      this.taddDialogVisiable = true
      this.queryInfo.qybm = t.qybm
      this.queryInfo.ctname = t.ctname
      this.taddForm.ctid = t.ctid
      this.queryInfo.zjid = t.zjid
      this.taddForm.zjmaxvalue = t.zjmaxvalue
      this.taddForm.zjminvalue = t.zjminvalue
      this.taddForm.zj = t.zj
    },
    //最大数
    thandleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getTotalList()
    },
    //pageNum的触发
    thandleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getTotalList()
    },
    //根据ID删除总价信息
    async delTotal(zjid) {
      const confirmResult = await this.$confirm(
        '删除后用户信息将永久删除，是否继续？',
        '提示',
        {
          confirmButtonText: '删除',
          cancelButtonText: '放弃',
        }
      ).catch((err) => err)
      if (confirmResult != 'confirm') {
        //取消
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete('/total/del?zjid=' + zjid)
      if (res.code != 200) {
        return this.$message.error('删除失败!')
      }
      this.$message.success('删除成功!')
      this.getTotalList()
    },

    //显示添加面积表单框信息
    aaddEquipment() {
      this.udialogStatus = 'aaddEquipment'
      this.aaddDialogVisiable = true
      this.queryInfo.ctname = null; 
      this.queryInfo.qybm = null; 
      this.aaddForm.mjminvalue = null
      this.aaddForm.mjmaxvalue = null
      this.aaddForm.mj = null
    },
    // 面积取消
    mjClose(){
      this.aaddDialogVisiable = false; 
      this.queryInfo.ctname = null; 
      this.queryInfo.qybm = null; 
      // this.caddForm.djminvalue = null
      // this.caddForm.djmaxvalue = null
      // this.caddForm.dj = null
    },
    //修改面积信息
    aeditEquipment(a) {
      this.udialogStatus = 'aeditEquipment'
      this.aaddDialogVisiable = true

      this.queryInfo.ctname = a.ctname
      this.queryInfo.ctid = a.ctid
      this.queryInfo.qybm = a.qybm
      this.queryInfo.mjid = a.mjid
      this.aaddForm.mj = a.mj
      this.aaddForm.mjmaxvalue = a.mjmaxvalue
      this.aaddForm.mjminvalue = a.mjminvalue
    },
    //查询面积区间维护信息
    async getAcreList() {
      if (this.queryInfo.ctname && this.ctList.length) {
        this.queryInfo.ctid = this.ctList.filter(
          (c) => c.ctname == this.queryInfo.ctname
        )[0].ctid
      } else {
        ;(this.queryInfo.qybm = null), (this.queryInfo.ctid = null)
      }
      const { data: res } = await this.$http.get('acre/list', {
        params: {
          qybm: this.queryInfo.qybm,
          ctid: this.queryInfo.ctid,
          pageNum: this.queryInfo.pageNum,
          pageSize: this.queryInfo.pageSize,
        },
      })
      this.acreList = res.data.records
      this.atotal = res.data.total
    },
    //新增和修改面积信息确认功能
    addAreaList() {
      this.$refs.addArFormRef.validate(async (valid) => {
        if (!valid) return
        if (this.queryInfo.ctname) {
          if (this.udialogStatus == 'aaddEquipment') {
            this.aaddForm.ctid = this.ctList.filter(
              (c) => c.ctname == this.queryInfo.ctname
            )[0].ctid

            const { data: res } = await this.$http.post(
              'acre/ins',
              this.aaddForm
            )
            this.acreList = res.data.records
            if (res.code != '200') {
              return this.$message.error('操作失败！！！')
            }
          }
          if (this.udialogStatus == 'aeditEquipment') {
            const { data: res } = await this.$http.put('acre/upl', {
              ctid: this.queryInfo.ctid,
              mjid: this.queryInfo.mjid,
              mj: this.aaddForm.mj,
              mjmaxvalue: this.aaddForm.mjmaxvalue,
              mjminvalue: this.aaddForm.mjminvalue,
            })
            if (res.code != '200') {
              return this.$message.error('操作失败！！！')
            }
          }
          this.$message.success('操作成功！！！')
          this.aaddDialogVisiable = false
          this.queryInfo.qybm = null
          this.queryInfo.ctid = null
          this.queryInfo.ctname = null
          this.aaddForm.mjmaxvalue = null
          this.aaddForm.mjminvalue = null
          this.getAcreList()
        } else {
          return this.$message.error('请选择中国区域和城市！！！')
        }
      })
    },
    //最大数
    ahandleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getAcreList()
    },
    //pageNum的触发
    ahandleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getAcreList()
    },
    //根据ID删除面积区间数据
    async delAcre(mjid) {
      const confirmResult = await this.$confirm(
        '删除后用户信息将永久删除，是否继续？',
        '提示',
        {
          confirmButtonText: '删除',
          cancelButtonText: '放弃',
        }
      ).catch((err) => err)
      if (confirmResult != 'confirm') {
        //取消
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete('/acre/del?mjid=' + mjid)
      if (res.code != 200) {
        return this.$message.error('删除失败!')
      }
      this.$message.success('删除成功!')
      this.getAcreList()
    },
  },
}
</script>
<style lang="less" scoped>
.el-pagination{
    text-align: left;
}
</style>
