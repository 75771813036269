import { render, staticRenderFns } from "./VideoPict.vue?vue&type=template&id=70e18020&scoped=true&"
import script from "./VideoPict.vue?vue&type=script&lang=js&"
export * from "./VideoPict.vue?vue&type=script&lang=js&"
import style0 from "./VideoPict.vue?vue&type=style&index=0&id=70e18020&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70e18020",
  null
  
)

export default component.exports