<template>
  <section>
    <el-row>
      <el-col :span="18">
        <el-form ref="formT" :model="formT" label-width="100px" label-position="right">

          <el-row>
            <el-col :span="12">
              <el-form-item label="容积" >
                <el-input v-model="formT.plotRatio"  style="width: 150px;" placeholder="容积"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="物业费" >
                <el-input v-model="formT.propertyFee"  style="width: 150px;" placeholder="输入数字 物业费 包含两位小数"></el-input>
              </el-form-item>

            </el-col>

          </el-row>

          <el-row>
            <el-col :span="12">
                <el-form-item label="产权年限"  style="color: blue" >
                  <el-select clearable  v-model="formT.propertyRight" placeholder="请选择产权年限"   >
                    <el-option label="70年" value="45"></el-option>
                    <el-option label="50年" value="46"></el-option>
                    <el-option label="40年" value="47"></el-option>
                    <el-option label="其他" value="48"></el-option>
                    <el-option label="未知" value="49"></el-option>
                    <el-option label="永久产权" value="50"></el-option>
                  </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="物业类型"  style="color: blue" >
                <el-select clearable  v-model="formT.wyTypeId" placeholder="请选择物业类型"   >
                  <el-option label="住宅" value="1"></el-option>
                  <el-option label="别墅" value="2"></el-option>
                  <el-option label="商务型公寓" value="4"></el-option>
                  <el-option label="办公" value="6"></el-option>
                  <el-option label="商业" value="7"></el-option>
                  <el-option label="其他" value="13"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>


          <el-row>

            <el-col :span="24">
              <el-form-item>
                <el-button  @click="addHandle"  type="primary" style="margin-left: 240px;padding-left:30px;padding-right:30px;" >
                  新增
                </el-button>
                <!--
                <el-button v-if="formT.point" @click="addHandle"  type="primary" style="margin-left: 240px;padding-left:30px;padding-right:30px;" >
                  修改
                </el-button>-->
              </el-form-item>
            </el-col>
          </el-row>


        </el-form>
      </el-col>


      <el-col :span="24">
        <el-table
          :data="pointdataCqnx"
          border
          style="width: 100%;"
          :cell-style="{padding:'5px 0px'}"
          :header-cell-style="headcell"
        >
          <!--<el-table-column-->
          <!--prop="totalPrice"-->
          <!--label="楼盘总价"align="center">-->
          <!--</el-table-column>-->
          <el-table-column
            prop="wyTypeId"
            label="物业类型" align="center">
          </el-table-column>

          <!--<el-table-column-->
          <!--prop="periodTime"-->
          <!--label="期数"align="center">-->
          <!--</el-table-column>-->

          <el-table-column
            prop="plotRatio"
            label="容积" align="center">
          </el-table-column>

          <el-table-column
            prop="propertyRight"
            label="产权年限code" align="center">
          </el-table-column>

          <el-table-column
            prop="propertyRightvalue"
            label="产权年限标签" align="center">
          </el-table-column>

          <el-table-column
            prop="propertyFee"
            label="物业费" align="center">
          </el-table-column>



          <el-table-column
            label="操作" align="center">
            <template slot-scope="scope">
              <el-button @click="deletClick(scope.row)" type="text" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>


  </section>
</template>
<script>
  export default {
    name: 'myWytype',
    props:{
      formid: {
        type: String,
        default: ''
      },
      pointdataCqnx:{
        type: Array,
        default: ''
      }
    },
    data() {
      return {
        pointdataCqnx_son:this.pointdataCqnx,
        formT:{}
      }
    },
    mounted() {
      this.queryLpPrice();
    },
    methods: {
      search(){
        this.pointdataCqnx_son=[];
        this.$emit('gesearch')
      },
      addHandle(){
        let this_ = this
        let params = this_.$api.getParam('bd7',{"lpid":this_.formid,"wyTypeId":this_.formT.wyTypeId,
          "plotRatio":this_.formT.plotRatio,"propertyRight":this_.formT.propertyRight,
          "propertyFee":this_.formT.propertyFee})
        this_.$api.postData(this_, params,'/openapi/service',1).then((res) => {
          if(res.code == "0"){
            this_.$message("新增成功")
            this_.search();
            //this.queryLpPrice();
          }
        })
      },
      headcell(){
        return 'text-align: center;background:#eef1f6;'
      },
      turnOther(){
        window.open('http://api.map.baidu.com/lbsapi/getpoint/index.html', '_blank')
      },
      queryLpPrice(){
        let this_ = this
        let params = this_.$api.getParam('bd13',{"lpid":this_.formid})
        this_.$api.postData(this_, params,'/openapi/service',1).then((res) => {
          if(res.code == "0"){
            this.pointdataCqnx_son=[];
            this.pointdataCqnx_son=res.data;
          }
        })
      },
      deletClick(data){
        let this_ = this
        let params = this_.$api.getParam('bd14',{"wytpid":data.wytpid})
        this_.$api.postData(this_, params,'/openapi/service',1).then((res) => {
          if(res.code == "0"){
            this_.search();
          }
        })
      }
    }
  }
</script>
