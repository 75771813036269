<template>
  <section>
    <el-row>
      <el-col :span="18">
        <el-form ref="formT" :model="formT" label-width="100px" label-position="right">

          <el-row>
            <el-col :span="12">
              <el-form-item label="均价最大" >
                <el-input v-model="formT.referencePriceMax"  style="width: 150px;" placeholder=""></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="均价最小" >
                <el-input v-model="formT.referencePriceMin"  style="width: 150px;" placeholder=""></el-input>
              </el-form-item>
            </el-col>

          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="总价最大" >
                <el-input v-model="formT.totalPriceMax"  style="width: 150px;" placeholder=""></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="总价最小" >
                <el-input v-model="formT.totalPriceMin"  style="width: 150px;" placeholder=""></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="物业类型"  style="color: blue" >
                <el-select clearable  v-model="formT.wyTypeId" placeholder="请选择物业类型"   >
                  <el-option label="住宅" value="1"></el-option>
                  <el-option label="别墅" value="2"></el-option>
                  <el-option label="商务型公寓" value="4"></el-option>
                  <el-option label="办公" value="6"></el-option>
                  <el-option label="商业" value="7"></el-option>
                  <el-option label="其他" value="13"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <el-button  @click="addHandle"  type="primary" style="margin-left: 240px;padding-left:30px;padding-right:30px;" >
                  新增
                </el-button>
                <!--
                <el-button v-if="formT.point" @click="addHandle"  type="primary" style="margin-left: 240px;padding-left:30px;padding-right:30px;" >
                  修改
                </el-button>-->
              </el-form-item>
            </el-col>
          </el-row>


        </el-form>
      </el-col>


      <el-col :span="24">
        <el-table
          :data="pointdatawyjg"
          border
          style="width: 100%;"
          :cell-style="{padding:'5px 0px'}"
          :header-cell-style="headcell"
        >
          <el-table-column
            prop="wyTypeIdvalue"
            label="物业类型" align="center">
          </el-table-column>
          <!--<el-table-column-->
          <!--prop="totalPrice"-->
          <!--label="楼盘总价"align="center">-->
          <!--</el-table-column>-->
          <el-table-column
            prop="referencePriceMax"
            label="均价最大" align="center">
          </el-table-column>

          <!--<el-table-column-->
          <!--prop="periodTime"-->
          <!--label="期数"align="center">-->
          <!--</el-table-column>-->

          <el-table-column
            prop="referencePriceMin"
            label="均价最小" align="center">
          </el-table-column>

          <el-table-column
            prop="totalPriceMax"
            label="总价最大" align="center">
          </el-table-column>

          <el-table-column
            prop="totalPriceMin"
            label="总价最小" align="center">
          </el-table-column>



          <el-table-column
            label="操作" align="center">
            <template slot-scope="scope">
              <el-button @click="deletClick(scope.row)" type="text" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>


  </section>
</template>
<script>
  export default {
    name: 'myWyprice',
    props:{
      formT:{
        type: Object,
        default: {}
      },
      formid: {
        type: String,
        default: ''
      },
      pointdatawyjg:{
        type: Array,
        default: []
      }
    },
    data() {
      return {
        pointdatawyjg_son:this.pointdatawyjg
      }
    },
    mounted() {
      this.queryLpPrice();
    },
    methods: {
      search(){
        this.pointdatawyjg_son=[];
        this.$emit('gesearch')
      },
      addHandle(){
        let this_ = this
        let params = this_.$api.getParam('bd8',{"lpid":this_.formid,"referencePriceMax":this_.formT.referencePriceMax,
          "referencePriceMin":this_.formT.referencePriceMin,"totalPriceMax":this_.formT.totalPriceMax,"wyTypeId":this_.formT.wyTypeId,
          "totalPriceMin":this_.formT.totalPriceMin})
        this_.$api.postData(this_, params,'/openapi/service',1).then((res) => {
          if(res.code == "0"){
            this_.$message("新增成功")
            this_.search();
            //this.queryLpPrice();
          }
        })
      },
      headcell(){
        return 'text-align: center;background:#eef1f6;'
      },
      turnOther(){
        window.open('http://api.map.baidu.com/lbsapi/getpoint/index.html', '_blank')
      },
      queryLpPrice(){
        let this_ = this
        let params = this_.$api.getParam('bd15',{"lpid":this_.formid})
        this_.$api.postData(this_, params,'/openapi/service',1).then((res) => {
          if(res.code == "0"){
            this.pointdatawyjg_son=[];
            this.pointdatawyjg_son=res.data;
          }
        })
      },
      deletClick(data){
        let this_ = this
        let params = this_.$api.getParam('bd16',{"wyprid":data.wyprid})
        this_.$api.postData(this_, params,'/openapi/service',1).then((res) => {
          if(res.code == "0"){
            this_.search();
          }
        })
      }
    }
  }
</script>
