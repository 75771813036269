<template>
  <div>
    <el-card>
      <!--导航栏-->
      <el-breadcrumb
        separatorClass="el-icon-arrow-right"
        style="font-size: 16px"
      >
        <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>新房管理</el-breadcrumb-item>
        <el-breadcrumb-item>商铺写字楼维护</el-breadcrumb-item>
      </el-breadcrumb>

      <!--搜索添加区域-->
      <el-row :gutter="25" style="font-size: 14px;margin:30px 0">
        <!--搜索区域-->
        <!--搜索添加-->
        <el-col :span="25" v-if="zhtype == 1">
          中国区域划分:<el-select clearable 
            v-model="xzladdForm.qybm"
            placeholder="请选择"
             style="margin-left: 10px;line-height: 32px;width:200px"
            @change="Selarea(xzladdForm.qybm)"
          >
            <el-option
              v-for="items in areaList"
              :value="items.qybm"
              :key="items.qybm"
              :label="items.qyname"
            >
            </el-option>
          </el-select>
          城市:<el-select clearable 
            v-model="xzladdForm.ctid"
            placeholder="请选择"
             style="margin-left: 10px;line-height: 32px;width:200px"
          >
            <el-option
              v-for="items in cityList"
              :value="items.ctid"
              :key="items.ctid"
              :label="items.ctname"
            >
            </el-option>
          </el-select>
          <el-button
            type="primary"
            icon="el-icon-search"
            style="width: 100px;margin-left: 80px"
            @click="getOfficeList"
            >搜索</el-button
          >
        </el-col>
        <!--添加按钮-->
        <el-col :span="2">
          <el-button
            type="primary"
            icon="el-icon-circle-plus"
            style="width:100px;margin-top:15px"
            @click="addXZL"
             v-if="zhtype == 1"
            >添加</el-button
          >
        </el-col>
      </el-row>

      <!--主体内容区域-->
      <template>
        <el-table
          :data="officeList"
          style="width: 100%;line-height:32px;font-size:18px"
          :cell-style="cellStyle"
          :header-cell-style="rowClass"
        >
        <el-table-column
            prop="xzlid"
            label="写字楼id"
            width="100"
            v-if="false"
          ></el-table-column>
          <el-table-column
            prop="ctname"
            label="城市"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="szlp"
            label="写字楼名称"
            width="200"
          ></el-table-column>
          <el-table-column prop="dj" label="均价" width="100">
          </el-table-column>
          <el-table-column
            prop="wylx"
            label="物业类型"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="zxzk"
            label="装修状态"
            width="150"
          ></el-table-column>
          <el-table-column
            prop="type"
            label="类型"
            width="150"
          ></el-table-column>
          <el-table-column
            prop="xzlbq"
            label="写字楼标签"
            width="150"
          ></el-table-column>
          <el-table-column label="操作" width="250" v-if="zhtype == 1">
            <template slot-scope="scope">
              <el-button type="primary" icon="el-icon-edit" @click="editOfs(scope.row)">编辑</el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                @click="delOfs(scope.row.xzlid)"
              >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
      <!--新增或修改商铺写字楼维护信息-->
      <el-dialog
        width="80%"
        :title="titleMap[dialogStatus]"
        :visible.sync="addDialogVisiable"
        @close="storeclose"
      >
        <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="楼盘基本信息" name="first">
            <base-info :area-list="areaList" :xzladdForm="xzladdForm" :ct="cityList" :isEdit="isEdit" @cancel="cancel" :lazy="true" @next="nextHander" @next1="nextHander1"></base-info>
          </el-tab-pane>
          <el-tab-pane label="相册视频维护" :disabled="cantab" name="fifth">
            <video-pict :formid="xzladdForm.xzlid" :xc="xc" @getimg="getImg"></video-pict>
          </el-tab-pane>
          <el-tab-pane label="地图坐标维护" :disabled="cantab" name="sixth">
            <map-info :formid="xzladdForm.xzlid" @getzb="getZb" :zb="zb" :zbInfo="zbInfo"></map-info>
          </el-tab-pane>
          <el-tab-pane label="经纪人信息" :disabled="cantab" name="seventh">
            <agent-info :formid="xzladdForm.xzlid" @getper="getPer" :jjrInfo="jjrInfo"></agent-info>
          </el-tab-pane>
          <el-tab-pane label="配套信息" :disabled="cantab" name="eighth">
            <support-info  :formid="xzladdForm.xzlid" @getpt="getPt" :pt="pt" :ptList="ptList"></support-info>
          </el-tab-pane>
          <el-tab-pane label="动态信息" :disabled="cantab" name="nine">
          <state-info :xzlid="xzladdForm.xzlid" :lpdt="lpdt" @getlpdt="getlpdt" ></state-info>
          </el-tab-pane>
        </el-tabs>
      </el-dialog>
      <!--分页-->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import BaseInfo from '@/components/office/BaseInfo'
import VideoPict from '@/components/office/VideoPict'
import MapInfo from '@/components/office/MapInfo'
import AgentInfo from '@/components/office/AgentInfo'
import SupportInfo from '@/components/office/SupportInfo'
import Vue from 'vue'
import StateInfo from '../office/StateInfo.vue'

export default {
  components: {
    BaseInfo,
    VideoPict,
    MapInfo,
    AgentInfo,
    SupportInfo,
    StateInfo,
  },

  created() {
    this.getOfficeList()
    this.SelQycodeAll()
    this.zhtype = localStorage.getItem('zhtype')
  },
  data() {
    return {
      zhtype: '',
      activeName: 'first', // 激活状态的tabs
      cantab: true, // tab是否禁用,true表禁用
      //查询信息实体
      queryInfo: {
        ctid: localStorage.getItem('bdcityid') || null,
        qybm: null, // 区域编码
        pageNum: 1, //当前页
        pageSize: 5, //每页最大数
      },
      xzladdForm: {
        xzlid: null,
        xzlbq: '',
        xxjs: '',
        wyf: '',
        sfkfg: '',
        sfkzc: '',
        lc: '',
        xzlty: '',
        jzmj: '',
        dz: '',
        szlp: '',
        syl: '',
        zxzk: '',
        dj: '',
        sj: '',
        klrq: '',
        jyzt: '',
        type: '',
        lmqybm: '',
        ctqybm: '',
        tradingAreaId: '',
        qybm: '',
        ctid: localStorage.getItem('bdcityid') || null,
        isdt: '',
        wylx: ''
      },
      areaList: [], // 区域
      cityList: [], // 城市
      adsList: [], // 辖区
      officeList: [], //商铺写字楼数据集
      total: 0, //写字楼总记录数
      addDialogVisiable: false, // 弹窗状态
      titleMap: {
        addEquipment: '新增写字楼信息',
        editEquipment: '修改写字楼信息',
      },
      dialogStatus: '', // 弹窗标题
      zbInfo: {}, // 坐标信息
      ptList: [], // 配套信息
      isEdit: false,
      zb: [], // 坐标
      pt: [], // 配套
       lpdt:[],//楼盘动态
      jjrInfo: [], // 经纪人
      xc: [], // 相册视频
    }
  },
  methods: {
    cancel(){
      this.addDialogVisiable = false
    },
    storeclose(){
      this.xzladdForm = {
        xzlid: 0,
        xzlbq: '',
        xxjs: '',
        wyf: '',
        sfkfg: '',
        sfkzc: '',
        lc: '',
        xzlty: '',
        jzmj: '',
        dz: '',
        szlp: '',
        syl: '',
        zxzk: '',
        dj: '',
        sj: '',
        klrq: '',
        jyzt: '',
        type: '',
        lmqybm: '',
        ctqybm: '',
        tradingAreaId: '',
        qybm: "",
        ctid: null,
        isdt: '',
        wylx: '',
      }
      this.getOfficeList()
    },
    // tab被点击时触发
    handleClick(){},
    
    // 查询坐标
    async getZb(){
      const { data: res } = await this.$http.get('/officepoint/list/xzlid?xzlid=' + this.xzladdForm.xzlid)
      this.zb = res.data
    },
    // 查配套
    async getPt(){
      const { data: res } = await this.$http.get('oassort/list', {
            params: {
                xzlid: this.xzladdForm.xzlid
            }
        })
        this.pt = res.data
    },
    // 查楼盘动态
             async getlpdt(){
            const { data: res } = await this.$http.get('/officedynamic/getOfficedynamic', {
                    params: {
                        xzlid: this.xzladdForm.xzlid,
                        pageNum:1,
                        pageSize:10
                    }
                })
                this.lpdt = res.data.records
            },
    // 查视频相册
    async getImg(){
      await this.$http.get('op/list?xzlid=' + this.xzladdForm.xzlid)
      .then(res => {this.xc = res.data.data})
    },
    // 查经纪人
    async getPer(){
      await this.$http.get('boz/list?xzlid=' + this.xzladdForm.xzlid)
      .then(res => this.jjrInfo = res.data.data)
    },
    // 改变tab状态
    async nextHander(value) {
      this.xzladdForm.ctid = null
      // this.getOfficeList()
      this.cantab = false
      this.xzladdForm.xzlid = value
      this.activeName = 'fifth'
      await this.getZb()
      await this.getPt()
    },
    async nextHander1(){
      // this.getOfficeList()
      this.activeName = 'fifth'
      await this.$http.get('/officepoint/list/xzlid?xzlid=' + this.xzladdForm.xzlid) // 查询坐标
      .then(res => {
        this.zbInfo = res.data
      })
      await this.$http.get('oassort/list?xzlid=' + this.xzladdForm.xzlid)  
      .then(res => {
        this.ptList = res.data.data
      })
    },
    
    // 写字楼添加按钮
    addXZL() {
      this.cantab = true
      this.activeName = 'first'
      this.dialogStatus = 'addEquipment'
      this.addDialogVisiable = true
      this.isEdit = false
      this.xzladdForm = {
        xzlid: 0,
        xzlbq: '',
        xxjs: '',
        wyf: '',
        sfkfg: '',
        sfkzc: '',
        lc: '',
        xzlty: '',
        jzmj: '',
        dz: '',
        szlp: '',
        syl: '',
        zxzk: '',
        dj: '',
        sj: '',
        klrq: '',
        jyzt: '',
        type: '',
        lmqybm: '',
        ctqybm: '',
        tradingAreaId: '',
        qybm: '',
        ctid: null,
        isdt: '',
        wylx: '',
      }
    },
    async Selarea(qybm) {
      this.queryInfo.pageNum = 1
      this.xzladdForm.ctid = null
      const { data: res } = await this.$http.get('city/sel?qybm=' + qybm)
      this.cityList = res.data
    },
    //将表格数据行居中
    cellStyle() {
      return 'text-align:center;'
    },
    //将表格数据列居中
    rowClass() {
      return 'text-align:center;'
    },
    //查询所有区域信息
    async SelQycodeAll() {
      const { data: res } = await this.$http.get('area/qycode')
      this.areaList = res.data
    },

    //显示所有商铺写字楼信息
    async getOfficeList() {
      const { data: res } = await this.$http.get('/ofs/list', {
        params: {
          qybm: this.xzladdForm.qybm,
          ctid: this.xzladdForm.ctid,
          pageNum: this.queryInfo.pageNum,
          pageSize: this.queryInfo.pageSize,
        },
      })
      this.officeList = res.data.records
      this.total = res.data.total
    },
    //最大数
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getOfficeList()
    },
    //pageNum的触发
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getOfficeList()
    },
    // 编辑写字楼信息
    async editOfs(info){
      console.log(info,222)
      // this.Selarea(info.qybm)
      this.xzladdForm = info
      this.activeName = 'first'
      this.cantab = false
      this.isEdit = true
      this.xzladdForm.xzlid = info.xzlid
      this.dialogStatus = 'editEquipment'
      this.addDialogVisiable = true
      this.getZb()
      this.getPt()
      this.getImg()
      this.getPer()
    },
    //根据Id删除写字楼信息
    async delOfs(xzlid) {
      const confirmResult = await this.$confirm(
        '删除后用户信息将永久删除，是否继续？',
        '提示',
        {
          confirmButtonText: '删除',
          cancelButtonText: '放弃',
        }
      ).catch((err) => err)
      if (confirmResult != 'confirm') {
        //取消
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete('/ofs/del?xzlid=' + xzlid)
      if (res.code != 200) {
        return this.$message.error('删除失败!')
      }
      this.$message.success('删除成功!')
      this.getOfficeList()
    },
  },
}
</script>
<style lang="less" scoped>
.el-card {
  height: 720px;
}
.el-table::before {
  width: auto;
}
::v-deep .el-form--inline .el-form-item__label {
  float: left;
}
.el-pagination{
    text-align: left;
}
</style>
