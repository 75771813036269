<template>
    <div>
        <el-card>
            <!--导航栏-->
            <el-breadcrumb separatorClass="el-icon-arrow-right" style="font-size: 16px">
                <el-breadcrumb-item :to="{path:'/welcome'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item >新房管理</el-breadcrumb-item>
                <el-breadcrumb-item >客户信息维护</el-breadcrumb-item>
            </el-breadcrumb>

            <el-row :gutter="25" style="font-size: 16px">
                <!--搜索区域-->
                <!--搜索添加-->
                <el-col :span="25">
                    姓名:<el-input placeholder="请输入姓名" style="width:200px;margin-right: 20px" v-model="queryInfo.khname"></el-input>
                    手机号:<el-input placeholder="请输入手机号" style="width:200px" v-model="queryInfo.khphone"></el-input>
                </el-col>
                <!--添加按钮-->
                <el-col :span="3">
                    <el-button type="primary" icon="el-icon-circle-plus" style="width:100px" @click="getUserList">查询</el-button>
                </el-col>
            </el-row>

            <!--客户信息主体内容-->
            <template>
                <el-table
                        :data="userList"
                        style="width: 100%;line-height: 32px; font-size: 18px">
                    <el-table-column
                        prop="khid"
                        label="客户id"
                        width="180">
                    </el-table-column>
                    <el-table-column
                            prop="khname"
                            label="姓名"
                            width="180">
                    </el-table-column>
                    <el-table-column
                            prop="khphone"
                            label="手机号"
                            width="180">
                    </el-table-column>
                    <el-table-column
                            prop="creatime"
                            label="注册时间">
                    </el-table-column>
                </el-table>
            </template>
            <!--分页-->
            <div>
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryInfo.pageNum"
                    :page-sizes="[5, 10, 15, 20, 30, 40, 50]"
                    :page-size="queryInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                    >
                </el-pagination>
            </div>
        </el-card>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                userList: [], // 用户列表
                queryInfo: {
                    khname: null,
                    khphone: null,
                    pageNum: 1,
                    pageSize: 5,
                },
                total: 0
            }
        },
        created(){
            this.getUserList()
        },
        methods: {
            // 查询
            // Search(){
            //     this.getUserList()
            // },
            // 获取客户信息列表
            async getUserList(){
                const {data:res} = await this.$http.get("/client/list", {params: this.queryInfo});
                this.userList = res.data.records
                this.total = res.data.total
            },
            //最大数
            handleSizeChange(newSize){
                this.queryInfo.pageSize = newSize;
                this.getUserList();
            },
            //pageNum的触发
            handleCurrentChange(newPage){
                this.queryInfo.pageNum = newPage;
                this.getUserList();
            },
        }
    }
</script>
<style lang="less" scoped>
// .el-card{
//     height: 720px;
// }
.el-pagination{
    text-align: left;
}
</style>