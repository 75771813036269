<template>
  <section>
    <!-- <el-form ref="" :model="pt" label-width="160px"> -->
      <!-- <el-form-item> -->
        <el-checkbox-group v-model="pxttList">
          <el-checkbox label="宽带"></el-checkbox>
          <el-checkbox label="水"></el-checkbox>
          <el-checkbox label="中央空调"></el-checkbox>
          <el-checkbox label="集中供暖"></el-checkbox>
          <el-checkbox label="免费车位"></el-checkbox>
          <el-checkbox label="员工餐厅"></el-checkbox>
        </el-checkbox-group>
      <!-- </el-form-item> -->
      <!-- <el-form-item > -->
        <el-button  type="primary" @click="addPt" style="padding-left:30px;padding-right:30px;">新增</el-button>
      <!-- </el-form-item>  -->
    <!-- </el-form> -->
    <el-table
      :data="pt"
      border
      style="width: 100%;"
      :cell-style="{ padding: '5px 0px' }"
      :header-cell-style="headcell"
    >
      <el-table-column prop="ptxx" label="已添加配套信息" align="center">
      </el-table-column>
      <el-table-column prop="xzlpdid" label="配套id" width="200" align="center" v-if="false">
      </el-table-column>

      <el-table-column prop="address" label="操作" align="center">
        <template slot-scope="scope">
          <el-button @click="delPt(scope.row)" type="text" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

  </section>
</template>
<script>
  export default {
    name: 'myWay',
    props:["ptList", 'formid', "pt"],
    data() {
      return {
          pxttList: [],
          ptinfo: {},
          // pt: []
      }
    },
    watch: {
        ptList: {
            handler(){
                this.ptinfo = this.ptList
            },
            immediate: true
        }
    },
    methods: {
        headcell() {
            return 'text-align: center;background:#eef1f6;'
        },
      // 查询配套信息
      // async selectPt(){
      //   const { data: res } = await this.$http.get('oassort/list', {
      //       params: {
      //           xzlid: this.formid
      //       }
      //   })
      //   this.ptinfo = res.data[0]
      //   this.pt = res.data
      // },
      // 添加配套信息
      async addPt(){
        if(this.pxttList.length==0){
          this.$message.error("请选择配套信息")
        }else{
          const { data: res } = await this.$http.post('oassort/ins', {
              pxttList: this.pxttList,
              xzlid: this.formid
          })
          if(res.code == "200"){
              this.$message.success("新增成功")
              this.pxttList = []
              this.$emit('getpt')
          }
        }
      },
      // 删除配套
      async delPt(info){
        const { data: res } = await this.$http.delete('oassort/del?xzlpdid=' + info.xzlpdid)
        this.$emit('getpt')
      },
    }
  }
</script>
<style scoped>
.el-form-item{
  margin-bottom: 10px;
}
</style>