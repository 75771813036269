<template>
  <div>
    <!--搜索添加区域-->
    <table style="margin-bottom: 30px">
      <tr style="font-size: 14px">
        <td style="display: flex;margin-right: 30px">
          <span style="width:50px;margin-left:20px">城市:</span>
          <el-select
            clearable
            v-model="queryInfo.ctid"
            placeholder="请选择城市"
            @change="Selcity(queryInfo.ctid)"
          >
            <el-option
              v-for="items in cityList"
              :value="items.ctid"
              :key="items.ctid"
              :label="items.ctname"
            ></el-option>
          </el-select>
          <span style="width:50px;margin-left:20px">辖区:</span>
          <el-select
            clearable
            v-model="queryInfo.ctqybmid"
            placeholder="请选择"
            @change="SelBusiness(queryInfo.ctqybmid)"
          >
            <el-option
              v-for="items in adsList"
              :value="items.ctqybm"
              :key="items.ctqybm"
              :label="items.ctqyname"
            >
            </el-option>
          </el-select>
          <span style="width:50px;margin-left:20px">商圈:</span>
          <el-select
            clearable
            v-model="queryInfo.tradingAreaid"
            placeholder="请选择"
          >
            <el-option
              v-for="items in businessList"
              :value="items.tradingAreaId"
              :key="items.tradingAreaId"
              :label="items.sqname"
            >
            </el-option>
          </el-select>
          <span style="width:100px;margin-left:20px">所属经纪人:</span>
          <el-select
            clearable
            v-model="queryInfo.brokerid"
            placeholder="请选择"
          >
            <el-option
              v-for="items in brokerList"
              :value="items.brokerid"
              :key="items.brokerid"
              :label="items.brokername"
            >
            </el-option>
          </el-select>
        </td>
        <td>
          <el-button type="primary" icon="el-icon-search" @click="getList"
            >查询</el-button
          >
          <el-button type="primary" icon="el-icon-circle-plus" @click="addInfo"
            >新增租房</el-button
          >
        </td>
      </tr>
    </table>

    <!--主体内容表单-->
    <template>
      <el-table
        :data="list"
        border
        style="width: 100%;line-height: 12px; font-size: 18px"
        :cell-style="cellStyle"
        :header-cell-style="rowClass"
      >
        <el-table-column
          prop="ctname"
          label="城市"
          width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="ctqyname"
          label="区域"
          width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="sqname"
          label="商圈"
          width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="runit"
          label="户型"
          width="200"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="rlocation"
          label="地址"
          width="400"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              class="el-icon-edit"
              type="primary"
              @click="changeInfo(scope.row)"
              >编辑</el-button
            >
            <el-button
              class="el-icon-delete"
              type="danger"
              @click="delInfo(scope.row.rid)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </template>

    <!-- 弹窗 -->
    <el-dialog
      width="80%"
      :title="titleMap[dialogStatus]"
      :visible.sync="DialogVisiable"
      @close="cancel"
      :before-close="handleClose"
      :close-on-click-modal="false"
      top="20px"
      :modal="true"
      :lock-scroll="true"
      class="dialogHeader"
      center
    >
      <el-tabs type="border-card" v-model="activeName">
        <el-tab-pane label="租房基本信息" name="first">
          <el-form
            :inline="true"
            :model="dialogInfo"
            ref="aaddFormRef"
            style="padding:30px"
            label-width="120px"
          >
            <!-- 第一排 -->
            <el-row>
              <el-col :span="6">
                <el-form-item
                  label="城市"
                  prop="ctid"
                  style="display:flex;align-items:center"
                >
                  <el-select
                    clearable
                    style="width: 200px;"
                    v-model="dialogInfo.ctid"
                    placeholder="请选择"
                    @change="Selcity(dialogInfo.ctid)"
                  >
                    <el-option
                      v-for="items in cityList"
                      :value="items.ctid"
                      :key="items.ctid"
                      :label="items.ctname"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="辖区"
                  prop="ctqybmid"
                  style="display:flex;align-items:center"
                >
                  <el-select
                    clearable
                    v-model="dialogInfo.ctqybmid"
                    placeholder="请选择"
                    style="width: 200px;"
                    @change="SelBusiness(dialogInfo.ctqybmid)"
                  >
                    <el-option
                      v-for="items in adsList"
                      :value="items.ctqybm"
                      :key="items.ctqybm"
                      :label="items.ctqyname"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="商圈"
                  prop="ctqybmid"
                  style="display:flex;align-items:center"
                >
                  <el-select
                    clearable
                    v-model="dialogInfo.tradingAreaid"
                    placeholder="请选择"
                    style="width: 200px;"
                  >
                    <el-option
                      v-for="items in businessList"
                      :value="items.tradingAreaId"
                      :key="items.tradingAreaId"
                      :label="items.sqname"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="所属经纪人"
                  prop="brokerid"
                  style="display:flex;align-items:center"
                >
                  <el-select
                    clearable
                    v-model="dialogInfo.brokerid"
                    placeholder="请选择"
                    style="width:200px"
                  >
                    <el-option
                      v-for="items in brokerList"
                      :value="items.brokerid"
                      :key="items.brokerid"
                      :label="items.brokername"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 第二排 -->
            <el-row>
              <el-col :span="6">
                <el-form-item
                  label="标题"
                  prop="rtitle"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.rtitle"
                    placeholder="请输入标题"
                    style="width:230px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="面积"
                  prop="rarea"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.rarea"
                    placeholder="请输入面积"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="户型"
                  prop="runit"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.runit"
                    placeholder="请输入户型"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="地理位置"
                  prop="rlocation"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.rlocation"
                    placeholder="请输入地理位置"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 第三排 -->
            <el-row>
              <el-col :span="6">
                <el-form-item
                  label="装修情况"
                  prop="decoration"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.decoration"
                    placeholder="请输入装修情况"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="朝向"
                  prop="orientation"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.orientation"
                    placeholder="请输入朝向"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="所在楼层"
                  prop="rlevel"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.rlevel"
                    placeholder="请输入所在楼层"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="水电类型"
                  prop="hydropower"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.hydropower"
                    placeholder="请输入水电类型"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 第四排 -->
            <el-row>
              <el-col :span="6">
                <el-form-item
                  label="评分"
                  prop="rscore"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.rscore"
                    placeholder="请输入评分"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="租金"
                  prop="rental"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.rental"
                    placeholder="请输入租金"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="有无电梯"
                  prop="iselevator"
                  style="display:flex;align-items:center"
                >
                  <el-select
                    clearable
                    v-model="dialogInfo.iselevator"
                    placeholder="请选择"
                    style="width: 200px;"
                  >
                    <el-option label="有" value="1"></el-option>
                    <el-option label="无" value="0"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="配套设施"
                  prop="facilityList"
                  style="display:flex;align-items:center"
                >
                  <el-select
                    clearable
                    multiple
                    v-model="dialogInfo.facilityList"
                    placeholder="请选择"
                    style="width: 200px;"
                  >
                    <el-option
                      v-for="item in deviceList"
                      :key="item.aaa101"
                      :label="item.aaa103"
                      :value="item.aaa101"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 第五排 -->
            <el-row>
              <!-- <h3 style="margin-left: 80px">房源须知：</h3> -->
              <el-col :span="6">
                <el-form-item
                  label="入住须知"
                  prop="inrule"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.inrule"
                    placeholder="请输入入住须知"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="退房须知"
                  prop="outrule"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.outrule"
                    placeholder="请输入退房须知"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="住宿期间须知"
                  prop="rulesList"
                  style="display:flex;align-items:center"
                >
                  <el-select
                    clearable
                    multiple
                    v-model="dialogInfo.rulesList"
                    placeholder="请选择"
                    style="width: 200px;"
                  >
                    <el-option
                      v-for="item in noticeList"
                      :key="item.aaa101"
                      :label="item.aaa103"
                      :value="item.aaa101"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row style="margin-top:20px">
              <el-col :span="6">
                <el-form-item label="封面">
                  <el-upload
                    class="avatar-uploader"
                    action="https://ddfun.baifun.cn/oss/uploadFileReturnUrl"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                  >
                    <img
                      v-if="dialogInfo.rentcover"
                      :src="dialogInfo.rentcover"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col :span="18">
                <template>
                  <div class="editorBox quill-wrap" style="display:flex">
                    <span>房源概况：</span>
                    <quill-editor
                      v-model="dialogInfo.roverviewStr"
                      ref="myQuillEditor"
                      :options="editorOption"
                    >
                    </quill-editor>
                  </div>
                </template>
              </el-col>
            </el-row>
            <el-form-item>
              <el-button
                type="primary"
                style="margin-top: 50px;"
                @click="handelConfirm"
                >确认</el-button
              >
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="租房相册信息" name="second" :disabled="cantab">
          <el-row>
            <el-col :span="24" style="margin-bottom: 20px;">
              <upload-img
                ref="uploadImg"
                @addvp="uploadClick"
                @delImg="delImg"
              ></upload-img>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-button @click="addHandle" type="primary" style="">
                新增
              </el-button>
            </el-col>
          </el-row>
          <el-table
            :data="imgList"
            border
            style="width: 100%;"
            :cell-style="{ padding: '5px 0px' }"
            :header-cell-style="headcell"
          >
            <el-table-column
              prop="cxfmtpurl"
              label="相册路径"
              align="center"
              width="600"
            >
            </el-table-column>
            <el-table-column prop="cxfmtpurl" label="图片展示" align="center">
              <template slot-scope="scope">
                <img
                  :src="scope.row.cxfmtpurl"
                  alt=""
                  srcset=""
                  style="width: 80px;height: 40px"
                />
              </template>
            </el-table-column>
            <el-table-column prop="address" label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  @click="deletClick(scope.row)"
                  type="text"
                  size="small"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
// require styles 引入样式
import { quillEditor, Quill } from 'vue-quill-editor'
import { container, ImageExtend, QuillWatch } from 'quill-image-extend-module'
Quill.register('modules/ImageExtend', ImageExtend)
import '@/assets/imagePaste'
import uploadImg from '@/components/upload/uploadImg.vue'
export default {
  components: { quillEditor, uploadImg },
  props: ['communityid'],
  data() {
    return {
      editorOption: {
        modules: {
          // imageDrop: true,
          ImageExtend: {
            // 如果不作设置，即{}  则依然开启复制粘贴功能且以base64插入
            loading: true,
            name: 'file', // 图片参数名
            size: 3, // 可选参数 图片大小，单位为M，1M = 1024kb
            action: 'https://ddfun.baifun.cn/oss/uploadFileReturnUrl', // 服务器地址, 如果action为空，则采用base64插入图片
            // response 为一个函数用来获取服务器返回的具体图片地址
            // 例如服务器返回{code: 200; data:{ url: 'baidu.com'}}
            // 则 return res.data.url
            response: (res) => {
              return res.filepath
            },

            sizeError: () => {}, // 图片超过大小的回调
            start: () => {}, // 可选参数 自定义开始上传触发事件
            end: () => {}, // 可选参数 自定义上传结束触发的事件，无论成功或者失败
            error: () => {}, // 可选参数 上传失败触发的事件
            success: () => {}, // 可选参数  上传成功触发的事件
            change: (xhr, formData) => {}, // 可选参数 每次选择图片触发，也可用来设置头部，但比headers多了一个参数，可设置formData
          },
          toolbar: {
            // 如果不上传图片到服务器，此处不必配置
            container: container,
            handlers: {
              image: function() {
                // 劫持原来的图片点击按钮事件
                QuillWatch.emit(this.quill.id)
              },
            },
          },
          imagePaste: {
            addImageBlob: function(blob, callback) {
              var formData = new FormData()
              formData.append('file', blob)
              // your upload function, get the uploaded image url, add then
              let config = {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Accept: '*/*',
                },
              }
              self.$http
                .post('/oss/uploadFileReturnUrl', formData, config)
                .then((res) => {
                  var imageUrl = res.data.filepath
                  callback(imageUrl)
                })
            },
          },
        },
      },
      activeName: 'first',
      dialogStatus: '', //新增弹窗状态
      DialogVisiable: false,
      titleMap: {
        addEquipment: '新增租房信息',
        editEquipment: '修改租房信息',
      },
      //查询信息实体
      queryInfo: {
        pageNum: 1, //当前页
        pageSize: 6, //每页最大数
        ctid: null,
        ctqybmid: null,
        tradingAreaid: null,
        brokerid: null,
        comid: this.communityid,
      },
      // 新增、编辑信息
      dialogInfo: {
        comid: this.communityid,
        rid: null,
        rental: null,
        inrule: null,
        outrule: null,
        rarea: null,
        runit: null,
        roverviewStr: null,
        rlocation: null,
        decoration: null,
        orientation: null,
        rlevel: null,
        hydropower: null,
        rscore: null,
        iselevator: null,
        rtitle: null,
        facilityList: [],
        rulesList: [],
        brokerid: null,
        rentcover: null,
        ctid: null,
        ctqybmid: null,
        tradingAreaid: null,
      },
      cantab: true,
      rid: '', // 租房id
      imgInfo: [], // 新增相册信息
      imgList: [], // 相册列表
      total: 0, //总记录数
      list: [], // 租房列表
      areaList: [], // 区域列表
      cityList: [], // 城市列表
      adsList: [], // 辖区列表
      businessList: [], // 商圈列表
      brokerList: [], // 经纪人列表
      deviceList: [],
      noticeList: []
    }
  },
  created() {
    this.getList()
    this.SelBroker()
    this.Selarea()
    this.getDevice()
    this.getNotice()
  },
  methods: {
    // 封面上传
    handleAvatarSuccess(res, file) {
      this.dialogInfo.rentcover = file.response.filepath
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error('上传附件只能是 JPG或PNG 格式!')
      }
      if (!isLt5M) {
        this.$message.error('上传附件大小不能超过 5M!')
      }
      return isJPG && isLt5M
    },
    // 获取配套设施列表
    async getDevice() {
      await this.$http.get('/dic/aaa100?aaa100=FACILITYTYPE').then((res) => {
        this.deviceList = res.data.data
      })
    },
    // 获取住宿须知列表
    async getNotice() {
      await this.$http.get('/dic/aaa100?aaa100=RULETYPE').then((res) => {
        this.noticeList = res.data.data
      })
    },
    // 查询城市列表
    async Selarea() {
      const { data: res } = await this.$http.get('city/sel')
      this.cityList = res.data
    },
    // 根据城市查询辖区列表
    async Selcity(ctid) {
      const { data: res } = await this.$http.get('ads/sel', {
        params: { ctid: ctid },
      })
      this.adsList = res.data
    },
    // 根据辖区查询商圈列表
    async SelBusiness(ctqybm) {
      const { data: res } = await this.$http.get(
        'newhome/ctqybm?ctqybm=' + ctqybm,
        {
          params: {
            pageNum: 1,
            pageSize: 1000,
          },
        }
      )
      this.businessList = res.data.records
    },
    // 查询经纪人列表
    async SelBroker() {
      const { data: res } = await this.$http.get('borker/borkerlist', {
        params: {
          pageNum: 1, //当前页
          pageSize: 1000, //每页最大数
          brokertype: 1, // 经纪人类型（1:社区经纪人、2:别墅文旅经纪人））
        },
      })
      this.brokerList = res.data.records
    },
    //查询租房列表
    async getList() {
      const { data: res } = await this.$http.get('/rent/rentlist', {
        params: this.queryInfo,
      })
      this.list = res.data.records
      this.rid = res.data.records[0].rid
      this.total = res.data.total
    },
    // 新增
    addInfo() {
      this.dialogStatus = 'addEquipment'
      this.DialogVisiable = true
    },
    // 修改
    async changeInfo(info) {
      this.cantab = false
      this.rid = info.rid
      this.getImg()
      this.dialogStatus = 'editEquipment'
      this.DialogVisiable = true
      const { data: res } = await this.$http.get(
        '/rent/rentInfo?rid=' + info.rid
      )
      let detail = res.data[0]
      if (detail.facilityId == '') {
        detail.facilityId = null
      } else if (detail.facilityId) {
        detail.facilityId = detail.facilityId.split(',')
      }
      if (detail.rulesId == '') {
        detail.rulesId = null
      } else if (detail.rulesId) {
        detail.rulesId = detail.rulesId.split(',')
      }
      this.dialogInfo = {
        comid: this.communityid,
        rid: detail.rid,
        rental: detail.rental,
        inrule: detail.inrule,
        outrule: detail.outrule,
        rarea: detail.rarea,
        runit: detail.runit,
        roverviewStr: detail.roverviewStr,
        rlocation: detail.rlocation,
        decoration: detail.decoration,
        orientation: detail.orientation,
        rlevel: detail.rlevel,
        hydropower: detail.hydropower,
        rscore: detail.rscore,
        iselevator: detail.iselevator,
        rtitle: detail.rtitle,
        facilityList: detail.facilityId,
        rulesList: detail.rulesId,
        brokerid: Number(detail.brokerid),
        rentcover: detail.rentcover,
        ctid: Number(detail.ctid),
        ctqybmid: Number(detail.ctqybmid),
        tradingAreaid: detail.tradingAreaid,
      }
    },
    //删除
    async delInfo(id) {
      const confirmResult = await this.$confirm(
        '删除后用户信息将永久删除，是否继续？',
        '提示',
        {
          confirmButtonText: '删除',
          cancelButtonText: '放弃',
        }
      ).catch((err) => err)
      if (confirmResult != 'confirm') {
        //取消
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete('/rent/delrent?rid=' + id)
      if (res.code != 200) {
        return this.$message.error('删除失败!')
      }
      this.$message.success('删除成功!')
      this.getList()
    },
    // 确认
    async handelConfirm() {
      if (
        !this.dialogInfo.rental ||
        !this.dialogInfo.rarea ||
        !this.dialogInfo.runit ||
        !this.dialogInfo.roverviewStr ||
        !this.dialogInfo.rlocation ||
        !this.dialogInfo.decoration ||
        !this.dialogInfo.orientation ||
        !this.dialogInfo.rlevel ||
        !this.dialogInfo.hydropower ||
        !this.dialogInfo.rscore ||
        !this.dialogInfo.iselevator ||
        !this.dialogInfo.rtitle ||
        !this.dialogInfo.rlevel ||
        !this.dialogInfo.brokerid ||
        !this.dialogInfo.rentcover ||
        !this.dialogInfo.ctid ||
        !this.dialogInfo.ctqybmid ||
        !this.dialogInfo.tradingAreaid
      ) {
        this.$message.error('请完整所有信息！')
        return
      }
      // 新增
      if (this.dialogStatus == 'addEquipment') {
        const { data: res } = await this.$http.post(
          'rent/insrent',
          this.dialogInfo
        )
        if (res.code == '200') {
          this.$message.success('新增成功')
          this.getList()
          setTimeout(() => {
            this.getImg()
          }, 500)
          this.cantab = false
          this.activeName = 'second'
        } else {
          this.$message.error('新增失败')
        }
        // 修改
      } else if (this.dialogStatus == 'editEquipment') {
        const { data: res } = await this.$http.put(
          'rent/uplrent',
          this.dialogInfo
        )
        if (res.code == '200') {
          this.$message.success('修改成功')
          this.DialogVisiable = false
          this.getList()
        } else {
          this.$message.error('修改失败')
        }
      }
    },
    // 取消
    cancel() {
      this.DialogVisiable = false
      this.cantab = true
      this.activeName = 'first'
      this.dialogInfo = {
        comid: this.communityid,
        rid: null,
        rental: null,
        inrule: null,
        outrule: null,
        rarea: null,
        runit: null,
        roverviewStr: null,
        rlocation: null,
        decoration: null,
        orientation: null,
        rlevel: null,
        hydropower: null,
        rscore: null,
        iselevator: null,
        rtitle: null,
        facilityList: [],
        rulesList: [],
        brokerid: null,
        rentcover: null,
        ctid: null,
        ctqybmid: null,
        tradingAreaid: null,
      }
    },
    // 查询相册列表
    async getImg() {
      await this.$http
        .get('rent/rentphotolist?rid=' + this.rid, {
          params: {
            pageNum: 1,
            pageSize: 1000,
          },
        })
        .then((res) => {
          this.imgList = res.data.data.records
        })
    },
    // 点击上传
    uploadClick(val) {
      let f = {}
      f.cxfmtpurl = val
      f.rid = this.rid
      this.imgInfo.push(f)
    },
    // 删除未上传图片
    delImg(val) {
      this.imgInfo = this.imgInfo.filter(
        (item) => item.cxfmtpurl != val.filepath
      )
    },
    // 新增图片
    async addHandle() {
      await this.$http.post('rent/insrentphoto', this.imgInfo).then((res) => {
        if (res.data.code == '200') {
          this.$message.success('新增成功')
          this.$refs.uploadImg.clear()
        } else {
          this.$message.error('新增失败')
        }
      })
      this.imgInfo = []
      this.getImg()
    },
    // 删除图片
    async deletClick(info) {
      await this.$http.delete('rent/delrentphoto?id=' + info.id).then((res) => {
        if (res.data.code == '200') {
          this.$message.success('删除成功')
          this.getImg()
        } else {
          this.$message.error('删除失败')
        }
      })
    },
    headcell() {
      return 'text-align: center;background:#eef1f6;'
    },
    onEditorBlur() {
      // 失去焦点事件
    },
    onEditorFocus() {
      // 获得焦点事件
    },
    onEditorChange() {
      // 内容改变事件
    },
    //最大数
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getList()
    },
    //pageNum的触发
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getList()
      this.$forceUpdate()
    },
    //将表格数据行居中
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center;'
    },
    //将表格数据列居中
    rowClass({ row, rowIndex }) {
      return 'text-align:center;'
    },
  },
}
</script>

<style scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px dashed #d9d9d9 !important;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
::v-deep .quill-editor .ql-editor {
  height: 250px;
}
</style>
