<template>
  <section>
    <el-row>
      <el-col :span="18">
        <el-form ref="form" :model="form" label-width="100px" label-position="right">
          <el-row>
            <el-col :span="12">
              <el-form-item label="姓名">
                <el-input v-model="form.jjrname" style="width: 200px;" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="电话号码">
                <el-input v-model="form.jjrphone" style="width: 200px;" ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="微信头像">
                <upload-img @addvp="uploadClickTx"></upload-img>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="微信二维码">
                <upload-img @addvp="uploadClick"></upload-img>
              </el-form-item>
            </el-col>
          </el-row>
          
          <el-form-item>
            <el-button v-if="!form.jjrid"  @click="addHandle" type="primary" style="margin-left: 240px;padding-left:30px;padding-right:30px;" >
              新增
            </el-button>
            <el-button v-if="form.jjrid"   @click="form={};myurl='';myurlTx=''" style="margin-left: 85px;padding-left:30px;padding-right:30px;" >
              取消
            </el-button>
            <!-- <el-button v-if="form.jjrid"  @click="onSubmit" type="primary" style="margin-left: 260px;padding-left:30px;padding-right:30px;" >
              新增
            </el-button> -->
          </el-form-item>
        </el-form>
      </el-col>
     
    </el-row>
    <el-table
      :data="personinfo"
      border
      style="width: 100%;" 
      :cell-style="{padding:'5px 0px'}"
      :header-cell-style="headcell"
      >
      <el-table-column
        prop="jjrid"
        label="经纪人id"
        width="200" align="center" v-if="false">
      </el-table-column>
      <el-table-column
        prop="xqname"
        label="所在小区"
        width="200" align="center">
      </el-table-column>
      <el-table-column
        prop="jjrname"
        label="经纪人"
        width="200" align="center">
      </el-table-column>
      <el-table-column
      prop="jjrphone"
      label="经纪人电话"
      width="200" align="center">
    </el-table-column>
     <el-table-column
      prop="jjrewm"
      label="经纪人微信二维码"
      width="200" align="center">

      <template slot-scope="scope">
        <img :src="scope.row.jjrewm" alt="" srcset="" style="width: 100px;height: 100px" v-if="scope.row.jjrewm">
        <span v-if="!scope.row.jjrewm">未上传</span>
      </template>
    </el-table-column>
    <el-table-column
      prop="txurl"
      label="经纪人微信头像"
      width="200" align="center">
      <template slot-scope="scope">
        <img :src="scope.row.txurl" alt="" srcset="" style="width: 100px;height: 100px" v-if="scope.row.txurl">
        <span v-if="!scope.row.txurl">未上传</span>
      </template>
    </el-table-column>
    
      <el-table-column
        label="操作" align="center">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small">修改</el-button>
          <el-button type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

  </section>
</template>
<script>
  import UploadImg from '@/components/upload/uploadImg.vue'
  export default {
    name: 'myPerson',
    components:{
      UploadImg
    },
    props: ["formid", "personinfo"],
    data() {
      return {
        form: {},
        txurl:'', // 微信头像
        jjrewm:'', // 微信二维码
        jjrid: 0
      }
    },
    mounted(){
      // this.searchData()
    },
    methods: {
      uploadClickTx(val){
        this.form.txurl = val
      },
      uploadClick(val){
        this.form.jjrewm = val
      },

      // 新增经纪人信息
      async addHandle(){
        await this.$http.post('borker/ins', this.form)
        .then(res => {
          this.jjrid = res.data.value
        })
        const { data: res } = await this.$http.post('bos/ins', {
          esfid: this.formid,
          jjrid: this.jjrid
        })
        this.$emit('getper')
      },
      
      headcell(){
        return 'text-align: center;background:#eef1f6;'
      }
    }
  }
</script>