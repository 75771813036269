<template>
    <div>
        <img src="../assets/login/homepag.png" height="150" width="172"/>
        <h2 style="color: darkgrey;margin-top: -50px">欢迎使用多点房后台管理系统</h2>
    </div>
</template>
<script>
    export default{

    }
</script>
<style lang="less" scoped>

</style>