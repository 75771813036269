<template>
  <section>
    <el-row>
      <el-col :span="18">
        <el-form
          ref="form"
          :model="form"
          label-width="100px"
          label-position="right"
        >
          <el-row>
            <el-col :span="8">
              <el-form-item label="户型名称">
                <el-input
                  v-model="form.hxname"
                  style="width: 200px;"
                   
                ></el-input>
                <!-- <el-select clearable v-model="form.hx" placeholder="请选择户型" style="width: 200px;">
                  <el-option
                    v-for="(i, k) in allDic.HX"
                    :key="k"
                    :value="i.aaa101"
                    :label="i.aaa103"
                  ></el-option>
                </el-select> -->
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="户型朝向">
                <!--<el-input v-model="form.hxcx" style="width: 200px;"  ></el-input>-->
                <el-select
                  clearable
                  v-model="form.hxcx"
                  placeholder="请选择户型朝向"
                  style="width: 200px;"
                >
                  <el-option label="南北通透" value="66"></el-option>
                  <el-option label="东西朝向" value="67"></el-option>
                  <el-option label="南向" value="68"></el-option>
                  <el-option label="东向" value="69"></el-option>
                  <el-option label="西向" value="70"></el-option>
                  <el-option label="北向" value="71"></el-option>
                  <el-option label="东南朝向" value="74"></el-option>
                  <el-option label="东北朝向" value="73"></el-option>
                  <el-option label="西北朝向" value="72"></el-option>
                  <el-option label="西南朝向" value="75"></el-option>
                  <el-option label="南北朝向" value="80"></el-option>
                  <el-option label="其他" value="77"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="户型总价(万元)">
                <el-input
                  v-model="form.hxzj"
                  style="width: 200px;"
                   
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="户型几室">
                <el-input
                  v-model="form.room"
                  style="width: 200px;"
                   
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="户型几厅">
                <el-input
                  v-model="form.hall"
                  style="width: 200px;"
                   
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="户型几卫">
                <el-input
                  v-model="form.toilet"
                  style="width: 200px;"
                   
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="户型几厨">
                <el-input
                  v-model="form.kitchen"
                  style="width: 200px;"
                   
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="户型套内面积">
                <el-input
                  v-model="form.insideArea"
                  style="width: 200px;"
                   
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="户型均价">
                <el-input
                  v-model="form.referencePrice"
                  style="width: 200px;"
                   
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="户型销售状态">
                <!--<el-input v-model="form.hxsf" style="width: 100px;"  ></el-input>-->
                <el-select
                  clearable
                  v-model="form.houseTypeStatus"
                  placeholder="请选择户型销售状态"
                  style="width: 200px;"
                >
                  <el-option label="主推" value="4"></el-option>
                  <el-option label="待售" value="1"></el-option>
                  <el-option label="在售" value="2"></el-option>
                  <el-option label="售罄" value="3"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="户型建筑面积">
                <el-input
                  v-model="form.jzmj"
                  style="width: 200px;"
                   
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="户型咨询电话">
                <el-input
                  v-model="form.zxdt"
                  style="width: 200px;"
                   
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="户型面积描述">
                <el-input
                  v-model="form.hxmj"
                  style="width: 200px;"
                   
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="户型解析">
                <el-input
                  v-model="form.hxanalyse"
                  style="width: 200px;"
                   
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="户型亮点1">
                <el-input
                  v-model="form.hxlightspot1"
                  style="width: 200px;"
                   
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="户型亮点2">
                <el-input
                  v-model="form.hxlightspot2"
                  style="width: 200px;"
                   
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="户型亮点3">
                <el-input
                  v-model="form.hxlightspot3"
                  style="width: 200px;"
                   
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <h2 style="margin: 30px;border-top:2px solid #f2f2f2">
            移动端户型解析：
          </h2>
          <el-row>
            <el-col :span="8">
              <el-form-item label="客厅">
                <el-input
                  v-model="form.hallanalyse"
                  style="width: 200px;"
                   
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="餐厅">
                <el-input
                  v-model="form.canteenanalyse"
                  style="width: 200px;"
                   
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="厨房">
                <el-input
                  v-model="form.kitchenanalyse"
                  style="width: 200px;"
                   
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="卧室">
                <el-input
                  v-model="form.roomanalyse"
                  style="width: 200px;"
                   
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="卫生间">
                <el-input
                  v-model="form.toiletanalyse"
                  style="width: 200px;"
                   
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item>
                <el-button   @click="addHxInfo" type="primary">
                  新增
                </el-button>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
              <el-form-item>
                <el-button    @click="sendbdHuXingClick();" type="primary"  >
                  户型基础信息推送百度
                </el-button>
                <el-button    @click="sendbdHuXingImageClick();" type="primary"  >
                  户型图推送百度
                </el-button>
              </el-form-item>
            </el-col> -->
          </el-row>
        </el-form>
      </el-col>
      <el-col :span="6" style="margin-bottom: 20px;">
        <upload-img @addvp="uploadClick" ref="uploadImg"></upload-img>
      </el-col>
    </el-row>
    <el-table
      :data="hxtList"
      border
      style="width: 100%;"
      :cell-style="{ padding: '5px 0px' }"
      :header-cell-style="headcell"
    >
      <el-table-column
        prop="lpname"
        label="楼盘名称"
        width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="hxmj" label="户型描述" width="100" align="center">
      </el-table-column>
      <el-table-column
        prop="hxname"
        label="户型名称"
        width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="jzmj" label="建筑面积" width="100" align="center">
      </el-table-column>
      <el-table-column
        prop="insideArea"
        label="套内面积"
        width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="houseTypeStatus"
        label="销售状态"
        width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="hxcx" label="朝向" width="60" align="center">
        <template scope="scope">
          {{ scope.row.hxcx | cxFilter }}
        </template>
      </el-table-column>
      <el-table-column
        prop="referencePrice"
        label="户型均价"
        width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="hxzj" label="户型总价" width="100" align="center">
      </el-table-column>
      <el-table-column prop="room" label="室" width="60" align="center">
      </el-table-column>
      <el-table-column prop="hall" label="厅" width="60" align="center">
      </el-table-column>
      <el-table-column prop="toilet" label="卫" width="60" align="center">
      </el-table-column>
      <el-table-column prop="kitchen" label="厨" width="60" align="center">
      </el-table-column>
      <el-table-column
        prop="isOpenRoom"
        label="是否为开间"
        width="100"
        align="center"
      >
        <template scope="scope">
          {{ scope.row.isOpenRoom | openRoom }}
        </template>
      </el-table-column>
      <el-table-column prop="hxturl" label="户型图" width="100" align="center">
        <template slot-scope="scope">
          <img
            :src="scope.row.hxturl"
            alt=""
            srcset=""
            style="width: 50px;height: 50px"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="hxturl"
        label="图片路径"
        width="250"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="address" label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="updateHandle(scope.row)"
            >修改</el-button
          >
          <el-button type="text" size="small" @click="deletHandle(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 修改弹窗 -->
    <el-dialog
      width="70%"
      title="修改户型信息"
      :visible.sync="showEdit"
      @close="cancel"
      :before-close="handleClose"
      append-to-body
    >
      <el-row>
        <el-col :span="18">
          <el-form
            ref="editInfo"
            :model="editInfo"
            label-width="100px"
            label-position="right"
          >
            <el-row>
              <el-col :span="8">
                <el-form-item label="户型名称">
                  <el-input
                    v-model="editInfo.hxname"
                    style="width: 200px;"
                     
                  ></el-input>
                  <!-- <el-select clearable v-model="form.hx" placeholder="请选择户型" style="width: 200px;">
                  <el-option
                    v-for="(i, k) in allDic.HX"
                    :key="k"
                    :value="i.aaa101"
                    :label="i.aaa103"
                  ></el-option>
                </el-select> -->
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="户型朝向">
                  <!--<el-input v-model="form.hxcx" style="width: 200px;"  ></el-input>-->
                  <el-select
                    clearable
                    v-model="editInfo.hxcx"
                    placeholder="请选择户型朝向"
                    style="width: 200px;"
                  >
                    <el-option label="南北通透" value="66"></el-option>
                    <el-option label="东西朝向" value="67"></el-option>
                    <el-option label="南向" value="68"></el-option>
                    <el-option label="东向" value="69"></el-option>
                    <el-option label="西向" value="70"></el-option>
                    <el-option label="北向" value="71"></el-option>
                    <el-option label="东南朝向" value="74"></el-option>
                    <el-option label="东北朝向" value="73"></el-option>
                    <el-option label="西北朝向" value="72"></el-option>
                    <el-option label="西南朝向" value="75"></el-option>
                    <el-option label="南北朝向" value="80"></el-option>
                    <el-option label="其他" value="77"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="户型总价(万元)">
                  <el-input
                    v-model="editInfo.hxzj"
                    style="width: 200px;"
                     
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="户型几室">
                  <el-input
                    v-model="editInfo.room"
                    style="width: 200px;"
                     
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="户型几厅">
                  <el-input
                    v-model="editInfo.hall"
                    style="width: 200px;"
                     
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="户型几卫">
                  <el-input
                    v-model="editInfo.toilet"
                    style="width: 200px;"
                     
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="户型几厨">
                  <el-input
                    v-model="editInfo.kitchen"
                    style="width: 200px;"
                     
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="户型套内面积">
                  <el-input
                    v-model="editInfo.insideArea"
                    style="width: 200px;"
                     
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="户型均价">
                  <el-input
                    v-model="editInfo.referencePrice"
                    style="width: 200px;"
                     
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="户型销售状态">
                  <!--<el-input v-model="form.hxsf" style="width: 100px;"  ></el-input>-->
                  <el-select
                    clearable
                    v-model="editInfo.houseTypeStatus"
                    placeholder="请选择户型销售状态"
                    style="width: 200px;"
                  >
                    <el-option label="主推" value="4"></el-option>
                    <el-option label="待售" value="1"></el-option>
                    <el-option label="在售" value="2"></el-option>
                    <el-option label="售罄" value="3"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="户型建筑面积">
                  <el-input
                    v-model="editInfo.jzmj"
                    style="width: 200px;"
                     
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="户型咨询电话">
                  <el-input
                    v-model="editInfo.zxdt"
                    style="width: 200px;"
                     
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="户型面积描述">
                  <el-input
                    v-model="editInfo.hxmj"
                    style="width: 200px;"
                     
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="户型解析">
                  <el-input
                    v-model="editInfo.hxanalyse"
                    style="width: 200px;"
                     
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="户型亮点1">
                  <el-input
                    v-model="editInfo.hxlightspot1"
                    style="width: 200px;"
                     
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="户型亮点2">
                  <el-input
                    v-model="editInfo.hxlightspot2"
                    style="width: 200px;"
                     
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="户型亮点3">
                  <el-input
                    v-model="editInfo.hxlightspot3"
                    style="width: 200px;"
                     
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <h2 style="margin: 30px;border-top:2px solid #f2f2f2">
              移动端户型解析：
            </h2>
            <el-row>
              <el-col :span="8">
                <el-form-item label="客厅">
                  <el-input
                    v-model="editInfo.hallanalyse"
                    style="width: 200px;"
                     
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="餐厅">
                  <el-input
                    v-model="editInfo.canteenanalyse"
                    style="width: 200px;"
                     
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="厨房">
                  <el-input
                    v-model="editInfo.kitchenanalyse"
                    style="width: 200px;"
                     
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="卧室">
                  <el-input
                    v-model="editInfo.roomanalyse"
                    style="width: 200px;"
                     
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="卫生间">
                  <el-input
                    v-model="editInfo.toiletanalyse"
                    style="width: 200px;"
                     
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
        <el-col :span="6" style="margin-bottom: 20px;">
                <el-upload
                  class="avatar-uploader"
                  action="https://ddfun.baifun.cn/oss/uploadFileReturnUrl"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img v-if="editInfo.hxturl" :src="editInfo.hxturl" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
          <!-- <upload-img @addvp="uploadClick2"></upload-img> -->
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="handelConfirm">确认</el-button>
      </span>
    </el-dialog>
  </section>
</template>
<script>
import uploadImg from '@/components/upload/uploadImg.vue'
// import { delete } from 'vue/types/umd';
export default {
  components: {
    uploadImg,
  },
  name: 'houseType',
  props: ['lpid', 'hxtList'],
  data() {
    return {
      showEdit: false, // 修改弹窗
      form: {
        lpid: 0,
        hxsf: null,
        zxdt: null,
        hxcx: null,
        insideArea: null,
        jzmj: null,
        hxyg: null,
        referencePrice: null,
        hxzj: null,
        room: null,
        houseTypeStatus: null,
        hxmj: null,
        hx: [],
        hxname: '',
        hxturl: null,
        toilet: null,
        hall: null,
        kitchen: null,
        isOpenRoom: null,
        wytype: null,
        // cydetails: null,
        hxanalyse: null,
        hxlightspot1: null,
        hxlightspot2: null,
        hxlightspot3: null,
        hallanalyse: null,
        canteenanalyse: null,
        kitchenanalyse: null,
        roomanalyse: null,
        toiletanalyse: null,
      },
      editInfo: {
        lpid: 0,
        hxsf: null,
        zxdt: null,
        hxcx: null,
        insideArea: null,
        jzmj: null,
        hxyg: null,
        referencePrice: null,
        hxzj: null,
        room: null,
        houseTypeStatus: null,
        hxmj: null,
        hx: [],
        hxname: '',
        hxturl: null,
        toilet: null,
        hall: null,
        kitchen: null,
        isOpenRoom: null,
        wytype: null,
        hxanalyse: null,
        hxlightspot1: null,
        hxlightspot2: null,
        hxlightspot3: null,
        hallanalyse: null,
        canteenanalyse: null,
        kitchenanalyse: null,
        roomanalyse: null,
        toiletanalyse: null,
      },
      allDic: JSON.parse(window.sessionStorage.getItem('saveDic')).data,
      myurl: null,
    }
  },
  filters: {
    cxFilter(value) {
      if (!value) return ''
      if (value == '66') {
        return '南北通透'
      } else if (value == '67') {
        return '东西朝向'
      } else if (value == '68') {
        return '南向'
      } else if (value == '69') {
        return '东向'
      } else if (value == '70') {
        return '西向'
      } else if (value == '71') {
        return '北向'
      } else if (value == '72') {
        return '西北朝向'
      } else if (value == '73') {
        return '东北朝向'
      } else if (value == '74') {
        return '东南朝向'
      } else if (value == '75') {
        return '西南朝向'
      } else if (value == '77') {
        return '其他'
      } else if (value == '80') {
        return '南北朝向'
      }
    },
    openRoom(value) {
      if (!value) return ''
      if (value == '0') {
        return '否'
      } else if (value == '1') {
        return '是'
      }
    },
  },
  mounted() {},
  methods: {
    // 户型图上传
    handleAvatarSuccess(res, file) {
      this.editInfo.hxturl = file.response.filepath
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error('上传附件只能是 JPG或PNG 格式!')
      }
      if (!isLt5M) {
        this.$message.error('上传附件大小不能超过 5M!')
      }
      return isJPG && isLt5M
    },
    // 弹窗
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => {})
    },
    // 取消
    cancel() {
      this.showEdit = false
      this.editInfo = {
        lpid: 0,
        hxsf: null,
        zxdt: null,
        hxcx: null,
        insideArea: null,
        jzmj: null,
        hxyg: null,
        referencePrice: null,
        hxzj: null,
        room: null,
        houseTypeStatus: null,
        hxmj: null,
        hx: [],
        hxname: '',
        hxturl: null,
        toilet: null,
        hall: null,
        kitchen: null,
        isOpenRoom: null,
        wytype: null,
        hxanalyse: null,
        hxlightspot1: null,
        hxlightspot2: null,
        hxlightspot3: null,
        hallanalyse: null,
        canteenanalyse: null,
        kitchenanalyse: null,
        roomanalyse: null,
        toiletanalyse: null,
      }
    },
    // 新增户型信息
    async addHxInfo() {
      this.form.lpid = this.lpid
      await this.$http.post('family/ins', this.form)
      this.$emit('gethx')
      this.cancel()
      this.$refs.uploadImg.clear()
    },
    // 修改户型信息
    async editorHandle() {
      delete this.form.lpname
      await this.$http
        .put('family/upl', this.form)
        .then((res) => {
          if (res.data.code == '200') {
            this.$message.success('修改成功')
            this.$emit('gethx')
            this.form = {}
          }
        })
        .catch((err) => this.$message.error(err))
    },
    // 删除户型
    deletHandle(info) {
      this.$http.delete('family/del?hxid=' + info.hxid).then((res) => {
        if (res.data.code == '200') {
          this.$message.success('删除成功')
          this.$emit('gethx')
        } else {
          this.$message.error('删除失败')
        }
      })
    },
    uploadClick(val) {
      this.form.hxturl = val
    },
    uploadClick2(val) {
      this.editInfo.hxturl = val
    },
    headcell() {
      return 'text-align: center;background:#eef1f6;'
    },
    // 点击修改
    updateHandle(info) {
      this.showEdit = true
      this.editInfo = info

      if (info.houseTypeStatus == '主推') {
        this.editInfo.houseTypeStatus = '4'
      } else if (info.houseTypeStatus == '待售') {
        this.editInfo.houseTypeStatus = '1'
      } else if (info.houseTypeStatus == '在售') {
        this.editInfo.houseTypeStatus = '2'
      } else if (info.houseTypeStatus == '售罄') {
        this.editInfo.houseTypeStatus = '3'
      }
      if (info.wytype == '住宅') {
        this.editInfo.wytype = '1'
      } else if (info.wytype == '别墅') {
        this.editInfo.wytype = '2'
      } else if (info.wytype == '商务型公寓') {
        this.editInfo.wytype = '4'
      } else if (info.wytype == '办公') {
        this.editInfo.wytype = '6'
      } else if (info.wytype == '商业') {
        this.editInfo.wytype = '7'
      } else if (info.wytype == '其他') {
        this.editInfo.wytype = '13'
      }
    },
    // 确认修改
    async handelConfirm() {
      this.editInfo.lpid = this.lpid
      await this.$http
        .put('family/upl', this.editInfo)
        .then((res) => {
          if (res.data.code == '200') {
            this.$message.success('修改成功')
            this.$emit('gethx')
            this.showEdit = false
            this.editInfo = {}
          }
        })
        .catch((err) => this.$message.error(err))
    },
  },
}
</script>
<style scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px dashed #d9d9d9 !important;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
