<template>
  <div>
    <el-card>
      <!--搜索添加区域-->
      <table>
        <tr style="line-height: 70px;font-size: 16px">
          <!-- <td>
            中国区域划分:<el-select
              clearable
              v-model="queryInfo.qybm"
              placeholder="请选择"
              @change="Selarea(queryInfo.qybm)"
              style="width:200px;margin: 0 30px 0 10px"
            >
              <el-option
                v-for="items in areaList"
                :value="items.qybm"
                :key="items.qybm"
                :label="items.qyname"
              >
              </el-option>
            </el-select>
          </td>
          <td>
            城市:<el-select
              clearable
              v-model="queryInfo.ctid"
              placeholder="请选择"
              style="width:200px;margin-left:10px"
            >
              <el-option
                v-for="items in cityList"
                :value="items.ctid"
                :key="items.ctid"
                :label="items.ctname"
              >
              </el-option>
            </el-select>
          </td> -->
          <td>
            <!-- <el-button
              type="primary"
              icon="el-icon-search"
              style="width: 100px;margin:0 20px 0 80px"
              @click="getList"
              >搜索</el-button
            > -->
            <el-button
              type="primary"
              icon="el-icon-circle-plus"
              style="width:100px"
              @click="addInfo"
              >添加</el-button
            >
          </td>
        </tr>
      </table>

      <!--主体内容表单-->
      <template>
        <el-table
          :data="commentList.records"
          border
          style="width: 100%;line-height: 12px; font-size: 18px"
          :cell-style="cellStyle"
          :header-cell-style="rowClass"
        >
          <el-table-column
            prop="id"
            label="id"
            width="100"
            v-if="false"
          ></el-table-column>
          <el-table-column
            prop="commentname"
            label="用户"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="comdate"
            label="点评时间"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="comdetails"
            label="内容"
            width="600"
          ></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                class="el-icon-edit"
                type="primary"
                @click="changeInfo(scope.row)"
                >修改</el-button
              >
              <el-button
                class="el-icon-delete"
                type="danger"
                @click="getDelJourn(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
      <!-- 弹窗 -->
      <el-dialog
        width="70%"
        :title="titleMap[dialogStatus]"
        :visible.sync="addDialogVisiable"
        @close="cancel"
        :before-close="handleClose"
        append-to-body
      >
        <el-form
          :inline="true"
          :model="addNewsInfo"
          ref="aaddFormRef"
          label-width="150px"
        >
          <el-row>
            <el-col :span="8">
              <el-form-item label="点评者头像">
                <el-upload
                  class="avatar-uploader"
                  action="https://ddfun.baifun.cn/oss/uploadFileReturnUrl"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img
                    v-if="addNewsInfo.comphoto"
                    :src="addNewsInfo.comphoto"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="姓名" prop="title">
                <el-input
                  v-model="addNewsInfo.commentname"
                  placeholder="请输入姓名"
                  style="width:200px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="点评日期">
                <el-date-picker
                  size="small"
                  v-model="addNewsInfo.comdate"
                  align="right"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  style="width: 200px;"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="点评内容">
                <el-input
                  v-model="addNewsInfo.comdetails"
                  placeholder="请输入点评内容"
                  type="textarea"
                  :rows="4"
                  style="width:200px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="confirm">确认</el-button>
        </span>
      </el-dialog>
      <!--分页-->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="commentList.total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
  name: 'myComment',
  props: ['lpid','commentList'],
  data() {
    let self = this
    return {
      dialogStatus: '', //新增弹窗状态
      addDialogVisiable: false,
      titleMap: {
        addEquipment: '新增点评信息',
        editEquipment: '修改点评信息',
      },
      //查询信息实体
      queryInfo: {
        lpid: this.lpid,
        pageNum: 1, //当前页
        pageSize: 6, //每页最大数
      },
      addNewsInfo: {
        id: null,
        comdate: null,
        comdetails: null,
        commentname: null,
        commentname: null,
        comphoto: null,
      },
      total: 0, //楼盘新闻总记录数
    }
  },

  methods: {
    // 发布者头像上传
    handleAvatarSuccess(res, file) {
      this.addNewsInfo.comphoto = file.response.filepath
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传附件只能是 JPG或PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => {})
    },
    // 取消
    cancel() {
      this.addDialogVisiable = false
      this.addNewsInfo = {
        id: null,
        comdate: null,
        comdetails: null,
        commentname: null,
        commentname: null,
        comphoto: null,
      }
    },
    async confirm() {
      if (this.dialogStatus == 'addEquipment') {
        const { data: res } = await this.$http.post('/comment/insComment', {
          lpid: this.lpid,
          id: this.addNewsInfo.id,
          comdate: this.addNewsInfo.comdate,
          comdetails: this.addNewsInfo.comdetails,
          commentname: this.addNewsInfo.commentname,
          commentname: this.addNewsInfo.commentname,
          comphoto: this.addNewsInfo.comphoto,
        })
        if (res.code == '200') {
          this.$message.success('新增成功')
          this.addDialogVisiable = false
          this.queryInfo.pageNum = 1
          this.$emit('getCommentList')
        } else {
          this.$message.error('新增失败')
        }
      } else if (this.dialogStatus == 'editEquipment') {
        const { data: res } = await this.$http.put('/comment/uplComment', {
          lpid: this.lpid,
          id: this.addNewsInfo.id,
          comdate: this.addNewsInfo.comdate,
          comdetails: this.addNewsInfo.comdetails,
          commentname: this.addNewsInfo.commentname,
          commentname: this.addNewsInfo.commentname,
          comphoto: this.addNewsInfo.comphoto,
        })
        if (res.code == '200') {
          this.$message.success('修改成功')
          this.addDialogVisiable = false
          this.queryInfo.pageNum = 1
          this.$emit('getCommentList')
        } else {
          this.$message.error('修改失败')
        }
      }
      this.addNewsInfo = {
        id: null,
        comdate: null,
        comdetails: null,
        commentname: null,
        commentname: null,
        comphoto: null,
      }
    },
    addInfo() {
      this.dialogStatus = 'addEquipment'
      this.addDialogVisiable = true
    },
    async changeInfo(info) {
      this.dialogStatus = 'editEquipment'
      this.addDialogVisiable = true
      let infoDetail = info
      this.addNewsInfo = {
        id: infoDetail.id,
        comdate: infoDetail.comdate,
        comdetails: infoDetail.comdetails,
        commentname: infoDetail.commentname,
        commentname: infoDetail.commentname,
        comphoto: infoDetail.comphoto,
      }
    },
    onEditorBlur() {
      // 失去焦点事件
    },
    onEditorFocus() {
      // 获得焦点事件
    },
    onEditorChange() {
      // 内容改变事件
    },
    //最大数
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.$emit('getCommentList')
    },
    //pageNum的触发
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.$emit('getCommentList')
    },

    //删除信息
    async getDelJourn(id) {
      const confirmResult = await this.$confirm(
        '删除后用户信息将永久删除，是否继续？',
        '提示',
        {
          confirmButtonText: '删除',
          cancelButtonText: '放弃',
        }
      ).catch((err) => err)
      if (confirmResult != 'confirm') {
        //取消
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(
        'comment/delComment?id=' + id
      )
      if (res.code != 200) {
        return this.$message.error('删除失败!')
      }
      this.$message.success('删除成功!')
      this.$emit('getCommentList')
    },
    //将表格数据行居中
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center;'
    },
    //将表格数据列居中
    rowClass({ row, rowIndex }) {
      return 'text-align:center;'
    },
  },
}
</script>
<style lang="less" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px dashed #d9d9d9 !important;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-card {
  height: 720px;
}
.dialog-footer {
  padding-top: 100px;
}
::v-deep .el-form--inline .el-form-item__label {
  float: left;
}
::v-deep .quill-editor .ql-editor {
  height: 500px;
}
</style>
