<template>
  <div>
    <el-card>
      <!--导航栏-->
      <el-breadcrumb
        separatorClass="el-icon-arrow-right"
        style="font-size: 16px"
      >
        <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>新房管理</el-breadcrumb-item>
        <el-breadcrumb-item>楼盘拉页维护</el-breadcrumb-item>
      </el-breadcrumb>

      <el-row :gutter="0" style="text-align:left;margin:30px 0">
        <!--搜索区域-->
        <!--搜索添加-->
        <el-col :span="16" class="ly-filter">
          <el-input
            v-model="queryInfo.lyname"
            placeholder="输入楼盘名字进行过滤"
          ></el-input>
          <el-date-picker
            v-model="beginTime"
            value-format="yyyy/MM/dd"
            type="date"
            placeholder="导出开始时间"
          ></el-date-picker>
          <el-date-picker
            v-model="endTime"
            value-format="yyyy/MM/dd"
            type="date"
            placeholder="导出结束时间"
          ></el-date-picker>
        </el-col>
        <!--添加按钮-->
        <el-col :span="8">
          <el-button
            type="primary"
            icon="el-icon-search"
            style="width: 100px;margin-left: 80px"
            @click="getPullList()"
            >搜索</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-circle-plus"
            style="width:100px"
            @click="addEquipment()"
            v-if="zhtype == 1"
            >添加</el-button
          >
        </el-col>
      </el-row>

      <!--楼盘拉页主体内容-->
      <template>
        <el-table :data="pullList" border>
          <el-table-column
            prop="lyname"
            label="楼盘名称"
            width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="phone"
            label="联系方式"
            width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="lyid"
            width="80"
            label="id"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="lylink"
            width="500"
            label="拉页地址"
            align="center"
          >
            <template slot-scope="scope"
              ><a :href="scope.row.lylink" target="_blank" class="buttonText">{{
                scope.row.lylink
              }}</a></template
            >
          </el-table-column>

          <el-table-column label="操作" width="600" align="center" v-if="zhtype == 1">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                @click="changeById(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="success"
                icon="el-icon-upload2"
                @click="makePull(scope.row)"
                >上传素材</el-button
              >
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="getLyuserList(scope.row.lyid)"
                >客户查询</el-button
              >
              <el-button
                type="success"
                icon="el-icon-share"
                @click="getuserExcel(scope.row)"
                >客户导出</el-button
              >
              <el-button type="primary" @click="getuserClick(scope.row.lyid)"
                >点击查询</el-button
              >
              <el-button
                type="danger"
                icon="el-icon-delete"
                @click="delLyinfo(scope.row)"
                >删除拉页</el-button
              >
              <!-- <el-button type="danger" @click="deleTest(scope.row.lyid)"
                >删除测试留电</el-button
              > -->
            </template>
          </el-table-column>
        </el-table>
      </template>

      <!--报名客户数据-->
      <el-dialog title="报名客户" width="50%" :visible.sync="UdiaFormVisible">
        <!--客户信息表格-->
        <el-table
          :data="lyuserList"
          align="center"
          :header-cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column
            prop="name"
            label="姓名"
            width="90"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="phone"
            label="电话"
            width="120"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="ctime"
            label="时间"
            width="170"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="fromname"
            label="来源"
            width="80"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="qcellcore"
            label="归属地"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column label="操作" width="200" align="center">
            <template slot-scope="scope">
              <el-button type="danger" @click="deleTest(scope.row.lyusid)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <!--分页显示-->
        <div>
          <el-pagination
            @size-change="uhandleSizeChange"
            @current-change="uhandleCurrentChange"
            :current-page="lyuserInfo.pageNum"
            :page-size="lyuserInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="utotal"
            style="margin-top: 50px; padding-bottom:30px"
          >
          </el-pagination>
        </div>
      </el-dialog>

      <!--报名客户数据-->
      <el-dialog
        title="电话点击数量"
        width="50%"
        :visible.sync="cdiaFormVisible"
      >
        <!--客户信息表格-->
        <el-table :data="clickList" align="center">
          <el-table-column
            prop="lyname"
            label="楼盘名称"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="ctime"
            label="电话点击时间"
            width="200"
          ></el-table-column>
        </el-table>
        <p style="padding-bottom: 20px">共： {{ ctotal }} 条</p>
      </el-dialog>

      <!-- 新增或修改拉页信息 -->
      <el-dialog
        :title="titleMap[dialogStatus]"
        width="40%"
        :visible.sync="Dialogvisible"
        @close="DialogClosed"
      >
        <el-form
          :model="pullForm"
          ref="pullFormRef"
          :rules="pullFormRules"
          label-width="130px"
        >
          <!-- 楼盘名称 -->
          <el-form-item label="楼盘名称" prop="lyname">
            <el-input v-model="pullForm.lyname"></el-input>
          </el-form-item>
          <!-- 联系电话 -->
          <el-form-item label="联系电话" prop="phone">
            <el-input v-model="pullForm.phone"></el-input>
          </el-form-item>
          <el-form-item label="拉页地址" prop="lylink" v-if="isShow">
            <el-input v-model="pullForm.lylink"></el-input>
          </el-form-item>
          <el-form-item label="户型图是否轮播" prop="iscarousel" v-if="isShow">
            <el-select
              v-model="pullForm.iscarousel"
              placeholder="请选择"
              style="width:100%"
            >
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="按钮颜色" prop="buttoncolor" v-if="isShow">
            <el-input
              v-model="pullForm.buttoncolor"
              placeholder="请输入按钮颜色（默认#d3af7b）"
            ></el-input>
          </el-form-item>
          <el-form-item label="按钮文字" prop="buttonword" v-if="isShow">
            <el-input
              v-model="pullForm.buttonword"
              placeholder="请输入按钮文字（默认'恭迎品鉴'）"
            ></el-input>
          </el-form-item>
          <el-form-item label="文字颜色" prop="wordcolor" v-if="isShow">
            <el-input
              v-model="pullForm.wordcolor"
              placeholder="请输入文字颜色（默认#FFFFFF）"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="getPullAll">确认</el-button>
          <el-button @click="Dialogvisible = false">取消</el-button>
        </span>
      </el-dialog>

      <!-- 制作拉页弹窗 -->
      <el-dialog
        title="制作拉页"
        :visible.sync="showMake"
        width="90%"
        :before-close="handleClose"
      >
        <section style="padding: 30px">
          <el-row>
            <el-col :span="8">
              <el-form
                ref="form"
                :model="form"
                label-width="100px"
                label-position="right"
              >
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="相片类型">
                      <el-select
                        clearable
                        v-model="form.xctype"
                        placeholder="请选择相册类型"
                        @change="changevideo"
                        style="width: 200px;"
                        :value="1"
                      >
                        <el-option
                          v-for="item in imgTypeList"
                          :label="item.aaa103"
                          :value="item.aaa101"
                          :key="item.aaa101"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8" v-if="isvideo">
                    <el-form-item label="视频封面">
                      <el-upload
                        class="avatar-uploader"
                        action="https://ddfun.baifun.cn/oss/uploadFileReturnUrl"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload"
                      >
                        <img
                          v-if="videocover"
                          :src="videocover"
                          class="avatar"
                        />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-button @click="addHandle" type="primary">
                    新增
                  </el-button>
                  <el-button @click="viewPull" type="success">
                    预览
                  </el-button>
                </el-row>
              </el-form>
            </el-col>
            <el-col :span="16" style="margin-bottom: 20px;">
              <el-input
                v-model="videourl"
                placeholder="请输入视频路径"
                v-if="form.xctype == 2"
              ></el-input>
              <upload-img
                ref="uploadImg"
                @addvp="uploadClick"
                @delImg="delImg"
                :isvideo="isvideo"
                v-if="form.xctype != 2"
              ></upload-img>
            </el-col>
          </el-row>
          <el-table
            :data="xc"
            border
            style="width: 100%;"
            :cell-style="{ padding: '5px 0px' }"
            :header-cell-style="headcell"
          >
            <el-table-column
              prop="type"
              label="类型"
              width="200"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="url"
              label="相册路径"
              align="center"
              width="600"
            >
            </el-table-column>
            <el-table-column prop="url" label="图片展示" align="center">
              <template slot-scope="scope">
                <img
                  :src="scope.row.url"
                  alt=""
                  @click="preview(scope.row.url)"
                  style="width: 80px;height: 40px;cursor: pointer;"
                />
              </template>
            </el-table-column>
            <el-table-column prop="sorn" label="排序" align="center">
              <template slot-scope="scope">
                <el-input-number
                  v-model="scope.row.sorn"
                  placeholder="请输入排序"
                  type="number"
                  style="width:100px"
                  :min="0"
                  :controls="false"
                  @change="handelSorn(scope.row)"
                />
              </template>
            </el-table-column>
            <el-table-column prop="address" label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  @click="deletClick(scope.row)"
                  type="text"
                  size="small"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </section>
      </el-dialog>

      <!-- 预览拉页弹窗 -->
      <el-dialog
        title="预览拉页"
        :visible.sync="showView"
        :before-close="closeView"
      >
        <div style="display:flex;flex-direction: column;align-items: center;">
          <img
            v-for="item in xc"
            :key="item.lyimageid"
            :src="item.url"
            alt=""
            style="width:400px"
          />
        </div>
      </el-dialog>

      <!-- 预览图片 -->
      <el-dialog
        :visible.sync="showImg"
        :before-close="closeImg"
        :show-close="false"
      >
        <img :src="previewImg" alt="">
      </el-dialog>
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[7, 10, 15, 20, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
    <!--分页-->
  </div>
</template>
<script>
import uploadImg from '@/components/upload/uploadImg.vue'
export default {
  components: {
    uploadImg,
  },
  created() {
    this.getPullList() //所有数据显示方法
    this.getImgType()
    this.zhtype = localStorage.getItem('zhtype')
  },
  data() {
    return {
      zhtype: '',
      showImg: false,
      previewImg: '',
      lyinfo: {},
      xc: [],
      form: {
        xctype: '1',
      },
      myurl: '',
      isvideo: false,
      inputFrom: [],
      imgTypeList: [], // 相册维护下拉类型列表
      videocover: '', // 视频封面
      videourl: '', // 视频路径
      showView: false, // 预览拉页弹窗
      showMake: false, // 制作拉页弹窗
      isShow: false, //添加链接是否可见
      beginTime: null,
      endTime: null,
      //查询信息实体
      queryInfo: {
        query: '', //查询信息
        area: '',
        city: '',
        lyid: null,
        pageNum: 1, //当前页
        pageSize: 7, //每页最大数
        lyname: '', //搜索栏名称
        sorn: null, // 顺序
        // lylink:'',//拉页链接
      },
      pullList: [], //楼盘拉页数据集
      lyuserList: [], //楼盘客户数据集
      clickList: [], // 点击数量统计
      ctotal: 0, // 点击总数
      total: 0, //楼盘数据分页总记录数
      // lynameByphone:[],//修改栏数据
      utotal: 0, //报名客户信息分页总记录数
      //查询报名客户实体
      lyuserInfo: {
        pageNum: 1, //客户当前页
        pageSize: 10, //客户每页最大数
        lyid: null, // 拉页id
      },
      clickInfo: {
        pageNum: 1,
      },
      UdiaFormVisible: false, //客户数据信息初始化为关闭状态
      cdiaFormVisible: false, //客户电话点击对话框为关闭状态
      titleMap: {
        addEquipment: '新增拉页信息',
        editEquipment: '修改拉页信息',
      },
      //新增和编辑弹框标题
      dialogStatus: '',
      Dialogvisible: false, //弹窗的初始状态
      //弹窗表单验证
      pullForm: {
        lyname: '', //楼盘名称
        phone: '', //联系电话
        lyid: null, // 拉页id
        lylink: '', //拉页链接
        iscarousel: '', // 是否轮播
        sorn: null, // 显示顺序
      },
      pullFormRules: {
        lyname: { required: true, message: '请输入楼盘名称', trigger: 'blur' },
        phone: { required: true, message: '请输入联系电话', trigger: 'blur' },
        lylink: { required: true, message: '请输入拉页地址', trigger: 'blur' },
        iscarousel: {
          required: true,
          message: '请选择是否轮播',
          trigger: 'blur',
        },
      },
    }
  },
  methods: {
    // 预览图片
    preview(url) {
      this.previewImg = url
      this.showImg = true
    },
    closeImg() {
      this.showImg = false
    },
    // 查视频相册列表
    async getImg() {
      await this.$http
        .get('pull/lyimage/list?lyid=' + this.lyinfo.lyid)
        .then((res) => {
          this.xc = []
          this.xc = res.data.data
          this.xc.map((item, index) => {
            if (item.sorn == null) {
              item.sorn = index
            }
          })
          // res.data.data.forEach((item) => this.xc.push(...item))
        })
    },
    // 编辑排序
    async handelSorn(value) {
      delete value.type
      value.type = value.typeid
      delete value.typeid
      this.lyinfo = value
      const { data: res } = await this.$http.post(
        'pull/lyimage/update',
        this.lyinfo
      )
      if (res.code == 200) {
        this.getImg()
        console.log(res)
      }
    },
    // 预览拉页
    viewPull() {
      if (this.xc.length === 0) {
        this.$message('暂无图片')
      } else {
        this.showView = true
      }
    },
    // 视频封面上传
    handleAvatarSuccess(res, file) {
      this.videocover = file.response.filepath
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传附件只能是 JPG或PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    // 获取相册维护下拉类型列表
    async getImgType() {
      await this.$http.get('/dic/aaa100?aaa100=LYTYPE').then((res) => {
        this.imgTypeList = res.data.data
      })
    },
    headcell() {
      return 'text-align: center;background:#eef1f6;'
    },
    changevideo(e) {
      if (this.form.xctype == '2') {
        this.isvideo = true
      } else {
        this.isvideo = false
      }
    },
    uploadClick(val) {
      let f = {}
      f.type = this.form.xctype
      f.url = val
      f.lyid = this.lyinfo.lyid
      this.inputFrom.push(f)
    },
    delImg(val) {
      this.inputFrom = this.inputFrom.filter(
        (item) => item.cxfmtpurl != val.filepath
      )
    },
    async addHandle() {
      if (this.form.xctype == 2) {
        let f = {}
        f.videocover = this.videocover
        f.url = this.videourl
        f.type = 2
        f.lyid = this.lyinfo.lyid
        this.inputFrom.push(f)
      }
      await this.$http.post('pull/lyimage/ins', this.inputFrom).then((res) => {
        if (res.data.code == '200') {
          this.$message.success('新增成功')
          if (this.form.xctype != 2) {
            this.$refs.uploadImg.clear()
          }
        } else {
          this.$message.error('新增失败')
        }
      })
      this.inputFrom = []
      this.getImg()
    },
    handleClick(val) {
      let this_ = this
      this_.myurl = val.cxfmtpurl
      this_.form = val
    },
    async deletClick(info) {
      await this.$http
        .delete('pull/lyimage/del?lyimageid=' + info.lyimageid)
        .then((res) => {
          if (res.data.code == '200') {
            this.$message.success('删除成功')
            this.getImg()
          } else {
            this.$message.error('删除失败')
          }
        })
    },
    // 关闭制作拉页弹窗
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => {})
    },
    // 关闭预览拉页弹窗
    closeView() {
      this.showView = false
    },

    //1.显示所有拉页信息
    async getPullList() {
      if (this.queryInfo.lyid) {
        this.queryInfo.pageNum = 1
      }
      const { data: res } = await this.$http.get('/pull/list', {
        params: {
          pageSize: this.queryInfo.pageSize,
          pageNum: this.queryInfo.pageNum,
          lyname: this.queryInfo.lyname,
        },
      })
      this.pullList = res.data.records
      this.total = res.data.total
    },
    //最大数
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getPullList()
    },
    //pageNum的触发
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getPullList()
    },
    //显示客户报名信息
    async getLyuserList(lyid) {
      if (lyid) {
        this.lyuserInfo.lyid = lyid
      }
      const { data: res } = await this.$http.get('/pull/list/user/', {
        params: this.lyuserInfo,
      })
      this.lyuserList = res.data.records
      this.utotal = res.data.total
      this.UdiaFormVisible = true
      // window.localStorage.setItem('lyname',lyname);
    },
    //最大数
    uhandleSizeChange(newSize) {
      this.lyuserInfo.pageSize = newSize
      this.getLyuserList()
    },
    //pageNum的触发
    uhandleCurrentChange(newPage) {
      this.lyuserInfo.pageNum = newPage
      // var lyname = window.localStorage.getItem('lyname');
      this.getLyuserList()
    },
    resetForm() {
      this.lyuserInfo.pageNum = 1
      this.lyuserInfo.pageSize = 5
    },
    //根据Id删除相关楼盘拉页信息
    async delLyinfo(item) {
      const confirmResult = await this.$confirm(
        item.lyname + '的楼盘信息将永久删除，是否继续？',
        '提示',
        {
          confirmButtonText: '删除',
          cancelButtonText: '放弃',
        }
      ).catch((err) => err)
      if (confirmResult != 'confirm') {
        //取消
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(
        '/pull/del?lyid=' + item.lyid
      )
      if (res.code != 200) {
        return this.$message.error('删除失败!')
      }
      this.$message.success('删除成功!')
      this.getPullList()
    },
    //实现报名客户信息数据导出信息
    async getuserExcel(info) {
      const { data: res } = await this.$http.get('/pull/excel/', {
        params: {
          lyid: info.lyid,
          beginTime: this.beginTime,
          endTime: this.endTime,
        },
        responseType: 'blob',
      })
      const link = document.createElement('a')
      let blob = new Blob([res], { type: 'application/vnd.ms-excel' })
      let objectUrl = URL.createObjectURL(blob) // 创建URL
      link.href = objectUrl
      link.download = info.lyname // 自定义文件名
      link.click() // 下载文件
      URL.revokeObjectURL(objectUrl) // 释放内存
      return this.$message.success('数据导出成功')
    },
    // 点击查询
    async getuserClick(lyid) {
      this.cdiaFormVisible = true
      const { data: res } = await this.$http.get('/pull/lpdjid/?lyid=' + lyid)
      this.clickList = res.data.list
      this.ctotal = res.data.count
    },
    //新增弹窗的显示
    addEquipment() {
      this.dialogStatus = 'addEquipment'
      this.isShow = false
      this.Dialogvisible = true
      this.pullForm = {
        lyname: '',
        phone: '',
        lylink: '',
        iscarousel: '',
        buttoncolor: '',
        buttonword: '',
        wordcolor: '',
        // lyid: null,
        sorn: null,
      }
    },
    //城市form框监听
    DialogClosed() {
      this.$refs.pullFormRef.resetFields()
    },
    //数据弹窗操作
    getPullAll() {
      this.$refs.pullFormRef.validate(async (valid) => {
        if (!valid) return
        //如果title是addEquipment则跳进新增执行新增方法
        if (this.dialogStatus === 'addEquipment') {
          const { data: res } = await this.$http.post('pull/ins', this.pullForm)
          if (res.code != 200) {
            return this.$message.error('操作失败')
          }
        }
        //如果title是editEquipment则跳进修改执行修改方法
        if ((this.dialogStatus = 'editEquipment')) {
          const { data: res } = await this.$http.put('pull/upl', this.pullForm)
          if (res.code != 200) {
            return this.$message.error('操作失败')
          }
        }
        this.$message.success('操作成功')
        this.Dialogvisible = false
        this.getPullList()
      })
    },
    //原数据显示在修改弹窗
    async changeById(info) {
      // const {data:res} = await this.$http.get('/pull/list/byid/'+info.lyid);
      this.Dialogvisible = true
      this.dialogStatus = 'editEquipment'
      this.Dialogvisible = true
      this.isShow = true
      this.pullForm = info
      this.getPullList()
    },
    // 制作拉页
    async makePull(info) {
      this.showMake = true
      this.lyinfo = info
      this.getImg()
    },
    // 删除测试留电数据
    deleTest(lyusid) {
      this.$http
        .delete('pull/test?lyusid=' + lyusid)
        .then((res) => {
          if (res.data.code == 200) {
            this.$message.success(res.data.msg)
            this.getLyuserList()
          }
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
  },
}
</script>
<style lang="less" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
  border: 1px dashed #d9d9d9 !important;
}
.avatar {
  width: 148px;
  height: 148px;
  display: block;
}
.el-card {
  height: 720px;
}
::v-deep .el-form--inline .el-form-item__label {
  float: left;
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog__body {
  padding: 0;
  line-height: 1;
}
::v-deep .el-form .el-form-item {
  padding: 0 10%;
}
.el-table::before {
  width: auto;
}
.ly-filter .el-input {
  width: 250px;
}
.ly-filter .el-date-editor {
  line-height: 32px;
  margin-left: 30px;
}
.el-pagination {
  text-align: left;
}
.el-dialog__body .el-pagination {
  text-align: center;
}
</style>
