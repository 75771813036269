<template>
  <div class="login_container">
    <div class="login_box">
      <!--登录块-->
      <div class="avatar_box">
        <!-- <h1 style="margin-left: 13px;margin-top: 35px">多点房</h1> -->
        <img src="../assets/login/homepag.png" />
      </div>
      <!-- <img src="../assets/logoheader.png" alt=""></div> -->
      <!--表单区域-->
      <el-form
        class="login_from"
        label-width="80px"
        ref="loginFormRef"
        :model="loginFrom"
        :rules="LoginRules"
      >
        <!--用户名-->
        <el-form-item prop="username">
          用户名:<el-input v-model="loginFrom.username"></el-input>
        </el-form-item>
        <!--密码-->
        <el-form-item prop="password">
          密码:<el-input
            type="password"
            v-model="loginFrom.password"
          ></el-input>
        </el-form-item>
        <!--按钮-->
        <el-form-item class="btns">
          <el-button type="primary" @click="login">提交</el-button>
          <el-button type="info" @click="resetLoginFrom()">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <p class="aaa">
      <span>百房网络科技有限公司</span>
      <a href="https://beian.miit.gov.cn">备案号: 粤ICP备20045882号</a>
    </p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //表单数据对象
      loginFrom: {
        // username:"admin",
        // password:"DDF22198",
      },
      //验证对象
      LoginRules: {
        //校验用户名
        name: [
          { required: true, message: '用户名为必填项', trigger: 'blur' }, //必填项验证
          {
            min: 5,
            max: 12,
            message: '长度在 5 到 12 个字符',
            trigger: 'blur',
          }, //长度验证
        ],
        //效验密码
        password: [
          { required: true, message: '用户密码为必填项', trigger: 'blur' }, //必填项验证
          { min: 6, max: 20, message: '长度在 6~10 个字符', trigger: 'blur' }, //长度验证
        ],
      },
    }
  },
  methods: {
    //重置表单内容
    resetLoginFrom() {
      this.$refs.loginFormRef.resetFields()
    },
    //登录方法
    login(user) {
      //1.验证校验规则
      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) return //验证失败则返回
        const { data: res } = await this.$http.post(
          '/admin/login',
          this.loginFrom
        ) //成功则访问后台
        if (res.code == 200) {
          const token = res.data
          const zhtype = res.zhtype
          const bdcityid = res.bdcityid
          localStorage.setItem('token', token)
          localStorage.setItem('zhtype', zhtype)
          localStorage.setItem('username', this.loginFrom.username)

          // 保存城市id
          localStorage.setItem('bdcityid', bdcityid || '')
          // if(bdcityid == null) {
          //   localStorage.setItem('bdcityid', null)
          // } else {
            
          // }
          this.$message.success('登录成功！') //信息提示
          this.$router.replace({ path: '/index' }) //页面路由进行跳转 使用replace可以让后面的页面都保存这个用户名
        } else if (res.code !== 200) {
          this.$message.error('用户名或密码输入错误！！！') //错误提示
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.login_container {
  background-color: #f0f2f5;
  height: 100%;
  .aaa {
    font-size: 24px;
    position: absolute;
    bottom: 200px;
    left: 40%;
    span {
      margin-right: 100px;
    }
    a {
      color: #000;
    }
  }
}
.login_box {
  width: 550px;
  height: 410px;
  background-color: #fff;
  border-radius: 16px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .avatar_box {
    width: 150px;
    height: 150px;
    border: 1px solid #eee;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 2px #ddd;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #0ee;
    text-align: center;
    img {
      width: 150px;
      height: 130px;
    }
  }
}
.btns {
  display: flex;
  justify-content: center;
}
.login_from {
  position: absolute;
  bottom: 10%;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  right: 50px;
}
</style>
