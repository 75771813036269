<template>
  <div>
    <el-card>
      <!--导航栏-->
      <el-breadcrumb
        separatorClass="el-icon-arrow-right"
        style="font-size: 16px"
      >
        <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>新房管理</el-breadcrumb-item>
        <el-breadcrumb-item>互动百科维护</el-breadcrumb-item>
      </el-breadcrumb>
      <!--搜索添加区域-->
      <table style="margin:30px 0">
        <!-- <el-col :span="16" class="bm-filter">
          关键字搜索:<el-input
            placeholder="请输入"
            v-model="queryInfo.equestion"
          ></el-input>
        </el-col>
        <el-col :span="3" class="bm-filter-btn">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="getByApplyList"
            >查询</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-circle-plus"
            @click="handelAdd()"
            >新增</el-button
          >
        </el-col> -->
        <tr style="line-height: 70px;font-size: 14px">
            <td style="display: flex;margin-right: 100px">
                <span style="width:200px">关键字搜索:</span><el-input
            placeholder="请输入"
            v-model="queryInfo.equestion"
          ></el-input>
            </td>
          <td>
            <el-button
            type="primary"
            icon="el-icon-search"
            @click="getWikiList"
            >查询</el-button
          >
            <el-button
              type="primary"
              icon="el-icon-circle-plus"
              @click="addInfo"
               v-if="zhtype == 1"
              >新增</el-button
            >
          </td>
        </tr>
      </table>

      <!--主体内容表单-->
      <template>
        <el-table
          :data="wikiList"
          border
          style="width: 100%;line-height: 12px; font-size: 18px"
          :cell-style="cellStyle"
          :header-cell-style="rowClass"
        >
          <el-table-column
            prop="esource"
            label="来源"
            width="300"
          ></el-table-column>
          <el-table-column
            prop="edate"
            label="创建时间"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="equestion"
            label="问题"
            width="600"
          ></el-table-column>
          <el-table-column label="操作" v-if="zhtype == 1">
            <template slot-scope="scope">
              <el-button
                class="el-icon-edit"
                type="primary"
                @click="changeInfo(scope.row)"
                >修改</el-button
              >
              <el-button
                class="el-icon-delete"
                type="danger"
                @click="getDelJourn(scope.row.eid)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
      <!-- 弹窗 -->
      <el-dialog
        width="70%"
        :title="titleMap[dialogStatus]"
        :visible.sync="addDialogVisiable"
        @close="cancel"
        :before-close="handleClose"
      >
        <el-form
          :inline="true"
          :model="addNewsInfo"
          ref="aaddFormRef"
          label-width="150px"
        >
          <el-row>
            <el-col :span="8">
              <el-form-item label="问题" prop="equestion">
                <el-input
                  v-model="addNewsInfo.equestion"
                  placeholder="请输入问题"
                  style="width:200px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="来源" prop="esource">
                <el-input
                  v-model="addNewsInfo.esource"
                  placeholder="请输入来源"
                  style="width:200px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="提问时间">
                <el-date-picker
                  size="small"
                  v-model="addNewsInfo.edate"
                  align="right"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  style="width: 200px;"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <template>
              <div class="editorBox quill-wrap">
                <el-input
                  type="textarea"
                  :rows="10"
                  style="width:800px"
                  placeholder="请输入解答"
                  v-model="addNewsInfo.eanswer">
                </el-input>
                <!-- <quill-editor
                  v-model="addNewsInfo.eanswer"
                  ref="myQuillEditor"
                  :options="editorOption"
                  @change="onEditorChange($event)"
                >
                </quill-editor> -->
              </div>
            </template>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="confirm">确认</el-button>
        </span>
      </el-dialog>
      <!--分页-->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
// require styles 引入样式
import { quillEditor, Quill } from 'vue-quill-editor'
import { container, ImageExtend, QuillWatch } from 'quill-image-extend-module'
Quill.register('modules/ImageExtend', ImageExtend)

import '../../assets/imagePaste'

export default {
  components: { quillEditor },
  mounted() {},
  data() {
    let self = this
    return {
      zhtype: '',
      cityall: JSON.parse(window.sessionStorage.getItem('cityAll')).records,
      editorOption: {
        modules: {
          // imageDrop: true,
          ImageExtend: {
            // 如果不作设置，即{}  则依然开启复制粘贴功能且以base64插入
            loading: true,
            name: 'file', // 图片参数名
            size: 3, // 可选参数 图片大小，单位为M，1M = 1024kb
            action: 'https://ddfun.baifun.cn/oss/uploadFileReturnUrl', // 服务器地址, 如果action为空，则采用base64插入图片
            // response 为一个函数用来获取服务器返回的具体图片地址
            // 例如服务器返回{code: 200; data:{ url: 'baidu.com'}}
            // 则 return res.data.url
            response: (res) => {
              return res.filepath
            },

            sizeError: () => {}, // 图片超过大小的回调
            start: () => {}, // 可选参数 自定义开始上传触发事件
            end: () => {}, // 可选参数 自定义上传结束触发的事件，无论成功或者失败
            error: () => {}, // 可选参数 上传失败触发的事件
            success: () => {}, // 可选参数  上传成功触发的事件
            change: (xhr, formData) => {}, // 可选参数 每次选择图片触发，也可用来设置头部，但比headers多了一个参数，可设置formData
          },
          toolbar: {
            // 如果不上传图片到服务器，此处不必配置
            container: container,
            handlers: {
              image: function() {
                // 劫持原来的图片点击按钮事件
                QuillWatch.emit(this.quill.id)
              },
            },
          },
          imagePaste: {
            addImageBlob: function(blob, callback) {
              var formData = new FormData()
              formData.append('file', blob)
              // your upload function, get the uploaded image url, add then
              let config = {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Accept: '*/*',
                },
              }
              self.$http
                .post('/oss/uploadFileReturnUrl', formData, config)
                .then((res) => {
                  var imageUrl = res.data.filepath
                  callback(imageUrl)
                })
            },
          },
        },
      },
      dialogStatus: '', //新增弹窗状态
      addDialogVisiable: false,
      titleMap: {
        addEquipment: '新增新闻信息',
        editEquipment: '修改新闻信息',
      },
      //查询信息实体
      queryInfo: {
        ctid: null,
        equestion: null,
        pageNum: 1, //当前页
        pageSize: 6, //每页最大数
      },
      addNewsInfo: {
        eid: null,
        esource: null,
        edate: null,
        equestion: null,
        eanswer: null,
      },
      total: 0, //总记录数
      wikiList: [], // 互动百科列表
    }
  },
  created() {
    this.getWikiList()
    this.zhtype = localStorage.getItem('zhtype')
  },
  methods: {
    //查询所有互动百科信息
    async getWikiList() {
      const { data: res } = await this.$http.get(
        '/encyclopedia/getencyclopedia',
        {
          params: this.queryInfo,
        }
      )
      this.wikiList = res.data.records
      this.total = res.data.total
    },
    // 关闭弹窗
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => {})
    },
    // 取消
    cancel() {
      this.addDialogVisiable = false
      this.addNewsInfo = {
        eid: null,
        esource: null,
        edate: null,
        equestion: null,
        eanswer: null,
      }
    },
    // 确认
    async confirm() {
      if (
        !this.addNewsInfo.esource ||
        !this.addNewsInfo.equestion ||
        !this.addNewsInfo.edate
      ) {
        this.$message.error('请完整所有信息！')
        return
      }
      // 新增
      if (this.dialogStatus == 'addEquipment') {
        const { data: res } = await this.$http.post(
          '/encyclopedia/insEncyclopedia',
          {
            eid: this.addNewsInfo.eid,
            esource: this.addNewsInfo.esource,
            edate: this.addNewsInfo.edate,
            equestion: this.addNewsInfo.equestion,
            eanswer: this.addNewsInfo.eanswer,
          }
        )
        // 修改
      } else if (this.dialogStatus == 'editEquipment') {
        const { data: res } = await this.$http.put(
          '/encyclopedia/uplEncyclopedia',
          {
            eid: this.addNewsInfo.eid,
            esource: this.addNewsInfo.esource,
            edate: this.addNewsInfo.edate,
            equestion: this.addNewsInfo.equestion,
            eanswer: this.addNewsInfo.eanswer,
          }
        )
      }
      this.addDialogVisiable = false
      this.queryInfo.pageNum = 1
      this.getWikiList()
      this.addNewsInfo = {
        eid: null,
        esource: null,
        edate: null,
        equestion: null,
        eanswer: null,
      }
    },
    // 新增
    addInfo() {
      this.dialogStatus = 'addEquipment'
      this.addDialogVisiable = true
    },
    // 修改
    async changeInfo(info) {
      this.dialogStatus = 'editEquipment'
      this.addDialogVisiable = true
      const { data: res } = await this.$http.get(
        'encyclopedia/encyclopediaList?eid=' + info.eid
      )
      let infoDetail = res.data[0]
      this.addNewsInfo = {
        eid: infoDetail.eid,
        esource: infoDetail.esource,
        edate: infoDetail.edate,
        equestion: infoDetail.equestion,
        eanswer: infoDetail.eanswer,
      }
    },
    onEditorBlur() {
      // 失去焦点事件
    },
    onEditorFocus() {
      // 获得焦点事件
    },
    onEditorChange() {
      // 内容改变事件
    },
    //最大数
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getWikiList()
    },
    //pageNum的触发
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getWikiList()
      this.$forceUpdate()
    },

    //删除信息
    async getDelJourn(eid) {
      const confirmResult = await this.$confirm(
        '删除后用户信息将永久删除，是否继续？',
        '提示',
        {
          confirmButtonText: '删除',
          cancelButtonText: '放弃',
        }
      ).catch((err) => err)
      if (confirmResult != 'confirm') {
        //取消
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(
        'encyclopedia/delEncyclopedia?eid=' + eid
      )
      if (res.code != 200) {
        return this.$message.error('删除失败!')
      }
      this.$message.success('删除成功!')
      this.getWikiList()
    },
    //将表格数据行居中
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center;'
    },
    //将表格数据列居中
    rowClass({ row, rowIndex }) {
      return 'text-align:center;'
    },
  },
}
</script>
<style lang="less" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px dashed #d9d9d9 !important;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-card {
  height: 720px;
}
.dialog-footer {
  padding-top: 100px;
}
::v-deep .el-form--inline .el-form-item__label {
  float: left;
}
::v-deep .quill-editor .ql-editor {
  height: 500px;
}
</style>
