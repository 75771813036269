<template>
  <section>
    <el-form ref="baseform" :model="baseform" label-width="160px">
      <el-form-item label="楼盘动态标题">
        <el-input type="textarea" v-model="baseform.dynamicTitle" :rows="4"></el-input>
      </el-form-item>
       <el-form-item label="楼盘动态时间">
          <el-date-picker v-model="baseform.dynamicTime"  :rows="4" type="datetime" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
      </el-form-item>
      <el-form-item label="楼盘动态内容">
        <el-input type="textarea" v-model="baseform.dynamicContent" :rows="4"></el-input>
      </el-form-item>
      <el-form-item >
        
        <el-button v-if="!baseform.xzlid" @click="addLpdt"  type="primary" style="padding-left:30px;padding-right:30px;" >
          新增
        </el-button>
        <!-- <el-button v-if="baseform.lpjtxxid" @click="editorHandle"  type="primary" style="padding-left:30px;padding-right:30px;" >
          修改
        </el-button> -->
      </el-form-item>
      
    </el-form>
    <el-table
      :data="lpdt"
      border
      style="width: 100%;"
      :cell-style="{ padding: '5px 0px' }"
      :header-cell-style="headcell"
    >
      <el-table-column prop="dynamicTitle" label="已添加楼盘动态标题" align="center">
      </el-table-column>
      <el-table-column prop="dynamicTime" label="已添加楼盘动态时间" align="center">
      </el-table-column>
      <el-table-column prop="dynamicContent" label="已添加楼盘动态内容" align="center">
      </el-table-column>
      <el-table-column prop="xzlid" label="楼盘id" width="200" align="center" v-if="false">
      </el-table-column>

      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button @click="delLpdt(scope.row)" type="text" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

  </section>
</template>
<script>
  export default {
    name: 'myState',
    props:['xzlid','lpdt','xzladdForm'],
    data() {
      return {
        baseform: {},
      }
    },
    methods: {
      // 添加楼盘动态
      async addLpdt(){
        if(this.baseform.length==0){
        }else{
          const { data: res } = await this.$http.post('/officedynamic/addOfficedynamic', {
              dynamicTitle: this.baseform.dynamicTitle,
              dynamicTime: this.baseform.dynamicTime,
              dynamicContent:this.baseform.dynamicContent,
              xzlid: this.xzlid,      
          })
          if(res.code == "200"){
              this.$message.success("新增成功")
              this.baseform.dynamicTitle = null
              this.baseform.dynamicTime = null
              this.baseform.dynamicContent = null
              this.$emit('getlpdt')
          }
        }
      },
      // 删除动态
      async delLpdt(info){
        const { data: res } = await this.$http.delete('/officedynamic/delOfficedynamic?id=' + info.id)
        this.$emit('getlpdt')
      },
      headcell(){
        return 'text-align: center;background:#eef1f6;'
      },
    }
  }
</script>
<style scoped>
.el-form-item{
  margin-bottom: 10px;
  text-align: left;
}
</style>