<template>
  <div>
    <el-card>
      <!--导航栏-->
      <el-breadcrumb
        separatorClass="el-icon-arrow-right"
        style="font-size: 16px"
      >
        <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>用户管理</el-breadcrumb-item>
        <el-breadcrumb-item>用户信息维护</el-breadcrumb-item>
      </el-breadcrumb>
      <!--搜索添加区域-->
      <table style="margin:30px 0">
        <tr style="line-height: 70px;font-size: 14px">
          <td style="display: flex;margin-right: 100px">
            <span style="width:100px">用户权限:</span>
            <el-select
                  v-model="queryInfo.bdcityid"
                  placeholder="请选择"
                  style="width: 200px;"
                >
                  <el-option
                    v-for="item in cityList"
                    :key="item.ctid"
                    :label="item.ctname"
                    :value="item.ctid"
                  ></el-option>
                </el-select>
          </td>
          <td>
            <el-button type="primary" icon="el-icon-search" @click="getWikiList"
              >查询</el-button
            >
            <el-button
              type="primary"
              icon="el-icon-circle-plus"
              @click="addInfo"
              v-if="zhtype == 1"
              >新增</el-button
            >
          </td>
        </tr>
      </table>

      <!--主体内容表单-->
      <template>
        <el-table
          :data="wikiList"
          border
          style="width: 100%;line-height: 12px; font-size: 18px"
          :cell-style="cellStyle"
          :header-cell-style="rowClass"
        >
          <el-table-column prop="uid" label="uid" width="100"></el-table-column>
          <el-table-column
            prop="username"
            label="账户"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="password"
            label="密码"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="ctname"
            label="城市权限"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="zhtype"
            :formatter="(row) => row.zhtype == 1 ? '管理员' : '普通用户'"
            label="用户级别"
            width="200"
          ></el-table-column>
          <el-table-column label="操作" v-if="zhtype == 1">
            <template slot-scope="scope">
              <el-button
                class="el-icon-edit"
                type="primary"
                @click="changeInfo(scope.row)"
                >修改</el-button
              >
              <el-button
                class="el-icon-delete"
                type="danger"
                @click="getDelJourn(scope.row.uid)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
      <!-- 弹窗 -->
      <el-dialog
        width="30%"
        :title="titleMap[dialogStatus]"
        :visible.sync="addDialogVisiable"
        @close="cancel"
        :before-close="handleClose"
      >
        <el-form :inline="true" :model="addNewsInfo" ref="aaddFormRef">
          <el-row>
            <el-col :span="24">
              <el-form-item label="账号" prop="username">
                <el-input
                  v-model="addNewsInfo.username"
                  placeholder="请输入账号"
                  style="width:200px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="密码" prop="password">
                <el-input
                  v-model="addNewsInfo.password"
                  placeholder="请输入密码"
                  style="width:200px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="级别">
                <el-select
                  v-model="addNewsInfo.zhtype"
                  placeholder="请选择"
                  style="width: 200px;"
                >
                  <el-option label="管理员" value="1"></el-option>
                  <el-option label="普通用户" value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="权限">
                <el-select
                  v-model="addNewsInfo.bdcityid"
                  placeholder="请选择"
                  style="width: 200px;"
                >
                  <el-option
                    v-for="item in cityList"
                    :key="item.ctid"
                    :label="item.ctname"
                    :value="item.ctid"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="confirm">确认</el-button>
        </span>
      </el-dialog>
      <!--分页-->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
// require styles 引入样式
import { quillEditor, Quill } from 'vue-quill-editor'
import { container, ImageExtend, QuillWatch } from 'quill-image-extend-module'
Quill.register('modules/ImageExtend', ImageExtend)

import '../../assets/imagePaste'

export default {
  components: { quillEditor },
  mounted() {},
  data() {
    let self = this
    return {
      cityList: [],
      zhtype: '',
      cityall: JSON.parse(window.sessionStorage.getItem('cityAll')).records,
      editorOption: {
        modules: {
          // imageDrop: true,
          ImageExtend: {
            // 如果不作设置，即{}  则依然开启复制粘贴功能且以base64插入
            loading: true,
            name: 'file', // 图片参数名
            size: 3, // 可选参数 图片大小，单位为M，1M = 1024kb
            action: 'https://ddfun.baifun.cn/oss/uploadFileReturnUrl', // 服务器地址, 如果action为空，则采用base64插入图片
            // response 为一个函数用来获取服务器返回的具体图片地址
            // 例如服务器返回{code: 200; data:{ url: 'baidu.com'}}
            // 则 return res.data.url
            response: (res) => {
              return res.filepath
            },

            sizeError: () => {}, // 图片超过大小的回调
            start: () => {}, // 可选参数 自定义开始上传触发事件
            end: () => {}, // 可选参数 自定义上传结束触发的事件，无论成功或者失败
            error: () => {}, // 可选参数 上传失败触发的事件
            success: () => {}, // 可选参数  上传成功触发的事件
            change: (xhr, formData) => {}, // 可选参数 每次选择图片触发，也可用来设置头部，但比headers多了一个参数，可设置formData
          },
          toolbar: {
            // 如果不上传图片到服务器，此处不必配置
            container: container,
            handlers: {
              image: function() {
                // 劫持原来的图片点击按钮事件
                QuillWatch.emit(this.quill.id)
              },
            },
          },
          imagePaste: {
            addImageBlob: function(blob, callback) {
              var formData = new FormData()
              formData.append('file', blob)
              // your upload function, get the uploaded image url, add then
              let config = {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Accept: '*/*',
                },
              }
              self.$http
                .post('/oss/uploadFileReturnUrl', formData, config)
                .then((res) => {
                  var imageUrl = res.data.filepath
                  callback(imageUrl)
                })
            },
          },
        },
      },
      dialogStatus: '', //新增弹窗状态
      addDialogVisiable: false,
      titleMap: {
        addEquipment: '新增用户信息',
        editEquipment: '修改用户信息',
      },
      //查询信息实体
      queryInfo: {
        bdcityid: null,
        username: null,
        pageNum: 1, //当前页
        pageSize: 6, //每页最大数
      },
      addNewsInfo: {
        uid: null,
        password: null,
        zhtype: null,
        username: null,
        bdcityid: null,
      },
      cityInfo: {
        pageNum: 1,
        pageSize: 20,
      },
      total: 0, //总记录数
      wikiList: [], // 互动百科列表
    }
  },
  created() {
    this.getWikiList()
    this.getCityList()
    this.zhtype = localStorage.getItem('zhtype')
  },
  methods: {
    //查询所有信息
    async getWikiList() {
      const { data: res } = await this.$http.get('/admin/userList', {
        params: this.queryInfo,
      })
      this.wikiList = res.data.records
      this.total = res.data.total
    },
    //城市列表
    async getCityList() {
      const { data: res } = await this.$http.get('/area/urban', {
        params: this.cityInfo,
      })
      this.cityList = res.data.records
    },
    // 关闭弹窗
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => {})
    },
    // 取消
    cancel() {
      this.addDialogVisiable = false
      this.addNewsInfo = {
        uid: null,
        password: null,
        zhtype: null,
        username: null,
        bdcityid: null,
      }
    },
    // 确认
    async confirm() {
      if (
        !this.addNewsInfo.password ||
        !this.addNewsInfo.username ||
        !this.addNewsInfo.zhtype ||
        !this.addNewsInfo.bdcityid
      ) {
        this.$message.error('请完整所有信息！')
        return
      }
      // 新增
      if (this.dialogStatus == 'addEquipment') {
        const { data: res } = await this.$http.post('/admin/addUser', {
          uid: this.addNewsInfo.uid,
          password: this.addNewsInfo.password,
          zhtype: this.addNewsInfo.zhtype,
          username: this.addNewsInfo.username,
          bdcityid: this.addNewsInfo.bdcityid,
        })
        this.$message.success(res.msg)
        // 修改
      } else if (this.dialogStatus == 'editEquipment') {
        const { data: res } = await this.$http.put('/admin/updateUser', {
          uid: this.addNewsInfo.uid,
          password: this.addNewsInfo.password,
          zhtype: this.addNewsInfo.zhtype,
          username: this.addNewsInfo.username,
          bdcityid: this.addNewsInfo.bdcityid,
        })
        this.$message.success(res.msg)
      }
      this.addDialogVisiable = false
      this.queryInfo.pageNum = 1
      this.getWikiList()
      this.addNewsInfo = {
        uid: null,
        password: null,
        username: null,
        zhtype: null,
      }
    },
    // 新增
    addInfo() {
      this.dialogStatus = 'addEquipment'
      this.addDialogVisiable = true
    },
    // 修改
    async changeInfo(info) {
      this.dialogStatus = 'editEquipment'
      this.addDialogVisiable = true
    //   let params = {
    //     uid: info.uid,
    //     pageNum: 1, //当前页
    //     pageSize: 6, //每页最大数
    //   }
    //   const { data: res } = await this.$http.get('/admin/userList', {
    //     params
    //   })
    //   let infoDetail = res.data.records[0]
      this.addNewsInfo = {
        uid: info.uid,
        password: info.password,
        zhtype: info.zhtype,
        username: info.username,
        bdcityid: info.bdcityid,
      }
    },
    onEditorBlur() {
      // 失去焦点事件
    },
    onEditorFocus() {
      // 获得焦点事件
    },
    onEditorChange() {
      // 内容改变事件
    },
    //最大数
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getWikiList()
    },
    //pageNum的触发
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getWikiList()
      this.$forceUpdate()
    },

    //删除信息
    async getDelJourn(uid) {
      const confirmResult = await this.$confirm(
        '删除后用户信息将永久删除，是否继续？',
        '提示',
        {
          confirmButtonText: '删除',
          cancelButtonText: '放弃',
        }
      ).catch((err) => err)
      if (confirmResult != 'confirm') {
        //取消
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete('/admin/delUser?uid=' + uid)
      if (res.code != 200) {
        return this.$message.error('删除失败!')
      }
      this.$message.success('删除成功!')
      this.getWikiList()
    },
    //将表格数据行居中
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center;'
    },
    //将表格数据列居中
    rowClass({ row, rowIndex }) {
      return 'text-align:center;'
    },
  },
}
</script>
<style lang="less" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px dashed #d9d9d9 !important;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-card {
  height: 720px;
}
.dialog-footer {
  padding-top: 100px;
}
::v-deep .el-form--inline .el-form-item__label {
  float: left;
}
::v-deep .quill-editor .ql-editor {
  height: 500px;
}
</style>
