<template>
  <section>
    <el-form ref="formT" :model="ptInfo" label-width="160px">
      <el-form-item label="周边学校信息">
        <el-input type="textarea" v-model="ptInfo.schoolxx" :rows="4"></el-input>
      </el-form-item>
      <el-form-item label="周边商业等配套信息">
        <el-input type="textarea" v-model="ptInfo.zbxx" :rows="4"></el-input>
      </el-form-item>
      <el-form-item label="是否为学区房">
        <el-select clearable  v-model="ptInfo.isxqf" placeholder="请选择活动区域"  style="width: 200px;">
          <el-option label="是" value="1"></el-option>
          <el-option label="否" value="0"></el-option>
        </el-select>
        <el-button @click="addHandle"  type="primary" style="margin-left: 60px;padding-left:30px;padding-right:30px;" >
          新增
        </el-button>
        <!-- <el-button v-if="ptInfo.ptid" @click="editorHandle"  type="primary" style="margin-left: 60px;padding-left:30px;padding-right:30px;" >
          修改
        </el-button>
        <el-button v-if="ptInfo.ptid" @click="delHandle"  type="primary" style="margin-left: 60px;padding-left:30px;padding-right:30px;" >
          删除
        </el-button> -->
      </el-form-item>
    </el-form>
    <el-table
      :data="ptList"
      border
      style="width: 100%;"
      :cell-style="{ padding: '5px 0px' }"
      :header-cell-style="headcell"
    >
      <el-table-column prop="zbxx" label="已添加周边商业等配套信息" align="center">
      </el-table-column>
      <el-table-column prop="schoolxx" label="已添加周边学校信息信息" align="center">
      </el-table-column>
      <el-table-column prop="isxqf" label="是否学区房" align="center">
      </el-table-column>
      <el-table-column prop="pdid" label="配套id" width="200" align="center" v-if="false">
      </el-table-column>

      <el-table-column prop="address" label="操作" align="center">
        <template slot-scope="scope">
          <el-button @click="delPt(scope.row)" type="text" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </section>
</template>
<script>
  export default {
    name: 'proInfo',
    props: ['lpid', 'ptList'],
    data() {
      return {
        ifdel: false,
        ptInfo: {}
      }
    },
    created(){
    },
    methods: {
      
      // 新增配套
      async addHandle(){
        this.ptInfo.lpid = this.lpid
        const { data: res } = await this.$http.post('/assort/ins', this.ptInfo)
        this.ptInfo = {}
        this.$emit('getpt')
      },
      // 修改配套
      async editorHandle(){
        const { data: res } = await this.$http.put('/assort/upl', this.ptInfo)
        this.$emit('getpt')
      },
      // 删除配套
      async delPt(info){
        const { data: res } = await this.$http.delete('/assort/del?ptid=' + info.ptid)
        this.$emit('getpt')
      },
      headcell(){
          return 'text-align: center;background:#eef1f6;'
      }
    }
  }
</script>
<style scoped>
.el-form-item{
  margin-bottom: 10px;
}
</style>
