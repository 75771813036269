<template>
  <div>
    <el-card>
      <!--导航栏-->
      <el-breadcrumb
        separatorClass="el-icon-arrow-right"
        style="font-size: 16px"
      >
        <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>新房管理</el-breadcrumb-item>
        <el-breadcrumb-item>资讯信息维护</el-breadcrumb-item>
      </el-breadcrumb>
      <!--搜索添加区域-->
      <table style="margin:30px 0">
        <tr style="line-height: 70px;font-size: 14px;">
          <td v-if="zhtype == 1">
            中国区域划分:<el-select
              clearable
              v-model="queryInfo.qybm"
              placeholder="请选择"
              @change="Selarea(queryInfo.qybm)"
              style="margin: 0 30px 0 10px;width:200px"
            >
              <el-option
                v-for="items in areaList"
                :value="items.qybm"
                :key="items.qybm"
                :label="items.qyname"
              >
              </el-option>
            </el-select>
          </td>
          <td v-if="zhtype == 1">
            城市:<el-select
              clearable
              v-model="queryInfo.ctid"
              placeholder="请选择"
              style="line-height: 20px;margin:0 10px;width:200px"
            >
              <el-option
                v-for="items in cityList"
                :value="items.ctid"
                :key="items.ctid"
                :label="items.ctname"
              >
              </el-option>
            </el-select>
          </td>
          <!-- <td>
            新闻类型:<el-select
              clearable
              v-model="queryInfo.type"
              placeholder="请选择类型(非必填)"
              style="line-height: 20px;"
            >
              <el-option :label="item.aaa103" :value="item.aaa101" v-for="item in newsTypeList" :key="item.aaa101"></el-option>
            </el-select>
          </td> -->
          <td>
            <el-button
              type="primary"
              icon="el-icon-search"
              style="width: 100px;margin:0 20px 0 80px"
              @click="getJournList"
              >搜索</el-button
            >
            <el-button
              type="primary"
              icon="el-icon-circle-plus"
              style="width:100px"
              @click="addInfo"
               v-if="zhtype == 1"
              >添加</el-button
            >
          </td>
        </tr>
      </table>

      <!--主体内容表单-->
      <template>
        <el-table
          :data="journList"
          border
          style="width: 100%;line-height: 12px; font-size: 18px"
          :cell-style="cellStyle"
          :header-cell-style="rowClass"
        >
          <el-table-column
            prop="nrid"
            label="id"
            width="100"
            v-if="false"
          ></el-table-column>
          <el-table-column
            prop="qybm"
            label="区域"
            width="100"
            v-if="false"
          ></el-table-column>
          <el-table-column
            prop="ctname"
            label="城市"
            width="60"
          ></el-table-column>
          <el-table-column prop="sn" label="顺序" width="50"></el-table-column>
          <el-table-column
            prop="source"
            label="来源"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="crtimedate"
            label="创建时间"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="title"
            label="标题"
            width="300"
          ></el-table-column>
          <!-- <el-table-column
            prop="subheading"
            label="副标题"
            width="100"
          ></el-table-column> -->
          <!-- <el-table-column
            prop="type"
            label="新闻类型"
            width="100"
          ></el-table-column> -->
          <el-table-column prop="zy" label="摘要" width="400"></el-table-column>
          <el-table-column
            prop="cotentStr"
            label="正文"
            width="400"
            v-if="false"
          ></el-table-column>
          <el-table-column label="操作" v-if="zhtype == 1" width="200">
            <template slot-scope="scope">
              <el-button
                class="el-icon-edit"
                type="primary"
                @click="changeInfo(scope.row)"
                >修改</el-button
              >
              <el-button
                class="el-icon-delete"
                type="danger"
                @click="getDelJourn(scope.row.nrid)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
      <!-- 弹窗 -->
      <el-dialog
        width="70%"
        :title="titleMap[dialogStatus]"
        :visible.sync="addDialogVisiable"
        @close="cancel"
        :before-close="handleClose"
      >
        <el-form
          :inline="true"
          :model="addNewsInfo"
          ref="aaddFormRef"
          label-width="150px"
        >
          <el-row>
            <el-col :span="8">
              <el-form-item label="中国划分区域" prop="qybm">
                <el-select
                  clearable
                  placeholder="请选择"
                  @change="Selarea(addNewsInfo.qybm)"
                  v-model="addNewsInfo.qybm"
                  style="width:200px"
                >
                  <el-option
                    v-for="items in areaList"
                    :value="items.qybm"
                    :key="items.qybm"
                    :label="items.qyname"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="城市" prop="ctid">
                <el-select
                  clearable
                  placeholder="请选择"
                  @change="Selcity(addNewsInfo.ctid)"
                  v-model="addNewsInfo.ctid"
                  style="width:200px"
                >
                  <el-option
                    v-for="items in cityList"
                    :value="items.ctid"
                    :key="items.ctid"
                    :label="items.ctname"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <!-- 顺序 -->
              <el-form-item label="顺序" prop="sn">
                <el-input
                  v-model="addNewsInfo.sn"
                  placeholder="请输入顺序"
                  style="width:200px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="标题" prop="title">
                <el-input
                  v-model="addNewsInfo.title"
                  placeholder="请输入标题"
                  style="width:200px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="副标题" prop="subheading">
                <el-input
                  v-model="addNewsInfo.subheading"
                  placeholder="请输入标题"
                  style="width:200px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="来源" prop="title">
                <el-input
                  v-model="addNewsInfo.source"
                  placeholder="请输入新闻来源"
                  style="width:200px"
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="8">
              <el-form-item label="新闻类型" prop="type">
                <el-select
                  clearable
                  placeholder="请选择"
                  v-model="addNewsInfo.type"
                  style="width:200px"
                >
                  <el-option :label="item.aaa103" :value="item.aaa101" v-for="item in newsTypeList" :key="item.aaa101"></el-option>
                </el-select>
              </el-form-item>
            </el-col> -->
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="发布者" prop="issuename">
                <el-input
                  v-model="addNewsInfo.issuename"
                  placeholder="请输入发布者姓名"
                  style="width: 200px;"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="浏览量" prop="viewamount">
                <el-input
                  v-model="addNewsInfo.viewamount"
                  placeholder="请输入浏览量"
                  style="width: 200px;"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="发布日期">
                <el-date-picker
                  size="small"
                  v-model="addNewsInfo.issuedate"
                  align="right"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  style="width: 200px;"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="摘要" prop="zy">
                <el-input
                  v-model="addNewsInfo.zy"
                  placeholder="请输入摘要内容"
                  type="textarea"
                  style="width: 200px;"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="是否为资讯中置顶的楼盘">
                <el-select
                  clearable
                  v-model="addNewsInfo.istop"
                  placeholder="请选择"
                  
                  style="width: 200px;"
                >
                  <el-option label="是" value="1"></el-option>
                  <el-option label="否" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="是否为展示到资讯封面">
                <el-select
                  clearable
                  v-model="addNewsInfo.iscover"
                  placeholder="请选择"
                  
                  style="width: 200px;"
                >
                  <el-option label="是" value="1"></el-option>
                  <el-option label="否" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="7">
              <el-form-item label="发布者头像">
                <el-upload
                  class="avatar-uploader"
                  action="https://ddfun.baifun.cn/oss/uploadFileReturnUrl"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img
                    v-if="addNewsInfo.issuephoto"
                    :src="addNewsInfo.issuephoto"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="7" v-if="addNewsInfo.iscover == 1">
              <el-form-item label="封面">
                <el-upload
                  class="avatar-uploader"
                  action="https://ddfun.baifun.cn/oss/uploadFileReturnUrl"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccessSec"
                  :before-upload="beforeAvatarUploadSec"
                >
                  <img
                    v-if="addNewsInfo.coverphoto"
                    :src="addNewsInfo.coverphoto"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <template>
              <div class="editorBox quill-wrap">
                <quill-editor
                  v-model="addNewsInfo.cotentStr"
                  ref="myQuillEditor"
                  :options="editorOption"
                  @change="onEditorChange($event)"
                >
                </quill-editor>
              </div>
            </template>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="confirm">确认</el-button>
        </span>
      </el-dialog>
      <!--分页-->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
// require styles 引入样式
import { quillEditor, Quill } from 'vue-quill-editor'
import { container, ImageExtend, QuillWatch } from 'quill-image-extend-module'
Quill.register('modules/ImageExtend', ImageExtend)

import '../../assets/imagePaste'

export default {
  components: { quillEditor },
  created() {
    this.getJournList()
    this.SelQycodeAll()
    this.getNewsTypeList()
    this.zhtype = localStorage.getItem('zhtype')
  },
  mounted() {},
  data() {
    let self = this
    return {
      zhtype: '',
      cityall: JSON.parse(window.sessionStorage.getItem('cityAll')).records,
      editorOption: {
        modules: {
          // imageDrop: true,
          ImageExtend: {
            // 如果不作设置，即{}  则依然开启复制粘贴功能且以base64插入
            loading: true,
            name: 'file', // 图片参数名
            size: 3, // 可选参数 图片大小，单位为M，1M = 1024kb
            action: 'https://ddfun.baifun.cn/oss/uploadFileReturnUrl', // 服务器地址, 如果action为空，则采用base64插入图片
            // response 为一个函数用来获取服务器返回的具体图片地址
            // 例如服务器返回{code: 200; data:{ url: 'baidu.com'}}
            // 则 return res.data.url
            response: (res) => {
              return res.filepath
            },

            sizeError: () => {}, // 图片超过大小的回调
            start: () => {}, // 可选参数 自定义开始上传触发事件
            end: () => {}, // 可选参数 自定义上传结束触发的事件，无论成功或者失败
            error: () => {}, // 可选参数 上传失败触发的事件
            success: () => {}, // 可选参数  上传成功触发的事件
            change: (xhr, formData) => {}, // 可选参数 每次选择图片触发，也可用来设置头部，但比headers多了一个参数，可设置formData
          },
          toolbar: {
            // 如果不上传图片到服务器，此处不必配置
            container: container,
            handlers: {
              image: function() {
                // 劫持原来的图片点击按钮事件
                QuillWatch.emit(this.quill.id)
              },
            },
          },
          imagePaste: {
            addImageBlob: function(blob, callback) {
              var formData = new FormData()
              formData.append('file', blob)
              // your upload function, get the uploaded image url, add then
              let config = {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Accept: '*/*',
                },
              }
              self.$http
                .post('/oss/uploadFileReturnUrl', formData, config)
                .then((res) => {
                  var imageUrl = res.data.filepath
                  callback(imageUrl)
                })
            },
          },
        },
      },
      dialogStatus: '', //新增弹窗状态
      addDialogVisiable: false,
      titleMap: {
        addEquipment: '新增新闻信息',
        editEquipment: '修改新闻信息',
      },
      //查询信息实体
      queryInfo: {
        query: null, //查询信息
        area: null,
        ctid: localStorage.getItem('bdcityid') || null,
        sn: null,
        title: null,
        type: 2,
        pageNum: 1, //当前页
        pageSize: 6, //每页最大数
      },
      addNewsInfo: {
        qybm: null,
        nrid: null,
        ctid: null,
        sn: null,
        title: null,
        type: 2,
        zy: null,
        cotentStr: null,
        source: null,
        subheading: null,
        issuename: null,
        issuedate: null,
        issuephoto: null,
        viewamount: null,
        coverphoto: null,
        istop: null,
        iscover: null,
      },
      journList: [], //楼盘新闻数据集合
      areaList: [], //区域数据集合
      cityList: [], //城市区域数据集合
      newsTypeList: [], // 新闻类型列表
      typeList: [
        {
          label: '百房资讯',
          value: 2,
        },
        {
          label: '楼盘新闻',
          value: 3,
        },
        {
          label: '新房动态',
          value: 4,
        },
      ], //楼盘新闻类型数据集合
      total: 0, //楼盘新闻总记录数
    }
  },

  methods: {
    // 发布者头像上传
    handleAvatarSuccess(res, file) {
      // this.logoUrl = URL.createObjectURL(file.raw)
      this.addNewsInfo.issuephoto = file.response.filepath
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error('上传附件只能是 JPG或PNG 格式!')
      }
      if (!isLt5M) {
        this.$message.error('上传附件大小不能超过 5M!')
      }
      return isJPG && isLt5M
    },
    // 封面上传
    handleAvatarSuccessSec(res, file) {
      // this.logoUrl = URL.createObjectURL(file.raw)
      this.addNewsInfo.coverphoto = file.response.filepath
    },
    beforeAvatarUploadSec(file) {
     const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error('上传附件只能是 JPG或PNG 格式!')
      }
      if (!isLt5M) {
        this.$message.error('上传附件大小不能超过 5M!')
      }
      return isJPG && isLt5M
    },
    // 获取新闻类型列表
    async getNewsTypeList() {
      await this.$http.get('/dic/aaa100?aaa100=newType').then((res) => {
        this.newsTypeList = res.data.data
      })
    },
    handleCustomMatcher(node, Delta) {
      let ops = []
      Delta.ops.forEach((op) => {
        if (op.insert && typeof op.insert === 'string') {
          // 如果粘贴了图片，这里会是一个对象，所以可以这样处理
          ops.push({
            insert: op.insert,
          })
        }
      })
      Delta.ops = ops
      return Delta
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => {})
    },
    // 取消
    cancel() {
      this.addDialogVisiable = false
      this.addNewsInfo = {
        qybm: null,
        nrid: null,
        ctid: null,
        sn: null,
        title: null,
        type: 2,
        zy: null,
        cotentStr: null,
        source: null,
        subheading: null,
        issuename: null,
        issuedate: null,
        issuephoto: null,
        viewamount: null,
        coverphoto: null,
        istop: null,
        iscover: null,
      }
      this.cityList = []
    },
    async confirm() {
      if (!this.addNewsInfo.ctid) {
        this.$message.error('城市必填！')
      } else {
        // if (this.addNewsInfo.type == '百房资讯') {
        //   this.addNewsInfo.type = '2'
        // } else if (this.addNewsInfo.type == '楼盘新闻') {
        //   this.addNewsInfo.type = '3'
        // } else if (this.addNewsInfo.type == '新房动态') {
        //   this.addNewsInfo.type = '4'
        // }
        // if (!parseInt(this.addNewsInfo.type)) {
        //   this.$message.warning('新闻类型必选！')
        //   let typeValue = await this.typeList.filter(
        //     (t) => t.label == this.addNewsInfo.type
        //   )[0].value
        //   this.addNewsInfo.type = typeValue
        // }
        this.addDialogVisiable = false
        if (this.dialogStatus == 'addEquipment') {
          const { data: res } = await this.$http.post('/journ/ins', {
            ctid: this.addNewsInfo.ctid,
            sn: this.addNewsInfo.sn,
            title: this.addNewsInfo.title,
            subheading: this.addNewsInfo.subheading,
            type: this.addNewsInfo.type,
            zy: this.addNewsInfo.zy,
            cotentStr: this.addNewsInfo.cotentStr,
            source: this.addNewsInfo.source,
            issuename: this.addNewsInfo.issuename,
            issuedate: this.addNewsInfo.issuedate,
            issuephoto: this.addNewsInfo.issuephoto,
            viewamount: this.addNewsInfo.viewamount,
            coverphoto: this.addNewsInfo.coverphoto,
            istop: this.addNewsInfo.istop,
            iscover: this.addNewsInfo.iscover,
            
          })
        } else if (this.dialogStatus == 'editEquipment') {
          // if (this.addNewsInfo == '百房资讯') {
          //   this.addNewsInfo = '2'
          // } else if (this.addNewsInfo == '楼盘新闻') {
          //   this.addNewsInfo = '3'
          // } else if (this.addNewsInfo == '新房动态') {
          //   this.addNewsInfo = '4'
          // }
          const { data: res } = await this.$http.put('/journ/upl', {
            nrid: this.addNewsInfo.nrid,
            ctid: this.addNewsInfo.ctid,
            sn: this.addNewsInfo.sn,
            title: this.addNewsInfo.title,
            type: this.addNewsInfo.type,
            zy: this.addNewsInfo.zy,
            cotentStr: this.addNewsInfo.cotentStr,
            source: this.addNewsInfo.source,
            subheading: this.addNewsInfo.subheading,
            issuename: this.addNewsInfo.issuename,
            issuedate: this.addNewsInfo.issuedate,
            issuephoto: this.addNewsInfo.issuephoto,
            viewamount: this.addNewsInfo.viewamount,
            coverphoto: this.addNewsInfo.coverphoto,
            istop: this.addNewsInfo.istop,
            iscover: this.addNewsInfo.iscover,
          })
          this.cityList = []
        }
        this.queryInfo.pageNum = 1
        this.getJournList()
        this.addNewsInfo = {
          qybm: null,
          nrid: null,
          ctid: null,
          sn: null,
          title: null,
          type: 2,
          zy: null,
          cotentStr: null,
          source: null,
          subheading: null,
          issuename: null,
          issuedate: null,
          issuephoto: null,
          viewamount: null,
          coverphoto: null,
          istop: null,
          iscover: null,
        }
      }
    },
    addInfo() {
      this.dialogStatus = 'addEquipment'
      this.addDialogVisiable = true
      this.queryInfo.qybm = null
      this.queryInfo.ctid = null
      this.cityList = []
    },
    async changeInfo(info) {
      this.dialogStatus = 'editEquipment'
      this.addDialogVisiable = true
      const { data: res } = await this.$http.get(
        '/journ/list?nrid=' + info.nrid
      )
      let infoDetail = res.data[0]
      // this.addNewsInfo.qybm = await this.cityall.filter(c => c.ctid==info.ctid)[0].qybm
      // this.addNewsInfo.qybm = info.qybm
      this.addNewsInfo = {
        qybm: info.qybm,
        nrid: infoDetail.nrid,
        ctid: Number(info.ctid),
        sn: infoDetail.sn,
        title: infoDetail.title,
        type: 2,
        zy: infoDetail.zy,
        cotentStr: infoDetail.contStr,
        source: infoDetail.source,
        issuename: infoDetail.issuename,
        issuedate: infoDetail.issuedate,
        issuephoto: infoDetail.issuephoto,
        viewamount: infoDetail.viewamount,
        coverphoto: infoDetail.coverphoto,
        istop: infoDetail.istop,
        iscover: infoDetail.iscover,
      }
      this.Selarea(this.addNewsInfo.qybm)
    },
    onEditorBlur() {
      // 失去焦点事件
    },
    onEditorFocus() {
      // 获得焦点事件
    },
    onEditorChange() {
      // 内容改变事件
    },
    //查询所有区域信息
    async SelQycodeAll() {
      const { data: res } = await this.$http.get(
        'area/qycode' + '?query' + this.queryInfo.query
      )
      this.areaList = res.data
    },
    //查询城市信息
    async Selarea(qybm) {
      this.queryInfo.ctid = null
      const { data: res } = await this.$http.get('city/sel' + '?qybm=' + qybm)
      this.cityList = res.data
    },
    async Selcity(ctid) {
      const { data: res } = await this.$http.get('ads/sel', {
        params: { ctid: ctid },
      })
      this.adsList = res.data
    },
    //查询所有楼盘新闻信息
    async getJournList() {
      const { data: res } = await this.$http.get('journ/ams', {
        params: this.queryInfo,
      })
      this.journList = res.data.records
      this.total = res.data.total
    },
    //最大数
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getJournList()
    },
    //pageNum的触发
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getJournList()
    },

    //删除楼盘信息
    async getDelJourn(nrid) {
      const confirmResult = await this.$confirm(
        '删除后用户信息将永久删除，是否继续？',
        '提示',
        {
          confirmButtonText: '删除',
          cancelButtonText: '放弃',
        }
      ).catch((err) => err)
      if (confirmResult != 'confirm') {
        //取消
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete('journ/del?nrid=' + nrid)
      if (res.code != 200) {
        return this.$message.error('删除失败!')
      }
      this.$message.success('删除成功!')
      this.getJournList()
    },
    //将表格数据行居中
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center;'
    },
    //将表格数据列居中
    rowClass({ row, rowIndex }) {
      return 'text-align:center;'
    },
  },
}
</script>
<style lang="less" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px dashed #d9d9d9 !important;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-card {
  height: 720px;
}
.dialog-footer {
  padding-top: 100px;
}
::v-deep .el-form--inline .el-form-item__label {
  float: left;
}
::v-deep .quill-editor .ql-editor {
  height: 500px;
}
// .editor {
//   line-height: normal !important;
//   height: 500px;
// }
// .ql-snow .ql-tooltip[data-mode='link']::before {
//   content: '请输入链接地址:';
// }
// .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
//   border-right: 0px;
//   content: '保存';
//   padding-right: 0px;
// }

// .ql-snow .ql-tooltip[data-mode='video']::before {
//   content: '请输入视频地址:';
// }

// .ql-snow .ql-picker.ql-size .ql-picker-label::before,
// .ql-snow .ql-picker.ql-size .ql-picker-item::before {
//   content: '14px';
// }
// .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='small']::before,
// .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='small']::before {
//   content: '10px';
// }
// .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='large']::before,
// .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='large']::before {
//   content: '18px';
// }
// .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='huge']::before,
// .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='huge']::before {
//   content: '32px';
// }

// .ql-snow .ql-picker.ql-header .ql-picker-label::before,
// .ql-snow .ql-picker.ql-header .ql-picker-item::before {
//   content: '文本';
// }
// .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='1']::before,
// .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='1']::before {
//   content: '标题1';
// }
// .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='2']::before,
// .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='2']::before {
//   content: '标题2';
// }
// .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='3']::before,
// .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='3']::before {
//   content: '标题3';
// }
// .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='4']::before,
// .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='4']::before {
//   content: '标题4';
// }
// .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='5']::before,
// .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='5']::before {
//   content: '标题5';
// }
// .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='6']::before,
// .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='6']::before {
//   content: '标题6';
// }

// .ql-snow .ql-picker.ql-font .ql-picker-label::before,
// .ql-snow .ql-picker.ql-font .ql-picker-item::before {
//   content: '标准字体';
// }
// .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='serif']::before,
// .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='serif']::before {
//   content: '衬线字体';
// }
// .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='monospace']::before,
// .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='monospace']::before {
//   content: '等宽字体';
// }
</style>
