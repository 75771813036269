<template>
  <div>
    <!--搜索添加区域-->
    <table style="margin-bottom: 30px">
      <tr style="font-size: 14px">
        <td style="display: flex;margin-right: 30px">
          <span style="width:50px;margin-left:20px">城市:</span>
          <el-select
            clearable
            v-model="queryInfo.ctid"
            placeholder="请选择城市"
            @change="Selcity(queryInfo.ctid)"
          >
            <el-option
              v-for="items in cityList"
              :value="items.ctid"
              :key="items.ctid"
              :label="items.ctname"
            ></el-option>
          </el-select>
          <span style="width:50px;margin-left:20px">辖区:</span>
          <el-select
            clearable
            v-model="queryInfo.ctqybmid"
            placeholder="请选择"
            @change="SelBusiness(queryInfo.ctqybmid)"
          >
            <el-option
              v-for="items in adsList"
              :value="items.ctqybm"
              :key="items.ctqybm"
              :label="items.ctqyname"
            >
            </el-option>
          </el-select>
          <span style="width:50px;margin-left:20px">商圈:</span>
          <el-select
            clearable
            v-model="queryInfo.tradingAreaid"
            placeholder="请选择"
          >
            <el-option
              v-for="items in businessList"
              :value="items.tradingAreaId"
              :key="items.tradingAreaId"
              :label="items.sqname"
            >
            </el-option>
          </el-select>
          <span style="width:100px;margin-left:20px">所属经纪人:</span>
          <el-select
            clearable
            v-model="queryInfo.brokerid"
            placeholder="请选择"
          >
            <el-option
              v-for="items in brokerList"
              :value="items.brokerid"
              :key="items.brokerid"
              :label="items.brokername"
            >
            </el-option>
          </el-select>
        </td>
        <td>
          <el-button type="primary" icon="el-icon-search" @click="getList"
            >查询</el-button
          >
          <el-button type="primary" icon="el-icon-circle-plus" @click="addInfo"
            >新增二手房</el-button
          >
        </td>
      </tr>
    </table>

    <!--主体内容表单-->
    <template>
      <el-table
        :data="list"
        border
        style="width: 100%;line-height: 12px; font-size: 18px"
        :cell-style="cellStyle"
        :header-cell-style="rowClass"
      >
        <el-table-column
          prop="communityname"
          label="社区"
          width="250"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="ctqyname"
          label="区域"
          width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="sqname"
          label="商圈"
          width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="eprice"
          label="参考总价（万）"
          width="200"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="etitle"
          label="标题"
          width="400"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              class="el-icon-edit"
              type="primary"
              @click="changeInfo(scope.row)"
              >编辑</el-button
            >
            <el-button
              class="el-icon-delete"
              type="danger"
              @click="delInfo(scope.row.esfid)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </template>

    <!-- 弹窗 -->
    <el-dialog
      width="80%"
      :title="titleMap[dialogStatus]"
      :visible.sync="DialogVisiable"
      @close="cancel"
      :before-close="handleClose"
      :close-on-click-modal="false"
      top="20px"
      :modal="true"
      :lock-scroll="true"
      class="dialogHeader"
      center
    >
      <el-tabs type="border-card" v-model="activeName">
        <el-tab-pane label="二手房基本信息" name="first">
          <el-form
            :inline="true"
            :model="dialogInfo"
            ref="aaddFormRef"
            style="padding:30px"
            label-width="120px"
          >
            <!-- 第一排 -->
            <el-row>
              <el-col :span="6">
                <el-form-item
                  label="城市"
                  prop="ctid"
                  style="display:flex;align-items:center"
                >
                  <el-select
                    clearable
                    style="width: 200px;"
                    v-model="dialogInfo.ctid"
                    placeholder="请选择"
                    @change="Selcity(dialogInfo.ctid)"
                  >
                    <el-option
                      v-for="items in cityList"
                      :value="items.ctid"
                      :key="items.ctid"
                      :label="items.ctname"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="辖区"
                  prop="ctqybmid"
                  style="display:flex;align-items:center"
                >
                  <el-select
                    clearable
                    v-model="dialogInfo.ctqybmid"
                    placeholder="请选择"
                    style="width: 200px;"
                    @change="SelBusiness(dialogInfo.ctqybmid)"
                  >
                    <el-option
                      v-for="items in adsList"
                      :value="items.ctqybm"
                      :key="items.ctqybm"
                      :label="items.ctqyname"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="商圈"
                  prop="ctqybmid"
                  style="display:flex;align-items:center"
                >
                  <el-select
                    clearable
                    v-model="dialogInfo.tradingAreaid"
                    placeholder="请选择"
                    style="width: 200px;"
                  >
                    <el-option
                      v-for="items in businessList"
                      :value="items.tradingAreaId"
                      :key="items.tradingAreaId"
                      :label="items.sqname"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="所属经纪人"
                  prop="brokerid"
                  style="display:flex;align-items:center"
                >
                  <el-select
                    clearable
                    v-model="dialogInfo.brokerid"
                    placeholder="请选择"
                    style="width:200px"
                  >
                    <el-option
                      v-for="items in brokerList"
                      :value="items.brokerid"
                      :key="items.brokerid"
                      :label="items.brokername"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 第二排 -->
            <el-row>
              <el-col :span="6">
                <el-form-item
                  label="标题"
                  prop="etitle"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.etitle"
                    placeholder="请输入标题"
                    style="width:230px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="评分"
                  prop="escore"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.escore"
                    placeholder="请输入评分"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="参考总价"
                  prop="eprice"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.eprice"
                    placeholder="请输入参考总价"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="房型"
                  prop="roomtype"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.roomtype"
                    placeholder="请输入房型"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 第三排 -->
            <el-row>
              <el-col :span="6">
                <el-form-item
                  label="户型面积"
                  prop="earea"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.earea"
                    placeholder="请输入户型面积"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="装修情况"
                  prop="decorate"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.decorate"
                    placeholder="请输入装修情况"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="朝向"
                  prop="aspect"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.aspect"
                    placeholder="请输入朝向"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="楼层"
                  prop="elevel"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.elevel"
                    placeholder="请输入楼层"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 第四排 -->
            <el-row>
              <el-col :span="6">
                <el-form-item
                  label="年代"
                  prop="etime"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.etime"
                    placeholder="请输入年代"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="用途"
                  prop="euse"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.euse"
                    placeholder="请输入用途"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="权属"
                  prop="trading"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.trading"
                    placeholder="请输入权属"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="房源编码"
                  prop="ecoding"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.ecoding"
                    placeholder="请输入房源编码"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 第五排 -->
            <el-row>
              <el-col :span="6">
                <el-form-item
                  label="房源坐落"
                  prop="location"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.location"
                    placeholder="请输入房源坐落"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="房源户型"
                  prop="housetype"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.housetype"
                    placeholder="请输入房源户型"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="户型结构"
                  prop="structure"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.structure"
                    placeholder="请输入户型结构"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="梯户比例"
                  prop="towerratio"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.towerratio"
                    placeholder="请输入梯户比例"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 第六排 -->
            <el-row>
              <el-col :span="6">
                <el-form-item
                  label="上次交易"
                  style="display:flex;align-items:center"
                >
                  <el-date-picker
                    style="width: 200px"
                    v-model="dialogInfo.dealtime"
                    align="right"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="购房年限"
                  prop="buy"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.buy"
                    placeholder="请输入购房年限"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="是否唯一"
                  style="display:flex;align-items:center"
                >
                  <el-select
                    v-model="dialogInfo.isonly"
                    placeholder="请选择"
                    style="width: 200px;"
                  >
                    <el-option label="否" value="否"></el-option>
                    <el-option label="是" value="是"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="看房时间"
                  prop="seetime"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.seetime"
                    placeholder="请输入看房时间"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 第七排 -->
            <el-row>
              <el-col :span="6">
                <el-form-item
                  label="物业公司"
                  prop="property"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.property"
                    placeholder="请输入物业公司"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="物业费用"
                  prop="propertycost"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.propertycost"
                    placeholder="请输入物业费用"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="产权所属"
                  prop="propertyright"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.propertyright"
                    placeholder="请输入产权所属"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="抵押信息"
                  prop="pledgeinfo"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.pledgeinfo"
                    placeholder="请输入抵押信息"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 图片 -->
            <el-row style="">
              <el-col :span="6">
                <el-form-item
                  label="水电类型"
                  prop="hydropower"
                  style="display:flex;align-items:center"
                >
                  <el-input
                    v-model="dialogInfo.hydropower"
                    placeholder="请输入水电类型"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="房源负责人图片">
                  <el-upload
                    class="avatar-uploader"
                    action="https://ddfun.baifun.cn/oss/uploadFileReturnUrl"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                  >
                    <img
                      v-if="dialogInfo.principalphoto"
                      :src="dialogInfo.principalphoto"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="房源发布机构">
                  <el-upload
                    class="avatar-uploader"
                    action="https://ddfun.baifun.cn/oss/uploadFileReturnUrl"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess2"
                    :before-upload="beforeAvatarUpload"
                  >
                    <img
                      v-if="dialogInfo.institutionphoto"
                      :src="dialogInfo.institutionphoto"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <template>
                  <div class="editorBox quill-wrap" style="display:flex">
                    <span>房源概况：</span>
                    <quill-editor
                      v-model="dialogInfo.egeneralStr"
                      ref="myQuillEditor"
                      :options="editorOption"
                    >
                    </quill-editor>
                  </div>
                </template>
              </el-col>
            </el-row>
            <el-form-item>
              <el-button
                type="primary"
                style="margin-top: 100px;"
                @click="handelConfirm"
                >确认</el-button
              >
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="二手房相册信息" name="second" :disabled="cantab">
          <el-row>
            <el-col :span="18">
              <template>
                <el-radio v-model="xctype" label="1">二手房相册</el-radio>
                <el-radio v-model="xctype" label="2">二手房封面</el-radio>
              </template>
            </el-col>
            <el-col :span="18" style="margin-bottom: 20px;">
              <upload-img
                ref="uploadImg"
                @addvp="uploadClick"
                @delImg="delImg"
              ></upload-img>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-button @click="addHandle" type="primary" style="">
                新增
              </el-button>
            </el-col>
          </el-row>
          <el-table
            :data="imgList"
            border
            style="width: 100%;"
            :cell-style="{ padding: '5px 0px' }"
            :header-cell-style="headcell"
          >
            <el-table-column
              prop="xctype"
              label="图片类型"
              align="center"
              width="300"
            >
            </el-table-column>
            <el-table-column
              prop="cxfmtpurl"
              label="图片路径"
              align="center"
              width="600"
            >
            </el-table-column>
            <el-table-column prop="cxfmtpurl" label="图片展示" align="center">
              <template slot-scope="scope">
                <img
                  :src="scope.row.cxfmtpurl"
                  alt=""
                  srcset=""
                  style="width: 80px;height: 40px"
                />
              </template>
            </el-table-column>
            <el-table-column prop="address" label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  @click="deletClick(scope.row)"
                  type="text"
                  size="small"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="二手房坐标信息" name="third" :disabled="cantab">
          <el-row>
            <el-col :span="18">
              <el-form
                ref="pointInfo"
                :model="pointInfo"
                label-width="100px"
                label-position="right"
              >
                <el-row>
                  <el-col :span="12">
                    <el-form-item>
                      <span style="padding: 0px 4px;">经纬度</span>
                      <el-input
                        v-model="pointInfo.point"
                        style="width: 188px;"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-form-item>
                  <el-button
                    @click="addPoint"
                    type="primary"
                    style="margin-left: 240px;padding-left:30px;padding-right:30px;"
                  >
                    新增
                  </el-button>
                </el-form-item>
              </el-form>
            </el-col>
            <el-col :span="6" style="margin: 8px 0px;">
              <el-button
                type="primary"
                style="padding-left:30px;padding-right:30px;"
                @click="turnOther"
              >
                跳转拾取坐标系统
              </el-button>
            </el-col>
          </el-row>
          <el-table
            :data="pointList"
            border
            style="width: 100%;"
            :cell-style="{ padding: '5px 0px' }"
            :header-cell-style="headcell"
          >
            <el-table-column
              prop="point"
              label="已添加坐标经纬度"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="tc" label="已添加坐标层级" align="center">
            </el-table-column>
            <el-table-column
              prop="zpid"
              label="zpid"
              width="200"
              align="center"
              v-if="false"
            >
            </el-table-column>
            <el-table-column
              prop="type"
              label="type"
              width="200"
              align="center"
              v-if="false"
            >
            </el-table-column>

            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button @click="delPoint(scope.row)" type="text" size="small"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="二手房编码信息" name="fourth" :disabled="cantab">
          <el-form
            ref="codeInfo"
            :model="codeInfo"
            label-width="120px"
            style="padding:30px"
          >
            <el-row>
              <el-col :span="6">
                <el-form-item label="二维码">
                  <el-upload
                    class="avatar-uploader"
                    action="https://ddfun.baifun.cn/oss/uploadFileReturnUrl"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess3"
                    :before-upload="beforeAvatarUpload"
                  >
                    <img
                      v-if="codeInfo.codephoto"
                      :src="codeInfo.codephoto"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <el-form-item
                  label="房源编码"
                  prop="ecode"
                  style="align-items:center"
                >
                  <el-input
                    v-model="codeInfo.ecode"
                    placeholder="请输入房源编码"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="参考总价"
                  prop="totalprice"
                  style="align-items:center"
                >
                  <el-input
                    v-model="codeInfo.totalprice"
                    placeholder="请输入参考总价"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="参考单位"
                  prop="singleprice"
                  style="align-items:center"
                >
                  <el-input
                    v-model="codeInfo.singleprice"
                    placeholder="请输入参考单位"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="详情链接"
                  prop="codeurl"
                  style="align-items:center"
                >
                  <el-input
                    v-model="codeInfo.codeurl"
                    placeholder="请输入详情链接"
                    style="width:200px"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item>
              <el-button
                @click="addCode"
                type="primary"
                style="margin-top:50px"
              >
                新增
              </el-button>
            </el-form-item>
          </el-form>
          <el-table
            :data="codeList"
            border
            style="width: 100%;"
            :cell-style="{ padding: '5px 0px' }"
            :header-cell-style="headcell"
          >
            <el-table-column prop="ecode" label="房源编码" align="center">
            </el-table-column>
            <el-table-column prop="totalprice" label="参考总价" align="center">
            </el-table-column>
            <el-table-column prop="singleprice" label="参考单位" align="center">
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button @click="delCode(scope.row)" type="text" size="small"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
// require styles 引入样式
import { quillEditor, Quill } from 'vue-quill-editor'
import { container, ImageExtend, QuillWatch } from 'quill-image-extend-module'
Quill.register('modules/ImageExtend', ImageExtend)
import '@/assets/imagePaste'
import uploadImg from '@/components/upload/uploadImg.vue'
export default {
  components: { quillEditor, uploadImg },
  props: ['communityid'],
  data() {
    return {
      editorOption: {
        modules: {
          // imageDrop: true,
          ImageExtend: {
            // 如果不作设置，即{}  则依然开启复制粘贴功能且以base64插入
            loading: true,
            name: 'file', // 图片参数名
            size: 3, // 可选参数 图片大小，单位为M，1M = 1024kb
            action: 'https://ddfun.baifun.cn/oss/uploadFileReturnUrl', // 服务器地址, 如果action为空，则采用base64插入图片
            // response 为一个函数用来获取服务器返回的具体图片地址
            // 例如服务器返回{code: 200; data:{ url: 'baidu.com'}}
            // 则 return res.data.url
            response: (res) => {
              return res.filepath
            },

            sizeError: () => {}, // 图片超过大小的回调
            start: () => {}, // 可选参数 自定义开始上传触发事件
            end: () => {}, // 可选参数 自定义上传结束触发的事件，无论成功或者失败
            error: () => {}, // 可选参数 上传失败触发的事件
            success: () => {}, // 可选参数  上传成功触发的事件
            change: (xhr, formData) => {}, // 可选参数 每次选择图片触发，也可用来设置头部，但比headers多了一个参数，可设置formData
          },
          toolbar: {
            // 如果不上传图片到服务器，此处不必配置
            container: container,
            handlers: {
              image: function() {
                // 劫持原来的图片点击按钮事件
                QuillWatch.emit(this.quill.id)
              },
            },
          },
          imagePaste: {
            addImageBlob: function(blob, callback) {
              var formData = new FormData()
              formData.append('file', blob)
              // your upload function, get the uploaded image url, add then
              let config = {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Accept: '*/*',
                },
              }
              self.$http
                .post('/oss/uploadFileReturnUrl', formData, config)
                .then((res) => {
                  var imageUrl = res.data.filepath
                  callback(imageUrl)
                })
            },
          },
        },
      },
      activeName: 'first',
      dialogStatus: '', //新增弹窗状态
      DialogVisiable: false,
      xctype: '1', // 1相册 2封面
      titleMap: {
        addEquipment: '新增二手房信息',
        editEquipment: '修改二手房信息',
      },
      //查询信息实体
      queryInfo: {
        pageNum: 1, //当前页
        pageSize: 6, //每页最大数
        ctid: null,
        ctqybmid: null,
        tradingAreaid: null,
        brokerid: null,
        comid: this.communityid,
      },
      // 新增、编辑信息
      dialogInfo: {
        comid: this.communityid,
        esfid: null,
        etitle: null,
        escore: null,
        eprice: null,
        egeneralStr: null,
        roomtype: null,
        earea: null,
        decorate: null,
        aspect: null,
        elevel: null,
        etime: null,
        trading: null,
        euse: null,
        ecoding: null,
        location: null,
        housetype: null,
        structure: null,
        towerratio: null,
        buy: null,
        dealtime: null,
        isonly: null,
        seetime: null,
        property: null,
        propertycost: null,
        propertyright: null,
        pledgeinfo: null,
        hydropower: null,
        brokerid: null,
        principalphoto: null,
        institutionphoto: null,
        ctid: null,
        ctqybmid: null,
        tradingAreaid: null,
      },
      pointInfo: {}, // 新增坐标信息
      codeInfo: {
        codephoto: null,
        ecode: null,
        totalprice: null,
        singleprice: null,
        codeurl: null,
      }, // 新增编码信息
      codeList: [], // 编码列表
      pointList: [], // 坐标列表
      cantab: true,
      esfid: '', // 二手房id
      imgInfo: [], // 新增相册信息
      imgList: [], // 相册列表
      total: 0, //总记录数
      list: [], // 二手房列表
      areaList: [], // 区域列表
      cityList: [], // 城市列表
      adsList: [], // 辖区列表
      businessList: [], // 商圈列表
      brokerList: [], // 经纪人列表
      deviceList: [
        {
          name: '床',
          id: '1',
        },
        {
          name: '宽带',
          id: '2',
        },
        {
          name: '电视',
          id: '3',
        },
        {
          name: '冰箱',
          id: '4',
        },
        {
          name: '洗衣机',
          id: '5',
        },
        {
          name: '空调',
          id: '6',
        },
        {
          name: '热水器',
          id: '7',
        },
        {
          name: '可做饭',
          id: '8',
        },
        {
          name: '卫生间',
          id: '9',
        },
        {
          name: '沙发',
          id: '10',
        },
        {
          name: '衣柜',
          id: '11',
        },
        {
          name: '阳台',
          id: '12',
        },
        {
          name: '电梯',
          id: '13',
        },
      ],
    }
  },
  created() {
    this.getList()
    this.SelBroker()
    this.Selarea()
  },
  methods: {
    // 负责人上传
    handleAvatarSuccess(res, file) {
      this.dialogInfo.principalphoto = file.response.filepath
    },
    // 机构上传
    handleAvatarSuccess2(res, file) {
      this.dialogInfo.institutionphoto = file.response.filepath
    },
    // 二维码上传
    handleAvatarSuccess3(res, file) {
      this.codeInfo.codephoto = file.response.filepath
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error('上传附件只能是 JPG或PNG 格式!')
      }
      if (!isLt5M) {
        this.$message.error('上传附件大小不能超过 5M!')
      }
      return isJPG && isLt5M
    },
    // 查询城市列表
    async Selarea() {
      const { data: res } = await this.$http.get('city/sel')
      this.cityList = res.data
    },
    // 根据城市查询辖区列表
    async Selcity(ctid) {
      const { data: res } = await this.$http.get('ads/sel', {
        params: { ctid: ctid },
      })
      this.adsList = res.data
    },
    // 根据辖区查询商圈列表
    async SelBusiness(ctqybm) {
      const { data: res } = await this.$http.get(
        'newhome/ctqybm?ctqybm=' + ctqybm,
        {
          params: {
            pageNum: 1,
            pageSize: 1000,
          },
        }
      )
      this.businessList = res.data.records
    },
    // 查询经纪人列表
    async SelBroker() {
      const { data: res } = await this.$http.get('borker/borkerlist', {
        params: {
          pageNum: 1, //当前页
          pageSize: 1000, //每页最大数
          brokertype: 1, // 经纪人类型（1:社区经纪人、2:别墅文旅经纪人））
        },
      })
      this.brokerList = res.data.records
    },
    //查询二手房列表
    async getList() {
      const { data: res } = await this.$http.get('/second/getesfList', {
        params: this.queryInfo,
      })
      this.list = res.data.records
      this.esfid = res.data.records[0].esfid
      this.total = res.data.total
    },
    // 新增
    addInfo() {
      this.dialogStatus = 'addEquipment'
      this.DialogVisiable = true
    },
    // 修改
    async changeInfo(info) {
      this.cantab = false
      this.esfid = info.esfid
      this.getImg()
      this.getPoint()
      this.getCode()
      this.dialogStatus = 'editEquipment'
      this.DialogVisiable = true
      const { data: res } = await this.$http.get(
        '/second/getesfinfo?esfid=' + info.esfid
      )
      let detail = res.data[0]
      if (detail.facilityId == '') {
        detail.facilityId = null
      } else if (detail.facilityId) {
        detail.facilityId = detail.facilityId.split(',')
      }
      this.dialogInfo = {
        comid: this.communityid,
        esfid: detail.esfid,
        etitle: detail.etitle,
        escore: detail.escore,
        eprice: detail.eprice,
        egeneralStr: detail.egeneralStr,
        roomtype: detail.roomtype,
        earea: detail.earea,
        decorate: detail.decorate,
        aspect: detail.aspect,
        elevel: detail.elevel,
        etime: detail.etime,
        trading: detail.trading,
        euse: detail.euse,
        ecoding: detail.ecoding,
        location: detail.location,
        housetype: detail.housetype,
        structure: detail.structure,
        dealtime: detail.dealtime,
        isonly: detail.isonly,
        seetime: detail.seetime,
        property: detail.property,
        propertycost: detail.propertycost,
        propertyright: detail.propertyright,
        pledgeinfo: detail.pledgeinfo,
        hydropower: detail.hydropower,
        towerratio: detail.towerratio,
        buy: detail.buy,
        brokerid: Number(detail.brokerid),
        principalphoto: detail.principalphoto,
        institutionphoto: detail.institutionphoto,
        ctid: Number(detail.ctid),
        ctqybmid: Number(detail.ctqybmid),
        tradingAreaid: detail.tradingAreaid,
      }
    },
    //删除
    async delInfo(id) {
      const confirmResult = await this.$confirm(
        '删除后用户信息将永久删除，是否继续？',
        '提示',
        {
          confirmButtonText: '删除',
          cancelButtonText: '放弃',
        }
      ).catch((err) => err)
      if (confirmResult != 'confirm') {
        //取消
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(
        '/second/delesf?esfid=' + id
      )
      if (res.code != 200) {
        return this.$message.error('删除失败!')
      }
      this.$message.success('删除成功!')
      this.getList()
    },
    // 确认
    async handelConfirm() {
      if (
        !this.dialogInfo.etitle ||
        !this.dialogInfo.escore ||
        !this.dialogInfo.eprice ||
        !this.dialogInfo.egeneralStr ||
        !this.dialogInfo.roomtype ||
        !this.dialogInfo.earea ||
        !this.dialogInfo.decorate ||
        !this.dialogInfo.aspect ||
        !this.dialogInfo.elevel ||
        !this.dialogInfo.etime ||
        !this.dialogInfo.trading ||
        !this.dialogInfo.euse ||
        !this.dialogInfo.ecoding ||
        !this.dialogInfo.location ||
        !this.dialogInfo.housetype ||
        !this.dialogInfo.aspect ||
        !this.dialogInfo.brokerid ||
        !this.dialogInfo.principalphoto ||
        !this.dialogInfo.institutionphoto ||
        !this.dialogInfo.ctid ||
        !this.dialogInfo.ctqybmid ||
        !this.dialogInfo.tradingAreaid
      ) {
        this.$message.error('请完整所有信息！')
        return
      }
      // 新增
      if (this.dialogStatus == 'addEquipment') {
        const { data: res } = await this.$http.post(
          'second/addesf',
          this.dialogInfo
        )
        if (res.code == '200') {
          this.$message.success('新增成功')
          this.getList()
          setTimeout(() => {
            this.getImg()
          }, 500)
          this.cantab = false
          this.activeName = 'second'
        } else {
          this.$message.error('新增失败')
        }
        // 修改
      } else if (this.dialogStatus == 'editEquipment') {
        const { data: res } = await this.$http.put(
          'second/updateesf',
          this.dialogInfo
        )
        if (res.code == '200') {
          this.$message.success('修改成功')
          this.DialogVisiable = false
          this.getList()
        } else {
          this.$message.error('修改失败')
        }
      }
    },
    // 取消
    cancel() {
      this.DialogVisiable = false
      this.cantab = true
      this.activeName = 'first'
      this.dialogInfo = {
        comid: this.communityid,
        esfid: null,
        etitle: null,
        escore: null,
        eprice: null,
        egeneralStr: null,
        roomtype: null,
        earea: null,
        decorate: null,
        aspect: null,
        elevel: null,
        etime: null,
        trading: null,
        euse: null,
        ecoding: null,
        location: null,
        housetype: null,
        structure: null,
        dealtime: null,
        isonly: null,
        seetime: null,
        property: null,
        propertycost: null,
        propertyright: null,
        pledgeinfo: null,
        hydropower: null,
        towerratio: null,
        buy: null,
        brokerid: null,
        principalphoto: null,
        institutionphoto: null,
        ctid: null,
        ctqybmid: null,
        tradingAreaid: null,
      }
    },
    // 查询相册列表
    async getImg() {
      await this.$http
        .get('second/secondphotolist?esfid=' + this.esfid, {
          params: {
            pageNum: 1,
            pageSize: 1000,
          },
        })
        .then((res) => {
          this.imgList = res.data.data.records
        })
    },
    // 点击上传
    uploadClick(val) {
      let f = {}
      f.cxfmtpurl = val
      f.esfid = this.esfid
      f.xctype = this.xctype
      this.imgInfo.push(f)
    },
    // 删除未上传图片
    delImg(val) {
      this.imgInfo = this.imgInfo.filter(
        (item) => item.cxfmtpurl != val.filepath
      )
    },
    // 新增图片
    async addHandle() {
      await this.$http
        .post('second/inssecondphoto', this.imgInfo)
        .then((res) => {
          if (res.data.code == '200') {
            this.$message.success('新增成功')
            this.$refs.uploadImg.clear()
          } else {
            this.$message.error('新增失败')
          }
        })
      this.imgInfo = []
      this.getImg()
    },
    // 删除图片
    async deletClick(info) {
      await this.$http
        .delete('second/delsecondphoto?id=' + info.id)
        .then((res) => {
          if (res.data.code == '200') {
            this.$message.success('删除成功')
            this.getImg()
          } else {
            this.$message.error('删除失败')
          }
        })
    },
    // 查坐标列表
    async getPoint() {
      const { data: res } = await this.$http.get(
        'second/getsecpointlist?esfid=' + this.esfid
      )
      this.pointList = res.data
    },
    // 新增坐标
    async addPoint() {
      this.pointInfo.esfid = this.esfid
      this.pointInfo.tc = 16
      this.pointInfo.type = 1
      const { data: res } = await this.$http.post(
        'second/inssecpoint',
        this.pointInfo
      )
      this.pointInfo.point = null
      this.getPoint()
    },
    // 删除坐标
    async delPoint(info) {
      const { data: res } = await this.$http.delete(
        'second/delsecpoint?zpid=' + info.zpid
      )
    },
    // 跳转拾取坐标
    turnOther() {
      window.open(
        'http://api.map.baidu.com/lbsapi/getpoint/index.html',
        '_blank'
      )
    },
    // 查询编码列表
    async getCode() {
      const { data: res } = await this.$http.get(
        'second/getcodelist?esfid=' + this.esfid
      )
      this.codeList = res.data
    },
    // 新增编码
    async addCode() {
      this.codeInfo.esfid = this.esfid
      const { data: res } = await this.$http.post(
        'second/inscode',
        this.codeInfo
      )
      this.codeInfo.codephoto = null
      this.codeInfo.ecode = null
      this.codeInfo.totalprice = null
      this.codeInfo.singleprice = null
      this.codeInfo.codeurl = null
      this.getCode()
    },
    // 删除编码
    async delCode(info) {
      const { data: res } = await this.$http.delete(
        'second/delcode?esfid=' + info.esfid
      )
    },
    headcell() {
      return 'text-align: center;background:#eef1f6;'
    },
    onEditorBlur() {
      // 失去焦点事件
    },
    onEditorFocus() {
      // 获得焦点事件
    },
    onEditorChange() {
      // 内容改变事件
    },
    //最大数
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getList()
    },
    //pageNum的触发
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getList()
      this.$forceUpdate()
    },
    //将表格数据行居中
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center;'
    },
    //将表格数据列居中
    rowClass({ row, rowIndex }) {
      return 'text-align:center;'
    },
  },
}
</script>

<style scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px dashed #d9d9d9 !important;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
::v-deep .quill-editor .ql-editor {
  height: 150px;
}
</style>
