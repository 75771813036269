<template>
  <el-container>
    <!--头部-->
    <el-header style="background:#fff;height:40px">
    <!-- <div class="title"> -->
    <!-- <img src="../assets/login/logo.png" height="80" width="80" /> -->
    <!-- 多点房后台管理系统 -->
    <!-- </div> -->
    <!-- <el-button
        type="info"
        @click="logout"
        style="background-color: cornflowerblue"
        >安全退出</el-button
      > -->
      <div>
      <el-popover
        placement="top-end"
        trigger="hover"
      >
      <p style="text-align:center;cursor: pointer;" @click="loginOut()">退出登录</p>
        <p class="userCon" slot="reference">
      <i class="el-icon-user-solid"></i
      ><span style="margin-left: 5px;">{{username}}</span>
    </p>
      </el-popover>
    </div>
    </el-header>
    <!--主体部分-->
    
    <el-container>
      <!--侧边栏-->
      <el-aside :width="isCollpase ? '62px' : '250px'">
        <div class="toggle-button" @click="toggleCollapase">|||</div>
        <el-menu
          :default-active="activeIndex"
          background-color="#fff"
          text-color="#333"
          active-text-color="#000"
          unique-opened
          :collapse="isCollpase"
          :collapseTransition="false"
          :router="true"
        >
          <!--一级菜单-->
          <el-submenu
            :index="item.id + ''"
            v-for="item in menuList"
            :key="item.id"
          >
            <template slot="title">
              <p style="margin-right: 140px">
                <!-- <i class="el-icon-setting"></i> -->
                <span style="color: #000;font-size: 16px">{{
                  item.title
                }}</span>
              </p>
            </template>
            <!--二级菜单-->
            <el-menu-item
              :index="it.spath"
              v-for="it in item.slist"
              :key="it.sid"
            >
              <template slot="title">
                <p style="margin-right: 100px">
                  <!-- <i class="el-icon-document"></i> -->
                  <span style="font-size: 15px">{{ it.stitle }}</span>
                </p>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <!--主体内容-->
      <el-main>
        <router-view class="view"></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
export default {
  created() {
    //查询menuList
    this.getMenuList()
  },
  data() {
    return {
      //菜单列表
      menuList: [],
      isCollpase: false, //伸缩
      activeIndex: '/bridalchambermaintenance',
      username: '',
    }
  },
  mounted() {
    this.activeIndex = window.location.pathname
    this.username = localStorage.getItem('username')
  },
  methods: {
    // 退出登录
    loginOut() {
      localStorage.clear();
      this.$router.push('/')
      // window.location.reload()
    },
    //获取导航栏
    async getMenuList() {
      const { data: res } = await this.$http.get('navig/menu')
      if (res.code != 200) return this.$message.error('获取列表失败！！！') //访问失败的错误信息
      // 隐藏菜单 || item.id === 500   || item.id === 600
      this.menuList = res.data
      // this.menuList = res.data.filter((item) => item.id === 100 || item.id === 600|| item.id === 700) // 主路由
      // this.menuList[0].slist = this.menuList[0].slist.filter(
      //   (item) =>
      //     item.sid !== 104 &&
      //     item.sid !== 106 &&
      //     item.sid !== 109 
      // )
    },
    //控制伸缩
    toggleCollapase() {
      this.isCollpase = !this.isCollpase
    },
    //安全退出
    logout() {
      window.sessionStorage.clear() //清除session
      this.$router.push('/login') //回到首页
    },
  },
}
</script>
<style lang="less" scoped>
/deep/.el-menu-item.is-active {
  font-weight: 600;
}
.userCon {
  padding: 15px 40px;
  font-size: 20px;
  text-align: right;
}
//布局器样式
.el-container {
  height: 100%;
  overflow: hidden;
}
//头部样式
.el-header {
  background-color: rgb(236, 236, 236);
  // display: flex;
  // justify-content: space-between; //左右贴边
  // padding-left: 0%; //左边距
  // align-items: center;
  color: #fff;
  font-size: 40px;
  color: #333;
  // font-size: 20px;
  text-align: center;
  // > div {
  //   display: flex;
  //   align-items: center;
  //   span {
  //     margin-left: 15px;
  //   }
  // }
  height: 54px;
  line-height: 1;
  // .title {

  // }
}
//侧边栏样式
.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  // line-height: 200px;
  overflow: auto;
  height: 100%;
  .el-menu {
    height: 95%;
    border-right: none;
  }
}
//主体样式
.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  line-height: 80px;
  // line-height: 160px; 原本160px,0304改成50px
  .view {
    height: 100%;
    /deep/ .el-card {
      height: 100%;
    }
    /deep/ .el-card__body {
      // height: 100%;
      // overflow: auto;
      .el-pagination {
        text-align: left;
        margin-top: 30px;
      }
    }
  }
}
//|||按钮样式
.toggle-button {
  background-color: #409eff;
  font-size: 10px;
  line-height: 30px;
  height: 30px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer; //显示小手
  position: relative;
  top: 0;
}
// /deep/ .el-table .cell {
//   line-height: 1;
// }
// /deep/ .el-table{
//   width: 100% !important;
//   line-height: 32px !important;
//   font-size: 18px !important
// }
</style>
