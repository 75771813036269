<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
    <div class="title-ddf">多点房后台管理系统</div>
    <router-view class="view"></router-view>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload,
    }
  },
  data() {
    return {
      isRouterAlive: true,
    }
  },
  mounted() {
    this.dic()
  },
  created() {},
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    },
    dic() {
      this.$http.get('dic/list').then((res) => {
        if (res.data.code == 200) {
          window.sessionStorage.setItem('saveDic', JSON.stringify(res.data))
        }
      })
      this.$http
        .get('/area/urban', {
          params: {
            pageNum: 1,
            pageSize: 1000,
          },
        })
        .then((res) => {
          if (res.data.code == 200) {
            window.sessionStorage.setItem(
              'cityAll',
              JSON.stringify(res.data.data)
            )
          }
        })
    },
  },
}
</script>

<style>
#app {
  height: 100%;
  overflow: hidden;
}
html,
body {
  height: 100%;
}
body .el-table th.gutter {
  display: table-cell !important;
}
.el-main .view .el-card__body {
  height: 90%;
  overflow: auto;
  /* box-sizing: border-box; */
}
.el-table {
  font-size: 21px !important;
}
#app .el-main {
  padding: 0;
  border: none;
}
#app .el-main .view .el-card {
  box-sizing: border-box;
}
.title-ddf {
  font-size: 40px;
  color: #409eff;
  text-align: center;
  background-color: #fff;
  font-weight: 600;
  padding: 10px 0;
  height: 50px;
  line-height: 50px;
}
</style>
