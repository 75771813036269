<template>
  <section>
    <el-form ref="baseform" :model="baseform" label-width="160px">
      <el-form-item label="公交线路信息">
        <el-input type="textarea" v-model="baseform.gjlx" :rows="4"></el-input>
      </el-form-item>
      <el-form-item label="地铁线路信息">
        <el-input type="textarea" v-model="baseform.dtxl" :rows="4"></el-input>
      </el-form-item>
      <el-form-item >
        
        <el-button v-if="!baseform.lpjtxxid" @click="addJt"  type="primary" style="padding-left:30px;padding-right:30px;" >
          新增
        </el-button>
        <!-- <el-button v-if="baseform.lpjtxxid" @click="editorHandle"  type="primary" style="padding-left:30px;padding-right:30px;" >
          修改
        </el-button> -->
      </el-form-item>
      
    </el-form>
    <el-table
      :data="jt"
      border
      style="width: 100%;"
      :cell-style="{ padding: '5px 0px' }"
      :header-cell-style="headcell"
    >
      <el-table-column prop="gjlx" label="已添加公交信息" align="center">
      </el-table-column>
      <el-table-column prop="dtxl" label="已添加地铁信息" align="center">
      </el-table-column>
      <el-table-column prop="esfjtxxidid" label="交通id" width="200" align="center" v-if="false">
      </el-table-column>

      <el-table-column prop="address" label="操作(无删除接口只能修改置空，无法删除)" align="center">
        <template slot-scope="scope">
          <el-button @click="delJt(scope.row)" type="text" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

  </section>
</template>
<script>
  export default {
    name: 'myWay',
    props:['formid','jt'],
    data() {
      return {
        baseform: {},
      }
    },
    methods: {
      // 添加交通信息
      async addJt(){
        if(this.baseform.length==0){
          this.$message.error("请添加信息")
        }else{
          const { data: res } = await this.$http.post('setr/ins', {
              gjlx: this.baseform.gjlx,
              dtxl: this.baseform.dtxl,
              esfid: this.formid
          })
          if(res.code == "200"){
              this.$message.success("新增成功")
              this.baseform.gjlx = null
              this.baseform.dtxl = null
              this.$emit('getjt')
          }
        }
      },
      // 删除交通(置空)
      async delJt(info){
        // const { data: res } = await this.$http.delete('setr/del?esfjtxxid=' + info.esfjtxxid)
        const { data: res } = await this.$http.put('setr/upl', {
          esfjtxxid: info.esfjtxxid,
          gjlx: null,
          dtxl:null
        })
        this.$emit('getjt')
      },
      headcell(){
        return 'text-align: center;background:#eef1f6;'
      },
    }
  }
</script>
<style scoped>
.el-form-item{
  margin-bottom: 10px;
}
</style>