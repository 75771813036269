<template>
  <div>
    <el-card>
      <!--导航栏-->
      <el-breadcrumb
        separatorClass="el-icon-arrow-right"
        style="font-size: 16px"
      >
        <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>新房管理</el-breadcrumb-item>
        <el-breadcrumb-item>新房房源录入/修改</el-breadcrumb-item>
      </el-breadcrumb>

      <el-row :span="24" class="sel" style="font-size: 14px;margin:20px 0">
        <el-row style="line-height: 60px" v-if="zhtype == 1">
          <el-col :span="8"
            >中国区域划分:<el-select
              clearable
              v-model="searchInfo.qybm"
              placeholder="请选择"
              style="margin-left: 10px;line-height: 32px;width:200px"
              @change="Selarea(searchInfo.qybm)"
            >
              <el-option
                v-for="items in areaList"
                :value="items.qybm"
                :key="items.qyname"
                :label="items.qyname"
              ></el-option
            ></el-select>
          </el-col>
          <el-col :span="8"
            >城市:<el-select
              clearable
              v-model="searchInfo.ctid"
              placeholder="请选择城市"
              style="margin-left: 10px;line-height: 20px;width:200px"
              @change="Selcity(searchInfo.ctid)"
            >
              <el-option
                v-for="items in cityList"
                :value="items.ctid"
                :key="items.ctid"
                :label="items.ctname"
              ></el-option> </el-select
          ></el-col>
          <el-col :span="8"
            >辖区:<el-select
              clearable
              v-model="searchInfo.ctqybm"
              placeholder="请选择"
              style="margin-left:10px;line-height: 32px;width:200px"
            >
              <el-option
                v-for="items in adsList"
                :value="items.ctqybm"
                :key="items.ctqybm"
                :label="items.ctqyname"
              >
              </el-option> </el-select
          ></el-col>
        </el-row>
        <el-row>
          <!-- <el-col :span="8" style="padding-left:25px"
            >物业类型:<el-select
              clearable
              v-model="searchInfo.wylxId"
              placeholder="请选择"
              style="margin-left: 10px;line-height: 20px;"
            >
              <el-option
                v-for="items in allDic.WYLX"
                :value="items.aaa101"
                :key="items.aaa101"
                :label="items.aaa103"
              ></el-option> </el-select
          ></el-col> -->
          <el-col :span="8"
            >新房分类:<el-select
              multiple
              v-model="searchInfo.newbqId"
              placeholder="请选择"
              style="margin-left: 10px;line-height: 20px;width:200px"
            >
              <el-option
                :label="item.aaa103"
                :value="item.aaa101"
                v-for="item in houseTypeList"
                :key="item.aaa101"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="8">
            楼盘名称:<el-input
              v-model="searchInfo.lpname"
              placeholder="请输入楼盘名称"
              style="margin-left: 10px;width:200px"
            ></el-input>
          </el-col>
          <el-col :span="8">
            <el-button
              type="primary"
              icon="el-icon-search"
              style="width: 100px;margin-left: 60px"
              @click="getLpInfo()"
              >搜索</el-button
            >
            <el-button
              type="primary"
              icon="el-icon-circle-plus"
              style="width:100px;margin-left: 40px"
              @click="addLpInfo"
              v-if="zhtype == 1"
              >新增</el-button
            >
          </el-col>
        </el-row>
        <el-row style="line-height: 40px">
          <!-- <el-col :span="8">
            <el-checkbox-group v-model="checkList">
              <el-checkbox label="没有户型"></el-checkbox>
              <el-checkbox label="没有相册"></el-checkbox>
              <el-checkbox label="没有电话"></el-checkbox>
            </el-checkbox-group>
          </el-col> -->
        </el-row>
      </el-row>

      <template>
        <el-table border :data="lpInfoList">
          <!--百度ID-->
          <!-- <el-table-column prop="name" label="百度id" width="120"></el-table-column> -->
          <!--城市-->
          <el-table-column
            prop="lpid"
            label="楼盘ID"
            width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="ctname"
            label="城市"
            width="150"
            align="center"
          ></el-table-column>
          <!--区域-->
          <el-table-column
            prop="ctqyname"
            label="区域"
            width="150"
            align="center"
          ></el-table-column>
          <!--商圈-->
          <!-- <el-table-column
            prop="sqname"
            label="商圈"
            width="100"
          ></el-table-column> -->
          <!--楼盘名称-->
          <el-table-column
            prop="lpname"
            label="楼盘名称"
            width="250"
            align="center"
          ></el-table-column>
          <!--楼盘别名-->
          <el-table-column
            prop="dictAlias"
            label="楼盘备案名"
            width="200"
            align="center"
          ></el-table-column>
          <!--楼盘标签-->
          <el-table-column
            prop="newbq"
            label="新房分类"
            width="250"
            align="center"
          ></el-table-column>
          <!--均价-->
          <!-- <el-table-column
            prop="junj"
            label="均价"
            width="80"
          ></el-table-column> -->
          <!--开盘时间-->
          <!-- <el-table-column
            prop="kpsj"
            label="开盘时间"
            width="100"
          ></el-table-column> -->
          <!--电话-->
          <!-- <el-table-column
            prop="zxdh"
            label="电话"
            width="150"
          ></el-table-column> -->
          <!--销售状态-->
          <!-- <el-table-column
            prop="xszt"
            label="销售状态"
            width="80"
          ></el-table-column> -->
          <!--物业类型-->
          <!-- <el-table-column
            prop="wylx"
            label="物业类型"
            width="80"
          ></el-table-column> -->
          <!--内部操作（包括删除与修改）-->
          <el-table-column
            prop="address"
            label="操作"
            width="300"
            align="center"
            v-if="zhtype == 1"
          >
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="small"
                @click="changeLpInfo(scope.row)"
                >修改</el-button
              >
              <el-button
                @click="delLpInfo(scope.row.lpid)"
                type="danger"
                size="small"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
      <div>
        <el-pagination
          @size-change="thandleSizeChange"
          @current-change="thandleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 30, 40, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
    <el-dialog
      :title="titleMap[dialogStatus]"
      :visible.sync="DialogVisible"
      :close-on-click-modal="false"
      width="90%"
      top="20px"
      :modal="true"
      :lock-scroll="true"
      class="dialogHeader"
      center
      @close="cancel"
    >
      <el-tabs type="border-card" v-model="activeName" @tab-click="handletab">
        <el-tab-pane label="楼盘基本信息" name="first">
          <my-base
            :baseform="queryInfo"
            :areaList="areaList"
            @next="nextHander"
            :isEdit="isEdit"
          ></my-base>
        </el-tab-pane>
        <el-tab-pane label="预售信息维护" :disabled="cantab" name="second">
          <befor-save
            :lpid="String(queryInfo.lpid)"
            :proSaleInfo="proSaleInfo"
            @getprosaleinfo="getProSaleInfo"
          ></befor-save>
        </el-tab-pane>
        <!-- <el-tab-pane label="配套信息维护" :disabled="cantab" name="third">
          <pro-info
            :lpid="String(queryInfo.lpid)"
            @getpt="getPtInfo"
            :ptList="ptList"
          ></pro-info>
        </el-tab-pane> -->
        <el-tab-pane label="户型信息维护" :disabled="cantab" name="four">
          <house-type
            :lpid="String(queryInfo.lpid)"
            @gethx="getHxInfo"
            :hxtList="hxtList"
          ></house-type>
        </el-tab-pane>
        <el-tab-pane label="相册视频维护" :disabled="cantab" name="five">
          <my-video
            :lpid="String(queryInfo.lpid)"
            :xc="xc"
            @getimg="getImg"
          ></my-video>
        </el-tab-pane>
        <el-tab-pane label="地图坐标维护" :disabled="cantab" name="six">
          <my-map
            :lpid="String(queryInfo.lpid)"
            :zb="zb"
            @getzb="getZb"
          ></my-map>
        </el-tab-pane>
        <el-tab-pane label="销售团队" :disabled="cantab" name="seven">
          <my-person
            :lpid="String(queryInfo.lpid)"
            @getper="getPer"
            :personinfo="personInfo"
          ></my-person>
        </el-tab-pane>
        <el-tab-pane label="媒体软文" :disabled="cantab" name="eight">
          <my-medium
            :lpid="String(queryInfo.lpid)"
            @getJournList="getJournList"
            :journList="journList"
          ></my-medium>
        </el-tab-pane>
        <el-tab-pane label="楼盘百科" :disabled="cantab" name="nine">
          <my-wiki
            :lpid="String(queryInfo.lpid)"
            :isEdit="isEdit"
            @getWiki="getWiki"
            :wikiList="wikiList"
          ></my-wiki>
        </el-tab-pane>
        <el-tab-pane label="楼盘点评" :disabled="cantab" name="ten">
          <my-comment
            :lpid="String(queryInfo.lpid)"
            :isEdit="isEdit"
            @getCommentList="getCommentList"
            :commentList="commentList"
          ></my-comment>
        </el-tab-pane>
        <el-tab-pane label="交房信息" :disabled="cantab" name="eleven">
          <my-delivery
            :lpid="String(queryInfo.lpid)"
            :isEdit="isEdit"
            @getDeliveryList="getDeliveryList"
            :deliveryList="deliveryList"
          ></my-delivery>
        </el-tab-pane>
        <el-tab-pane label="楼盘百问" :disabled="cantab" name="twelve">
          <my-problem
            :lpid="String(queryInfo.lpid)"
            :isEdit="isEdit"
            @getProblemList="getProblemList"
            :problemList="problemList"
          ></my-problem>
        </el-tab-pane>
        <el-tab-pane label="户型概况" :disabled="cantab" name="thirteen">
          <my-overview
            :lpid="String(queryInfo.lpid)"
            :isEdit="isEdit"
            @getOverviewList="getOverviewList"
            :overviewList="overviewList"
          ></my-overview>
        </el-tab-pane>
        <!-- <el-tab-pane label="交通信息" :disabled="cantab" name="eight">
          <my-way
            :lpid="String(queryInfo.lpid)"
            :jt="jt"
            @getjt="getJt"
          ></my-way>
        </el-tab-pane> -->
        <!-- 这里是我新添的动态模块 -->
        <!-- <el-tab-pane label="动态信息" :disabled="cantab" name="nine">
          <my-state
            :lpid="String(queryInfo.lpid)"
            :lpdt="lpdt"
            @getlpdt="getlpdt"
          ></my-state>
        </el-tab-pane> -->
      </el-tabs>
    </el-dialog>
  </div>
</template>
<script>
import myBase from '@/components/newhouse/mybase.vue'
import beforSave from '@/components/newhouse/beforSave.vue'
import proInfo from '@/components/newhouse/proInfo.vue'
import houseType from '@/components/newhouse/houseType.vue'
import myVideo from '@/components/newhouse/myVideo.vue'
import myMap from '@/components/newhouse/myMap.vue'
import myPerson from '@/components/newhouse/myPerson.vue'
import myWay from '@/components/newhouse/myWay.vue'
import myState from '@/components/newhouse/myState.vue'
import myLpPrice from '@/components/newhouse/myLpPrice.vue'
import myOpentime from '@/components/newhouse/myOpentime.vue'
import myWytype from '@/components/newhouse/myWytype.vue'
import myWyprice from '@/components/newhouse/myWyprice.vue'
import myMedium from '@/components/newhouse/myMedium.vue'
import myWiki from '@/components/newhouse/myWiki.vue'
import myComment from '@/components/newhouse/myComment.vue'
import myDelivery from '@/components/newhouse/myDelivery.vue'
import myProblem from '@/components/newhouse/myProblem.vue'
import myOverview from '../newhouse/myOverview.vue'
export default {
  //引用vue reload方法
  inject: ['reload'],
  components: {
    myBase,
    beforSave,
    proInfo,
    houseType,
    myVideo,
    myMap,
    myPerson,
    myWay,
    myLpPrice,
    myOpentime,
    myWytype,
    myWyprice,
    myState,
    myMedium,
    myWiki,
    myComment,
    myDelivery,
    myProblem,
    myOverview,
  },
  data() {
    return {
      zhtype: '',
      // queryInfo: {},
      dialogStatus: '',
      activeName: 'first',
      titleMap: {
        addEquipment: '新增楼盘信息',
        editEquipment: '编辑楼盘信息',
      },
      DialogVisible: false,
      cantab: true,
      searchInfo:{
        pageNum: 1, //当前页
        pageSize: 5, //每页最大数
        qybm: null,
        ctid: null,
        ctqybm: null,
        newbqId: null,
        lpname: null,
      },
      queryInfo: {
        lpid: null,
        qybm: null,
        xszt: null,
        lmqybm: null,
        tradingAreaId: null,
        ctqybm: null,
        ctid: localStorage.getItem('bdcityid') || null,
        jzmj: null,
        wylxList: [],
        wylxId: null,
        junj: null,
        lpwzms: null,
        lpname: null,
        kpsj: null,
        iskbm: null,
        zdmj: null,
        gfhs: null,
        cwl: null,
        cws: null,
        wygs: null,
        wyglf: null,
        lhv: null,
        rjl: null,
        jzlx: null,
        sldz: null,
        cqnxList: [],
        zxzk: null,
        kfsname: null,
        zxdh: null,
        lpdescribe: null,
        score: null,
        locationdepictStr: null,
        hxarearange: null,
        pricerecord: null,
        preferential: null,
        limitactivity: null,
        limitdate: null,
        updatetime: null,
        chatnumber: null,
        dfprefer: null,
        dfdate: null,
        newbqList: [],
        lplabelList: [],
        lpinfolableList: [],
        newbqId: null,
        jfsj: null,
        dictAlias: null,
        longitude: null,
        latitude: null,
        ownershipId: null,
        closeTimeStr: null,
        totalBuildNum: null,
        developersAddress: null,
        lyurl: null,
        iscooperate: '',
        sorn: null,
        iphonelyurl: null,
        pageNum: 1, //当前页
        pageSize: 5, //每页最大数
        dictFeatureIdsList: [],
        hxList: [],
        scrId: '',
        adver: null,
      },
      isChange: '',
      areaList: [],
      cityList: [],
      adsList: [],
      checkList: [],
      allDic: JSON.parse(window.sessionStorage.getItem('saveDic')).data,
      lpInfoList: [], // 楼盘信息
      total: 0,
      proSaleInfo: [], // 预售信息
      ptList: [], // 配套信息
      hxtList: [], // 户型信息
      xc: [], // 相册视频
      personInfo: [], // 经纪人信息
      jt: [], // 交通
      lpdt: [], //楼盘动态
      zb: [], // 坐标
      isEdit: false,
      houseTypeList: [], //新房分类类型列表
      journList: {}, // 媒体软文列表
      wikiList: [], // 楼盘百科列表
      commentList: {}, // 楼盘点评列表
      deliveryList: {}, // 交房信息列表
      problemList: [], // 楼盘百问列表
      overviewList: {}, // 户型概况列表
    }
  },
  created() {
    this.SelQycodeAll()
    this.getLpInfo()
    this.getHouseType()
    this.zhtype = localStorage.getItem('zhtype')
  },
  methods: {
    // 获取新房分类类型列表
    async getHouseType() {
      await this.$http.get('/dic/aaa100?aaa100=NEWBQ').then((res) => {
        this.houseTypeList = res.data.data
      })
    },
    handletab() {
    },
    refresh () {
      this.reload()
    },
    // 关闭
    cancel() {
      // this.refresh()
      this.isEdit = false
      this.queryInfo = {
        lpid: null,
        qybm: null,
        xszt: null,
        lmqybm: null,
        tradingAreaId: null,
        ctqybm: null,
        ctid: null,
        jzmj: null,
        wylxList: [],
        wylxId: null,
        junj: null,
        lpwzms: null,
        lpname: null,
        kpsj: null,
        iskbm: null,
        zdmj: null,
        gfhs: null,
        cwl: null,
        cws: null,
        wygs: null,
        wyglf: null,
        lhv: null,
        rjl: null,
        jzlx: null,
        sldz: null,
        cqnxList: [],
        zxzk: null,
        kfsname: null,
        zxdh: null,
        lpdescribe: null,
        score: null,
        locationdepictStr: null,
        hxarearange: null,
        pricerecord: null,
        preferential: null,
        limitactivity: null,
        limitdate: null,
        updatetime: null,
        chatnumber: null,
        dfprefer: null,
        dfdate: null,
        newbqList: [],
        lplabelList: [],
        lpinfolableList: [],
        newbqId: null,
        jfsj: null,
        dictAlias: null,
        longitude: null,
        latitude: null,
        ownershipId: null,
        closeTimeStr: null,
        totalBuildNum: null,
        developersAddress: null,
        lyurl: null,
        iscooperate: '',
        sorn: null,
        iphonelyurl: null,
        pageNum: 1, //当前页
        pageSize: 5, //每页最大数
        dictFeatureIdsList: [],
        hxList: [],
        scrId: '',
        adver: null,
      }
      this.getLpInfo()
    },

    //最大数
    thandleSizeChange(newSize) {
      this.searchInfo.pageSize = newSize
      this.getLpInfo()
    },
    //pageNum的触发
    thandleCurrentChange(newPage) {
      this.searchInfo.pageNum = newPage
      this.getLpInfo()
    },
    nextHander(val) {
      this.cantab = false
      // this.queryInfo.lpid = val
      this.activeName = 'second'
      this.getLpInfo()
    },
    // 查询预售信息
    async getProSaleInfo() {
      const { data: res } = await this.$http.get(
        'prosale/list?lpid=' + this.queryInfo.lpid
      )
      this.proSaleInfo = res.data
    },
    // 查询新房配套信息
    async getPtInfo() {
      const { data: res } = await this.$http.get(
        '/assort/list?lpid=' + this.queryInfo.lpid
      )
      this.ptList = res.data
    },
    // 查询新房户型信息
    async getHxInfo() {
      const { data: res } = await this.$http.get(
        '/family/list?lpid=' + this.queryInfo.lpid
      )
      this.hxtList = res.data
    },
    // 查视频相册
    async getImg() {
      await this.$http
        .get('photo/htlist?lpid=' + this.queryInfo.lpid)
        .then((res) => {
          this.xc = []
          this.xc = res.data.data
          // res.data.data.forEach((item) => this.xc.push(...item))
        })
    },
    // 查坐标
    async getZb() {
      const { data: res } = await this.$http.get(
        'point/list/lpid?lpid=' + this.queryInfo.lpid
      )
      this.zb = res.data
    },
    // 查询经纪人信息
    async getPer() {
      const { data: res } = await this.$http.get(
        'lyfigure/lyfigurelist' + '?lpid=' + this.queryInfo.lpid
      )
      this.personInfo = res.data
    },
    // 查交通
    async getJt() {
      const { data: res } = await this.$http.get('traffic/list', {
        params: {
          lpid: this.queryInfo.lpid,
        },
      })
      this.jt = res.data
    },
    // 查楼盘动态
    async getlpdt() {
      const { data: res } = await this.$http.get('/lpdynamic/getLpdynamic', {
        params: {
          lpid: this.queryInfo.lpid,
          pageNum: 1,
          pageSize: 10,
        },
      })
      this.lpdt = res.data.records
    },
    //查询媒体软文信息
    async getJournList() {
      const { data: res } = await this.$http.get('journ/ams', {
        params: {
          lpid: this.queryInfo.lpid,
          pageNum: 1,
          pageSize: 6,
          type: 5,
        },
      })
      this.journList = res.data
    },
    // 查询楼盘百科
    async getWiki() {
      const { data: res } = await this.$http.get(
        'lpcyclopedia/lpcyclopedialist?lpid=' + this.lpid
      )
      this.wikiList = res.data
    },
    //查询所有点评信息
    async getCommentList() {
      const { data: res } = await this.$http.get('comment/getComment', {
        params: {
          lpid: this.queryInfo.lpid,
          pageNum: 1,
          pageSize: 6,
        },
      })
      this.commentList = res.data
      this.$forceUpdate()
    },
    //查询所有交房信息列表信息
    async getDeliveryList() {
      const { data: res } = await this.$http.get('delivery/getDelivery', {
        params: {
          lpid: this.queryInfo.lpid,
          pageNum: 1,
          pageSize: 6,
        },
      })
      this.deliveryList = res.data
    },
    //查询所有楼盘百问信息
    async getProblemList() {
      const { data: res } = await this.$http.get('lpquestions/getLpquestions', {
        params: {
          lpid: this.queryInfo.lpid,
          pageNum: 1,
          pageSize: 6,
        },
      })
      this.problemList = res.data
    },
    //查询样板图赏析
    async getOverviewList() {
      const { data: res } = await this.$http.get('modelinfo/getmodelinfo', {
        params: {
          lpid: this.queryInfo.lpid,
          pageNum: 1,
          pageSize: 5,
          covertype: 1,
        },
      })
      this.overviewList = res.data
    },
    // 新增
    async addLpInfo() {
      this.DialogVisible = true
      this.cantab = true
      this.activeName = 'first'
      this.queryInfo = {
        lpid: null,
        qybm: null,
        xszt: null,
        lmqybm: null,
        tradingAreaId: null,
        ctqybm: null,
        ctid: null,
        jzmj: null,
        wylx: null,
        wylxId: null,
        junj: null,
        lpwzms: null,
        lpname: null,
        kpsj: null,
        iskbm: null,
        zdmj: null,
        gfhs: null,
        cwl: null,
        cws: null,
        wygs: null,
        wyglf: null,
        lhv: null,
        rjl: null,
        jzlx: null,
        sldz: null,
        cqnxId: null,
        zxzk: null,
        kfsname: null,
        zxdh: null,
        lpdescribe: null,
        score: null,
        locationdepictStr: null,
        hxarearange: null,
        pricerecord: null,
        preferential: null,
        limitactivity: null,
        limitdate: null,
        updatetime: null,
        chatnumber: null,
        dfprefer: null,
        dfdate: null,
        newbq: null,
        newbqId: null,
        lplabelId: null,
        lpinfolable: null,
        jfsj: null,
        dictAlias: null,
        longitude: null,
        latitude: null,
        ownershipId: null,
        closeTimeStr: null,
        totalBuildNum: null,
        developersAddress: null,
        lyurl: null,
        iscooperate: '',
        sorn: null,
        iphonelyurl: null,
        pageNum: 1, //当前页
        pageSize: 5, //每页最大数
        dictFeatureIdsList: [],
        hxList: [],
        scrId: '',
        adver: null,
      }
    },
    // 修改
    async changeLpInfo(info) {
      this.lpid = info.lpid
      this.activeName = 'first'
      const { data: res } = await this.$http.get(
        '/newhome/list?lpid=' + info.lpid
      )
      let quIn = res.data[0]
      if (quIn.ctqybm == null) {
        quIn.ctqybm = ''
      }
      if (quIn.lmqybm == null) {
        quIn.lmqybm = ''
      }
      if (quIn.newbqId == '') {
        quIn.newbqId = null
      } else if (quIn.newbqId) {
        quIn.newbqId = quIn.newbqId.split(',')
      }
      if (quIn.lplabelId == '') {
        quIn.lplabelId = null
      } else if (quIn.lplabelId) {
        quIn.lplabelId = quIn.lplabelId.split(',')
      }
      if (quIn.lpinfolable == '') {
        quIn.lpinfolable = null
      } else if (quIn.lpinfolable) {
        quIn.lpinfolable = quIn.lpinfolable.split(',')
      }
      if (quIn.cqnxId == '') {
        quIn.cqnxId = null
      } else if (quIn.cqnxId) {
        quIn.cqnxId = quIn.cqnxId.split(',')
      }
      if (quIn.wylxId == '') {
        quIn.wylxId = null
      } else if (quIn.wylxId) {
        quIn.wylxId = quIn.wylxId.split(',')
      }
      ;(this.queryInfo = {
        lpid: quIn.lpid,
        qybm: quIn.qybm,
        xszt: quIn.xsztId,
        lmqybm: quIn.lmqybm,
        tradingAreaId: quIn.tradingAreaId,
        ctqybm: quIn.ctqybm,
        ctid: quIn.ctid,
        jzmj: quIn.jzmj,
        wylxList: quIn.wylxId,
        junj: quIn.junj,
        lpwzms: quIn.lpwzms,
        lpname: quIn.lpname,
        kpsj: quIn.kpsj,
        iskbm: quIn.iskbm,
        zdmj: quIn.zdmj,
        gfhs: quIn.gfhs,
        cwl: quIn.cwl,
        cws: quIn.cws,
        wygs: quIn.wygs,
        wyglf: quIn.wyglf,
        lhv: quIn.lhv,
        rjl: quIn.rjl,
        jzlx: quIn.jzlx,
        sldz: quIn.sldz,
        cqnxList: quIn.cqnxId,
        zxzk: quIn.zxzk,
        kfsname: quIn.kfsname,
        zxdh: quIn.zxdh,
        lpdescribe: quIn.lpdescribe,
        score: quIn.score,
        locationdepictStr: quIn.locationdepictStr,
        hxarearange: quIn.hxarearange,
        pricerecord: quIn.pricerecord,
        preferential: quIn.preferential,
        limitactivity: quIn.limitactivity,
        limitdate: quIn.limitdate,
        updatetime: quIn.updatetime,
        chatnumber: quIn.chatnumber,
        dfprefer: quIn.dfprefer,
        dfdate: quIn.dfdate,
        newbqList: quIn.newbqId,
        lplabelList: quIn.lplabelId,
        lpinfolableList: quIn.lpinfolable,
        jfsj: quIn.jfsj,
        dictAlias: quIn.dictAlias,
        longitude: quIn.longitude,
        latitude: quIn.latitude,
        ownershipId: quIn.ownershipId,
        closeTimeStr: quIn.closeTimeStr,
        totalBuildNum: quIn.totalBuildNum,
        developersAddress: quIn.developersAddress,
        lyurl: quIn.lyurl,
        iscooperate: quIn.iscooperate == null ? '' : quIn.iscooperate,
        sorn: quIn.sorn,
        iphonelyurl: quIn.iphonelyurl,
        pageNum: 1, //当前页
        pageSize: 5, //每页最大数
        dictFeatureIdsList: [],
        hxList: [],
        scrId: null,
        adver: quIn.adver,
        lplogo: quIn.lplogo,
        chatphoto: quIn.chatphoto,
        limitphoto: quIn.limitphoto
      }),
        (this.cantab = false)
      this.isEdit = true
      this.DialogVisible = true
      this.getProSaleInfo()
      this.getPtInfo()
      this.getHxInfo()
      this.getImg()
      this.getZb()
      this.getPer()
      this.getJt()
      this.getlpdt()
      this.getJournList()
      this.getWiki()
      this.getCommentList()
      this.getDeliveryList()
      this.getProblemList()
      this.getOverviewList()
    },
    async SelQycodeAll() {
      const { data: res } = await this.$http.get('area/qycode', {})
      this.areaList = res.data
    },
    //筛选区域之后根据对应区域查询对应城市信息
    async Selarea(qybm) {
      const { data: res } = await this.$http.get('city/sel?qybm=' + qybm)
      this.cityList = res.data
    },
    async Selcity(ctid) {
      const { data: res } = await this.$http.get('ads/sel', {
        params: { ctid: ctid },
      })
      this.adsList = res.data
    },
    // 获取全部新房信息
    async getLpInfo() {
      if (this.searchInfo.newbqId && this.searchInfo.newbqId.length > 0) {
        var newbqId = this.searchInfo.newbqId.join(',')
      }
      if (this.searchInfo.ctqybm == 0) {
        this.searchInfo.ctqybm = null
      }
      if (this.searchInfo.lmqybm == 0) {
        this.searchInfo.lmqybm = null
      }
      const { data: res } = await this.$http.get('/newhome/bstag', {
        params: {
          pageNum: this.searchInfo.pageNum,
          pageSize: this.searchInfo.pageSize,
          ctid: this.searchInfo.ctid,
          ctqybm: this.searchInfo.ctqybm,
          lpname: this.searchInfo.lpname,
          newbqId: newbqId,
        },
      })
      this.lpInfoList = res.data.records
      this.queryInfo.lpid = res.data.records[0].lpid

      this.total = res.data.total
    },

    // 删除楼盘信息
    async delLpInfo(lpid) {
      var this_ = this
      this.$confirm('删除操作不可逆，确定删除?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const { data: res } = await this.$http.delete(
            '/newhome/del?lpid=' + lpid
          )
          if (res.code === '200') {
            this.$message.success('删除楼盘数据成功！')
            this.queryInfo = {
              lpid: null,
              qybm: null,
              xszt: null,
              lmqybm: null,
              tradingAreaId: null,
              ctqybm: null,
              ctid: null,
              jzmj: null,
              wylxList: [],
              wylxId: null,
              junj: null,
              lpwzms: null,
              lpname: null,
              kpsj: null,
              iskbm: null,
              zdmj: null,
              gfhs: null,
              cwl: null,
              cws: null,
              wygs: null,
              wyglf: null,
              lhv: null,
              rjl: null,
              jzlx: null,
              sldz: null,
              cqnxList: [],
              zxzk: null,
              kfsname: null,
              zxdh: null,
              lpdescribe: null,
              score: null,
              locationdepictStr: null,
              hxarearange: null,
              pricerecord: null,
              preferential: null,
              limitactivity: null,
              limitdate: null,
              updatetime: null,
              chatnumber: null,
              dfprefer: null,
              dfdate: null,
              newbqList: [],
              lplabelList: [],
              lpinfolableList: [],
              newbqId: null,
              jfsj: null,
              dictAlias: null,
              longitude: null,
              latitude: null,
              ownershipId: null,
              closeTimeStr: null,
              totalBuildNum: null,
              developersAddress: null,
              lyurl: null,
              iscooperate: null,
              sorn: null,
              iphonelyurl: null,
              pageNum: 1, //当前页
              pageSize: 5, //每页最大数
              dictFeatureIdsList: [],
              hxList: [],
              scrId: '',
            }
            this.getLpInfo()
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
  },
}
</script>
<style lang="less" scoped>
.el-card {
  // height:720px;
  height: 100%;
}
.sel tr td .el-input {
  width: 75%;
}
</style>
