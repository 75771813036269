<template>
  <div>
    <el-card>
      <!--导航栏-->
      <el-breadcrumb
        separatorClass="el-icon-arrow-right"
        style="font-size: 16px"
      >
        <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>社区房源管理</el-breadcrumb-item>
        <el-breadcrumb-item>社区房源录入/修改</el-breadcrumb-item>
      </el-breadcrumb>
      <!--搜索添加区域-->
      <table style="margin:30px 0">
        <tr style="line-height: 70px;font-size: 14px">
          <td style="display: flex;margin-right: 100px">
            <span style="width:150px">社区名称:</span
            ><el-input
              placeholder="请输入"
              v-model="queryInfo.communityname"
            ></el-input>
          </td>
          <td>
            <el-button type="primary" icon="el-icon-search" @click="getList"
              >查询</el-button
            >
          <el-button type="primary" icon="el-icon-circle-plus" @click="addInfo" v-if="zhtype == 1"
            >新增社区</el-button
          >
          </td>
        </tr>
      </table>

      <!--主体内容表单-->
      <template>
        <el-table
          :data="list"
          border
          style="width: 100%;line-height: 12px; font-size: 18px"
          :cell-style="cellStyle"
          :header-cell-style="rowClass"
        >
          <el-table-column
            prop="id"
            label="社区ID"
            width="300"
          ></el-table-column>
          <el-table-column
            prop="communityname"
            label="社区名称"
            width="300"
          ></el-table-column>
          <el-table-column label="操作" v-if="zhtype == 1">
            <template slot-scope="scope">
              <el-button
                type="success"
                icon="el-icon-view"
                @click="handelEnter(scope.row)"
                >查看房源</el-button
              >
              <el-button
                class="el-icon-edit"
                type="primary"
                @click="changeInfo(scope.row)"
                >编辑社区信息</el-button
              >
              <el-button
                class="el-icon-delete"
                type="danger"
                @click="delInfo(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
      <!-- 弹窗 -->
      <el-dialog
        width="70%"
        :title="titleMap[dialogStatus]"
        :visible.sync="DialogVisiable"
        @close="cancel"
        :before-close="handleClose"
        :close-on-click-modal="false"
        top="20px"
        :modal="true"
        :lock-scroll="true"
        class="dialogHeader"
        center
      >
        <el-tabs type="border-card" v-model="activeName">
          <el-tab-pane label="社区基本信息" name="first">
            <el-form
              :inline="true"
              :model="dialogInfo"
              ref="aaddFormRef"
              label-width="150px"
              style="padding:30px"
            >
              <el-row>
                <el-col :span="12">
                  <el-form-item label="社区名称" prop="communityname">
                    <el-input
                      v-model="dialogInfo.communityname"
                      placeholder="请输入社区名称"
                      style="width:200px"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="关联经纪人">
                    <el-select
                      clearable
                      v-model="dialogInfo.brokerList"
                      multiple
                      placeholder="请选择经纪人(多选)"
                      style="width: 200px;"
                    >
                      <el-option
                        v-for="item in saleList"
                        :label="item.brokername"
                        :value="item.brokerid"
                        :key="item.brokerid"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <template>
                  <div class="editorBox quill-wrap">
                    <span>社区简要：</span>
                    <quill-editor
                      v-model="dialogInfo.informationStr"
                      ref="myQuillEditor"
                      :options="editorOption"
                    >
                    </quill-editor>
                  </div>
                </template>
              </el-row>
              <el-form-item>
                <el-button
                  type="primary"
                  style="margin-top: 50px;"
                  @click="handelConfirm"
                  >确认</el-button
                >
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="社区相册信息" name="second" :disabled="cantab">
            <el-row>
            <el-col :span="24" style="margin-bottom: 20px;">
              <upload-img
                ref="uploadImg"
                @addvp="uploadClick"
                @delImg="delImg"
              ></upload-img>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-button @click="addHandle" type="primary" style="">
                新增
              </el-button>
            </el-col>
          </el-row>
          <el-table
            :data="imgList"
            border
            style="width: 100%;"
            :cell-style="{ padding: '5px 0px' }"
            :header-cell-style="headcell"
          >
            <el-table-column
              prop="cxfmtpurl"
              label="相册路径"
              align="center"
              width="600"
            >
            </el-table-column>
            <el-table-column prop="cxfmtpurl" label="图片展示" align="center">
              <template slot-scope="scope">
                <img
                  :src="scope.row.cxfmtpurl"
                  alt=""
                  srcset=""
                  style="width: 80px;height: 40px"
                />
              </template>
            </el-table-column>
            <el-table-column prop="address" label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  @click="deletClick(scope.row)"
                  type="text"
                  size="small"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          </el-tab-pane>
        </el-tabs>
      </el-dialog>
      <!--分页-->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
// require styles 引入样式
import { quillEditor, Quill } from 'vue-quill-editor'
import { container, ImageExtend, QuillWatch } from 'quill-image-extend-module'
Quill.register('modules/ImageExtend', ImageExtend)
import '@/assets/imagePaste'
import uploadImg from '@/components/upload/uploadImg.vue'
export default {
  components: { quillEditor, uploadImg },
  data() {
    return {
      zhtype: '',
      dialogStatus: '', //新增弹窗状态
      DialogVisiable: false,
      activeName: 'first',
      titleMap: {
        addEquipment: '新增社区信息',
        editEquipment: '修改社区信息',
      },
      //查询信息实体
      queryInfo: {
        ctid: null,
        pageNum: 1, //当前页
        pageSize: 6, //每页最大数
        brokertype: 1, // 经纪人类型（1:社区经纪人、2:别墅文旅经纪人））
        communityname: null,
      },
      dialogInfo: {
        brokerList: [],
        communityname: null,
        informationStr: null,
        id: null,
        brokertype: 1,
      },
      cantab: true,
      comid: '',
      imgInfo: [], // 新增相册信息
      imgList: [], // 相册列表
      total: 0, //总记录数
      list: [], // 社区列表
      saleList: [], // 经纪人列表
      editorOption: {
        modules: {
          // imageDrop: true,
          ImageExtend: {
            // 如果不作设置，即{}  则依然开启复制粘贴功能且以base64插入
            loading: true,
            name: 'file', // 图片参数名
            size: 3, // 可选参数 图片大小，单位为M，1M = 1024kb
            action: 'https://ddfun.baifun.cn/oss/uploadFileReturnUrl', // 服务器地址, 如果action为空，则采用base64插入图片
            // response 为一个函数用来获取服务器返回的具体图片地址
            // 例如服务器返回{code: 200; data:{ url: 'baidu.com'}}
            // 则 return res.data.url
            response: (res) => {
              return res.filepath
            },

            sizeError: () => {}, // 图片超过大小的回调
            start: () => {}, // 可选参数 自定义开始上传触发事件
            end: () => {}, // 可选参数 自定义上传结束触发的事件，无论成功或者失败
            error: () => {}, // 可选参数 上传失败触发的事件
            success: () => {}, // 可选参数  上传成功触发的事件
            change: (xhr, formData) => {}, // 可选参数 每次选择图片触发，也可用来设置头部，但比headers多了一个参数，可设置formData
          },
          toolbar: {
            // 如果不上传图片到服务器，此处不必配置
            container: container,
            handlers: {
              image: function() {
                // 劫持原来的图片点击按钮事件
                QuillWatch.emit(this.quill.id)
              },
            },
          },
          imagePaste: {
            addImageBlob: function(blob, callback) {
              var formData = new FormData()
              formData.append('file', blob)
              // your upload function, get the uploaded image url, add then
              let config = {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Accept: '*/*',
                },
              }
              self.$http
                .post('/oss/uploadFileReturnUrl', formData, config)
                .then((res) => {
                  var imageUrl = res.data.filepath
                  callback(imageUrl)
                })
            },
          },
        },
      },
    }
  },
  created() {
    this.getList()
    this.getBorkerList()
    this.zhtype = localStorage.getItem('zhtype')
  },
  methods: {
    // 录入房源
    handelEnter(info) {
      this.$router.push({
        path: '/enterHouse',
        query:{
          id: info.id
        }
      })
    },
    //查询社区列表信息
    async getList() {
      const { data: res } = await this.$http.get('/community/communitylist', {
        params: this.queryInfo,
      })
      this.list = res.data.records
      this.comid = res.data.records[0].id
      this.total = res.data.total
    },
    //查询经纪人列表信息
    async getBorkerList() {
      const { data: res } = await this.$http.get('/borker/borkerlist', {
        params: this.queryInfo,
      })
      this.saleList = res.data.records
      this.saleList.map(item => {
        item.brokerid = item.brokerid.toString()
      })
    },
    // 关闭弹窗
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => {})
    },
    // 取消
    cancel() {
      this.DialogVisiable = false
      this.cantab = true
      this.activeName = 'first'
      this.dialogInfo = {
        brokerList: [],
        communityname: null,
        informationStr: null,
        id: null,
        brokertype: 1,
      }
    },
    // 新增
    addInfo() {
      this.dialogStatus = 'addEquipment'
      this.DialogVisiable = true
    },
    // 修改
    async changeInfo(info) {
      this.cantab = false
      this.comid = info.id
      this.getImg()
      this.dialogStatus = 'editEquipment'
      this.DialogVisiable = true
      const { data: res } = await this.$http.get(
        '/community/communityinfo?id=' + info.id
      )
      let detail = res.data[0]
      this.dialogInfo.id = detail.id
      this.dialogInfo.communityname = detail.communityname
      this.dialogInfo.informationStr = detail.informationStr
      this.dialogInfo.brokerList = detail.brokerList
    },
    //删除
    async delInfo(id) {
      const confirmResult = await this.$confirm(
        '删除后用户信息将永久删除，是否继续？',
        '提示',
        {
          confirmButtonText: '删除',
          cancelButtonText: '放弃',
        }
      ).catch((err) => err)
      if (confirmResult != 'confirm') {
        //取消
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(
        'community/delcommunity?id=' + id
      )
      if (res.code != 200) {
        return this.$message.error('删除失败!')
      }
      this.$message.success('删除成功!')
      this.getList()
    },
    // 确认
    async handelConfirm() {
      if (!this.dialogInfo.communityname || !this.dialogInfo.informationStr) {
        this.$message.error('请完整所有信息！')
        return
      }
      // 新增
      if (this.dialogStatus == 'addEquipment') {
        const { data: res } = await this.$http.post(
          'community/inscommunity',
          this.dialogInfo
        )
        if (res.code == '200') {
          this.$message.success('新增成功')
          this.getList()
          setTimeout(() => {
            this.getImg()
          }, 500)
          this.cantab = false
          this.activeName = 'second'
        } else {
          this.$message.error('新增失败')
        }
        // 修改
      } else if (this.dialogStatus == 'editEquipment') {
        const { data: res } = await this.$http.put(
          'community/uplcommunity',
          this.dialogInfo
        )
        if (res.code == '200') {
          this.$message.success('修改成功')
          this.DialogVisiable = false
          this.getList()
        } else {
          this.$message.error('修改失败')
        }
      }
    },
    // 查询相册列表
    async getImg() {
      await this.$http.get('community/comphotolist?comid=' + this.comid,{
        params:{
            pageNum: 1,
            pageSize: 1000
        }
      }).then((res) => {
        this.imgList = res.data.data.records
      })
    },
    // 点击上传
    uploadClick(val) {
      let f = {}
      f.cxfmtpurl = val
      f.comid = this.comid
      this.imgInfo.push(f)
    },
    // 删除未上传图片
    delImg(val) {
      this.imgInfo = this.imgInfo.filter(
        (item) => item.cxfmtpurl != val.filepath
      )
    },
    // 新增图片
    async addHandle() {
      await this.$http.post('community/inscomphoto', this.imgInfo).then((res) => {
        if (res.data.code == '200') {
          this.$message.success('新增成功')
          this.$refs.uploadImg.clear()
        } else {
          this.$message.error('新增失败')
        }
      })
      this.imgInfo = []
      this.getImg()
    },
    // 删除图片
    async deletClick(info) {
      await this.$http.delete('community/delcomphoto?id=' + info.id).then((res) => {
        if (res.data.code == '200') {
          this.$message.success('删除成功')
          this.getImg()
        } else {
          this.$message.error('删除失败')
        }
      })
    },
    headcell() {
      return 'text-align: center;background:#eef1f6;'
    },
    onEditorBlur() {
      // 失去焦点事件
    },
    onEditorFocus() {
      // 获得焦点事件
    },
    onEditorChange() {
      // 内容改变事件
    },
    //最大数
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getList()
    },
    //pageNum的触发
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getList()
      this.$forceUpdate()
    },
    //将表格数据行居中
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center;'
    },
    //将表格数据列居中
    rowClass({ row, rowIndex }) {
      return 'text-align:center;'
    },
  },
}
</script>
<style lang="less" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px dashed #d9d9d9 !important;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-card {
  height: 720px;
}
.dialog-footer {
  padding-top: 100px;
}
::v-deep .el-form--inline .el-form-item__label {
  float: left;
}
::v-deep .quill-editor .ql-editor {
  height: 250px;
}
</style>
