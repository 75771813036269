<template>
  <section>
    <el-row>
      <el-col :span="18">
        <el-form ref="formT" :model="formT" label-width="100px" label-position="right">

          <el-row>
            <el-col :span="24">
              <!--<el-form-item >-->
                <!--<span style="padding: 0px 4px;">楼盘总价单位万元</span>-->
                <!--<el-input v-model="formT.totalPrice"  style="width: 150px;"></el-input>-->
              <!--</el-form-item>-->
              <el-form-item label="报价时间">
                <el-date-picker
                  v-model="formT.priceDateStr"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="百度采集,可空"  style="width: 150px;" >
                </el-date-picker>
              </el-form-item>
            </el-col>

          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="价最大">
                <el-input v-model="formT.referencePriceMax"  style="width: 150px;" placeholder="百度采集,填写数字"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="均价最小">

                <el-input v-model="formT.referencePriceMin"  style="width: 150px;" placeholder="百度采集,填写数字"></el-input>
              </el-form-item>
            </el-col>
          </el-row>


          <el-row>
            <el-col :span="12">
              <el-form-item  label="周边最大">
                <el-input v-model="formT.aroundPriceMax"  style="width: 150px;" placeholder="百度采集,填写数字"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="周边最小">
                <el-input v-model="formT.aroundPriceMin"  style="width: 150px;" placeholder="百度采集,填写数字"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>

            <el-col :span="12">
              <el-form-item label="总价最大">
                <el-input v-model="formT.totalPriceMax"  style="width: 150px;" placeholder="百度采集,填写数字"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="总价最小" >
                <el-input v-model="formT.totalPriceMin"  style="width: 150px;" placeholder="百度采集,填写数字"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>

            <el-col :span="24">
              <el-form-item>
                <el-button  @click="addHandle"  type="primary" style="margin-left: 240px;padding-left:30px;padding-right:30px;" >
                  新增
                </el-button>
                <!--
                <el-button v-if="formT.point" @click="addHandle"  type="primary" style="margin-left: 240px;padding-left:30px;padding-right:30px;" >
                  修改
                </el-button>-->
              </el-form-item>
            </el-col>
          </el-row>


        </el-form>
      </el-col>


      <el-col :span="24">
        <el-table
          :data="pointdataJg"
          border
          style="width: 100%;"
          :cell-style="{padding:'5px 0px'}"
          :header-cell-style="headcell"
        >
          <!--<el-table-column-->
            <!--prop="totalPrice"-->
            <!--label="楼盘总价"align="center">-->
          <!--</el-table-column>-->
          <el-table-column
            prop="referencePriceMax"
            label="楼盘均价" align="center">
          </el-table-column>

          <!--<el-table-column-->
            <!--prop="periodTime"-->
            <!--label="期数"align="center">-->
          <!--</el-table-column>-->

          <el-table-column
            prop="priceDateStr"
            label="报价时间" align="center">
          </el-table-column>

          <el-table-column
            prop="aroundPriceMax"
            label="周边最大" align="center">
          </el-table-column>

          <el-table-column
            prop="aroundPriceMin"
            label="周边最小" align="center">
          </el-table-column>

          <el-table-column
            prop="referencePriceMin"
            label="均价最小" align="center">
          </el-table-column>

          <el-table-column
            prop="totalPriceMax"
            label="总价最大" align="center">
          </el-table-column>

          <el-table-column
            prop="totalPriceMin"
            label="总价最小" align="center">
          </el-table-column>

          <el-table-column
            label="操作" align="center">
            <template slot-scope="scope">
              <el-button @click="deletClick(scope.row)" type="text" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>


  </section>
</template>
<script>
  export default {
    name: 'myLpPrice',
    props:{
      formid: {
        type: String,
        default: ''
      },
      pointdataJg:{
        type: Array,
        default: []
      }
    },
    data() {
      return {
        pointdataJg_son:this.pointdataJg,
        formT:{}
      }
    },
    mounted() {
      // this.queryLpPrice();
      this.$emit('gesearch')
    },
    methods: {
      search(){
        this.pointdataJg_son=[];
        this.$emit('gesearch')
      },
      addHandle(){
        let this_ = this
        let params = this_.$api.getParam('bd5',{"lpid":this_.formid,"referencePriceMax":this_.formT.referencePriceMax,
          "priceDateStr":this_.formT.priceDateStr,"aroundPriceMax":this_.formT.aroundPriceMax,
          "aroundPriceMin":this_.formT.aroundPriceMin,"totalPriceMax":this_.formT.totalPriceMax,"totalPriceMin":this_.formT.totalPriceMin,"referencePriceMin":this_.formT.referencePriceMin})
        this_.$api.postData(this_, params,'/openapi/service',1).then((res) => {
          if(res.code == "0"){
            this_.$message("新增成功")
            this_.search();
            //this.queryLpPrice();
          }
        })
      },
      headcell(){
        return 'text-align: center;background:#eef1f6;'
      },
      turnOther(){
        window.open('http://api.map.baidu.com/lbsapi/getpoint/index.html', '_blank')
      },
      queryLpPrice(){
        let this_ = this
        let params = this_.$api.getParam('bd9',{"lpid":this_.formid})
        this_.$api.postData(this_, params,'/openapi/service',1).then((res) => {
          if(res.code == "0"){
            this.pointdataJg_son=[];
            this.pointdataJg_son=res.data;
          }
        })
      },
      deletClick(data){
        let this_ = this
        let params = this_.$api.getParam('bd10',{"priceid":data.priceid})
        this_.$api.postData(this_, params,'/openapi/service',1).then((res) => {
          if(res.code == "0"){
            this_.search();
          }
        })
      }
    }
  }
</script>
