<template>
  <div :class="{ upload: !isvideo }">
    <el-upload
      action="https://ddfun.baifun.cn/oss/uploadFileReturnUrl"
      list-type="picture-card"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :on-success="uploadsuccess"
      :on-error="uploaderror"
      :before-upload="beforeAvatarUpload"
      ref="aupload"
      :limit="limit"
      multiple
    >
      <i class="el-icon-plus" v-if="!isvideo"></i>
      <el-button
        v-if="isvideo"
        style="margin-left: 10px; display: inline-block"
        size="small"
        type="info"
        >选择视频文件</el-button
      >
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'uploadImg',
  data() {
    return {
      imageUrl: [],
      dialogImageUrl: '',
      dialogVisible: false,
    }
  },
  props: ['isvideo', 'limit'], // limit:上传个数； multiple：是否可以多个选中
  methods: {
    // 成功后清空
    clear() {
      this.$refs.aupload.clearFiles()
    },
    //  删除图片
    handleRemove(file, fileList) {
      this.$emit('delImg', file.response)
    },
    // 预览图片
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    //上传失败
    uploaderror(res, file) {
      this.$message.error('文件上传失败！')
    },
    // 上传成功
    uploadsuccess(res, file, fileList) {
      this.$message.success('文件上传成功！')
      fileList = null
      this.imageUrl = file.response.filepath
      this.$emit('addvp', this.imageUrl)
    },
    // 上传前校验
    beforeAvatarUpload(file) {
      if (!this.isvideo) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
        const isLt5M = file.size / 1024 / 1024 < 5
        if (!isJPG) {
          this.$message.error('上传附件只能是 JPG或PNG 格式!')
        }
        if (!isLt5M) {
          this.$message.error('上传附件大小不能超过 5M!')
        }
        return isJPG && isLt5M
      } else if (this.isvideo) {
        const isMP4 =
          file.type === 'video/mp4' ||
          file.type === 'video/ogg' ||
          file.type === 'video/webm'
        if (!isMP4) {
          this.$message.error('上传附件只能是MP4或OGG或WEBM格式!')
        }
      }
    },
  },
}
</script>
<style scoped>
.el-button {
  height: 20%;
}
.avatar-uploader ::v-deep ul {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
