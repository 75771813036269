<template>
    <div>
        <el-card>
            <!--导航栏-->
            <el-breadcrumb separatorClass="el-icon-arrow-right" style="font-size: 16px">
                <el-breadcrumb-item :to="{path:'/welcome'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item >新房管理</el-breadcrumb-item>
                <el-breadcrumb-item >楼盘留言维护</el-breadcrumb-item>
            </el-breadcrumb>

            <el-row :gutter="25" style="font-size: 16px">
                <!--搜索区域-->
                <!--搜索添加-->
                <el-col :span="10">
                    问题名称：<el-input v-model="queryInfo.wtname" placeholder="请输入问题名称" style="width: 300px"></el-input>
                </el-col>
                <el-col :span="5">
                    <el-button type="primary" icon="el-icon-search" style="width: 100px;margin-left: 80px" @click="getMsgList">搜索</el-button>
                </el-col>
            </el-row>

            <!--楼盘留言维护主体内容-->
            <template>
                <el-table
                        :cell-style="Cell"
                        :header-cell-style="Cell"
                        :data="msgList"
                        border
                        style="width: 100%;line-height: 50px;">
                    <el-table-column prop="wtname" label="问题标题" width="120"></el-table-column>
                    <el-table-column prop="wttype" label="问题类型" width="120"></el-table-column>
                    <el-table-column prop="pttype" label="评论类型" width="120"></el-table-column>
                    <el-table-column prop="khname" label="客户姓名" width="120"></el-table-column>
                    <el-table-column prop="phone" label="客户手机" width="120"></el-table-column>
                    <el-table-column prop="lpname" label="新房名称" width="150"></el-table-column>
                    <el-table-column prop="address" label="二手房名" width="150" v-if="false"></el-table-column>
                    <el-table-column prop="fbtime" label="生成时间" width="300"></el-table-column>
                    <!--外部操作（包括发布各种外部链接）-->
                    <el-table-column fixed="right" label="操作" width="200">
                        <template slot-scope="scope">
                            <el-button class="el-icon-edit" @click="detailInfo(scope.row)">详情</el-button>
                            <el-button class="el-icon-share" @click="delInfo(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
            <el-dialog title="回复留言信息" width="80%" :visible.sync="Dialogvisible">
                <el-dialog
                  width="60%"
                  title="回复内容"
                  :visible.sync="lyDialogvisible"
                  append-to-body
                  center>
                  <el-row>
                    <el-input type="textarea" v-model="hfInfo.dams"></el-input>
                  </el-row>
                  <span slot="footer" class="dialog-footer">
                    <el-button  @click="cancel">取 消</el-button>
                    <el-button type="primary" @click="confirmHf">确认提交</el-button>
                  </span>
                  <!-- <el-row>
                      <el-button    type="primary"  @click="confirmHf();">
                        确认提交
                      </el-button>
                      <el-button    type="primary"  @click="cancel();">
                        取消
                      </el-button>
                  </el-row> -->
                </el-dialog>
                <el-form ref="liuyanInfo" :model="liuyanInfo" label-width="80px">
                <el-col :span="24">
                  <el-form-item label="问题标题" >
                    <el-input v-model="liuyanInfo.wtname" ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="问题类型">
                    <el-select clearable  v-model="liuyanInfo.wttype" placeholder="请类型">
                      <el-option label="买房" value="1"></el-option>
                      <el-option label="租房" value="2"></el-option>
                      <el-option label="卖房" value="3"></el-option>
                      <el-option label="贷款" value="4"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="评论类型">
                    <el-select clearable  v-model="liuyanInfo.pttype" placeholder="请类型">
                      <el-option label="留言" value="1"></el-option>
                      <el-option label="常见问题" value="2"></el-option>
                      <el-option label="精选问题" value="3"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="楼盘名称" >
                    <el-input v-model="liuyanInfo.lpname"   :disabled="true"></el-input>
                  </el-form-item>
                </el-col>
                <!-- <el-col :span="8">
                  <el-form-item label="二手房名称" >
                    <el-input v-model="liuyanInfo.xqname"   :disabled="true"></el-input>
                  </el-form-item>
                </el-col> -->
                <el-col :span="8">
                  <el-form-item label="客户姓名" >
                    <el-input v-model="liuyanInfo.khname"   :disabled="true"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="客户电话" >
                    <el-input v-model="liuyanInfo.phone"  :disabled="true" ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24" style="margin-bottom: 20px;">
                  <el-form-item>
                    <el-button    type="primary"  @click="confirmChange();">
                      确认修改
                    </el-button>
                    <el-button    type="primary"  @click="replyInfo();">
                      回复
                    </el-button>
                  </el-form-item>
                </el-col>
              </el-form>

              <el-table
                :data="replyList"
                border
                style="width: 100%;"
                :cell-style="{padding:'5px 0px'}"
                :header-cell-style="Cell"
              >
                <el-table-column
                  prop="dams"
                  label="回答描述" align="center"  type="textarea"
                  :rows="2">
                </el-table-column>

                <el-table-column
                  prop="jjrname"
                  label="回答经纪人" align="center">
                  <template slot-scope="scope">
                  {{ scope.row.jjrname || "admin"}}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="dftime"
                  label="回答时间" align="center">
                </el-table-column>
                <el-table-column
                  label="操作" align="center">
                  <template slot-scope="scope">

                    <el-button @click="deletClickHd(scope.row)" type="text" size="small">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
                    <!-- <el-form :model="pullForm" ref="pullFormRef" :rules="pullFormRules" label-width="80px">
                        <el-form-item label="楼盘名称" prop="lyname">
                            <el-input v-model="pullForm.lyname"></el-input>
                        </el-form-item>
                        <el-form-item label="联系电话" prop="phone">
                            <el-input v-model="pullForm.phone"></el-input>
                        </el-form-item>
                        <el-form-item label="显示顺序" prop="sorn">
                            <el-input v-model="pullForm.sorn"></el-input>
                        </el-form-item>
                    </el-form> -->
                    <span slot="footer" class="dialog-footer">
                        <el-button type="primary">确认</el-button>
                        <el-button @click="Dialogvisible = false">取消</el-button>
                    </span>
            </el-dialog>
            <div>
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryInfo.pageNum"
                    :page-sizes="[5, 10, 20, 30, 50]"
                    :page-size="queryInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                    >
                </el-pagination>
            </div>
        </el-card>
    </div>
</template>
<script>
    export default {
        data() {
            return {
              jjrid: null, //经纪人id
              wtid: null, // wtid
              hfInfo: {}, // 回复留言
                //查询信息实体
                queryInfo: {
                    pageNum: 1,//当前页
                    pageSize: 5,//每页最大数
                    wtname: null, // 问题名称
                    wttype: null, // 问题类型
                    pttype: null, // 评论类型
                },
                liuyanInfo: {
                    wtname: null,
                    wttype: null,
                    pttype: null,
                    lpname: null,
                    // erfname: null, // 二手房
                    khname: null,
                    phone: null
                },
                msgList: [], // 留言列表
                replyList: [], // 回答列表
                Dialogvisible: false,
                lyDialogvisible: false,
                total: 0
            }
        },
        created(){
            this.getMsgList()
        },
        methods:{
          // 确认回复留言
          confirmHf(){
              this.$http.post('message/save', {
                dams: this.hfInfo.dams,
                wtid :this.wtid,
                jjrid: this.jjrid
              })
              .then(res => {
                this.getReply()
              })
              this.lyDialogvisible = false
          },
          // 取消回复留言
          cancel(){
            this.hfInfo.dams = null
            this.lyDialogvisible = false
          },
          // 查询回复信息列表
          getReply(){
            this.$http.get('message/dlis?wtid=' + this.wtid)
            .then(res => {
              this.replyList = res.data.data
              })
          },
          // 删除留言回复内容
          async deletClickHd(info){
            await this.$http.delete('message/delete?dwid=' + info.dwid)
            .then(res => {
              if(res.data.code == "200"){
                this.$message.success("删除成功")
                this.getReply()
              }else(
                this.$message.error("删除失败")
              )
            })
          },
          //最大数
            handleSizeChange(newSize){
                this.queryInfo.pageSize = newSize;
                this.getMsgList();
            },
            //pageNum的触发
            handleCurrentChange(newPage){
                this.queryInfo.pageNum = newPage;
                this.getMsgList();
            },
            Cell(){
                return "text-align: center;"
            },
            getMsgList(){
                this.$http.get('message/list', {params: this.queryInfo})
                .then(res => {
                  this.msgList = res.data.data.records
                  this.total = res.data.data.total
                })
            },

            // 详情
            detailInfo(info){
                this.Dialogvisible = true
                this.wtid = info.wtid
                this.getReply()
                this.liuyanInfo = {
                    wtname: info.wtname,
                    wttype: info.wttype,
                    pttype: info.pttype,
                    lpname: info.lpname,
                    // erfname: null, // 二手房
                    khname: info.khname,
                    phone: info.phone
                }
            },

            // 回复
            replyInfo(){
                this.lyDialogvisible = true
            },

            // 确认修改
            confirmChange(){
              this.$http.put('message/upl', {
                pttype: this.liuyanInfo.pttype,
                wtname: this.liuyanInfo.wtname,
                wttype: this.liuyanInfo.wttype,
                khname: this.liuyanInfo.khname,
                phone: this.liuyanInfo.phone,
                wtid: this.wtid,
                lpid: this.liuyanInfo.lpid,
              })
              .then(res => {
                    if(res.data.code == "200"){
                        this.$message.success("修改成功！")
                        this.getMsgList()
                    }
                })
            },
            delInfo(info){
                this.$http.delete('/message/del' + '?wtid=' + info.wtid)
                .then(res => {
                    if(res.data.code == "200"){
                        this.$message.success("删除成功！")
                    }
                })
                this.queryInfo = {
                    pageNum: 1,//当前页
                    pageSize: 5,//每页最大数
                    wtname: null, // 问题名称
                    wttype: null, // 问题类型
                    pttype: null, // 评论类型
                },
                this.getMsgList()
            }
        },
    }
</script>
<style lang="less" scoped>
.el-card{
    height: 720px;
}
</style>