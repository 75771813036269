<template>
  <div>
    <el-card>
      <!--搜索添加区域-->
      <table>
        <tr style="line-height: 70px;font-size: 16px">
          <td>
            <el-button
              type="primary"
              icon="el-icon-circle-plus"
              style="width:100px"
              @click="addInfo"
              v-if="journList.length === 0"
              >添加</el-button
            >
          </td>
        </tr>
      </table>

      <!--主体内容表单-->
      <template>
        <el-table
          :data="problemList"
          border
          style="width: 100%;line-height: 12px; font-size: 18px"
          :cell-style="cellStyle"
          :header-cell-style="rowClass"
        >
          <el-table-column
            prop="questionstitle"
            label="标题"
            width="800"
          ></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                class="el-icon-edit"
                type="primary"
                @click="changeInfo(scope.row)"
                >修改</el-button
              >
              <el-button
                class="el-icon-delete"
                type="danger"
                @click="getDelJourn(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
      <!-- 弹窗 -->
      <el-dialog
        width="70%"
        :title="titleMap[dialogStatus]"
        :visible.sync="addDialogVisiable"
        @close="cancel"
        :before-close="handleClose"
        append-to-body
      >
        <el-form
          :inline="true"
          :model="addNewsInfo"
          ref="aaddFormRef"
          label-width="150px"
        >
          <el-row>
            <el-col :span="24">
              <el-form-item label="标题" prop="questionstitle">
                <el-input
                  v-model="addNewsInfo.questionstitle"
                  placeholder="请输入标题"
                  style="width:200px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <template>
              <div class="editorBox quill-wrap">
                <quill-editor
                  v-model="addNewsInfo.detailsStr"
                  ref="myQuillEditor"
                  :options="editorOption"
                  @change="onEditorChange($event)"
                >
                </quill-editor>
              </div>
            </template>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="confirm">确认</el-button>
        </span>
      </el-dialog>
      <!--分页-->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
// require styles 引入样式
import { quillEditor, Quill } from 'vue-quill-editor'
import { container, ImageExtend, QuillWatch } from 'quill-image-extend-module'
Quill.register('modules/ImageExtend', ImageExtend)

import '@/assets/imagePaste'

export default {
  name: 'myProblem',
  components: { quillEditor },
  created() {
    this.$emit('getProblemList')
  },
  mounted() {},
  props: ['lpid','problemList'],
  data() {
    let self = this
    return {
      editorOption: {
        modules: {
          // imageDrop: true,
          ImageExtend: {
            // 如果不作设置，即{}  则依然开启复制粘贴功能且以base64插入
            loading: true,
            name: 'file', // 图片参数名
            size: 3, // 可选参数 图片大小，单位为M，1M = 1024kb
            action: 'https://ddfun.baifun.cn/oss/uploadFileReturnUrl', // 服务器地址, 如果action为空，则采用base64插入图片
            // response 为一个函数用来获取服务器返回的具体图片地址
            // 例如服务器返回{code: 200; data:{ url: 'baidu.com'}}
            // 则 return res.data.url
            response: (res) => {
              return res.filepath
            },

            sizeError: () => {}, // 图片超过大小的回调
            start: () => {}, // 可选参数 自定义开始上传触发事件
            end: () => {}, // 可选参数 自定义上传结束触发的事件，无论成功或者失败
            error: () => {}, // 可选参数 上传失败触发的事件
            success: () => {}, // 可选参数  上传成功触发的事件
            change: (xhr, formData) => {}, // 可选参数 每次选择图片触发，也可用来设置头部，但比headers多了一个参数，可设置formData
          },
          toolbar: {
            // 如果不上传图片到服务器，此处不必配置
            container: container,
            handlers: {
              image: function() {
                // 劫持原来的图片点击按钮事件
                QuillWatch.emit(this.quill.id)
              },
            },
          },
          imagePaste: {
            addImageBlob: function(blob, callback) {
              var formData = new FormData()
              formData.append('file', blob)
              // your upload function, get the uploaded image url, add then
              let config = {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Accept: '*/*',
                },
              }
              self.$http
                .post('/oss/uploadFileReturnUrl', formData, config)
                .then((res) => {
                  var imageUrl = res.data.filepath
                  callback(imageUrl)
                })
            },
          },
        },
      },
      dialogStatus: '', //新增弹窗状态
      addDialogVisiable: false,
      titleMap: {
        addEquipment: '新增楼盘百问',
        editEquipment: '修改楼盘百问',
      },
      //查询信息实体
      queryInfo: {
        lpid: this.lpid,
        pageNum: 1, //当前页
        pageSize: 6, //每页最大数
      },
      addNewsInfo: {
        lpid: this.lpid,
        id: null,
        questionstitle: null,
        detailsStr: null,
      },
      journList: [], //楼盘新闻数据集合
      total: 1, //楼盘新闻总记录数
    }
  },

  methods: {
    handleCustomMatcher(node, Delta) {
      let ops = []
      Delta.ops.forEach((op) => {
        if (op.insert && typeof op.insert === 'string') {
          // 如果粘贴了图片，这里会是一个对象，所以可以这样处理
          ops.push({
            insert: op.insert,
          })
        }
      })
      Delta.ops = ops
      return Delta
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => {})
    },
    // 取消
    cancel() {
      this.addDialogVisiable = false
      this.addNewsInfo = {
        id: null,
        questionstitle: null,
        detailsStr: null,
      }
    },
    async confirm() {
      this.addDialogVisiable = false
      if (this.dialogStatus == 'addEquipment') {
        const { data: res } = await this.$http.post('/lpquestions/insLpquestions', {
          questionstitle: this.addNewsInfo.questionstitle,
          detailsStr: this.addNewsInfo.detailsStr,
          lpid: this.lpid,
        })
      } else if (this.dialogStatus == 'editEquipment') {
        const { data: res } = await this.$http.put('/lpquestions/uplLpquestions', {
          id: this.addNewsInfo.id,
          questionstitle: this.addNewsInfo.questionstitle,
          detailsStr: this.addNewsInfo.detailsStr,
          lpid: this.lpid,
        })
      }
      this.queryInfo.pageNum = 1
      this.$emit('getProblemList')
      this.addNewsInfo = {
        lpid: this.lpid,
        id: null,
        questionstitle: null,
        detailsStr: null,
      }
    },
    addInfo() {
      this.dialogStatus = 'addEquipment'
      this.addDialogVisiable = true
    },
    async changeInfo(info) {
      this.dialogStatus = 'editEquipment'
      this.addDialogVisiable = true
      this.addNewsInfo = {
        id: info.id,
        questionstitle: info.questionstitle,
        detailsStr: info.detailsStr,
      }
      this.$forceUpdate()
    },
    onEditorBlur() {
      // 失去焦点事件
    },
    onEditorFocus() {
      // 获得焦点事件
    },
    onEditorChange() {
      // 内容改变事件
    },
    //最大数
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.$emit('getProblemList')
    },
    //pageNum的触发
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.$emit('getProblemList')
    },

    //删除信息
    async getDelJourn(id) {
      const confirmResult = await this.$confirm(
        '删除后用户信息将永久删除，是否继续？',
        '提示',
        {
          confirmButtonText: '删除',
          cancelButtonText: '放弃',
        }
      ).catch((err) => err)
      if (confirmResult != 'confirm') {
        //取消
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(
        '/lpquestions/delDelivery?id=' + id
      )
      if (res.code != 200) {
        return this.$message.error('删除失败!')
      }
      this.$message.success('删除成功!')
      this.$emit('getProblemList')
    },
    //将表格数据行居中
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center;'
    },
    //将表格数据列居中
    rowClass({ row, rowIndex }) {
      return 'text-align:center;'
    },
  },
}
</script>
<style lang="less" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px dashed #d9d9d9 !important;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-card {
  height: 720px;
}
.dialog-footer {
  padding-top: 100px;
}
::v-deep .el-form--inline .el-form-item__label {
  float: left;
}
::v-deep .quill-editor .ql-editor {
  height: 500px;
}
</style>
