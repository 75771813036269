<template>
  <section>
    <el-row>
      <el-col :span="18">
        <el-form ref="baseform" :model="baseform" label-width="100px" label-position="right">
          
          <el-row>
            
            <el-col :span="12">
              <el-form-item >
                <span style="padding: 0px 4px;">经纬度</span>
                <el-input v-model="baseform.point"  style="width: 188px;"></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
              <el-form-item label="坐标层级">
                <el-input v-model="baseform.tc"  style="width: 88px;"></el-input>
              </el-form-item>
            </el-col> -->
          </el-row>
          
          <el-form-item>
            <el-button @click="addHandle"  type="primary" style="margin-left: 240px;padding-left:30px;padding-right:30px;" >
              新增
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="6" style="margin: 8px 0px;">
        <el-button  type="primary" style="padding-left:30px;padding-right:30px;" @click="turnOther">
          跳转拾取坐标系统
        </el-button>
        
      </el-col>
    </el-row>
    <el-table
      :data="zb"
      border
      style="width: 100%;"
      :cell-style="{ padding: '5px 0px' }"
      :header-cell-style="headcell"
    >
      <el-table-column prop="point" label="已添加坐标经纬度" align="center">
      </el-table-column>
      <el-table-column prop="tc" label="已添加坐标层级" align="center">
      </el-table-column>
      <el-table-column prop="zpid" label="zpid" width="200" align="center" v-if="false">
      </el-table-column>
      <el-table-column prop="type" label="type" width="200" align="center" v-if="false">
      </el-table-column>

      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button @click="delZb(scope.row)" type="text" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

  </section>
</template>
<script>
  export default {
    name: 'myMap',
    props:["lpid", 'zb'],
    data() {
      return {
        baseform: {},
      }
    },
    methods: {
      async addHandle(){
        this.baseform.lpid = this.lpid
        this.baseform.tc = 16
        this.baseform.type = 1
        const { data: res } = await this.$http.post('point/ins', this.baseform)
        this.baseform.point = null
        this.$emit("getzb")
      },
      async delZb(info){
        const { data: res } = await this.$http.delete('point/del?zpid=' + info.zpid)
        this.$emit("getzb")
      },
      headcell(){
        return 'text-align: center;background:#eef1f6;'
      },
      turnOther(){ 
        window.open('http://api.map.baidu.com/lbsapi/getpoint/index.html', '_blank')
      }
    }
  }
</script>