<template>
  <div>
    <el-card>
      <!--导航栏-->
      <el-breadcrumb
        separatorClass="el-icon-arrow-right"
        style="font-size: 16px"
      >
        <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>新房管理</el-breadcrumb-item>
        <el-breadcrumb-item>新闻轮播图维护</el-breadcrumb-item>
      </el-breadcrumb>

      <!--搜索添加区域-->
      <el-row :gutter="25">
        <!--搜索区域-->
        <!--搜索添加-->
        <el-col :span="25" style="font-size:16px">
          中国区域划分:<el-select clearable 
            v-model="queryInfo.qybm"
            placeholder="请选择"
            style="margin-right: 20px;line-height: 32px"
            @change="Selarea(queryInfo.qybm)"
          >
            <el-option
              v-for="items in areaList"
              :value="items.qybm"
              :key="items.qybm"
              :label="items.qyname"
            ></el-option>
          </el-select>
          城市:<el-select clearable 
            v-model="queryInfo.ctid"
            placeholder="请选择"
            style="line-height: 32px"
            @change="queryInfo.pageNum = 1"
          >
            <el-option
              v-for="items in cityList"
              :value="items.ctid"
              :key="items.ctid"
              :label="items.ctname"
            ></el-option>
          </el-select>
          <el-button
            type="primary"
            icon="el-icon-search"
            style="width: 100px;margin-left: 80px"
            @click="getSildeList"
            >搜索</el-button
          >
        </el-col>
        <!--添加按钮-->
        <el-col :span="2">
          <el-button
            type="primary"
            icon="el-icon-circle-plus"
            style="width:100px;margin-top:10px"
            @click="addInfo"
            >添加</el-button
          >
        </el-col>
      </el-row>

      <!--主体内容区域-->
      <template>
        <el-table
          :data="sildList"
          border
          style="width: 100%;line-height:32px;font-size:18px"
          :cell-style="cellStyle"
          :header-cell-style="rowClass"
        >
          <el-table-column
            prop="lbtid"
            label="编号"
            width="100"
            v-if="false"
          ></el-table-column>
          <el-table-column
            prop="ctname"
            label="城市"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="sorn"
            label="顺序"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="isshow"
            label="是否显示"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="imageurl"
            label="图片路径"
            width="300"
          ></el-table-column>
          <el-table-column prop="ms" label="描述" width="210"></el-table-column>
          <el-table-column label="操作" width="500">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-zoom-in"
                @click="getPreview(scope.row.imageurl)"
                >预览图</el-button
              >
              <el-button type="primary" class="el-icon-edit" @click="alter(scope.row)">修改</el-button>
              <el-button
                type="danger"
                class="el-icon-delete"
                @click="delSilde(scope.row.lbtid)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>

      <!--预览图-->
      <template>
        <el-image-viewer
          v-if="showViewer"
          :on-close="() => {showViewer = false}"
          :url-list="[src]"
        ></el-image-viewer>
      </template>

      <!--分页显示-->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          
        >
        </el-pagination>
      </div>

      <!--新增轮播图信息-->
      <el-dialog
        :title="titleMap[dialogStatus]"
        width="70%"
        :visible.sync="addDialogVisiable"
        :before-close="handleClose"
      >
        <el-form
          inline
          :model="addNewsInfo"
          ref="addFormRef"
          :rules="addFormRules"
          class="el-form-item__content"
        >
        <el-col :span="16">
            <el-row>
                <el-col :span="12">
                    <!-- 中国区域划分 -->
                    <el-form-item label="中国区域划分:">
                    <el-select clearable  placeholder="请选择活动区域" v-model="addForm.qybm" @change="Selarea(addForm.qybm)">
                        <el-option 
                        v-for="items in areaList"
                        :value="items.qybm"
                        :key="items.qybm"
                        :label="items.qyname">
                        </el-option>
                    </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <!-- 城市 -->
                    <el-form-item label="城市:">
                    <el-select clearable  placeholder="请选择城市" v-model="addForm.ctname">
                        <el-option
                        v-for="items in cityList"
                        :value="items.ctname"
                        :key="items.ctid"
                        :label="items.ctname"
                        ></el-option>
                    </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <!-- 顺序 -->
                    <el-form-item label="顺序:">
                    <el-input v-model="addForm.sorn" placeholder="请输入显示顺序"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <!-- 是否显示 -->
                    <el-form-item label="是否显示:">
                    <el-select clearable  placeholder="请选择是否显示" v-model="addForm.isshow">
                        <el-option label="是" value="1"></el-option>
                        <el-option label="否" value="0"></el-option>
                    </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <!-- 描述 -->
                    <el-form-item label="描述" style="width: 100%;">
                    <el-input type="textarea" v-model="addForm.ms" maxlength="100" show-word-limit  style="width: 300%;"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
          </el-col>
          <el-col :span="8">
          <!-- 图片上传 -->
          <el-form-item style="padding-right:210px">
            <upload-img :limit="1" :image-url="addForm.imageurl" @addvp="uploadClick"></upload-img>
          </el-form-item>
          </el-col>
          <!-- 富文本编辑器 -->
          <el-form-item>
            <template>
              <div>
                <quill-editor
                  v-model="addForm.cotentStr"
                  ref="myQuillEditor"
                  class="editor"
                  :options="editorOption"
                  @blur="onEditorBlur($event)"
                  @focus="onEditorFocus($event)"
                  @change="onEditorChange($event)"
                >
                </quill-editor>
              </div>
            </template>
          </el-form-item>
        </el-form>
        <!--内容底部区域-->
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisiable = false">取消</el-button>
          <el-button type="primary" @click="confirm">确认</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>
<script>
  import UploadImg from '@/components/upload/uploadImg.vue'
//在对应文件中局部引入 :
import { quillEditor } from 'vue-quill-editor' //调用编辑器
// require styles 引入样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
// 工具栏配置
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
  ['blockquote', 'code-block'], // 引用  代码块-----['blockquote', 'code-block']
  [{ header: 1 }, { header: 2 }], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
  [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
  [{ script: 'sub' }, { script: 'super' }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
  [{ indent: '-1' }, { indent: '+1' }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
  [{ direction: 'rtl' }], // 文本方向-----[{'direction': 'rtl'}]
  [{ size: ['small', false, 'large', 'huge'] }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
  [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
  [{ font: [] }], // 字体种类-----[{ font: [] }]
  [{ align: [] }], // 对齐方式-----[{ align: [] }]
  ['clean'], // 清除文本格式-----['clean']
  ['image'], // 链接、图片、视频-----['link', 'image', 'video']
]
export default {
  created() {
    this.getSildeList()
    this.SelQycodeAll()
  },
  components: {
    'el-image-viewer': () =>
      import('element-ui/packages/image/src/image-viewer'),
    quillEditor,
    UploadImg,
  },
  data() {
    return {
      cityall: JSON.parse(window.sessionStorage.getItem('cityAll')).records,
      dialogStatus: "", //新增和编辑城市信息弹框标题
      titleMap:{
        addEquipment:'新增轮播图信息',
        editEquipment: "修改轮播图信息"
                },
      content: [],
      //查询信息实体
      queryInfo: {
        qybm: null, //区域
        ctid: null, //城市
        address: null, //辖区
        pageNum: 1, //当前页
        pageSize: 5, //每页最大数
      },
      areaList: [], //区域所有信息
      cityList: [], //城市所有信息
      sildList: [], //轮播图集合
      total: 0, //分页总记录数
      addDialogVisiable: false, //增加轮播图弹窗显示状态初始值
      // 新增轮播图信息的表单内容
      addForm: {
        qybm: null,
        isshow: null,
        sorn: null,
        ms: null,
        imageUrl: null,
        cotentStr: null,
        ctid: null,
        ctname: null,
        lbtid: null
      },
      addFormRules: {},
      showViewer: false,
      ms: '',
      src: '',
      editorOption: {
        placeholder: '请输入文本...',
        theme: 'snow',
        modules: {
          toolbar: {
            container: toolbarOptions,
          },
        },
      },
      //图片样式
      imageUrl: '',
      addNewsInfo: {}
    }
  },
  methods: {
    handleClose(done){
      this.$confirm('确认关闭？')
      .then(_ => {
          done();
      })
      .catch(_ => {});
    },
    // 确认按钮
    async confirm(){
      if(this.dialogStatus == "addEquipment"){
        let ctinfo = await this.cityList.filter(c => c.ctname === this.addForm.ctname)
        this.addForm.ctid = ctinfo[0].ctid
        this.$http.post('/siles/ins', {
          isshow: this.addForm.isshow,
          sorn:  parseInt(this.addForm.sorn),
          ms:  this.addForm.ms,
          imageurl:  this.addForm.imageurl,
          cotentStr:  this.addForm.cotentStr,
          ctid: this.addForm.ctid
        })
        .then(res => {
          if(res.data.code == "200"){
            this.$message.success("添加成功")
            this.addForm = {
            qybm: null,
            isshow: null,
            sorn: null,
            ms: null,
            imageUrl: null,
            cotentStr: null,
            ctid: null,
            ctname: null,
            lbtid: null
          }
          this.getSildeList()
          }else{
            this.$message.error("添加失败")
          }
        })  
      }
      if(this.dialogStatus == "editEquipment"){
        await this.$http.put('/siles/upl', {
          isshow: this.addForm.isshow,
          sorn:  parseInt(this.addForm.sorn),
          ms:  this.addForm.ms,
          imageurl:  this.addForm.imageurl,
          cotentStr:  this.addForm.cotentStr,
          ctid: this.addForm.ctid,
          lbtid: this.addForm.lbtid
        })
        this.addForm = {
            qybm: null,
            isshow: null,
            sorn: null,
            ms: null,
            imageUrl: null,
            cotentStr: null,
            ctid: null,
            ctname: null,
            lbtid: null
          }
          this.getSildeList()
      }
      this.addDialogVisiable = false
    },
    // uploadimg组件传过来的事件
    uploadClick(val){
      this.addForm.imageurl = val
      },
      // 修改按钮
      alter(info){
        this.addForm.qybm = this.cityall.filter(c => c.ctid==info.ctid)[0].qybm
        this.addDialogVisiable = true
        this.dialogStatus = "editEquipment"
        this.addForm.cotentStr = info.cotentStr
        this.addForm.ctname = info.ctname
        this.addForm.ctid = info.ctid
        this.addForm.imageurl = info.imageurl
        this.addForm.isshow = info.isshow
        this.addForm.ms = info.ms
        this.addForm.sorn = info.sorn
        this.addForm.lbtid = info.lbtid
        this.$http.get('/siles/cotentStr?lbtid=' + info.lbtid)
        .then(res => {
          if(res.data.code == 200){
            this.addForm.cotentStr = res.data.data[0].cotentStr
          }
        })
      },
      // 添加按钮
      addInfo(){
        this.queryInfo.qybm = null
        this.queryInfo.ctid = null
        this.cityList = []
        this.addDialogVisiable = true
        this.dialogStatus = "addEquipment"
        this.addForm.qybm = null
        this.addForm.ctid = null
        this.addForm.sorn = null
        this.addForm.isshow = null
        this.addForm.ms = null
        this.addForm.cotentStr = null
      },
    //查询所有区域信息
    async SelQycodeAll() {
      const { data: res } = await this.$http.get(
        'area/qycode' + '?query=' + this.queryInfo.query
      )
      this.areaList = res.data
    },
    //查询城市信息
    async Selarea(qybm) {
      const { data: res } = await this.$http.get('city/sel', {
        params: { qybm: qybm },
      })
      this.cityList = res.data
    },
    //查询所有轮播图数据
    async getSildeList() {
      const { data: res } = await this.$http.get('/siles/list', {
        params: {
          ctid: this.queryInfo.ctid,
          pageSize: this.queryInfo.pageSize,
          pageNum: this.queryInfo.pageNum,
        },
      })
      this.sildList = res.data.records
      this.total = res.data.total
    },
    //最大数
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getSildeList()
    },
    //pageNum的触发
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getSildeList()
    },
    //根据Id删除辖区信息
    async delSilde(lbtid) {
      const confirmResult = await this.$confirm(
        '删除后用户信息将永久删除，是否继续？',
        '提示',
        {
          confirmButtonText: '删除',
          cancelButtonText: '放弃',
        }
      ).catch((err) => err)
      if (confirmResult != 'confirm') {
        //取消
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete('/siles/del?lbtid=' + lbtid)
      if (res.code != 200) {
        return this.$message.error('删除失败!')
      }
      this.$message.success('删除成功!')
      this.queryInfo.pageNum = 1
      this.getSildeList()
    },
    //轮播图添加监听添加用户
    addDialogClosed() {
      this.$refs.addFormRef.resetFields()
    },
    //将表格数据行居中
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center;'
      
    },
    //将表格数据列居中
    rowClass({ row, rowIndex }) {
      return 'text-align:center;'
    },
    //根据点击显示相应的图片
    getPreview(imageurl) {
      this.src = imageurl
      this.showViewer = true
      //cthis.imgList ? this.showViewer = true : this.$message.info('当前没有可预览的图片')
    },
    onEditorBlur() {
      // 失去焦点事件
    },
    onEditorFocus() {
      // 获得焦点事件
    },
    onEditorChange() {
      // 内容改变事件
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
  },
}
</script>
<style lang="less" scoped>
.el-card {
  // height: 720px;
  height: auto;
}
.el-form-item__content {
  width: 100%;
  margin-top: -50px;
}
.dialog-footer {
  padding-top: 100px;
}
//图片格式
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

//富文本汉化
.editor {
  line-height: normal !important;
  height: 500px;
}
.ql-snow .ql-tooltip[data-mode='link']::before {
  content: '请输入链接地址:';
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: '保存';
  padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode='video']::before {
  content: '请输入视频地址:';
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: '14px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='small']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='small']::before {
  content: '10px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='large']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='large']::before {
  content: '18px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='huge']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='huge']::before {
  content: '32px';
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: '文本';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='1']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='1']::before {
  content: '标题1';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='2']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='2']::before {
  content: '标题2';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='3']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='3']::before {
  content: '标题3';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='4']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='4']::before {
  content: '标题4';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='5']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='5']::before {
  content: '标题5';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='6']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='6']::before {
  content: '标题6';
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: '标准字体';
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='serif']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='serif']::before {
  content: '衬线字体';
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='monospace']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='monospace']::before {
  content: '等宽字体';
}

.avatar-uploader{
    border: 1px dashed rgb(221, 221, 221);
}
/deep/ .el-table .cell {
  line-height: normal !important;
}
</style>
