<template>
  <div>
    <el-card>
      <!--导航栏-->
      <el-breadcrumb
        separatorClass="el-icon-arrow-right"
        style="font-size: 16px"
      >
        <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>新房管理</el-breadcrumb-item>
        <el-breadcrumb-item>房产视频维护</el-breadcrumb-item>
      </el-breadcrumb>
      <!--搜索添加区域-->
      <table style="margin:30px 0">
        <tr style="line-height: 70px;font-size: 14px">
          <td style="display: flex;margin-right: 100px">
            <span style="width:100px">发布者名称:</span>
            <el-input
              v-model="queryInfo.issuename"
              placeholder="请输入关键字"
              style="width:200px"
            ></el-input>
            <span style="width:100px">文案描述:</span>
            <el-input
              v-model="queryInfo.vdescribe"
              placeholder="请输入关键字"
              style="width:200px"
            ></el-input>
          </td>
          <td>
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="getvideoList"
              >查询</el-button
            >
            <el-button
              type="primary"
              icon="el-icon-circle-plus"
              @click="addInfo"
              v-if="zhtype == 1"
              >新增</el-button
            >
          </td>
        </tr>
      </table>

      <!--主体内容表单-->
      <template>
        <el-table
          :data="videoList"
          border
          style="width: 100%;line-height: 12px; font-size: 18px"
          :cell-style="cellStyle"
          :header-cell-style="rowClass"
        >
          <el-table-column prop="id" label="id" width="80"></el-table-column>
          <el-table-column
            prop="issuename"
            label="发布者"
            width="160"
          ></el-table-column>
          <el-table-column
            prop="issuedate"
            label="发布日期"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="videourl"
            label="视频路径"
            width="400"
          ></el-table-column>
          <el-table-column
            prop="vdescribe"
            label="视频描述"
            width="300"
          ></el-table-column>
          <el-table-column label="操作" v-if="zhtype == 1">
            <template slot-scope="scope">
              <el-button
                class="el-icon-edit"
                type="primary"
                @click="changeInfo(scope.row)"
                >修改</el-button
              >
              <el-button
                class="el-icon-delete"
                type="danger"
                @click="getDelJourn(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
      <!-- 弹窗 -->
      <el-dialog
        width="60%"
        :title="titleMap[dialogStatus]"
        :visible.sync="addDialogVisiable"
        @close="cancel"
        :before-close="handleClose"
      >
        <el-form :inline="true" :model="addNewsInfo" ref="aaddFormRef">
          <el-row>
            <el-col :span="8">
              <el-form-item label="发布者" prop="issuename">
                <el-input
                  v-model="addNewsInfo.issuename"
                  placeholder="请输入"
                  style="width:200px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="发布日期" prop="issuedate">
                <el-date-picker
                  style="width: 200px"
                  size="small"
                  v-model="addNewsInfo.issuedate"
                  align="right"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="视频描述" prop="vdescribe">
                <el-input
                  v-model="addNewsInfo.vdescribe"
                  placeholder="请输入"
                  style="width:200px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="发布者头像">
                <el-upload
                  class="avatar-uploader"
                  action="https://ddfun.baifun.cn/oss/uploadFileReturnUrl"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img
                    v-if="addNewsInfo.issuephoto"
                    :src="addNewsInfo.issuephoto"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="视频封面">
                <el-upload
                  class="avatar-uploader"
                  action="https://ddfun.baifun.cn/oss/uploadFileReturnUrl"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess2"
                  :before-upload="beforeAvatarUpload"
                >
                  <img
                    v-if="addNewsInfo.videocover"
                    :src="addNewsInfo.videocover"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="视频">
                <upload-img
                  ref="uploadImg"
                  @addvp="uploadClick"
                  @delImg="delImg"
                  :isvideo="true"
                ></upload-img>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="confirm">确认</el-button>
        </span>
      </el-dialog>
      <!--分页-->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import uploadImg from '@/components/upload/uploadImg.vue'
export default {
  components: {
    uploadImg,
  },
  data() {
    let self = this
    return {
      zhtype: '',
      dialogStatus: '', //新增弹窗状态
      addDialogVisiable: false,
      titleMap: {
        addEquipment: '新增视频信息',
        editEquipment: '修改视频信息',
      },
      //查询信息实体
      queryInfo: {
        issuename: null,
        vdescribe: null,
        pageNum: 1, //当前页
        pageSize: 6, //每页最大数
      },
      addNewsInfo: {
        id: null,
        videocover: null,
        videourl: null,
        vdescribe: null,
        issuename: null,
        issuephoto: null,
        issuedate: null,
      },
      cityInfo: {
        pageNum: 1,
        pageSize: 20,
      },
      total: 0, //总记录数
      videoList: [], // 列表
    }
  },
  created() {
    this.getvideoList()
    this.zhtype = localStorage.getItem('zhtype')
  },
  methods: {
    // 图片上传
    handleAvatarSuccess(res, file) {
      this.addNewsInfo.issuephoto = file.response.filepath
    },
    handleAvatarSuccess2(res, file) {
      this.addNewsInfo.videocover = file.response.filepath
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error('上传附件只能是 JPG或PNG 格式!')
      }
      if (!isLt5M) {
        this.$message.error('上传附件大小不能超过 5M!')
      }
      return isJPG && isLt5M
    },
    uploadClick(val) {
      console.log(val)
      this.addNewsInfo.videourl = val
      //   let f = {}
      //   f.cxfmtpurl = val
      //   this.inputFrom.push(f)
    },

    delImg(val) {
      this.addNewsInfo.videourl = ''
    },
    //查询所有信息
    async getvideoList() {
      const { data: res } = await this.$http.get('/lpvideo/lpvideolist', {
        params: this.queryInfo,
      })
      this.videoList = res.data.records
      this.total = res.data.total
    },
    // 关闭弹窗
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => {})
    },
    // 取消
    cancel() {
      this.addDialogVisiable = false
      this.addNewsInfo = {
        id: null,
        videocover: null,
        videourl: null,
        vdescribe: null,
        issuename: null,
        issuephoto: null,
        issuedate: null,
      }
    },
    // 确认
    async confirm() {
      if (
        !this.addNewsInfo.videocover ||
        !this.addNewsInfo.videourl ||
        !this.addNewsInfo.vdescribe ||
        !this.addNewsInfo.issuename ||
        !this.addNewsInfo.issuephoto ||
        !this.addNewsInfo.issuedate
      ) {
        this.$message.error('请完整所有信息！')
        return
      }
      // 新增
      if (this.dialogStatus == 'addEquipment') {
        const { data: res } = await this.$http.post('/lpvideo/inslpvideo', {
          id: this.addNewsInfo.id,
          videocover: this.addNewsInfo.videocover,
          videourl: this.addNewsInfo.videourl,
          vdescribe: this.addNewsInfo.vdescribe,
          issuename: this.addNewsInfo.issuename,
          issuephoto: this.addNewsInfo.issuephoto,
          issuedate: this.addNewsInfo.issuedate,
        })
        this.$message.success(res.msg)
        // 修改
      } else if (this.dialogStatus == 'editEquipment') {
        const { data: res } = await this.$http.put('/lpvideo/uplborker', {
          id: this.addNewsInfo.id,
          videocover: this.addNewsInfo.videocover,
          videourl: this.addNewsInfo.videourl,
          vdescribe: this.addNewsInfo.vdescribe,
          issuename: this.addNewsInfo.issuename,
          issuephoto: this.addNewsInfo.issuephoto,
          issuedate: this.addNewsInfo.issuedate,
        })
        this.$message.success(res.msg)
      }
      this.addDialogVisiable = false
      this.queryInfo.pageNum = 1
      this.getvideoList()
      this.addNewsInfo = {
        id: null,
        videocover: null,
        videourl: null,
        vdescribe: null,
        issuename: null,
        issuephoto: null,
        issuedate: null,
      }
    },
    // 新增
    addInfo() {
      this.dialogStatus = 'addEquipment'
      this.addDialogVisiable = true
    },
    // 修改
    async changeInfo(info) {
      this.dialogStatus = 'editEquipment'
      this.addDialogVisiable = true
        // let params = {
        //   id: info.id,
        //   pageNum: 1, //当前页
        //   pageSize: 6, //每页最大数
        // }
        // const { data: res } = await this.$http.get('/lpvideo/lpvideolist', {
        //   params
        // })
        // let infoDetail = res.data.records[0]
      this.addNewsInfo = {
        id: info.id,
        videocover: info.videocover,
        videourl: info.videourl,
        vdescribe: info.vdescribe,
        issuename: info.issuename,
        issuephoto: info.issuephoto,
        issuedate: info.issuedate,
      }
    },
    onEditorBlur() {
      // 失去焦点事件
    },
    onEditorFocus() {
      // 获得焦点事件
    },
    onEditorChange() {
      // 内容改变事件
    },
    //最大数
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getvideoList()
    },
    //pageNum的触发
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getvideoList()
      this.$forceUpdate()
    },

    //删除信息
    async getDelJourn(id) {
      const confirmResult = await this.$confirm(
        '删除后信息将永久删除，是否继续？',
        '提示',
        {
          confirmButtonText: '删除',
          cancelButtonText: '放弃',
        }
      ).catch((err) => err)
      if (confirmResult != 'confirm') {
        //取消
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(
        '/lpvideo/delborker?id=' + id
      )
      if (res.code != 200) {
        return this.$message.error('删除失败!')
      }
      this.$message.success('删除成功!')
      this.getvideoList()
    },
    //将表格数据行居中
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center;'
    },
    //将表格数据列居中
    rowClass({ row, rowIndex }) {
      return 'text-align:center;'
    },
  },
}
</script>
<style lang="less" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px dashed #d9d9d9 !important;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-card {
  height: 720px;
}
.dialog-footer {
  padding-top: 100px;
}
::v-deep .el-form--inline .el-form-item__label {
  float: left;
}
::v-deep .quill-editor .ql-editor {
  height: 500px;
}
</style>
