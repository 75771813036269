<template>
  <section style="padding:50px;">
    <div v-if="wikiList.length === 0">
      <el-form>
        <el-form-item>
          <template>
            <div class="editorBox quill-wrap">
              <quill-editor
                v-model="form.cydetailsStr"
                ref="myQuillEditor"
                :options="editorOption"
                @change="onEditorChange($event)"
              >
              </quill-editor>
            </div>
          </template>
        </el-form-item>
      </el-form>
      <!-- <el-input
        style="width: 500px;"
        type="textarea"
        :rows="8"
        placeholder="请输入楼盘百科"
        v-model="form.cydetailsStr"
      >
      </el-input> -->
    </div>
    <el-button
      v-if="wikiList.length === 0"
      
      @click="handelWiki"
      type="primary"
      style="margin:50px"
    >
      确认
    </el-button>
    <el-table
      :data="wikiList"
      border
      style="width: 100%;"
      :cell-style="{ padding: '5px 0px' }"
      :header-cell-style="headcell"
    >
      <el-table-column prop="id" label="ID" width="1000" align="center">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small"
            >修改</el-button
          >
          <el-button type="text" size="small" @click="del(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      append-to-body
      title="修改楼盘百科"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      style="padding:50px"
    >
      <!-- <el-input
        type="textarea"
        :rows="10"
        placeholder="请输入楼盘百科"
        v-model="edit.cydetailsStr"
      >
      </el-input> -->
      <template>
        <div class="editorBox quill-wrap">
          <quill-editor
            v-model="edit.cydetailsStr"
            ref="myQuillEditor"
            :options="editorOption"
            @change="onEditorChange($event)"
          >
          </quill-editor>
        </div>
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handelEdit()">确 定</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
// require styles 引入样式
import { quillEditor, Quill } from 'vue-quill-editor'
import { container, ImageExtend, QuillWatch } from 'quill-image-extend-module'
Quill.register('modules/ImageExtend', ImageExtend)

import '@/assets/imagePaste'
export default {
  name: 'myWiki',
  components: { quillEditor },
  props: ['lpid', 'isEdit','wikiList'],
  data() {
    let self = this
    return {
      cityall: JSON.parse(window.sessionStorage.getItem('cityAll')).records,
      editorOption: {
        modules: {
          // imageDrop: true,
          ImageExtend: {
            // 如果不作设置，即{}  则依然开启复制粘贴功能且以base64插入
            loading: true,
            name: 'file', // 图片参数名
            size: 3, // 可选参数 图片大小，单位为M，1M = 1024kb
            action: 'https://ddfun.baifun.cn/oss/uploadFileReturnUrl', // 服务器地址, 如果action为空，则采用base64插入图片
            // response 为一个函数用来获取服务器返回的具体图片地址
            // 例如服务器返回{code: 200; data:{ url: 'baidu.com'}}
            // 则 return res.data.url
            response: (res) => {
              return res.filepath
            },

            sizeError: () => {}, // 图片超过大小的回调
            start: () => {}, // 可选参数 自定义开始上传触发事件
            end: () => {}, // 可选参数 自定义上传结束触发的事件，无论成功或者失败
            error: () => {}, // 可选参数 上传失败触发的事件
            success: () => {}, // 可选参数  上传成功触发的事件
            change: (xhr, formData) => {}, // 可选参数 每次选择图片触发，也可用来设置头部，但比headers多了一个参数，可设置formData
          },
          toolbar: {
            // 如果不上传图片到服务器，此处不必配置
            container: container,
            handlers: {
              image: function() {
                // 劫持原来的图片点击按钮事件
                QuillWatch.emit(this.quill.id)
              },
            },
          },
          imagePaste: {
            addImageBlob: function(blob, callback) {
              var formData = new FormData()
              formData.append('file', blob)
              // your upload function, get the uploaded image url, add then
              let config = {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Accept: '*/*',
                },
              }
              self.$http
                .post('/oss/uploadFileReturnUrl', formData, config)
                .then((res) => {
                  var imageUrl = res.data.filepath
                  callback(imageUrl)
                })
            },
          },
        },
      },
      dialogVisible: false,
      wikiList: [],
      form: {
        cydetailsStr: null,
      },
      edit: {
        id: null,
        cydetailsStr: null,
      },
    }
  },
  created() {
  },
  methods: {
    onEditorChange() {
      // 内容改变事件
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => {})
    },
    headcell() {
      return 'text-align: center;background:#eef1f6;'
    },
    // 新增
    async handelWiki() {
      this.form.lpid = this.lpid
      const { data: res } = await this.$http.post(
        'lpcyclopedia/inslpcyclopedia',
        this.form
      )
      if (res.code == '200') {
        this.$message.success('新增成功')
        this.form.cydetailsStr = ''
        this.$emit('getWiki')
      } else {
        this.$message.error('新增失败')
      }
    },
    // 修改
    handleClick(val) {
      this.dialogVisible = true
      this.edit.cydetailsStr = val.cydetailsStr
      this.edit.id = val.id
    },
    async handelEdit() {
      this.edit.lpid = this.lpid
      const { data: res } = await this.$http.put(
        'lpcyclopedia/uplpcyclopedia',
        this.edit
      )
      if (res.code == '200') {
        this.$message.success('修改成功')
        this.dialogVisible = false
        this.$emit('getWiki')
      } else {
        this.$message.error('修改失败')
      }
    },
    // 删除
    async del(val) {
      await this.$http
        .delete('lpcyclopedia/dellpcyclopedia?id=' + val.id)
        .then((res) => {
          if (res.data.code == '200') {
            this.$message.success('删除成功')
            this.$emit('getWiki')
          } else {
            this.$message.error('删除失败')
          }
        })
    },
  },
}
</script>

<style scoped>
::v-deep .quill-editor .ql-editor {
  height: 250px;
}</style>
