import { render, staticRenderFns } from "./myDelivery.vue?vue&type=template&id=32957c1f&scoped=true&"
import script from "./myDelivery.vue?vue&type=script&lang=js&"
export * from "./myDelivery.vue?vue&type=script&lang=js&"
import style0 from "./myDelivery.vue?vue&type=style&index=0&id=32957c1f&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32957c1f",
  null
  
)

export default component.exports