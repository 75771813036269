<template>
  <section>
    <el-row>
      <el-col :span="8">
        <el-form
          ref="form"
          :model="form"
          label-width="100px"
          label-position="right"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="相片类型">
                <el-select
                  clearable
                  v-model="form.xctype"
                  placeholder="请选择相册类型"
                  @change="changevideo"
                   
                  style="width: 200px;"
                  :value="12"
                >
                  <el-option
                    v-for="item in imgTypeList"
                    :label="item.aaa103"
                    :value="item.aaa101"
                    :key="item.aaa101"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="isvideo">
              <el-form-item label="视频封面">
                <el-upload
                  class="avatar-uploader"
                  action="https://ddfun.baifun.cn/oss/uploadFileReturnUrl"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img v-if="videocover" :src="videocover" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item>
            <el-button @click="addHandle"   type="primary" style="">
              新增
            </el-button>
            <!-- <el-button v-if="form.xcid" @click="form={};"   style="margin-left: 80px;padding-left:30px;padding-right:30px;" >
              取消
            </el-button> -->
            <!-- <el-button v-if="form.xcid" @click="addHandle"   type="primary" style="margin-left: 240px;padding-left:30px;padding-right:30px;" >
              确定
            </el-button> -->
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="8" style="margin-bottom: 20px;">
        <upload-img
        ref="uploadImg"
          @addvp="uploadClick"
          @delImg="delImg"
          :isvideo="isvideo"
          v-if="form.xctype"
        ></upload-img>
      </el-col>
    </el-row>
    <el-table
      :data="xc"
      border
      style="width: 100%;"
      :cell-style="{ padding: '5px 0px' }"
      :header-cell-style="headcell"
    >
      <!-- <el-table-column prop="mpms" label="相册描述" align="center">
      </el-table-column> -->
      <el-table-column prop="xctype" label="类型" width="200" align="center">
      </el-table-column>
      <el-table-column prop="cxfmtpurl" label="相册路径" align="center" width="600">
      </el-table-column>
      <el-table-column prop="cxfmtpurl" label="图片展示" align="center">
        <template slot-scope="scope">
          <img
            :src="scope.row.cxfmtpurl"
            alt=""
            srcset=""
            style="width: 80px;height: 40px"
          />
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作" align="center">
        <template slot-scope="scope">
          <!-- <el-button @click="handleClick(scope.row)" type="text" size="small">修改</el-button> -->
          <el-button @click="deletClick(scope.row)" type="text" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </section>
</template>
<script>
import uploadImg from '@/components/upload/uploadImg.vue'
export default {
  components: {
    uploadImg,
  },
  name: 'myVideo',
  props: ['lpid', 'xc'],
  data() {
    return {
      form: {
        xctype: '12',
      },
      myurl: '',
      isvideo: false,
      inputFrom: [],
      imgTypeList: [], // 相册维护下拉类型列表
      videocover: '', // 视频封面
    }
  },
  created() {
    this.getImgType()
    // this.form.xctype = '12' // 默认选中效果图
  },
  methods: {
    // 视频封面上传
    handleAvatarSuccess(res, file) {
      this.videocover = file.response.filepath
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传附件只能是 JPG或PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    // 获取相册维护下拉类型列表
    async getImgType() {
      await this.$http.get('/dic/aaa100?aaa100=XCTYPE').then((res) => {
        this.imgTypeList = res.data.data
      })
    },
    headcell() {
      return 'text-align: center;background:#eef1f6;'
    },
    changevideo(e) {
      if (this.form.xctype == '2') {
        this.isvideo = true
      } else {
        this.isvideo = false
      }
    },
    uploadClick(val) {
      let f = {}
      f.mpms = this.form.mpms
      f.xctype = this.form.xctype
      f.cxfmtpurl = val
      f.lpid = this.lpid
      f.videocover = this.videocover
      this.inputFrom.push(f)
    },

    delImg(val) {
      this.inputFrom = this.inputFrom.filter(
        (item) => item.cxfmtpurl != val.filepath
      )
    },
    async addHandle() {
      await this.$http.post('photo/ins', this.inputFrom).then((res) => {
        if (res.data.code == '200') {
            this.$message.success('新增成功')
            this.$refs.uploadImg.clear()
          } else {
            this.$message.error('新增失败')
          }
      })
      this.inputFrom = []
      this.$emit('getimg')
    },
    handleClick(val) {
      let this_ = this
      this_.myurl = val.cxfmtpurl
      this_.form = val
    },
    async deletClick(info) {
      await this.$http.delete('photo/del?xcid=' + info.xcid).then((res) => {
        if (res.data.code == '200') {
          this.$message.success('删除成功')
          this.$emit('getimg')
        } else {
          this.$message.error('删除失败')
        }
      })
    },
  },
}
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
  border: 1px dashed #d9d9d9 !important;
}
.avatar {
  width: 148px;
  height: 148px;
  display: block;
}
</style>
