<template>
  <div>
    <el-card>
      <!--导航栏-->
      <el-breadcrumb
        separatorClass="el-icon-arrow-right"
        style="font-size: 16px;margin-bottom:30px"
      >
        <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/comhome' }">社区房源管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/comhome' }">社区房源录入/修改</el-breadcrumb-item>
        <el-breadcrumb-item>录入在售/在租房源</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- 主体内容 -->
      <el-tabs type="border-card" v-model="activeName">
        <el-tab-pane label="在售房源" name="first">
          <is-sale :communityid="communityid"></is-sale>
        </el-tab-pane>
        <el-tab-pane label="在租房源" name="second">
          <is-rent :communityid="communityid"></is-rent>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import isSale from './components/isSale.vue'
import isRent from './components/isRent.vue'
export default {
  components: {
    isSale,
    isRent,
  },
  data() {
    return {
      activeName: 'first',
      communityid: '', // 社区id
    }
  },
  created() {
    this.communityid = this.$route.query.id
  },
  methods: {},
}
</script>

<style></style>
