import { render, staticRenderFns } from "./Building.vue?vue&type=template&id=44b7e63d&scoped=true&"
import script from "./Building.vue?vue&type=script&lang=js&"
export * from "./Building.vue?vue&type=script&lang=js&"
import style0 from "./Building.vue?vue&type=style&index=0&id=44b7e63d&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44b7e63d",
  null
  
)

export default component.exports