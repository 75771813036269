<template>
  <div>
    <el-card>
      <!--导航栏-->
      <el-breadcrumb
        separatorClass="el-icon-arrow-right"
        style="font-size: 16px"
      >
        <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>新房管理</el-breadcrumb-item>
        <el-breadcrumb-item>楼盘报名信息查询</el-breadcrumb-item>
      </el-breadcrumb>
      <el-tabs
        type="border-card"
        style="margin-top: 15px;line-height: 10px;font-size: 14px "
      >
        <el-tab-pane label="新房">
          <el-row :gutter="25" style="font-size: 14px;margin:30px 0">
            <!--搜索区域-->
            <!--搜索添加-->
            <el-col :span="21" class="bm-filter">
              楼盘名称:<el-input
                placeholder="请输入楼盘名称"
                v-model="queryInfo.lpname"
                style="margin-left: 10px;width:150px"
              ></el-input>
              手机号码:<el-input
                placeholder="请输入手机号"
                v-model="queryInfo.phone"
                style="margin-left: 10px;width:150px"
              ></el-input>
              姓名:<el-input
                placeholder="请输入姓名"
                v-model="queryInfo.name"
                style="margin-left: 10px;width:150px"
              ></el-input>
              销售姓名:<el-input
                placeholder="请输入销售姓名"
                v-model="queryInfo.figurename"
                style="margin-left: 10px;width:150px"
              ></el-input>
              留资入口:<el-select
                clearable
                placeholder="请选择留资入口"
                v-model="queryInfo.inlet"
                style="margin-left: 10px;width:150px"
              >
                <el-option label="领取优惠" value="领取优惠"></el-option>
                <el-option label="领取项目资料" value="领取项目资料"></el-option>
                <el-option label="专属经纪人留资" value="专属经纪人留资"></el-option>
                <el-option label="新人首次到访礼" value="新人首次到访礼"></el-option>
                <el-option label="限时在线预约特价" value="限时在线预约特价"></el-option>
                <!-- <el-option label="预约查看户型图片" value="预约查看户型图片"></el-option> -->
              </el-select>
            </el-col>
            <!--按钮-->
            <el-col :span="1" class="bm-filter-btn">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="getByApplyList"
                >查询</el-button
              >
              <!-- <el-button
            type="primary"
            icon="el-icon-circle-plus"
            @click="handelAdd()"
            >新增</el-button
          > -->
            </el-col>
          </el-row>

          <!--楼盘报名信息主体内容-->
          <template>
            <el-table
              :data="applyList"
              stripe
              style="width: 100%;line-height: 32px"
            >
              <el-table-column
                prop="name"
                label="姓名"
                width="100"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="phone"
                label="手机号码"
                width="150"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="cip"
                label="ip"
                width="150"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="qcellcore"
                label="号码归属地"
                width="100"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="bmsj"
                width="200"
                label="报名时间"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="lpname"
                label="报名楼盘"
                width="250"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="figurename"
                label="所属销售"
                width="200"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="inlet"
                label="留资入口"
                width="200"
                align="center"
              ></el-table-column>
              <el-table-column label="操作" align="center" v-if="zhtype == 1">
                <template slot-scope="scope">
                  <el-button
                    class="el-icon-edit"
                    type="primary"
                    @click="changeInfo(scope.row)"
                    >修改</el-button
                  >
                  <el-button
                    class="el-icon-delete"
                    type="danger"
                    @click="getDel(scope.row.bmid)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </template>
          <!-- 新增或修改弹窗 -->
          <el-dialog
            :title="titleMap[dialogStatus]"
            width="40%"
            :visible.sync="Dialogvisible"
            @close="DialogClosed"
          >
            <el-form
              :model="pullForm"
              ref="pullFormRef"
              :rules="pullFormRules"
              label-width="80px"
            >
              <!-- 楼盘名称 -->
              <el-form-item label="姓名" prop="name">
                <el-input v-model="pullForm.name"></el-input>
              </el-form-item>
              <!-- 联系电话 -->
              <el-form-item label="手机号码" prop="phone">
                <el-input v-model="pullForm.phone"></el-input>
              </el-form-item>
              <el-form-item label="报名时间" prop="bmsj">
                <el-date-picker
                  size="small"
                  v-model="pullForm.bmsj"
                  align="right"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                  style="width: 100%"
                >
                </el-date-picker>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="getPullAll">确认</el-button>
              <el-button @click="Dialogvisible = false">取消</el-button>
            </span>
          </el-dialog>
          <!--分页显示-->
          <div>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryInfo.pageNum"
              :page-sizes="[5, 10, 20, 30, 50]"
              :page-size="queryInfo.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>
<script>
export default {
  created() {
    this.getByApplyList()
    this.zhtype = localStorage.getItem('zhtype')
  },
  data() {
    return {
      zhtype: '',
      //查询信息实体
      queryInfo: {
        query: '', //查询信息
        pageNum: 1, //当前页
        pageSize: 5, //每页最大数
        phone: '',
        khphone: '',
        name: '',
        figurename: '',
      },
      applyList: [],
      total: 0,
      titleMap: {
        addEquipment: '新增报名信息',
        editEquipment: '修改报名信息',
      },
      //新增和编辑弹框标题
      dialogStatus: '',
      Dialogvisible: false, //弹窗的初始状态
      //弹窗表单验证
      pullForm: {
        name: '', //姓名
        phone: '', //联系电话
        bmsj: '', // 报名时间
      },
      pullFormRules: {
        name: { required: true, message: '请输入姓名', trigger: 'blur' },
        phone: { required: true, message: '请输入手机号', trigger: 'blur' },
        bmsj: { required: true, message: '请选择报名时间', trigger: 'blur' },
      },
    }
  },
  methods: {
    async getByApplyList() {
      const { data: res } = await this.$http.get('/apply/list', {
        params: this.queryInfo,
      })
      this.applyList = res.data.records
      this.total = res.data.total
    },
    //删除信息
    async getDel(id) {
      const confirmResult = await this.$confirm(
        '删除后用户信息将永久删除，是否继续？',
        '提示',
        {
          confirmButtonText: '删除',
          cancelButtonText: '放弃',
        }
      ).catch((err) => err)
      if (confirmResult != 'confirm') {
        //取消
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete('apply/del?bmid=' + id)
      if (res.code != 200) {
        return this.$message.error('删除失败!')
      }
      this.$message.success('删除成功!')
      this.getByApplyList()
    },
    //最大数
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getByApplyList()
    },
    //pageNum的触发
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getByApplyList()
    },
    //新增弹窗的显示
    handelAdd() {
      this.dialogStatus = 'addEquipment'
      this.Dialogvisible = true
      this.pullForm = {
        name: '', //姓名
        phone: '', //联系电话
        bmsj: '', // 报名时间
      }
    },
    //原数据显示在修改弹窗
    async changeInfo(info) {
      // const {data:res} = await this.$http.get('/pull/list/byid/'+info.lyid);
      this.dialogStatus = 'editEquipment'
      this.Dialogvisible = true
      this.pullForm = info
      this.getByApplyList()
    },
    //form框监听
    DialogClosed() {
      this.$refs.pullFormRef.resetFields()
    },
    //数据弹窗操作
    getPullAll() {
      this.$refs.pullFormRef.validate(async (valid) => {
        if (!valid) return
        //如果title是addEquipment则跳进新增执行新增方法
        if (this.dialogStatus === 'addEquipment') {
          const { data: res } = await this.$http.post(
            'apply/ins',
            this.pullForm
          )
          if (res.code != 200) {
            return this.$message.error('操作失败')
          }
        } else {
          const { data: res } = await this.$http.put('apply/upl', this.pullForm)
          if (res.code != 200) {
            return this.$message.error('操作失败')
          }
        }
        this.$message.success('操作成功')
        this.Dialogvisible = false
        this.getByApplyList()
      })
    },
  },
}
</script>
<style lang="less" scoped>
.bm-filter .el-input {
  width: 200px;
  margin-right: 5%;
}
.bm-filter-btn .el-button {
  width: 100px;
}
</style>
