<template>
    <div>
        <h1>快速直达维护</h1>
    </div>
</template>
<script>
    export default{
        data(){
            return{

            }
        }
    }
</script>
<style lang="less" scoped>

</style>