import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'

//导入axios
import axios from 'axios'
import 'lib-flexible/flexible.js'

import store from './store'
let options = {
  fullscreenEl: false,
}
Vue.use(preview, options)
Vue.use(preview)
//挂载axios
Vue.prototype.$http = axios
//设置访问根路径
// axios.defaults.baseURL= "http://47.106.8.146:8888/"
axios.defaults.baseURL = 'https://ddfun.baifun.cn'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
Vue.config.silent = true // 取消控制台所有日志打印，默认值为false
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
