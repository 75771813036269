<template>
  <div>
    <el-card>
      <!--导航栏-->
      <el-breadcrumb
        separatorClass="el-icon-arrow-right"
        style="font-size: 16px"
      >
        <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>社区房源管理</el-breadcrumb-item>
        <el-breadcrumb-item>社区经纪人信息维护</el-breadcrumb-item>
      </el-breadcrumb>
      <!--搜索添加区域-->
      <table style="margin:30px 0">
        <tr style="line-height: 70px;font-size: 14px">
          <!-- <td style="display: flex;margin-right: 100px">
            <span style="width:200px">关键字搜索:</span
            ><el-input
              placeholder="请输入"
              v-model="queryInfo.equestion"
            ></el-input>
          </td>
          <td>
            <el-button type="primary" icon="el-icon-search" @click="getList"
              >查询</el-button
            > -->
          <el-button type="primary" icon="el-icon-circle-plus" @click="addInfo" v-if="zhtype == 1"
            >新增</el-button
          >
          <!-- </td> -->
        </tr>
      </table>

      <!--主体内容表单-->
      <template>
        <el-table
          :data="list"
          border
          style="width: 100%;line-height: 12px; font-size: 18px"
          :cell-style="cellStyle"
          :header-cell-style="rowClass"
        >
          <el-table-column
            prop="brokername"
            label="姓名"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="brokernumber"
            label="电话"
            width="200"
          ></el-table-column>
          <el-table-column prop="brokerphoto" label="头像" align="center" width="200">
            <template slot-scope="scope">
              <img
                :src="scope.row.brokerphoto"
                alt=""
                srcset=""
                style="width: 60px;height: 60px"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="zysq"
            label="主营商圈"
            width="250"
          ></el-table-column>
          <el-table-column
            prop="workingtime"
            label="从业年数"
            width="200"
          ></el-table-column>
          <el-table-column label="操作" v-if="zhtype == 1">
            <template slot-scope="scope">
              <el-button
                class="el-icon-edit"
                type="primary"
                @click="changeInfo(scope.row)"
                >修改</el-button
              >
              <el-button
                class="el-icon-delete"
                type="danger"
                @click="delInfo(scope.row.brokerid)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
      <!-- 弹窗 -->
      <el-dialog
        width="70%"
        :title="titleMap[dialogStatus]"
        :visible.sync="DialogVisiable"
        @close="cancel"
        :before-close="handleClose"
      >
        <el-form
          :inline="true"
          :model="dialogInfo"
          ref="aaddFormRef"
          label-width="150px"
        >
          <el-row>
            <el-col :span="8">
              <el-form-item label="头像">
                <el-upload
                  class="avatar-uploader"
                  action="https://ddfun.baifun.cn/oss/uploadFileReturnUrl"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img
                    v-if="dialogInfo.brokerphoto"
                    :src="dialogInfo.brokerphoto"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="姓名" prop="brokername">
                <el-input
                  v-model="dialogInfo.brokername"
                  placeholder="请输入姓名"
                  style="width:200px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="电话" prop="brokernumber">
                <el-input
                  v-model="dialogInfo.brokernumber"
                  placeholder="请输入电话"
                  style="width:200px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="职位" prop="posts">
                <el-input
                  v-model="dialogInfo.posts"
                  placeholder="请输入职位"
                  style="width:200px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="从业年数" prop="workingtime">
                <el-input
                  v-model="dialogInfo.workingtime"
                  placeholder="请输入从业年数"
                  style="width:200px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="评分" prop="score">
                <el-input
                  v-model="dialogInfo.score"
                  placeholder="请输入评分"
                  style="width:200px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="专业评分" prop="professional">
                <el-input
                  v-model="dialogInfo.professional"
                  placeholder="请输入专业评分"
                  style="width:200px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="主营商圈" prop="zysq">
                <el-input
                  v-model="dialogInfo.zysq"
                  placeholder="请输入主营商圈"
                  style="width:200px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="个人介绍">
                <el-input
                  style="width: 300px;"
                  
                  type="textarea"
                  :rows="8"
                  v-model="dialogInfo.information"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="confirm">确认</el-button>
        </span>
      </el-dialog>
      <!--分页-->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      zhtype: '',
      dialogStatus: '', //新增弹窗状态
      DialogVisiable: false,
      titleMap: {
        addEquipment: '新增经纪人信息',
        editEquipment: '修改经纪人信息',
      },
      //查询信息实体
      queryInfo: {
        ctid: null,
        pageNum: 1, //当前页
        pageSize: 6, //每页最大数
        brokertype: 1, // 经纪人类型（1:社区经纪人、2:别墅文旅经纪人））
      },
      dialogInfo: {
        brokerid: null,
        brokername: null,
        brokernumber: null,
        brokerphoto: null,
        professional: null,
        score: null,
        workingtime: null,
        zysq: null,
        information: null,
        posts: null,
        brokertype: 1,
      },
      total: 0, //总记录数
      list: [], // 列表
    }
  },
  created() {
    this.getList()
    this.zhtype = localStorage.getItem('zhtype')
  },
  methods: {
    // 头像上传
    handleAvatarSuccess(res, file) {
      this.dialogInfo.brokerphoto = file.response.filepath
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error('上传附件只能是 JPG或PNG 格式!')
      }
      if (!isLt5M) {
        this.$message.error('上传附件大小不能超过 5M!')
      }
      return isJPG && isLt5M
    },
    //查询列表信息
    async getList() {
      const { data: res } = await this.$http.get('/borker/borkerlist', {
        params: this.queryInfo,
      })
      this.list = res.data.records
      this.total = res.data.total
    },
    // 关闭弹窗
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => {})
    },
    // 取消
    cancel() {
      this.DialogVisiable = false
      this.dialogInfo = {
        brokerid: null,
        brokername: null,
        brokernumber: null,
        brokerphoto: null,
        professional: null,
        score: null,
        workingtime: null,
        zysq: null,
        information: null,
        posts: null,
        brokertype: 1,
      }
    },
    // 确认
    async confirm() {
      if (
        !this.dialogInfo.brokername ||
        !this.dialogInfo.brokernumber ||
        !this.dialogInfo.brokerphoto ||
        !this.dialogInfo.professional ||
        !this.dialogInfo.score ||
        !this.dialogInfo.workingtime ||
        !this.dialogInfo.zysq ||
        !this.dialogInfo.information ||
        !this.dialogInfo.posts
      ) {
        this.$message.error('请完整所有信息！')
        return
      }
      // 新增
      if (this.dialogStatus == 'addEquipment') {
        const { data: res } = await this.$http.post('/borker/insborker', {
          brokername: this.dialogInfo.brokername,
          brokernumber: this.dialogInfo.brokernumber,
          brokerphoto: this.dialogInfo.brokerphoto,
          professional: this.dialogInfo.professional,
          score: this.dialogInfo.score,
          workingtime: this.dialogInfo.workingtime,
          zysq: this.dialogInfo.zysq,
          information: this.dialogInfo.information,
          posts: this.dialogInfo.posts,
          brokertype: 1,
        })
        // 修改
      } else if (this.dialogStatus == 'editEquipment') {
        const { data: res } = await this.$http.put('/borker/uplborker', {
          brokerid: this.dialogInfo.brokerid,
          brokername: this.dialogInfo.brokername,
          brokernumber: this.dialogInfo.brokernumber,
          brokerphoto: this.dialogInfo.brokerphoto,
          professional: this.dialogInfo.professional,
          score: this.dialogInfo.score,
          workingtime: this.dialogInfo.workingtime,
          zysq: this.dialogInfo.zysq,
          information: this.dialogInfo.information,
          posts: this.dialogInfo.posts,
          brokertype: 1,
        })
      }
      this.DialogVisiable = false
      this.queryInfo.pageNum = 1
      this.getList()
      this.dialogInfo = {
        brokerid: null,
        brokername: null,
        brokernumber: null,
        brokerphoto: null,
        professional: null,
        score: null,
        workingtime: null,
        zysq: null,
        information: null,
        posts: null,
        brokertype: 1,
      }
    },
    // 新增
    addInfo() {
      this.dialogStatus = 'addEquipment'
      this.DialogVisiable = true
    },
    // 修改
    async changeInfo(info) {
      this.dialogStatus = 'editEquipment'
      this.DialogVisiable = true
      this.dialogInfo = {
        brokerid: info.brokerid,
        brokername: info.brokername,
        brokernumber: info.brokernumber,
        brokerphoto: info.brokerphoto,
        professional: info.professional,
        score: info.score,
        workingtime: info.workingtime,
        zysq: info.zysq,
        information: info.information,
        posts: info.posts,
        brokertype: 1,
      }
    },
    //删除
    async delInfo(id) {
      const confirmResult = await this.$confirm(
        '删除后用户信息将永久删除，是否继续？',
        '提示',
        {
          confirmButtonText: '删除',
          cancelButtonText: '放弃',
        }
      ).catch((err) => err)
      if (confirmResult != 'confirm') {
        //取消
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(
        'borker/delborker?brokerid=' + id
      )
      if (res.code != 200) {
        return this.$message.error('删除失败!')
      }
      this.$message.success('删除成功!')
      this.getList()
    },
    onEditorBlur() {
      // 失去焦点事件
    },
    onEditorFocus() {
      // 获得焦点事件
    },
    onEditorChange() {
      // 内容改变事件
    },
    //最大数
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getList()
    },
    //pageNum的触发
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getList()
      this.$forceUpdate()
    },
    //将表格数据行居中
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center;'
    },
    //将表格数据列居中
    rowClass({ row, rowIndex }) {
      return 'text-align:center;'
    },
  },
}
</script>
<style lang="less" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px dashed #d9d9d9 !important;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-card {
  height: 720px;
}
.dialog-footer {
  padding-top: 100px;
}
::v-deep .el-form--inline .el-form-item__label {
  float: left;
}
::v-deep .quill-editor .ql-editor {
  height: 500px;
}
</style>
