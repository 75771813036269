import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import{Message} from 'element-ui'
Vue.prototype.$message = Message;
// Vue.prototype.$axios = axios
import Login from '../components/Login.vue' //引入Login页面
import Index from '../components/Index.vue'   //引入主页面
import Welcome from '../components/Welcome.vue' //引入进入效果页面
import Building from '../components/houses/Building.vue' //引入楼盘区域维护
import Bridalch from '../components/houses/Bridalch.vue' //引入新房区域维护
import Storeand from '../components/houses/Storeand.vue' //引入商铺写字楼维护
import Homepage from '../components/houses/Homepage.vue' //引入新闻轮播图维护
import Property from '../components/houses/Property.vue' //引入楼盘新闻维护
import Customer from '../components/houses/Customer.vue' //引入客户信息维护
import Apply from '../components/houses/Apply.vue'       //引入楼盘报名信息查询
import Pullapage from '../components/houses/Pullapage.vue' //引入楼盘拉页维护
import Mainten from '../components/houses/Mainten.vue'      //引入楼盘留言维护
import Second from '../components/resold/Second.vue'      //引入二手房维护
import PriceArea from '../components/houses/PriceArea.vue'//引入价格面积区间维护
import Household from '../components/homes/Household.vue' //引入快速直达维护
import Formaction from '../components/homes/Formaction.vue' //引入家居资讯维护
import Wiki from '../components/houses/Wiki.vue' // 引入互动百科维护
import CommunityIndex from '../components/community/CommunityIndex.vue' // 引入社区房源
import CommunitySale from '../components/community/CommunitySale.vue' // 引入社区房源经纪人
import EnterHouse from '../components/community/EnterHouse.vue'
import UserInfo from '../components/user/userInfo.vue'
import AllVideo from '../components/video/allVideo.vue'

Vue.use(VueRouter)

const routes = [
    {
        path:"/",
        redirect:"/login",
    },
    {
        path:"/login",
        component:Login
    },
    {
        path: "/index",
        component: Index,
        redirect:"bridalchambermaintenance",
        children:[
            {path:"/welcome",component:Welcome,},
            {path:"/buildingareamaintenance",component:Building,},
            {path:"/bridalchambermaintenance",component:Bridalch,},
            {path:"/priceArea",component:PriceArea,},
            {path:"/storeandofficebuildingmaintenance",component:Storeand,},
            {path:"/homepagerotationchartmaintenance",component:Homepage,},
            {path:"/propertynewsmaintenance",component:Property,},
            {path:'/customerinformationmaintenance',component:Customer,},
            {path:'/propertyregistrationinformationenquiry',component:Apply,},
            {path:'/buildingpullpagemaintenance',component:Pullapage,},
            {path:'/maintenanceofbuildingmessages',component:Mainten,},
            {path:"/second-handhousemaintenance",component:Second,},
            {path:"/householdmanagement",component:Household,},
            {path:"/householdinformation",component:Formaction},
            {path:"/encyclopediamaintenance",component:Wiki},
            {path:"/comhome",component:CommunityIndex},
            {path:"/combrokerinformation",component:CommunitySale},
            {path:"/enterHouse",component:EnterHouse},
            {path: "/userinformation", component:UserInfo},
            {path: "/lpvideo", component:AllVideo},
            {path: "*",component: Welcome}
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})
router.beforeEach((to, from, next) => {
    if(to.path === "/login"){
        next()
    }else{
        if(localStorage.getItem('token')){
            let token = localStorage.getItem('token')
            axios.post("/admin/getMessage",{}, {
                headers: {
                    "Accept": "*/*",
                    "token": token
                }
            })
            .then(res => {
                if(res.data.code == "200"){
                    next()
                }else{
                    Vue.prototype.$message.error("token异常")
                    next("/login")
                } 
            })
            .catch( () => {
                Vue.prototype.$message.error("token异常")
            })
        }else{
            next("/login")
            Vue.prototype.$message.error("token不存在")
        }
    }

})
export default router


